import { RouteComponentProps } from "../../types/RouteComponents";

export const RouteFight = ({ instruction }: RouteComponentProps) => {
  const { content, directions } = instruction;

  return (
    <div>
      <p className="fight">{content}</p>
      {directions?.map((direction, index) => (
        <p key={`${content}-${index}`} className="fight-direction">
          {direction}
        </p>
      ))}
      <br />
    </div>
  );
};
