import { Link } from "react-router-dom";
import { useEffect } from "react";
import { PageProps } from "../types/PageProps";

export default function Home({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Home");
  });
  return (
    <>
      <h1 className="main-heading">Paper Mario Archives v2</h1>
      <p className="main-paragraph">
        Paper Mario Archives is a website dedicated to documenting the many tricks and glitches used in Paper Mario 64
        Speedruns.
      </p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Tips">
          Tips
        </Link>
      </h1>
      <p className="home-link-description">
        Getting started? See our <Link to="/Tips">Tips</Link> page for help!
      </p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Tricks">
          Tricks
        </Link>
      </h1>
      <p className="home-link-description">A list of all tricks organized alphabetically.</p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Categories">
          Categories
        </Link>
      </h1>
      <p className="home-link-description">More about the main speedrun categories of Paper Mario.</p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Routes">
          Routes
        </Link>
      </h1>
      <p className="home-link-description">Every route for every main speedrun category.</p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Bingo">
          Bingo
        </Link>
      </h1>
      <p className="home-link-description">A detailed guide to Bingo and with a card generator.</p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Mechanics">
          Mechanics
        </Link>
      </h1>
      <p className="home-link-description">A list of all techniques and glitches in Paper Mario.</p>
      <h1 className="home-heading">
        <Link className="home-link" to="/Resources">
          Resources
        </Link>
      </h1>
      <p className="home-link-description">Find more Paper Mario resources here!</p>
      <p className="home-link-description">
        <Link to="/Changelog">View the latest changes to the website here.</Link>
      </p>
    </>
  );
}
