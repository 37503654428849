import { useEffect } from "react";
import { tricks } from "../data/tricks";
import { Link, useLocation } from "react-router-dom";
import { PageProps } from "../types/PageProps";
import { Trick } from "../types/Trick";

export default function TrickPage({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });

  const path = useLocation().pathname.split("/")[2];
  const trick = tricks.find((trick) => trick.path === path && trick?.description) as Trick;

  return (
    <>
      <h1 className="heading">{trick.name}</h1>
      <dl className="list">
        {trick.description && (
          <>
            <dt className="detail-title">Description</dt>
            <dd>{trick.description}</dd>
          </>
        )}
        {trick.versions && (
          <>
            <dt className="detail-title">Versions</dt>
            <dd>{trick.versions}</dd>
          </>
        )}
        {trick.categories && (
          <>
            <dt className="detail-title">Used in</dt>
            {trick.categories.map((category) => (
              <dd key={category.name}>
                <Link to={category.path}>{category.name}</Link>
              </dd>
            ))}
          </>
        )}
        {trick.discoveredBy && (
          <>
            <dt className="detail-title">Discovered by</dt>
            <dd>{trick.discoveredBy}</dd>
          </>
        )}
        {trick.videoTutorials && (
          <>
            <dt className="detail-title">Video Tutorials</dt>
            {trick.videoTutorials.map((video, i) => (
              <dd key={`${i}.${video.author}`}>
                <a target="_blank" rel="noopener noreferrer" href={video.url}>
                  {video.author}
                </a>
              </dd>
            ))}
          </>
        )}
      </dl>
      {trick.content}
    </>
  );
}
