import { useState } from "react";
import { Instruction, InstructionTypes, Stat } from "../../types/RouteComponents";
import { RouteInstruction } from "./RouteInstruction";

type RouteEditorInstructionProps = {
  instruction: Instruction;
  index: number;
  route: Instruction[];
  setRoute: (route: Instruction[]) => void;
  decrementControlPosition: () => void;
  variables: string[];
};

export const RouteEditorInstruction = ({
  instruction,
  index,
  route,
  setRoute,
  decrementControlPosition,
  variables,
}: RouteEditorInstructionProps) => {
  const unusedVariables = variables.filter((variable) => (instruction.conditions || {})[variable] === undefined);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editField, setEditField] = useState<string>(instruction.content ?? "");
  const [bold, setBold] = useState<boolean>(instruction.bold ?? false);
  const [space, setSpace] = useState<boolean>(instruction.space ?? false);
  const [turns, setTurns] = useState<number>(instruction.directions?.length ?? 1);
  const [directions, setDirections] = useState<string[]>(instruction.directions ?? []);
  const [stat, setStat] = useState<Stat>(instruction.stat ?? "hp");
  const [hp, setHP] = useState<number>(instruction.hp ?? 10);
  const [fp, setFP] = useState<number>(instruction.fp ?? 5);
  const [bp, setBP] = useState<number>(instruction.bp ?? 3);
  const [condition, setCondition] = useState<string>(unusedVariables[0] || "");
  const [conditionValue, setConditionValue] = useState<boolean>(true);

  const isText = instruction.type === InstructionTypes.Text;
  const isFight = instruction.type === InstructionTypes.Fight;
  const isHeading = instruction.type === InstructionTypes.Heading;
  const isLevelUp = instruction.type === InstructionTypes.LevelUp;
  const isRebadge = instruction.type === InstructionTypes.Rebadge;
  const isImage = instruction.type === InstructionTypes.Image;

  const toggleEditMode = () => setEditMode(!editMode);
  const updateRoute = () => {
    let newRoute = [];
    newRoute.push(...route);
    if (isText) {
      newRoute[index].bold = bold;
      newRoute[index].space = space;
    }
    if (isText || isFight || isHeading || isImage) {
      newRoute[index].content = editField;
    }
    if (isFight || isRebadge) {
      newRoute[index].directions = directions.slice(0, turns);
    }
    if (isLevelUp) {
      newRoute[index].stat = stat;
      newRoute[index].hp = hp;
      newRoute[index].fp = fp;
      newRoute[index].bp = bp;
    }
    setRoute(newRoute);
    setEditMode(!editMode);
  };
  const deleteInstruction = () => {
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(index, 1);
    setRoute(newRoute);
    setEditMode(!editMode);
    decrementControlPosition();
  };
  const addCondition = () => {
    let newRoute = [];
    newRoute.push(...route);
    let newConditions = newRoute[index].conditions || {};
    newConditions[condition] = conditionValue;
    newRoute[index].conditions = newConditions;
    setRoute(newRoute);
    setCondition(unusedVariables[0] || "");
  };
  const removeCondition = (name: string) => {
    let newRoute = [];
    newRoute.push(...route);
    let newConditions = newRoute[index].conditions || {};
    delete newConditions[name];
    newRoute[index].conditions = newConditions;
    setRoute(newRoute);
    setCondition(unusedVariables[0] || "");
  };

  return (
    <>
      <div
        className="route-editor-instruction"
        style={{
          padding: `${isFight || isLevelUp || isHeading || isRebadge ? "0.5rem" : "0"} 0 0 0`,
        }}
      >
        <label className={"instruction-label" + (editMode && !isFight ? " edit-mode-label" : "")}>{index}: </label>
        {!editMode && (
          <>
            <button className="route-editor-control" onClick={toggleEditMode}>
              Edit
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: isText ? "row" : "column",
              }}
            >
              <RouteInstruction instruction={instruction} />
            </div>
          </>
        )}
        {editMode && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                minHeight: "2.5rem",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <button className="route-editor-control" onClick={toggleEditMode}>
                Cancel
              </button>
              {!isLevelUp && !isRebadge && (
                <input
                  className={`route-${isFight ? "fight-" : ""}input`}
                  onChange={(e) => setEditField(e.target.value)}
                  defaultValue={instruction.content}
                  onKeyDown={(e) => {
                    e.key === "Enter" && updateRoute();
                  }}
                ></input>
              )}
              {isText && (
                <>
                  <label className="route-checkbox-label">Bold?</label>
                  <input
                    type="checkbox"
                    className="route-checkbox"
                    onChange={() => {
                      setBold(!bold);
                    }}
                    checked={bold}
                  />
                  <label className="route-checkbox-label">Space after?</label>
                  <input
                    type="checkbox"
                    className="route-checkbox"
                    onChange={() => {
                      setSpace(!space);
                    }}
                    checked={space}
                  />
                </>
              )}
              {isFight && (
                <>
                  <label className="route-checkbox-label">Turns:&nbsp;&nbsp;</label>
                  <select
                    className="route-select"
                    defaultValue={turns}
                    onChange={(e) => setTurns(Number(e.target.value))}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((number) => (
                      <option key={`turns-${number}`} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {Array.from(Array(turns).keys()).map((direction, index) => (
                      <div key={`direction-${index}`} style={{ display: "flex", flexDirection: "row" }}>
                        <label className="route-checkbox-label">{index + 1}:&nbsp;&nbsp;</label>
                        <input
                          type="text"
                          className="route-fight-input"
                          onChange={(e) => {
                            let newDirections = [];
                            newDirections.push(...directions);
                            newDirections[index] = e.target.value;
                            setDirections(newDirections);
                          }}
                          onKeyDown={(e) => {
                            e.key === "Enter" && updateRoute();
                          }}
                          defaultValue={directions[index]}
                        ></input>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {isLevelUp && (
                <>
                  <label className="route-checkbox-label">Stat?&nbsp;&nbsp;</label>
                  <select
                    className="route-select"
                    defaultValue={stat}
                    onChange={(e) => setStat(e.target.value as Stat)}
                  >
                    <option value="hp">HP</option>
                    <option value="fp">FP</option>
                    <option value="bp">BP</option>
                  </select>
                  <label className="route-checkbox-label">HP:&nbsp;&nbsp;</label>
                  <select className="route-select" defaultValue={hp} onChange={(e) => setHP(Number(e.target.value))}>
                    {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65].map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <label className="route-checkbox-label">FP:&nbsp;&nbsp;</label>
                  <select className="route-select" defaultValue={fp} onChange={(e) => setFP(Number(e.target.value))}>
                    {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65].map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <label className="route-checkbox-label">BP:&nbsp;&nbsp;</label>
                  <select className="route-select" defaultValue={bp} onChange={(e) => setBP(Number(e.target.value))}>
                    {[3, 6, 9, 12, 15, 18, 21, 24, 27, 30].map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {isRebadge && (
                <>
                  <label className="route-checkbox-label">Directions:&nbsp;&nbsp;</label>
                  <select
                    className="route-select"
                    defaultValue={turns}
                    onChange={(e) => setTurns(Number(e.target.value))}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((number) => (
                      <option key={`turns-${number}`} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {Array.from(Array(turns).keys()).map((direction, index) => (
                      <div key={`direction-${index}`} style={{ display: "flex", flexDirection: "row" }}>
                        <label className="route-checkbox-label">{index + 1}:&nbsp;&nbsp;</label>
                        <input
                          type="text"
                          className="route-fight-input"
                          onChange={(e) => {
                            let newDirections = [];
                            newDirections.push(...directions);
                            newDirections[index] = e.target.value;
                            setDirections(newDirections);
                          }}
                          defaultValue={directions[index]}
                        ></input>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <button className="route-editor-control" onClick={updateRoute}>
                Update
              </button>
              <button className="route-editor-control" onClick={deleteInstruction}>
                Delete
              </button>
            </div>
            {unusedVariables.length > 0 && (
              <div
                style={{
                  display: "flex",
                  minHeight: "2.5rem",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    padding: "0 0.5rem 0 0",
                  }}
                >
                  Variable:
                </label>
                <select
                  className="route-select"
                  defaultValue={variables[0]}
                  onChange={(e) => setCondition(e.target.value)}
                >
                  {unusedVariables.map((variable) => (
                    <option key={`variable-${variable}`} value={variable}>
                      {variable}
                    </option>
                  ))}
                </select>
                <label
                  style={{
                    padding: "0 0.5rem 0 0",
                  }}
                >
                  Show this instruction when "{condition}" is:
                </label>
                <select
                  className="route-select"
                  defaultValue="true"
                  onChange={(e) => setConditionValue(e.target.value === "true")}
                >
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <button className="route-editor-control" style={{ width: "8rem" }} onClick={addCondition}>
                  Add Condition
                </button>
              </div>
            )}
            {Object.keys(instruction.conditions || {}).length > 0 && (
              <dl className="route-list">
                <dt className="detail-title" style={{ margin: "0 0 0.5rem 0" }}>
                  Conditions:
                </dt>
                {Object.entries(instruction.conditions || {}).map(([name, value]) => (
                  <li style={{ margin: "0 0 0.5rem 0.5rem", display: "flex", alignItems: "center" }}>
                    <span style={{ minWidth: "12rem" }}>
                      {name}: {`${value}`}
                    </span>
                    <button
                      className="route-editor-control"
                      style={{ width: "8rem" }}
                      onClick={() => removeCondition(name)}
                    >
                      Remove Condition
                    </button>
                  </li>
                ))}
              </dl>
            )}
          </div>
        )}
      </div>
      {instruction.space && <br />}
    </>
  );
};
