import { Link } from "react-router-dom";

export const allBossesBeginner = (
  <>
    <h1 className="heading">Prologue</h1>

    <dl className="list">
      <dt className="route-instruction">
        - Do <Link to="/Quick_Jump">Quick Jumps</Link> on Bowser by pressing A when Mario squats before jumping
      </dt>
      <dt className="route-instruction">- Talk to Goompa on the balcony</dt>
      <dt className="route-instruction">- Talk to Goompapa by the gate</dt>
      <dt className="route-instruction">- Head to the balcony</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 2 bush coins [2 coins]</dt>
      <dt className="route-instruction">
        - Hammer from the leftmost bush (get it from the front of the bush, it's faster)
      </dt>
      <dt className="route-instruction-bold">- 10 coins from the brick block on the ground. [12 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Defeat Jr. Troopa by spamming Jump. (Don't forget your <Link to="/Quick_Jump">Quick Jumps</Link>!)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hammer coin block for 1 coin. [13 coins]</dt>
      <dt className="route-instruction-bold">- Fire Flower and 4 trampoline coins. [17 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Power Jump Tutorial</dt>
      <dt className="route-instruction-bold">- Equip Power Jump immediately</dt>
      <dt className="route-instruction-bold">- Two coin blocks on the next screen. [19 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump Red / Headbonk Red</dd>
      <dd className="route-instruction-bold">T2: Power Jump Blue / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba King</dt>
      <dd className="route-instruction-bold">T1: Power Jump King / Headbonk Tree</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Headbonk</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the shop in Toad Town</dt>
      <dt className="route-instruction-bold">- Buy a Fire Flower and a Fright Jar (Fire Flower first) [4 coins]</dt>
      <dt className="route-instruction">- Go to Shooting Star Summit</dt>
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Action Command Tutorial</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Magikoopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Do Nothing - (Blocking saves a few frames!)</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Black_Toad_Skip">Black Toad Skip</Link> or <Link to="/Merlon_Skip">Merlon Skip</Link>
      </dt>
      <dt className="route-instruction">
        - Alternatively, go speak to the black toads, then go speak to Merlon, then go speak to the black toads again
      </dt>
    </dl>

    <h1 className="heading">Chapter 1</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 1 coin block [5 coins]</dt>
      <dt className="route-instruction-bold">- Fright Jar</dt>
      <dt className="route-instruction-bold">- POW Block</dt>
      <dt className="route-instruction-bold">- 10 coin block [15 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Koopa Village and go to the 5th house</dt>
      <dt className="route-instruction">
        - It helps to look at the fuzzies and count the amount of bounces that they do to ensure you never fail the
        minigame.{" "}
      </dt>
      <dd className="route-instruction">
        If they bounce an even number of times, it's guaranteed to not be in the tree to the left or right of its
        original position.{" "}
      </dd>
      <dd className="route-instruction">
        If they bounce and odd number of times, it's guaranteed to be to the left or right of its original position,
        unless it bounces from the far left to the far right or vise versa
      </dd>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: Fire Flower (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Get a coin from the bush left of the Save Block while leaving Koopa Village. [16 coins]
      </dt>
      <dt className="route-instruction-bold">- 5 coins on ground [21 coins]</dt>
      <dt className="route-instruction-bold">- Thunder Bolt</dt>
      <dt className="route-instruction-bold">- Hidden Fire Flower</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Koopa with a jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Key Fight</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">
        T1: Power Jump / Untimed Shell Toss (Use untimed Power Shell instead if you missed the first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Bounce</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Staircase_Skip">Staircase Skip</Link>
      </dt>
      <dt className="route-instruction">- Talk to Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jail Fight</dt>
      <dd className="route-instruction-bold">T1: Fright Jar (Up 1) / Untimed Body Slam Bob-ombs, Bomb Koopas</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 9 coins from Jail Fight [30 coins]</dt>
      <dt className="route-instruction">- Go left into the pit</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike with Jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 1</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">T1: Power Jump 2nd Koopa / Bomb</dd>
      <dd className="route-instruction-bold">T2: Jump x1</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 1 (BACKUP: Missed First Strike)</dt>
      <dd className="route-instruction-bold">T1: Bomb / Jump x2</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 2</dt>
      <dd className="route-instruction-bold">T1: Fright Jar (Up 1) / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Power Jump / Untimed Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3 (BACKUP: Fright Fail)</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3 (BACKUP: Missed First Strike)</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Untimed Body Slam</dd>
      <dd className="route-instruction-bold">T2: Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3 (BACKUP: Missed First Strike and Fright Fail)</dt>
      <dd className="route-instruction-bold">T1: Bomb / Jump x2</dd>
      <dd className="route-instruction-bold">T2: Jump x2 / Untimed Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Destroy 1 fire bar for coins [40 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go upstairs and left to get the key behind the cracked wall</dt>
      <dt className="route-instruction">- Go right and upstairs, then head left to the locked door</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Pie_Jumps">Pie Jumps</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- If you have less than 3 FP, hit the Heart Block before fighting Cannons</dt>
      <dt className="route-instruction-bold">- First Strike the Cannons with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Cannons</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Thunder Bolt (Down 1) / Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- 10 Cannon coins [50 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T3: POW Block (Up 1) / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Yes to all 3 questions</dt>
    </dl>

    <h1 className="heading">Parakarry</h1>

    <dl className="list">
      <dt className="route-instruction">- Say No to both tutorials</dt>
      <dt className="route-instruction-bold">- Honey Syrup</dt>
      <dt className="route-instruction">- Say Yes to Jr Troopa</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr Troopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T3: Hammer</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy Speedy Spin</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 1st Magical Seed</dt>
      <dt className="route-instruction">- Take Train to Chapter 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Find 3 Letters and bring them to Parakarry</dt>
      <dt className="route-instruction">- Don't get any Whacka Bumps yet</dt>
      <dt className="route-instruction-bold">- Damage Dodge</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Parakarry</dt>
      <dt className="route-instruction-bold">- Head towards Dry Dry Desert</dt>
      <dt className="route-instruction-bold">
        - Cross the bridge (answer 2nd option) or do <Link to="/Buzzar_Skip">Buzzar Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Enter the desert</dt>
      <dt className="route-instruction-bold">- Immediately leave and head back to the train</dt>
      <dt className="route-instruction-bold">- Get the Magical Seed on the way</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction">- Take the train to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        (Entering the desert may look like it does nothing, but it activates an important story trigger that will let us
        enter the Ruins in an already completed state later. Do not skip this!)
      </dt>
    </dl>

    <h1 className="heading">Sushie</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Blue_House_Skip">Blue House Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce / Sky Dive</dd>
      <dd className="route-instruction-bold">
        T3: Keep attacking until Blooper is dead (Use Refresh if you are going to die)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go talk to Kolorado by the volcano</dt>
      <dt className="route-instruction-bold">- Jammin' Jelly behind the tree</dt>
      <dt className="route-instruction-bold">- Get Sushie</dt>
    </dl>

    <h1 className="heading">Bow</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Power Quake</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Quake (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Blue pipe to sewers, go left to Dark Koopas</dt>
      <dt className="route-instruction-bold">- Let them all First Strike you for peril</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x2</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If you get an FP drop, do the 8 FP fight</dt>
      <dt className="route-instruction">- If you get a POW Block, do the POW Block fight</dt>
      <dt className="route-instruction">- If you get neither, eat the Honey Syrup (Up 1) and do the 8 FP fight</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x3 (8 FP)</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x3 (POW Block)</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: POW Block / Swap to Kooper (Down 2)</dd>
      <dd className="route-instruction-bold">T2: Power Quake / Power Shell</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x4</dt>
      <dd className="route-instruction-bold">T0: Don't block (peril)</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Swap to Bombette (Up 2 if Sushie, Down 1 if Kooper)</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Pick up 2 FP drops if possible</dt>
      <dt className="route-instruction">- Take pipe to Boo's Mansion</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Do <Link to="/Record_Skip">Record Skip</Link> or do the Record minigame and use it to get the Weight
      </dt>
      <dt className="route-instruction">- Place the Weight</dt>
      <dt className="route-instruction">- Blow up the wall</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1) while the Super Boots chest opens</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Get Boo Portrait</dt>
      <dt className="route-instruction-bold">- Get Bow</dt>
    </dl>

    <h1 className="heading">Tubba Blubba</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Get a Strange Leaf if you used the Honey Syrup and have less than 9 FP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Stanley_Save">Stanley Save</Link>
      </dt>
      <dt className="route-instruction-bold">- Repel Gel</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go left for the Key</dt>
      <dt className="route-instruction-bold">- Upgrade Parakarry (Up/Down 3)</dt>
      <dt className="route-instruction">- Get caught</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right</dt>
      <dt className="route-instruction-bold">
        - If you have less than 9 FP, eat Honey Syrup (Up 1) or Strange Leaf (Down 1) while unlocking the door
      </dt>
      <dt className="route-instruction">- Get Key in spike room</dt>
      <dt className="route-instruction-bold">- Mega Rush</dt>
      <dt className="route-instruction-bold">- Maple Syrup</dt>
      <dt className="route-instruction">- Get Key in Clubba room</dt>
      <dt className="route-instruction">- Get Yakkey</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Yakkey_Trickshot">Yakkey Trickshot</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Quick_Escape">Quick Escape</Link>
      </dt>
      <dt className="route-instruction">- Make sure you have 9 FP for the Heart</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Right before the fight:</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Mega Rush (Down 2)</dd>
      <dd className="route-instruction-bold">Unequip Speedy Spin (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Z, Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tubba's Heart</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x6 / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x4 (x5 and x5 works too) / Sky Dive if needed</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tubba Blubba</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say Yes to Bow</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Damage Dodge (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go to Forever Forest and go left</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 3/3</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Forever Forest</dt>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Right x1</dd>
      <dd className="route-instruction-bold">Go right and get the Magical Seed</dd>
      <dd className="route-instruction">Right x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Return to Toad Town</dt>
    </dl>

    <h1 className="heading">Tutankoopa</h1>

    <dl className="list">
      <dt className="route-instruction">- Take the train to Mt. Rugged</dt>
      <dt className="route-instruction-bold">- Hit the Heart Block by Parakarry</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Fight Buzzar (first option)</dt>
      <dt className="route-instruction">- Buzzar will disable your partner on T1. This is normal</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Buzzar - Mash to block on T1, other blocks don't matter</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Jump x2 / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block before entering desert</dt>
      <dt className="route-instruction-bold">
        - Enter Desert, alternate up and right until you reach the Ruins (start with up)
      </dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Unequip Damage Dodge (Down 3)</dd>
      <dd className="route-instruction-bold">Equip Power Quake (Up 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Enter Ruins, take the lower loading zone, and grab the Ruins Key</dt>
      <dt className="route-instruction-bold">- Go right in the next room and grab another Ruins Key</dt>
      <dt className="route-instruction-bold">- Drop down-left and use the 2 keys to get the Super Hammer</dt>
      <dt className="route-instruction-bold">
        - Backtrack through the Pokey room and continue right to fight the Stone Chomps
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- We will get peril from these Stone Chomps</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Pyramid - Block 0/3</dt>
      <dd className="route-instruction-bold">T1: Jump x2 / Do Nothing</dd>
      <dd className="route-instruction-bold">T1: Jump x2 / Do Nothing</dd>
      <dd className="route-instruction-bold">T1: Jump x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Avoid heart drops if possible</dt>
      <dt className="route-instruction-bold">
        - Go all the way left, bomb the wall, drop down and go right for a Ruins Key
      </dt>
      <dt className="route-instruction">- Go left</dt>
      <dt className="route-instruction-bold">- Upgrade Bombette (Down 2)</dt>
      <dt className="route-instruction">- Bomb the wall and fight the Stone Chomps</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Diamond</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake (Timed Power Quake instead if not in peril)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Take the spring and go right</dt>
      <dt className="route-instruction-bold">
        - Place the Pyramid Stone (Mash A) in the first pillar and the Diamond Stone (Mash A) in the middle pillar
      </dt>
      <dt className="route-instruction">
        - Unlock the locked door, solve the puzzle and challenge the last Stone Chomps
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Diamond</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake (Timed Power Quake instead if not in peril)</dd>
    </dl>
    <dl className="list"></dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Place the Lunar Stone in the rightmost pillar (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- IF YOU ARE NOT IN PERIL, hit the Heart Block and rebadge here</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge ONLY IF NOT IN PERIL:</dt>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tutankoopa (peril)</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x4 / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Jump x2 (Power Bounce x3 instead if 3cap)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tutankoopa (not in peril)</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump Tutankoopa / Shell Shot Tutankoopa</dd>
      <dd className="route-instruction-bold">T3: Power Jump Tutankoopa / Shell Shot Tutankoopa</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge ONLY IF YOU WERE NOT IN PERIL:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Damage Dodge (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Leave Ruins</dt>
      <dt className="route-instruction">- Left, Down, Left</dt>
      <dt className="route-instruction-bold">- Hidden Thunder Rage</dt>
    </dl>
    <dl className="list">
      <img className="image" src="https://mario.wiki.gallery/images/8/88/PMHiddenBlock3.png" alt="Thunder Rage"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Down, Down, Left</dt>
      <dt className="route-instruction-bold">- Get a Whacka Bump</dt>
      <dt className="route-instruction">- Take the train to Toad Town</dt>
      <dt className="route-instruction">- Head to Chapter 5 through the sewers by breaking the boards</dt>
    </dl>

    <h1 className="heading">Lava Piranha</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Raphael_Skip">Raphael Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Fire Shield</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ultra_Hammer_Early">Ultra Hammer Early</Link>
      </dt>
      <dt className="route-instruction-bold">- Ultra Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Peril from Fire Bars</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Flarakarry">Flarakarry</Link>
      </dt>
      <dt className="route-instruction-bold">- Maple Syrup (2nd Block)</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Lava_Piranha_Out_Of_Bounds">Lava Piranha Out of Bounds</Link>
      </dt>
      <dt className="route-instruction-bold">
        - If you are not doing <Link to="/Lava_Piranha_Out_Of_Bounds">Lava Piranha Out of Bounds</Link>, swap to Sushie
        (Down 2) and do the backup fight
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lava Piranha - Block 0/3</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">T1: Power Bounce x5 / Swap to Sushie (Down 2) - Life Shroom</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x3 (x4 and x4 work too) / Untimed Belly Flop if x4 and x3</dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T3: Squirt / Power Bounce x4</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x3</dd>
    </dl>
    <dl className="list">
      <dt className="fight">BACKUP: Lava Piranha - Block 0/3</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 / Belly Flop - Life Shroom</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x5 / Belly Flop</dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T3: Squirt / Power Bounce x4</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x3</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - If you did <Link to="/Lava_Piranha_Out_Of_Bounds">Lava Piranha Out of Bounds</Link>, fall right, then hold
        up-right after the fight as if you were doing <Link to="/Lakilester_Peach_Warp">Lakilester Peach Warp</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Quiz: 1, 1, 1, 1, 1, 1, 1, 1, 1, 2</dt>
    </dl>

    <h1 className="heading">General Guy</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Coconut from tree</dt>
      <dt className="route-instruction-bold">- Volcano Vase from the chest in the jungle</dt>
      <dt className="route-instruction-bold">- Magical Seed from Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Hidden Repel Gel on the beach</dt>
      <img className="image" src="https://mario.wiki.gallery/images/d/d4/PMHiddenBlock27.png" alt="Repel Gel"></img>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge ONLY IF YOU HAD PERIL FOR TUTANKOOPA:</dt>
      <dd className="route-instruction-bold">Equip Damage Dodge (Down 3)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
      <dt className="route-instruction">- Take the Whale back to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 2/2</dt>
      <dd className="route-instruction-bold">T1: Coconut (Down 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Jump x1 / Shell Shot - peril</dd>
      <dd className="route-instruction-bold">T3: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Enter Toy Box</dt>
      <dt className="route-instruction-bold">- Swap to Bow (Down 1)</dt>
      <dt className="route-instruction-bold">- Hidden Stone Cap</dt>
      <img className="image" src="https://mario.wiki.gallery/images/5/52/PMHiddenBlock14.png" alt="Stone Cap"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Save in case of double 4 cap on Anti Guy</dt>
      <dt className="route-instruction">- Go left, fight Anti Guy (2nd option twice)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Anti Guy</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Stone Cap (Down 1)</dd>
      <dd className="route-instruction-bold">T3: Smack x4</dd>
      <dd className="route-instruction-bold">T4: Smack x4</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x5 / Smack x4</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Plus</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Damage Dodge (Down 4)</dd>
      <dd className="route-instruction-bold">Equip Power Plus (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Right switch to Green Station (Down 1)</dt>
      <dt className="route-instruction-bold">- Right switch to Pink Station (Down 2)</dt>
      <dt className="route-instruction">- Fix tracks</dt>
      <dt className="route-instruction">- Go right in Pink Station</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Pink_Station_Clips">Pink Station Clips</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Frying Pan</dt>
      <dt className="route-instruction-bold">- Left switch to Red Station (Down 1)</dt>
      <dt className="route-instruction-bold">- Heart Block</dt>
      <dt className="route-instruction-bold">- Hidden Volt Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/6/6a/PMHiddenBlock23.png" alt="Volt Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="fight">Big Lantern Ghost - Block 1/2</dt>
      <dd className="route-instruction-bold">T1: Do Nothing / Do Nothing </dd>
      <dd className="route-instruction-bold">T2: Do Nothing / Swap to Goombario (Up/Down 3)</dd>
      <dd className="route-instruction-bold">T3: Headbonk x2 / Power Bounce x4</dd>
      <dd className="route-instruction-bold">T4: Headbonk x2 / Power Bounce x3</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Upgrade Watt (Up 2)</dt>
      <dt className="route-instruction">- Fight General Guy</dt>
    </dl>
    <dl className="list">
      <dt className="fight">General Guy</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">T1: Jump x2 / Untimed Electro Dash</dd>
      <dd className="route-instruction-bold">T2: Untimed Power Quake</dd>
      <dd className="route-instruction-bold">T3: Jump x2 / Power Shock</dd>
      <dd className="route-instruction-bold">T4: Eat Maple Syrup (Down 2) / Turbo Charge</dd>
      <dd className="route-instruction-bold">T5: WITH PARTNER'S TURN: Swap to Bow (Up 1) / Jump x2</dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T6: Jump x2 / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce x4</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: Leave, hit the Heart Block, do <Link to="/Early_Whale">Early Whale</Link>, and skip to the Fuzzipede
        fight
      </dt>
      <dt className="route-instruction-bold">- Touch card</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Cake Directions:</dt>
      <dt className="route-instruction">- Sugar (2nd from left), Eggs, talk to Twink</dt>
      <dt className="route-instruction">- Mash A</dt>
      <dt className="route-instruction">
        - Flour (far left), Butter (far right, not including water and cleanser), talk to Twink
      </dt>
      <dt className="route-instruction">- Bake for at least 23 seconds from when the ticking starts</dt>
      <dt className="route-instruction">
        - Cream (2nd from right, not including water and cleanser), Strawberries (3rd from right), talk to Twink
      </dt>
      <dt className="route-instruction">- Grab cake and leave</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the docks and spin jump on the whale</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Early_Fuzzipede">Early Fuzzipede</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzipede - Block 0/3</dt>
      <dd className="route-instruction-bold">T1: Volt Shroom (Mash A) / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Do Nothing / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Do Nothing / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x4</dd>
    </dl>

    <h1 className="heading">Bloopers</h1>

    <dl className="list">
      <dt className="route-instruction">- Green pipe to sewers</dt>
      <dt className="route-instruction-bold">- Swap to Bow (Up 1)</dt>
      <dt className="route-instruction">- Break the stone block and fight Electro Blooper</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Electro Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x5</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP (2+ Fright Fails)</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you leveled up here, encounter the Spiked Gloomba in the room before Super Blooper
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">(2x Fright Fail Backup) Spiked Gloomba - Block 0/3</dt>
      <dd className="route-instruction-bold">T1: Do Nothing / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Hammer / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Run Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Maple Syrup (Up 2)</dt>
      <dt className="route-instruction">- Go right and fight Super Blooper</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Super Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x4 / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x4</dd>
      <dd className="route-instruction-bold">T3: Star Storm / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x4 (Remember to menu up!)</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- If you did not level up here, sleep at the Toad House</dt>
      <dt className="route-instruction">- Plant seeds and enter Flower Fields</dt>
    </dl>

    <h1 className="heading">Chapter 6</h1>

    <dl className="list">
      <dt className="route-instruction">- Mole order: Top right, then clockwise</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Top Right Mole - Block 0/3</dt>
      <dd className="route-instruction-bold">T1: Thunder Rage (Down 2) / Swap to Bombette (Up 2)</dd>
      <dd className="route-instruction-bold">T2: Untimed Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Right Mole</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
      <dt className="route-instruction-bold">- First strike the bottom left mole with Jump or Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Left Mole</dt>
      <dd className="route-instruction-bold">T0: First Strike</dd>
      <dd className="route-instruction-bold">T1: Shell Shot 1st Mole (Air Lift instead if 2+ Fright Fails) / Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Top Left Mole</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 2 Red Berries</dt>
      <dt className="route-instruction-bold">- Magical Bean</dt>
      <dt className="route-instruction-bold">- Eat Red Berry (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get 2 Yellow Berries (down-left) and give to Yellow Flower (down-right)</dt>
      <dt className="route-instruction-bold">- Eat a Yellow Berry (Mash A)</dt>
      <dt className="route-instruction-bold">- Jammin' Jelly in the tree in Lily's room</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction-bold">- Blue Berry</dt>
      <dt className="route-instruction-bold">- Blue Berry to Blue Flower (left)</dt>
      <dt className="route-instruction">- Hit the hidden block to the right of the spring on the way to the Maze</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lakitu Fight - Block 0/1</dt>
      <dd className="route-instruction-bold">T1: Shell Shot Lakitu / Star Storm</dd>
      <dt className="fight">Spinyless</dt>
      <dd className="route-instruction-bold">T2: Shell Shot</dd>
      <dt className="fight">Spiny Spawn</dt>
      <dd className="route-instruction-bold">T2: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 15 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Jump (A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Talk to Rosie</dt>
      <dt className="route-instruction-bold">- Get the hidden Shooting Star by taking the spring</dt>
      <img
        className="image"
        src="https://mario.wiki.gallery/images/1/1e/PMHiddenBlock35.png"
        alt="Shooting Star"
      ></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Talk to Posie (down-left)</dt>
      <dt className="route-instruction">- Talk to Rosie (left again)</dt>
      <dt className="route-instruction-bold">- Dizzy Dial in the left block in the Bubble Berry room</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction">- Place the Water Stone</dt>
      <dt className="route-instruction-bold">- Bubble Berry</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Fall in spikes to get to 9 HP</dt>
      <dt className="route-instruction-bold">- Shooting Star</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry before the fight (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Spike Fight - Block 0/2</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5 / Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go up-right</dt>
      <dt className="route-instruction-bold">- Hidden Thunder Rage</dt>
      <img className="image" src="https://mario.wiki.gallery/images/5/51/PMHiddenBlock36.png" alt="Thunder Rage"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Dizzy Dial works 100% on Lakitus, and the Magikoopa can't kill you</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Machine Fight</dt>
      <dd className="route-instruction-bold">T1: Dizzy Dial (Down 2) / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Thunder Rage (Mash A) / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Break the Weather Machine</dt>
      <dt className="route-instruction">- Talk to Wise Wisterwood</dt>
      <dt className="route-instruction-bold">- Eat Whacka Bump while planting (Up 2)</dt>
      <dt className="route-instruction">
        - To eat while planting, get very close to the center of the brown circle, mount Laki, then eat the item and
        hold the direction of the center
      </dt>
      <dt className="route-instruction-bold">- S. Jump Charge</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Huff N' Puff - Block 1/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Swap to Bow (Up 3)</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Outta Sight</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Smack x2</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-hp">- Level Up: HP</dt>
      <dt className="route-instruction-bold">15 HP | 20 FP | 15 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Transform into a Koopatrol, talk to the guard at the top, go down and transform into a Clubba, and then head
        back to the top
      </dt>
    </dl>

    <h1 className="heading">Chapter 7</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Talk to Merlon</dt>
      <dt className="route-instruction-bold">- Quick Change</dt>
      <dt className="route-instruction">- Go into the sewers through the green pipe, then fall down and go right</dt>
      <dt className="route-instruction-bold">- Ultra Boots</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/c/c1/PMHiddenBlock12.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Ride Laki across the spikes and go right</dt>
      <dt className="route-instruction-bold">- Shooting Star</dt>
      <dt className="route-instruction">- Hit the hidden blocks to open the path to Chapter 7</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Murder_Solved_Early">Murder Solved Early</Link> (6 steps on the staircase)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Swap to Sushie while Herringway is opening the door (Up 1)</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Chapter_7_Sushie_Glitch">Sushie Glitch</Link>
      </dt>
      <dt className="route-instruction">- Head right on Sushie</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr Troopa - Block 2/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5 / Squirt</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right and fight Monstar, 2nd option</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Monstar</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x3</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Repel Gel behind tree</dt>
      <dt className="route-instruction">- Enter Shiver Mountain</dt>
      <dt className="route-instruction-bold">- Encounter the first enemy</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Gulpit</dt>
      <dd className="route-instruction-bold">T1: Run Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Continue to the next room</dt>
      <dt className="route-instruction">- Swim past the Kooper switch and encounter the White Clubba</dt>
    </dl>
    <dl className="list">
      <dt className="fight">White Clubba</dt>
      <dd className="route-instruction-bold">T1: WITH PARTNER'S TURN: Swap to Lakilester (Down 1) / Run Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ice_Staircase_Skip">Ice Staircase Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Up 4)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Blue Key</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Mirror_Clip">Mirror Clip</Link>
      </dt>
      <dt className="route-instruction">- Break panel</dt>
      <dt className="route-instruction">- Hit Red button</dt>
      <dt className="route-instruction-bold">- Red Key (4th Bombette is real)</dt>
      <dt className="route-instruction">- Hit Blue button</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 1</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 2</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 3</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 15 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Triple Dip</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Kooper_Puzzle_Skip">Kooper Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- P-Up D-Down</dt>
      <dt className="route-instruction-bold">- Hidden Jammin' Jelly</dt>
      <dt className="route-instruction-bold">- Palace Key</dt>
      <dt className="route-instruction-bold">- Swap to Watt (Up/Down 4) while opening the lock</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 3)</dd>
      <dd className="route-instruction-bold">Equip Damage Dodge (R, Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Crystal King - Block 5/6</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Electro Dash</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Electro Dash</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Electro Dash</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Electro Dash</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>

    <h1 className="heading">Chapter 8</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Pillar Shooting Star</dt>
      <dt className="route-instruction-bold">- Upgrade Sushie (Up 2) on stairs</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the Dojo</dt>
      <dt className="route-instruction-bold">- Swap to Kooper (Down 3)</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Damage Dodge (R, Up 1)</dd>
      <dd className="route-instruction-bold">Equip P-Up D-Down (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Chan</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x7</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Lee</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x3 / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Master 1 - Block 0/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Do Nothing / Swap to Parakarry (Down 2)</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Master 2</dt>
      <dd className="route-instruction-bold">T1: Repel Gel (Up 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x5 / Do Nothing (Shell Shot instead if 4 capped)</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5 / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Down 1)</dt>
      <dt className="route-instruction-bold">- Swap to Bow (Down 1)</dt>
      <dt className="route-instruction">- Go for 6 caps to help insulate against 3 caps</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Master 3</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x6 / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Repel Gel (Up 1)</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x6 / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x6 / Outta Sight</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x6</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Safety save before Kent C. Koopa</dt>
      <dt className="route-instruction">- Go towards Koopa Village, select 3rd option twice to fight Kent C. Koopa</dt>
      <dt className="route-instruction">- Switch fights if Lullaby fails or reset</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Kent C. Koopa</dt>
      <dd className="route-instruction-bold">T1: Lullaby / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Jump x2 / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5 tail / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x4 tail / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Kent C. Koopa (Bow Backup)</dt>
      <dd className="route-instruction-bold">T1: Lullaby / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Lullaby</dd>
      <dd className="route-instruction-bold">T3: Jump x2 / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 tail / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x4 tail / Do Nothing</dd>
      <dd className="route-instruction-bold">T6: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP (Spiny Spawn)</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 18 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Quick Change (Down 2) if you just leveled up</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Shooting Star Summit</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right from the locked door and fight the Koopatrol</dt>
      <dt className="route-instruction">- Swap to Bombette (Up 2)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP (Spinyless)</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 18 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge only if you leveled up here:</dt>
      <dd className="route-instruction-bold">Equip Quick Change (Down 4)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Jail Life Shroom from the lower box on the right</dt>
      <dt className="route-instruction-bold">- Spiny Spawn: Hit the Heart Block</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Block_Clip">Block Clip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Fast_Flood_Room">Fast Flood Room</Link>
      </dt>
      <dt className="route-instruction-bold">- Spinyless: Get first struck by the first bullet bill</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bombshell Bill - Block 0/2</dt>
      <dd className="route-instruction-bold">T0: Get First Struck</dd>
      <dd className="route-instruction-bold">T1: Jump x1 / Do Nothing</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Cannonless">Cannonless</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Sushie (Up 1)</dt>
      <dt className="route-instruction-bold">- Spiny Spawn: Get first struck by the first Dry Bones</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dry Bones - Block 1/3</dt>
      <dd className="route-instruction-bold">T0: Get First Struck</dd>
      <dd className="route-instruction-bold">T1: Do Nothing / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Run Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Save before Anti Guys (really bad caps can kill you)</dt>
      <dt className="route-instruction-bold">- Quiz: 2, 2, 1</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Anti Guy Unit</dt>
      <dd className="route-instruction-bold">T1: Power Quake / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Repel Gel (Up 1)</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5 first guy / Power Bomb</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 second guy / Outta Sight</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x5 third guy</dd>
      <dd className="route-instruction-bold">T6: Jump x1 if 4 capped</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get and eat the Maple Syrup (Mash A) if in peril</dt>
      <dt className="route-instruction-bold">- Get Jammin' Jelly</dt>
      <dt className="route-instruction">- Maze: Up, Down, Down, Up, Down, Up</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block if you aren't in peril</dt>
      <dt className="route-instruction">- If not in peril and 0 transform, you lose</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts (peril)</dt>
      <dd className="route-instruction-bold">T1: Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts (not peril) - Block 1/4</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 21 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Damage Dodge (R, Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 2/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Turbo Charge</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Electro Dash</dd>
      <dd className="route-instruction-bold">T3: Power Bounce x5 / Outta Sight</dd>
      <dd className="route-instruction-bold">T4: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Mash A)</dt>
      <dt className="route-instruction-bold">- If you have less than 2 Life Shrooms, get the one in the Library</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x3 (x4 and x4 work too)</dd>
      <dd className="route-instruction-bold">T3: Jump x1 if x3 and x4, Jump x2 if double x3</dd>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Damage Dodge (R, Up 1)</dd>
      <dd className="route-instruction-bold">Unequip Quick Change (Up 2)</dd>
      <dd className="route-instruction-bold">Equip Triple Dip (Up 1)</dd>
      <dd className="route-instruction-bold">Equip Fire Shield (R, Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Always go for the highest bounce possible</dt>
      <dt className="route-instruction">- If your bounce caps are good, you will win earlier than the route shows</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 1</dt>
      <dd className="route-instruction-bold">T1: Power Bounce (Down 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce</dd>
      <dd className="route-instruction-bold">T3: WITH PARTNER'S TURN: Swap to Sushie (Down 2) / Star Beam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Follow the beamless route, then switch to your fight when Bowser uses Star Shield
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Beamless</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Water Block - Life Shroom</dd>
      <dd className="route-instruction-bold">T2: Peach Beam / Swap to Watt (Up 1) - Block</dd>
      <dd className="route-instruction-bold">
        T3: Triple Dip (Shooting Star, Shooting Star, Red Berry) / Electro Dash - Block (peril)
      </dd>
      <dd className="route-instruction-bold">T4: Power Bounce / Electro Dash - Life Shroom</dd>
      <dd className="route-instruction-bold">T5: Triple Dip (Shooting Star, Shooting Star) / Electro Dash</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4</dt>
      <dd className="route-instruction-bold">T5: Peach Beam / Electro Dash - Life Shroom</dd>
      <dd className="route-instruction-bold">T6: Triple Dip (Shooting Star, Shooting Star)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - <Link to="/Luigi_Skip">Luigi Skip</Link>
      </dt>
    </dl>
  </>
);
