import { RouteComponentProps } from "../../types/RouteComponents";

export const RouteRebadge = ({ instruction }: RouteComponentProps) => {
  const { directions } = instruction;

  return (
    <div>
      <p className="rebadge">Rebadge</p>
      {directions?.map((direction, index) => (
        <p key={`rebadge-${index}`} className="rebadge-direction">
          {direction}
        </p>
      ))}
      <br />
    </div>
  );
};
