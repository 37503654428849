import { useEffect, useState } from "react";
import { PageProps } from "../types/PageProps";
import { UserRoute } from "../types/RouteComponents";
import { Link } from "react-router-dom";
import { isValidRouteJSON } from "../utilities/isValidRouteJSON";

export default function RouteLibrary({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Route Library");
  });

  const [routes, setRoutes] = useState<UserRoute[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [name, setName] = useState<string>("");

  const readRoutesFromLocalStorage = () => {
    const objects = { ...localStorage };
    let newRoutes: UserRoute[] = [];
    Object.entries(objects).forEach((obj) => {
      const { 0: key, 1: value } = obj;
      if (key.slice(0, 6) === "route-") {
        const obj = JSON.parse(value);
        if (obj.name && obj.description && obj.instructions) {
          newRoutes.push({
            name: obj?.name,
            description: obj?.description ?? "No description",
            instructions: obj?.instructions ?? [],
          });
        }
      }
    });
    setRoutes(newRoutes);
  };

  const createRoute = () => {
    try {
      if (name.length < 1) {
        throw new Error("Route name cannot be empty.");
      }
      if (localStorage.getItem(`route-${name}`)) {
        throw new Error("Route name is already in use.");
      }
      localStorage.setItem(
        `route-${name}`,
        JSON.stringify({
          name: name,
          description: "No description",
          instructions: [],
          variables: [],
        })
      );
      window.location.href = `/Route_Editor?name=${name}`;
    } catch (err: any) {
      setErrorMessage(err.message || "Something went wrong.");
    }
  };

  const processFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = e.currentTarget.files as FileList;
      const file = files[0];
      if (file) {
        const route = await file.text();
        if (!isValidRouteJSON(route))
          throw new Error("File could not be read.");

        const parsedRoute = JSON.parse(route);
        if (
          parsedRoute.name &&
          parsedRoute.description &&
          parsedRoute.instructions
        ) {
          if (localStorage.getItem(`route-${parsedRoute.name}`)) {
            throw new Error("This route already exists.");
          }
          localStorage.setItem(`route-${parsedRoute.name}`, route);
          window.location.href = `/Route_Editor?name=${file.name.slice(0, -4)}`;
        } else {
          throw new Error(
            "This file is missing at least one of the following: name, description, instructions."
          );
        }
      }
    } catch (err: any) {
      setErrorMessage(err?.message || "Something went wrong with your upload.");
    }
  };

  useEffect(() => {
    readRoutesFromLocalStorage();
  }, []);

  return (
    <>
      <h1 className="heading">Route Library</h1>
      <p>
        At the request of exactly 1 person and nobody else, I decided to build a
        way for people to create routes on the website.
      </p>
      <p style={{ margin: "0 0 1.5rem 0" }}>
        Please note that your routes are stored in your browser, so if you'd
        like to keep them extra safe <b>please download them!</b>
      </p>
      <div>
        <ul className="route-list" style={{ width: "auto" }}>
          <li className="list-link">
            <Link to="/Route_Editor_Guide">How to use the Route Editor</Link>
          </li>
          {routes.length > 0 && <li className="route-title">Your Routes</li>}
          {routes
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((route, index) => (
              <li className="list-link" key={`route-${index}`}>
                <Link to={`/Route_Editor?name=${route.name}`}>
                  {route.name}
                </Link>
                {route.description && ` - ${route.description}`}
              </li>
            ))}
          <li className="route-title">Create Route</li>
          <li className="list-link">
            <input
              className="route-input"
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyDown={(e) => e.key === "Enter" && createRoute()}
            ></input>
            <button
              className="route-editor-control"
              style={{ width: "4rem", margin: "0 0 0 1rem" }}
              onClick={createRoute}
            >
              Create
            </button>
          </li>
          <li className="route-title">Import Route</li>
          <li className="list-link">
            <input type="file" accept=".txt" required onChange={processFile} />
          </li>
          {errorMessage && <li className="list-link">{errorMessage}</li>}
        </ul>
      </div>
    </>
  );
}
