import { anyPercentNoAce } from "./categories/anyPercentNoAce";
import { allCards } from "./categories/allCards";
import { glitchless } from "./categories/glitchless";
import { anyPercentNoPeachWarp } from "./categories/anyPercentNoPeachWarp";
import { allBosses } from "./categories/allBosses";
import { oneHundredPercent } from "./categories/oneHundredPercent";
import { reverseAllCards } from "./categories/reverseAllCards";
import { noMajorSequenceBreaks } from "./categories/noMajorSequenceBreaks";
import { Category } from "../types/Category";

export const categories: Category[] = [
  anyPercentNoAce,
  allCards,
  glitchless,
  anyPercentNoPeachWarp,
  allBosses,
  oneHundredPercent,
  reverseAllCards,
  noMajorSequenceBreaks,
];
