import { useEffect } from "react";
import { Link } from "react-router-dom";
import { PageProps } from "../types/PageProps";

export default function BackupCoins({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });

  return (
    <>
      <h1 className="heading">Backup Coins</h1>

      <dl className="list">
        <dt className="detail-title">
          If you get encountered and lose coins, you may find yourself unable to buy Speedy Spin. Use these backups
          based on how many coins you need.
        </dt>
        <p></p>
        <dt className="detail-title">
          <Link to="/All_Cards">All Cards</Link> and <Link to="/Glitchless">Glitchless</Link>
        </dt>
        <p></p>
        <dd className="detail-title">1 coin - Head to Koopa Village and shake the leftmost bush in the first screen.</dd>
        <p></p>
        <img className="backup-coins-image" src="https://s1.gifyu.com/images/backup1.png" alt="Backup"></img>
        <p></p>
        <dd className="detail-title">
          2-4 coins - Pick up the Sleepy Sheep behind the fence on the way back to Toad Town, and sell it at Shroom
          Grocery (go left from the badge shop).
        </dd>
        <p></p>
        <img className="backup-coins-image" src="https://s1.gifyu.com/images/backup2.png" alt="Backup"></img>
        <p></p>
        <dd className="detail-title">
          5-10 coins - Go to the Toad Town Harbor and hit the 10 coin block on the crates.
        </dd>
        <p></p>
        <img className="backup-coins-image" src="https://s1.gifyu.com/images/backup3.png" alt="Backup"></img>
      </dl>
    </>
  );
}
