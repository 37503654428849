import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const parseLinks = (input: string): JSX.Element => {
  let splitContent = input.split("{{");

  return (
    <>
      {splitContent.length === 1 ? (
        splitContent[0]
      ) : (
        <>
          {splitContent.length > 1 && (
            <>
              {splitContent.map((substring, index) => {
                const subSubstring = substring.split("}}");
                const name = subSubstring[1]?.split("[")[1]?.split("]")[0];
                if (index === 0) {
                  return <Fragment key={`link-map-${index}`}>{substring}</Fragment>;
                }
                return (
                  <Fragment key={`link-map-${index}`}>
                    <Link to={subSubstring[0]}>{name}</Link>
                    {subSubstring[1]?.split("[")[1]?.split("]").slice(1)}
                  </Fragment>
                );
              })}
            </>
          )}
          {splitContent.length < 2 && <>{input}</>}
        </>
      )}
    </>
  );
};

const parseItalics = (input: string): JSX.Element => {
  let splitContent = input.split("*");

  return (
    <>
      {splitContent.length === 1 ? (
        parseLinks(input)
      ) : (
        <>
          {splitContent.length % 2 === 0 && <>{parseLinks(input)}</>}
          {splitContent.length % 2 === 1 && (
            <>
              {splitContent.map((substring, index) => (
                <Fragment key={`italics-map-${index}`}>
                  {index % 2 === 0 ? parseLinks(substring) : <i>{parseLinks(substring)}</i>}
                </Fragment>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

const parseBold = (input: string): JSX.Element => {
  let splitContent = input.split("**");

  return (
    <>
      {splitContent.length === 1 ? (
        parseItalics(input)
      ) : (
        <>
          {splitContent.length % 2 === 0 && <>{parseItalics(input)}</>}
          {splitContent.length % 2 === 1 && (
            <>
              {splitContent.map((substring, index) => (
                <Fragment key={`bold-map-${index}`}>
                  {index % 2 === 0 ? parseItalics(substring) : <b>{parseLinks(substring)}</b>}
                </Fragment>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export const parseContent = (input: string): JSX.Element => {
  let splitContent = input.split("***");

  return (
    <>
      {splitContent.length === 1 ? (
        parseBold(input)
      ) : (
        <>
          {splitContent.length % 2 === 0 && <>{parseBold(input)}</>}
          {splitContent.length % 2 === 1 && (
            <>
              {splitContent.map((substring, index) => (
                <Fragment key={`content-map-${index}`}>
                  {index % 2 === 0 ? (
                    parseBold(substring)
                  ) : (
                    <b>
                      <i>{parseLinks(substring)}</i>
                    </b>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};
