import { Link } from "react-router-dom";
import { Technique } from "../types/Technique";

export const techniques: Technique[] = [
  {
    name: "Hammer Cancel",
    path: "Hammer_Cancel",
    description: (
      <>
        Hammer Canceling is a technique that allows the player to skip the ending lag of the hammer by pressing B again
        right when the hammer touches the ground.
      </>
    ),
    versions: "All",
    content: (
      <>
        <img className="image" src="https://s12.gifyu.com/images/HammerCancel2.gif" alt="Hammer Cancel"></img>
        <p className="image-description">Hammering normally</p>
        <br />
        <img className="image" src="https://s12.gifyu.com/images/HammerCancel1.gif" alt="Hammer Cancel"></img>
        <p className="image-description">Hammering with Hammer Cancels</p>
      </>
    ),
  },
  {
    name: "Hammer Cancel Jump",
    path: "Hammer_Cancel_Jump",
    description: <>This page is currently under construction.</>,
    content: <></>,
  },
  {
    name: "Hammer Trickshot",
    path: "Hammer_Trickshot",
    description: (
      <>
        Hammer Trickshots are a technique that allows Mario to extend his hitbox through some walls and objects by using
        his hammer.{" "}
      </>
    ),
    mechanics: (
      <>
        When Mario uses his hammer, the game checks what direction Mario is facing to determine if the hammer will get
        interrupted by an object. However, when Mario spins, then hammers in the opposite direction, the game uses
        Mario\'s direction from before spinning to determine if the hammer will get interrupted instead of the direction
        he is actually hammering. This happens because the game does not update Mario\'s direction before hammering this
        way.
      </>
    ),
    versions: "All",
    content: (
      <>
        <img className="image" src="https://s12.gifyu.com/images/HammerTrickshot.gif" alt="Hammer Trickshot"></img>
        <p className="image-description">Using a Hammer Trickshot to get the Odd Key.</p>
      </>
    ),
  },
  {
    name: "NPC Luring",
    path: "NPC_Luring",
    description: <>NPC Luring is a technique that lets the player move wandering NPCs.</>,
    mechanics: (
      <>
        Wandering NPCs have a "wandering radius" that they are allowed to randomly walk around. When Mario gets close
        enough to a wandering NPC to see the speech bubble appear above its head, then walks away, the NPC will begin to
        move in Mario\'s direction. This can be exploited in a couple of areas to get NPCs close enough to walls in
        order to perform an <Link to="/NPC_Clipping">NPC Clip</Link>.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Blue House Skip (NPC Luring)",
        path: "/Tricks/Blue_House_Skip",
      },
      {
        name: "Chapter 4 Early",
        path: "/Tricks/Chapter_4_Early",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipLure1.gif" alt="NPC Luring"></img>
        <p className="image-description">Luring Toads in Toad Town.</p>
      </>
    ),
  },
  {
    name: "Parakarry Height Gain",
    path: "Parakarry_Height_Gain",
    description: (
      <>Parakarry Height Gaining is a technique that allows Mario to gain height by using Parakarry in certain areas.</>
    ),
    versions: "All",
    tricks: [
      {
        name: "Stanley Save",
        path: "/Tricks/Stanley_Save",
      },
    ],
    content: (
      <>
        <img
          className="image"
          src="https://s12.gifyu.com/images/ParakarryHeightGain.gif"
          alt="Parakarry Height Gain"
        ></img>
        <p className="image-description">Using a Parakarry Height Gain to get Mega Rush quickly.</p>
      </>
    ),
  },
  {
    name: "Quick Jumps",
    path: "Quick_Jumps",
    description: <>Quick Jumping is a technique that speeds up all of Mario\'s Jump attacks.</>,
    mechanics: (
      <>
        When Mario walks forwards to use a jump attack, he briefly crouches right before jumping. If the player presses
        A during this crouch animation, Mario will jump immediately.
      </>
    ),
    versions: "All",
    content: (
      <>
        <img className="image" src="https://s12.gifyu.com/images/QuickJump2.gif" alt="Quick Jump"></img>
        <img className="image" src="https://s12.gifyu.com/images/QuickJump1.gif" alt="Quick Jump"></img>
        <p className="image-description">Top: Normal Jump - Bottom: Quick Jump</p>
      </>
    ),
  },
  {
    name: "Spin Cancel",
    path: "Spin_Cancel",
    description: (
      <>
        Spin Canceling is a movement technique that uses a wall to cancel Mario\'s spin early with Speedy Spin, allowing
        the player to continue spinning without losing speed by jumping or letting the spin animation complete. In many
        hallways, spin canceling off of a wall is the fastest way to move because Mario spends more time spinning and
        less time jumping between spins.
      </>
    ),
    versions: "All",
    content: (
      <>
        <img className="image" src="https://s11.gifyu.com/images/SpinCancel.gif" alt="Spin Cancel"></img>
        <p className="image-description">Spin Canceling in the sewers.</p>
      </>
    ),
  },
  {
    name: "Swag Hammer",
    path: "Swag_Hammer",
    description: (
      <>
        A Swag Hammer is when Mario uses his hammer on the same frame that he touches a Loading Zone. Swag Jumps can
        also be performed, but they are less stylish.
      </>
    ),
    versions: "All",
    content: (
      <>
        <img className="image" src="https://s12.gifyu.com/images/SwagHammer.gif" alt="Swag Hammer"></img>
        <p className="image-description">A Swag Hammer.</p>
      </>
    ),
  },
];

/*
    description: <></>,
    mechanics: <></>,
    versions: '',
    tricks: [{
        name: '',
        link: ''
    }],
    categories: [{
        name: 'Any% (No ACE)',
        link: '/Any_(No_ACE)'
    }, {
        name: 'All Cards',
        link: '/All_Cards'
    }, {
        name: 'Any% (No Peach Warp)',
        link: '/Any_(No_Peach_Warp)'
    }, {
        name: 'All Bosses',
        link: '/All_Bosses'
    }, {
        name: '100%',
        link: '/100'
    }, {
        name: 'Reverse All Cards',
        link: '/Reverse_All_Cards'
    }, {
        name: 'No Major Sequence Breaks',
        link: '/No_Major_Sequence_Breaks'
    }],
    discoveredBy: false,
    videos: false,
    videoTutorials: false,
    content: <>

    </>
*/
