import { Link } from "react-router-dom";
import { useEffect } from "react";
import { tricks } from "../data/tricks";
import { PageProps } from "../types/PageProps";

export default function Tricks({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Tricks");
  });
  let letters = [
    { char: "A" },
    { char: "B" },
    { char: "C" },
    { char: "D" },
    { char: "E" },
    { char: "F" },
    { char: "G" },
    /*{char:'H'}, */
    { char: "I" },
    { char: "J" },
    { char: "K" },
    { char: "L" },
    { char: "M" },
    /*{char:'N'}, */
    { char: "O" },
    { char: "P" },
    { char: "Q" },
    { char: "R" },
    { char: "S" },
    { char: "T" },
    { char: "U" },
    { char: "V" },
    { char: "W" },
    /*{char:'X'}, */
    { char: "Y" },
    /*{char:'Z'}*/
  ];
  return (
    <>
      <h1 className="heading">Tricks</h1>

      <p className="page-description">All Paper Mario Speedrun Tricks organized alphabetically.</p>
      <div className="tricks-list">
        {letters.map((item, i) => (
          <ul className="index-list" key={i}>
            <p className="tricks-title">{item.char}</p>
            {tricks.map(
              (trick, j) =>
                trick.name[0] === item.char && (
                  <li className="list-link" key={`${i}.${j}`}>
                    <Link to={`/Tricks/${trick.path}`}>{trick.name}</Link>
                  </li>
                )
            )}
          </ul>
        ))}
      </div>
    </>
  );
}
