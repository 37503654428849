import { Fragment, useEffect, useState } from "react";
import { PageProps } from "../types/PageProps";
import { Instruction, InstructionTypes, Stat } from "../types/RouteComponents";
import { RouteEditorInstruction } from "../components/route-components/RouteEditorInstruction";
import { DownloadButton } from "../components/DownloadButton";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { RoutePreview } from "../components/route-components/RoutePreview";
import { RouteEditorVariableMenu } from "../components/route-components/RouteEditorVariableMenu";

export default function RouteEditor({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });
  const [URLSearchParams] = useSearchParams();

  const routeName = `route-${URLSearchParams.get("name") || ""}`;
  const routeInStorage = JSON.parse(localStorage.getItem(routeName) || "{}");

  const [route, setRoute] = useState<Instruction[]>(
    (localStorage.getItem(routeName) || "{}")[0] === "[" ? routeInStorage || [] : routeInStorage.instructions ?? []
  );
  //const [raw, setRaw] = useState<string>(JSON.stringify(routeInStorage));
  const [input, setInput] = useState<string>("");
  const [name, setName] = useState<string>(routeInStorage.name || "Untitled Route");
  const [description, setDescription] = useState<string>(routeInStorage.description || "No description");
  const [bold, setBold] = useState<boolean>(false);
  const [space, setSpace] = useState<boolean>(false);
  const [stat, setStat] = useState<Stat>("hp");
  const [mode, setMode] = useState<string>("Editor");
  const [hp, setHP] = useState<number>(10);
  const [fp, setFP] = useState<number>(5);
  const [bp, setBP] = useState<number>(3);
  const [turns, setTurns] = useState<number>(1);
  const [directions, setDirections] = useState<string[]>([]);
  const [rebadgeDirections, setRebadgeDirections] = useState<string[]>([]);
  const [inputType, setInputType] = useState<InstructionTypes>(InstructionTypes.Text);
  const [controlPosition, setControlPosition] = useState<number>(routeInStorage?.instructions?.length ?? 0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [variables, setVariables] = useState<string[]>(routeInStorage.variables || []);

  useEffect(() => {
    if (!name) return;
    localStorage.setItem(
      routeName,
      JSON.stringify({
        name: routeInStorage.name,
        description: routeInStorage.description,
        instructions: route,
        variables: variables,
      })
    );
    // setRaw(
    //   JSON.stringify({
    //     name: routeInStorage?.name || "Untitled Route",
    //     description: routeInStorage?.description ?? "No description",
    //     instructions: route,
    //   })
    // );
    // eslint-disable-next-line
  }, [route, setRoute, routeName, name, description, variables]);

  if (!localStorage.getItem(routeName)) {
    return <Navigate to="/Route_Library" />;
  }

  const incrementControlPosition = () => setControlPosition((controlPosition) => controlPosition + 1);

  const handleNameChange = () => {
    try {
      if (name.length < 1) {
        throw new Error("Route name cannot be empty.");
      }
      if (localStorage.getItem(`route-${name}`)) {
        throw new Error("Route name is already in use.");
      }
      localStorage.removeItem(routeName);
      localStorage.setItem(
        `route-${name}`,
        JSON.stringify({
          name: name,
          description: description ?? "No description",
          instructions: route,
          variables: variables,
        })
      );
      window.location.href = `/Route_Editor?name=${name}`;
    } catch (err: any) {
      setErrorMessage(err.message || "Something went wrong.");
    }
  };

  const addTextToRoute = () => {
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(controlPosition, 0, {
      type: InstructionTypes.Text,
      content: input,
      bold: bold,
      space: space,
    });
    setRoute(newRoute);
  };

  const addFightToRoute = () => {
    let directionsToAdd: string[] = [];
    for (let i = 0; i < turns; i++) {
      directionsToAdd.push(directions[i]);
    }
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(controlPosition, 0, {
      type: InstructionTypes.Fight,
      content: input,
      directions: directionsToAdd,
    });
    setRoute(newRoute);
    setDirections([]);
    setTurns(1);
  };

  const addRebadgeToRoute = () => {
    let directionsToAdd: string[] = [];
    for (let i = 0; i < turns; i++) {
      directionsToAdd.push(rebadgeDirections[i]);
    }
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(controlPosition, 0, {
      type: InstructionTypes.Rebadge,
      directions: directionsToAdd,
    });
    setRoute(newRoute);
    setRebadgeDirections([]);
    setTurns(1);
  };

  const addHeadingToRoute = () => {
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(controlPosition, 0, {
      type: InstructionTypes.Heading,
      content: input,
    });
    setRoute(newRoute);
  };

  const addLevelUpToRoute = () => {
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(controlPosition, 0, {
      type: InstructionTypes.LevelUp,
      content: input,
      stat: stat,
      hp: hp,
      fp: fp,
      bp: bp,
    });
    setRoute(newRoute);
  };

  const addImageToRoute = () => {
    let newRoute = [];
    newRoute.push(...route);
    newRoute.splice(controlPosition, 0, {
      type: InstructionTypes.Image,
      content: input,
    });
    setRoute(newRoute);
  };

  const addInstructionToRoute = () => {
    inputType === InstructionTypes.Text && addTextToRoute();
    inputType === InstructionTypes.Fight && addFightToRoute();
    inputType === InstructionTypes.Heading && addHeadingToRoute();
    inputType === InstructionTypes.LevelUp && addLevelUpToRoute();
    inputType === InstructionTypes.Rebadge && addRebadgeToRoute();
    inputType === InstructionTypes.Image && addImageToRoute();
    setInput("");
    incrementControlPosition();
  };

  const removeRouteFromLocalStorage = () => {
    localStorage.removeItem(routeName);
    window.location.href = "/Route_Library";
  };

  return (
    <>
      <h1 className="heading">Route Editor</h1>
      <div className="route-editor">
        <p>
          <Link to="/Route_Library">Back to Route Library</Link>
        </p>
        <div>
          <input
            className="route-input"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            onKeyDown={(e) => e.key === "Enter" && handleNameChange()}
          ></input>
          <button className="route-editor-control" style={{ width: "10rem" }} onClick={handleNameChange}>
            Update Name
          </button>
          {errorMessage && <span>{errorMessage}</span>}
        </div>
        <div>
          <input
            className="route-input"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              localStorage.setItem(
                `route-${name}`,
                JSON.stringify({
                  name: name,
                  description: description,
                  instructions: route,
                })
              )
            }
          ></input>
          <button
            className="route-editor-control"
            style={{ width: "10rem" }}
            onClick={() =>
              localStorage.setItem(
                `route-${name}`,
                JSON.stringify({
                  name: name,
                  description: description,
                  instructions: route,
                })
              )
            }
          >
            Update Description
          </button>
        </div>
        <div className="import-controls">
          {/* <textarea
            className="route-textarea"
            onChange={(e) => {
              setRaw(e.target.value);
            }}
            value={raw}
          ></textarea>
          <button
            className="bingo-button-show"
            onClick={() => {
              localStorage.removeItem(routeName);
              localStorage.setItem(JSON.parse(raw).name ? `route-${JSON.parse(raw).name}` : routeName, raw);
              window.location.href = `/Route_Editor?name=${JSON.parse(raw).name || "Untitled%20Route"}`;
            }}
          >
            Import Raw
          </button> */}
          {["Editor", "Preview", "Variables"].map((modeName, index) =>
            mode === modeName ? (
              <button className="bingo-button-show" disabled key={`${modeName}-${index}`}>
                {modeName}
              </button>
            ) : (
              <button
                className="bingo-button-show"
                onClick={() => {
                  setMode(modeName);
                }}
                key={`${modeName}-${index}`}
              >
                {modeName}
              </button>
            )
          )}
          <DownloadButton name={routeName.slice(6)} contents={localStorage.getItem(routeName) || "[]"} />
          <button
            className="bingo-button-show"
            onClick={() => {
              window.confirm("Are you sure?") && removeRouteFromLocalStorage();
            }}
          >
            Delete Route
          </button>
        </div>
        {mode === "Preview" && (
          <RoutePreview
            route={{
              name: name,
              description: description,
              instructions: route,
              variables: variables,
            }}
          />
        )}
        {mode === "Variables" && (
          <RouteEditorVariableMenu
            variables={variables}
            setVariables={setVariables}
            instructions={route}
            setInstructions={setRoute}
          />
        )}
        {mode === "Editor" && (
          <>
            <div className="route-instructions">
              {[{} as Instruction, ...route].map((instruction, index) => (
                <Fragment key={`instruction-${index - 1}`}>
                  {index > 0 && (
                    <RouteEditorInstruction
                      instruction={instruction}
                      index={index - 1}
                      route={route}
                      setRoute={setRoute}
                      decrementControlPosition={() =>
                        setControlPosition((controlPosition) =>
                          controlPosition > index - 1 ? controlPosition - 1 : controlPosition
                        )
                      }
                      variables={variables}
                    />
                  )}
                  {controlPosition === index && (
                    <div className="route-controls">
                      <select
                        className="route-select"
                        defaultValue={controlPosition}
                        onChange={(e) => setControlPosition(Number(e.target.value))}
                      >
                        {route.map((_instruction, index) => (
                          <option key={`position-${index}`} value={index}>
                            {index}
                          </option>
                        ))}
                        <option value={route.length}>{route.length}</option>
                      </select>
                      <select
                        className="route-select"
                        defaultValue={inputType}
                        onChange={(e) => setInputType(e.target.value as InstructionTypes)}
                      >
                        <option value={InstructionTypes.Text}>Text</option>
                        <option value={InstructionTypes.Fight}>Fight</option>
                        <option value={InstructionTypes.Heading}>Heading</option>
                        <option value={InstructionTypes.LevelUp}>Level Up</option>
                        <option value={InstructionTypes.Rebadge}>Rebadge</option>
                        <option value={InstructionTypes.Image}>Image</option>
                      </select>
                      {(inputType === InstructionTypes.Text ||
                        inputType === InstructionTypes.Heading ||
                        inputType === InstructionTypes.Image) && (
                        <>
                          <input
                            type="text"
                            className="route-input"
                            onKeyDown={(e) => e.key === "Enter" && addInstructionToRoute()}
                            onChange={(e) => {
                              setInput(e.target.value);
                            }}
                            value={input}
                          ></input>
                        </>
                      )}
                      {inputType === InstructionTypes.Text && (
                        <>
                          <label className="route-checkbox-label">Bold?</label>
                          <input
                            type="checkbox"
                            className="route-checkbox"
                            onChange={() => {
                              setBold(!bold);
                            }}
                            checked={bold}
                          />
                          <label className="route-checkbox-label">Space after?</label>
                          <input
                            type="checkbox"
                            className="route-checkbox"
                            onChange={() => {
                              setSpace(!space);
                            }}
                            checked={space}
                          />
                        </>
                      )}
                      {inputType === InstructionTypes.Fight && (
                        <>
                          <label className="route-checkbox-label">Name:&nbsp;&nbsp;</label>
                          <input
                            type="text"
                            className="route-fight-input"
                            onChange={(e) => {
                              setInput(e.target.value);
                            }}
                            value={input}
                            onKeyDown={(e) => e.key === "Enter" && addInstructionToRoute()}
                          ></input>
                          <label className="route-checkbox-label">Turns:&nbsp;&nbsp;</label>
                          <select
                            className="route-select"
                            defaultValue={turns}
                            onChange={(e) => setTurns(Number(e.target.value))}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((number) => (
                              <option key={`turns-${number}`} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            {Array.from(Array(turns).keys()).map((_direction, index) => (
                              <div
                                key={`direction-${index}`}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <label className="route-checkbox-label">{index + 1}:&nbsp;&nbsp;</label>
                                <input
                                  type="text"
                                  className="route-fight-input"
                                  onChange={(e) => {
                                    let newDirections = [];
                                    newDirections.push(...directions);
                                    newDirections[index] = e.target.value;
                                    setDirections(newDirections);
                                  }}
                                  defaultValue={directions[index]}
                                  onKeyDown={(e) => e.key === "Enter" && addInstructionToRoute()}
                                ></input>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      {inputType === InstructionTypes.LevelUp && (
                        <>
                          <label className="route-checkbox-label">Stat?&nbsp;&nbsp;</label>
                          <select
                            className="route-select"
                            defaultValue={stat}
                            onChange={(e) => setStat(e.target.value as Stat)}
                          >
                            <option value="hp">HP</option>
                            <option value="fp">FP</option>
                            <option value="bp">BP</option>
                          </select>
                          <label className="route-checkbox-label">HP:&nbsp;&nbsp;</label>
                          <select
                            className="route-select"
                            defaultValue={hp}
                            onChange={(e) => setHP(Number(e.target.value))}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65].map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                          <label className="route-checkbox-label">FP:&nbsp;&nbsp;</label>
                          <select
                            className="route-select"
                            defaultValue={fp}
                            onChange={(e) => setFP(Number(e.target.value))}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65].map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                          <label className="route-checkbox-label">BP:&nbsp;&nbsp;</label>
                          <select
                            className="route-select"
                            defaultValue={bp}
                            onChange={(e) => setBP(Number(e.target.value))}
                          >
                            {[3, 6, 9, 12, 15, 18, 21, 24, 27, 30].map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                      {inputType === InstructionTypes.Rebadge && (
                        <>
                          <label className="route-checkbox-label">Directions:&nbsp;&nbsp;</label>
                          <select
                            className="route-select"
                            defaultValue={turns}
                            onChange={(e) => setTurns(Number(e.target.value))}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((number) => (
                              <option key={`turns-${number}`} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            {Array.from(Array(turns).keys()).map((_direction, index) => (
                              <div
                                key={`rebadge-direction-${index}`}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <label className="route-checkbox-label">{index + 1}:&nbsp;&nbsp;</label>
                                <input
                                  type="text"
                                  className="route-fight-input"
                                  onChange={(e) => {
                                    let newRebadgeDirections = [];
                                    newRebadgeDirections.push(...rebadgeDirections);
                                    newRebadgeDirections[index] = e.target.value;
                                    setRebadgeDirections(newRebadgeDirections);
                                  }}
                                  onKeyDown={(e) => e.key === "Enter" && addInstructionToRoute()}
                                  defaultValue={directions[index]}
                                ></input>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <button className="route-editor-control" onClick={addInstructionToRoute}>
                        Add
                      </button>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
