import { useEffect } from "react";
import { PageProps } from "../types/PageProps";

export default function NotFound({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });
  return (
    <>
      <h1 className="heading">Page Not Found</h1>
      <p className="normal-text">
        The page you are looking for does not exist. This may be due to ongoing website construction.
      </p>
    </>
  );
}
