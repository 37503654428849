import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PageProps } from "../types/PageProps";
import { categories } from "../data/categories";
import { Route } from "../types/Route";

export default function RoutePage({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });
  const categoryPath = useLocation().pathname;

  const category = categories.find((category) => category.path === categoryPath.split("/")[2]);
  const route = category?.routes.find((route) => route.path === categoryPath.split("/")[3]) as Route;

  return (
    <>
      {category && (
        <h1 className="heading">
          {category.name} - {route.level}
        </h1>
      )}
      <dl className="list">
        {route.description && (
          <>
            <dt className="detail-title">Description</dt>
            <dd>{route.description}</dd>
          </>
        )}
        {route.versions && (
          <>
            <dt className="detail-title">Versions</dt>
            <dd>{route.versions}</dd>
          </>
        )}
        {route.external && (
          <>
            <dt className="detail-title">External Document</dt>
            <dd>
              <a target="_blank" rel="noopener noreferrer" href={route.external}>
                Click here
              </a>
            </dd>
          </>
        )}
        {route.videoTutorials && (
          <>
            <dt className="detail-title">Video Tutorials</dt>
            {route.videoTutorials.map((video, i) => (
              <dd key={`${i}.${video.author}`}>
                <a target="_blank" rel="noopener noreferrer" href={video.url}>
                  {video.author}
                </a>
              </dd>
            ))}
          </>
        )}
        {route.requiredTricks && (
          <>
            <dt className="detail-title">Required Tricks</dt>
            {route.requiredTricks.map((trick, i) => (
              <dd key={`${i}.${trick.name}`}>
                <Link to={trick.path}>{trick.name}</Link>
              </dd>
            ))}
          </>
        )}
        {route.expectedTricks && (
          <>
            <dt className="detail-title">Expected Tricks</dt>
            {route.expectedTricks.map((trick, i) => (
              <dd key={`${i}.${trick.name}`}>
                <Link to={trick.path}>{trick.name}</Link>
              </dd>
            ))}
          </>
        )}
        {route.optionalTricks && (
          <>
            <dt className="detail-title">Optional Tricks</dt>
            {route.optionalTricks.map((trick, i) => (
              <dd key={`${i}.${trick.name}`}>
                <Link to={trick.path}>{trick.name}</Link>
              </dd>
            ))}
          </>
        )}
      </dl>
      {route.content}
    </>
  );
}
