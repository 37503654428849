import { useEffect } from "react";
import { Link } from "react-router-dom";
import { PageProps } from "../types/PageProps";

export default function Changelog({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });
  const items = [
    {
      title: <>6/29/2023</>,
      content: (
        <>
          Fixed a bug causing the video tutorial links to not be clickable on the{" "}
          <Link to="/Tricks/Early_Lakilester">Early Lakilester</Link> page. Fixed errors on the{" "}
          <Link to="/Tricks/Bowless_Green_Station">Bowless Green Station</Link> and{" "}
          <Link to="/Tricks/Blue_Switch_Jailbreak">Blue Switch Jailbreak</Link> articles. Added an additional note to{" "}
          <Link to="/Tricks/Raphael_Skip">Raphael Skip</Link> with visual cues for the inputs when falling around the
          loading zone.
        </>
      ),
    },
    {
      title: <>5/25/2023</>,
      content: (
        <>
          Updated the rest of the gifs on the <Link to="/Mechanics">Mechanics</Link> pages. All gifs on the website
          should now be up to the new PMA standard.
        </>
      ),
    },
    {
      title: <>5/24/2023</>,
      content: (
        <>
          Added Seed Dupe routes for <Link to="/Categories/Any_(No_ACE)">Any% (No ACE)</Link>,{" "}
          <Link to="/Categories/All_Cards">All Cards</Link>, and{" "}
          <Link to="/Categories/Any_(No_Peach_Warp)">Any% (No Peach Warp)</Link>. Added the{" "}
          <Link to="/Categories/Glitchless">Glitchless</Link> Hybrid route. Updated several gifs on the{" "}
          <Link to="/Mechanics">Mechanics</Link> pages. Fixed some bugs on the{" "}
          <Link to="/Bingo_Generator">Bingo Generator</Link> and added an extra button for generating a random seed
          instead of a set seed.
        </>
      ),
    },
    {
      title: <>5/11/2023</>,
      content: (
        <>
          Added the <Link to="/Routes/Any_(No_Peach_Warp)/Beginner">Any% (No Peach Warp) Beginner</Link> and{" "}
          <Link to="/Routes/Any_(No_Peach_Warp)/Expert">Any% (No Peach Warp) Expert</Link> routes.
          <br />
          Added the <Link to="/Routes/All_Bosses/Beginner">All Bosses Beginner</Link> and{" "}
          <Link to="/Routes/All_Bosses/Expert">All Bosses Expert</Link> routes.
        </>
      ),
    },
    {
      title: <>5/10/2023</>,
      content: (
        <>
          Added the <Link to="/Routes/Any_(No_ACE)/Expert">Any% (No ACE) Expert</Link> route, complete with toggles for{" "}
          <Link to="/Tricks/Jr_Skip">Jr Skip</Link> and <Link to="/Glitches/Item_Duplication">Item Duplication</Link>.
          Your browser will also remember which route you were looking at if you leave the page and come back.
          <br />
          The bingo generator also remembers your last seed, so it now no longer sends you to a new tab.
          <br />
          Added <Link to="/Routes/All_Cards/Beginner">All Cards Beginner</Link>,{" "}
          <Link to="/Routes/All_Cards/Intermediate">All Cards Intermediate</Link>, and{" "}
          <Link to="/Routes/All_Cards/Expert">All Cards Expert</Link>.
          <br />
          The changelog can now be found on the <Link to="/Home">Home</Link> page rather than{" "}
          <Link to="/Resources">Resources</Link>.
        </>
      ),
    },
    {
      title: <>5/9/2023</>,
      content: (
        <>
          We made the move to PaperMarioArchives v2! It may not look very different to the casual observer, but it has
          been completely overhauled from the ground up. For those who may not know, I have been struggling to solve
          issues with website expansion causing the build process to fail. I shelved the problem, deciding to wait for
          the day that I became powerful enough to solve this problem myself. About a year later, having received formal
          training in React now as a professional Software Engineer, I now have the power to do it right this time.
          PaperMarioArchives is now a fine React App specimen. It is much easier to update and should perform better on
          all systems. In addition to all of these behind-the-scenes updates, the Route pages have returned! I will be
          rolling them back out slowly over the next week or so, just to make sure that everything is working without
          them first. Now that we're on the latest version of React, I should no longer have issues expanding the
          website. Fingers crossed for the future of PaperMarioArchives!
        </>
      ),
    },
    {
      title: <>6/24/2024</>,
      content: (
        <>
          I updated the app to TypeScript because TypeScript is good and fair. A lot of code clutter has been cleaned up
          and some improvements have been made to the Bingo tips. It's now a lot easier for me to update the app too. I
          also updated some of the discovered by sections for various tricks with new information from r0bd0g.
        </>
      ),
    },
    {
      title: <>7/14/2024</>,
      content: (
        <>
          Added the <Link to="/Route_Library">Route Library</Link>. It's still somewhat fresh now but this is a feature 
          that I've had a lot of fun working on, and there are tons of ways to expand it moving forward!
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="heading">Changelog</h1>
      <dl className="list">
        {items.map((item, i) => (
          <div key={i}>
            <dt className="detail-title">{item.title}</dt>
            <dt>{item.content}</dt>
            <br />
          </div>
        ))}
      </dl>
    </>
  );
}
