import { Link } from "react-router-dom";
import { Category } from "../../types/Category";
import { allBossesBeginner } from "../routes/allbossesbeginner";
import { allBossesExpert } from "../routes/allbossesexpert";

export const allBosses: Category = {
  name: "All Bosses",
  path: "All_Bosses",
  description: (
    <>
      Beat Paper Mario as fast as possible while also defeating all unique minor, major, and optional bosses:
      <ul style={{ marginLeft: "1rem" }}>
        <li>All Chapter Bosses</li>
        <li>All Jr Troopa Fights</li>
        <li>All Bloopers</li>
        <li>All Dojo Fights</li>
        <li>Goomba King</li>
        <li>Buzzar</li>
        <li>Lantern Ghost</li>
        <li>Anti Guy</li>
        <li>Fuzzipede</li>
        <li>Kent C. Koopa</li>
        <li>Lakilester</li>
        <li>Monstar</li>
        <li>Anti Guys Unit</li>
      </ul>
      Note: Story battles containing only enemies you can encounter normally in the overworld are not required.
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#All_Bosses",
  routes: [
    {
      level: "Beginner",
      path: "Beginner",
      tag: "No Clippy Boots or Item Duplication - Early Whale optional",
      external: "https://docs.google.com/document/d/1Wn2ILVRcP8dG56w08pJPHee2T4IZgXmBBJMyMeP5nHM/edit?usp=sharing",
      description: (
        <>
          This is a beginner <Link to="/All_Bosses">All Bosses</Link> route that has a high chance to win. This route
          does a slightly modified chapter order compared to the <Link to="/All_Bosses_Expert">Expert</Link> route to
          avoid doing <Link to="/Clippy_Boots">Clippy Boots</Link>. <Link to="/Item_Duplication">Item Duplication</Link>{" "}
          is also not used in this route, making it viable on all versions and lessening the impact of item menuing
          errors.
        </>
      ),
      versions: "All",
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Chapter 7 Sushie Glitch",
          path: "/Tricks/Chapter_7_Sushie_Glitch",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Pie Jumps",
          path: "/Tricks/Pie_Jumps",
        },
        {
          name: "Buzzar Skip",
          path: "/Tricks/Buzzar_Skip",
        },
        {
          name: "Record Skip",
          path: "/Tricks/Record_Skip",
        },
        {
          name: "Stanley_Save",
          path: "/Tricks/Stanley_Save",
        },
        {
          name: "Yakkey Trickshot",
          path: "/Tricks/Yakkey_Trickshot",
        },
        {
          name: "Quick Escape",
          path: "/Tricks/Quick_Escape",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Lava Piranha Out Of Bounds",
          path: "/Tricks/Lava_Piranha_Out_Of_Bounds",
        },
        {
          name: "Pink Station Clips",
          path: "/Tricks/Pink_Station_Clips",
        },
        {
          name: "Early Whale",
          path: "/Tricks/Early_Whale",
        },
        {
          name: "Yellow Berry Skip",
          path: "/Tricks/Yellow_Berry_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
        {
          name: "Ice Staircase Skip",
          path: "/Tricks/Ice_Staircase_Skip",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
      ],
      content: allBossesBeginner,
    },
    {
      level: "Expert",
      path: "Expert",
      tag: "Does Early Whale, Clippy Boots, and Item Duplication",
      external: "https://docs.google.com/document/d/1pei7RBl0kehvcyk4m0ytPbKrm8coe6Z8E3IA8YNUoMM/edit?usp=sharing",
      description: (
        <>
          This is an optimized <Link to="/All_Bosses">All Bosses</Link> route that has a high chance to win. This route
          does <Link to="/Clippy_Boots">Clippy Boots</Link> and <Link to="/Item_Duplication">Item Duplication</Link> to
          keep the same general structure as the World Record route, but gets Bow to make the fights much safer. Dizzy
          Shell strategies are optional.
        </>
      ),
      versions: "NTSC-J",
      requiredTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Record Skip",
          path: "/Tricks/Record_Skip",
        },
        {
          name: "Stanley_Save",
          path: "/Tricks/Stanley_Save",
        },
        {
          name: "Quick Escape",
          path: "/Tricks/Quick_Escape",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Lava Piranha Out Of Bounds",
          path: "/Tricks/Lava_Piranha_Out_Of_Bounds",
        },
        {
          name: "Pink Station Clips",
          path: "/Tricks/Pink_Station_Clips",
        },
        {
          name: "Early Whale",
          path: "/Tricks/Early_Whale",
        },
        {
          name: "Clippy Boots",
          path: "/Tricks/Clippy_Boots",
        },
        {
          name: "Chapter 7 Sushie Glitch",
          path: "/Tricks/Chapter_7_Sushie_Glitch",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Pie Jumps",
          path: "/Tricks/Pie_Jumps",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Yakkey Trickshot",
          path: "/Tricks/Yakkey_Trickshot",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Yellow Berry Skip",
          path: "/Tricks/Yellow_Berry_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
        {
          name: "Ice Staircase Skip",
          path: "/Tricks/Ice_Staircase_Skip",
        },
      ],
      content: allBossesExpert,
    },
    {
      level: "WR Route (Old)",
      tag: "Skips Bow - Very Risky",
      external: "https://docs.google.com/document/d/16HiROGPO_00tZ-whHHdr3oUrQfpKJm5yUXz56groRYs/edit?usp=sharing",
    },
  ],
};
