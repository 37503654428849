import { useEffect, useState } from "react";

type FourRouteSwitcherProps = {
  route1: JSX.Element;
  route2: JSX.Element;
  route3: JSX.Element;
  route4: JSX.Element;
  toggle1: string;
  toggle2: string;
};

export default function FourRouteSwitcher({
  route1,
  route2,
  route3,
  route4,
  toggle1,
  toggle2,
}: FourRouteSwitcherProps) {
  let initialState1 = "false";
  let initialState2 = "false";
  if (typeof window !== "undefined" && typeof window.localStorage !== "undefined") {
    if (localStorage.getItem("Any% (No ACE) - Expert (Old) Jr Skip") === "true") {
      initialState1 = "true";
    } else {
      localStorage.setItem("Any% (No ACE) - Expert (Old) Jr Skip", "false");
    }
    if (localStorage.getItem("Any% (No ACE) - Expert (Old) Item Duplication") === "true") {
      initialState2 = "true";
    } else {
      localStorage.setItem("Any% (No ACE) - Expert (Old) Item Duplication", "false");
    }
  }
  const [switch1, setSwitch1] = useState(initialState1);
  const [switch2, setSwitch2] = useState(initialState2);
  const [route, setRoute] = useState(route1);

  useEffect(() => {
    if (switch1 === "true" && switch2 === "true") {
      setRoute(route4);
    } else if (switch1 === "false" && switch2 === "true") {
      setRoute(route3);
    } else if (switch1 === "true" && switch2 === "false") {
      setRoute(route2);
    } else {
      setRoute(route1);
    }
    localStorage.setItem("Any% (No ACE) - Expert (Old) Jr Skip", switch1);
    localStorage.setItem("Any% (No ACE) - Expert (Old) Item Duplication", switch2);
  }, [switch1, switch2, route1, route2, route3, route4]);

  function handleSwitch1() {
    setSwitch1(`${switch1 === "false"}`);
  }

  function handleSwitch2() {
    setSwitch2(`${switch2 === "false"}`);
  }

  return (
    <>
      <dl>
        <p></p>
        <dt className="detail-title">
          <label className="switch">
            <input type="checkbox" onChange={handleSwitch1} checked={switch1 === "true"}></input>
            <span className="slider round"></span>
          </label>
          &nbsp;&nbsp;{toggle1}
        </dt>
        <p></p>
        <dt className="detail-title">
          <label className="switch">
            <input type="checkbox" onChange={handleSwitch2} checked={switch2 === "true"}></input>
            <span className="slider round"></span>
          </label>
          &nbsp;&nbsp;{toggle2}
        </dt>
      </dl>
      <div>{route}</div>
    </>
  );
}
