import { RouteComponentProps } from "../../types/RouteComponents";

export const RouteHeading = ({ instruction }: RouteComponentProps) => {
  const { content } = instruction;

  return (
    <>
      <h1 className="heading">{content}</h1>
      <br />
    </>
  );
};
