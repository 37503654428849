import { Link } from "react-router-dom";

export const allCardsIntermediate = (
  <>
    <h1 className="heading">Prologue</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 3 bush coins [3 coins]</dt>
      <dt className="route-instruction-bold">- 10 coins from the brick block on the ground. [13 coins]</dt>
      <dt className="route-instruction-bold">- Fire Flower and 4 trampoline coins. [17 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Equip Power Jump immediately</dt>
      <dt className="route-instruction-bold">- Two coin blocks on the next screen. [19 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump Red / Headbonk Red</dd>
      <dd className="route-instruction-bold">T2: Power Jump Blue / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba King</dt>
      <dd className="route-instruction-bold">T1: Power Jump King / Headbonk Tree</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Headbonk</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy a Fire Flower and a Fright Jar [4 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Magikoopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Black_Toad_Skip">Black Toad Skip</Link>
      </dt>
    </dl>

    <h1 className="heading">Chapter 1</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 1 coin block [5 coins]</dt>
      <dt className="route-instruction-bold">- Fright Jar</dt>
      <dt className="route-instruction-bold">- POW Block</dt>
      <dt className="route-instruction-bold">- 10 coin block [15 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: Fire Flower (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Bush coin in Koopa Village [16 coins]</dt>
      <dt className="route-instruction-bold">- 5 coins on ground [21 coins]</dt>
      <dt className="route-instruction-bold">- Thunder Bolt</dt>
      <dt className="route-instruction-bold">- Hidden Fire Flower</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Koopa with a jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Key Fight</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">
        T1: Power Jump / Untimed Shell Toss (Use untimed Power Shell instead if you missed the first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Bounce</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Staircase_Skip">Staircase Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Hit the trap block and get Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jail Fight</dt>
      <dd className="route-instruction-bold">T1: Fright Jar (Up 1) / Bomb Koopa, Untimed Body Slam Bob-omb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 9 coins from Jail Fight [30 coins]</dt>
      <dt className="route-instruction">- Go left into the pit</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike with Jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 1</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">T1: Power Jump 2nd Koopa / Bomb 1st Koopa</dd>
      <dd className="route-instruction-bold">T2: Jump x1</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 2</dt>
      <dd className="route-instruction-bold">T1: Fright Jar (Up 1) / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Power Jump / Untimed Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 10 fire bar coins [40 coins]</dt>
      <dt className="route-instruction">- Go upstairs and left for the Key</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Pie_Jumps">Pie Jumps</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If a Fright Jar failed, hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Cannons with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Cannons</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Thunder Bolt (Down 1) / Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- 10 cannon coins [50 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T3: POW Block (Up 1) / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Yes to all 3 questions</dt>
    </dl>

    <h1 className="heading">Parakarry</h1>

    <dl className="list">
      <dt className="route-instruction">- Say No to both tutorials</dt>
      <dt className="route-instruction-bold">- Honey Syrup</dt>
      <dt className="route-instruction">- Say Yes to Jr Troopa</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr Troopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T3: Hammer</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy Speedy Spin</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 1st Magical Seed</dt>
      <dt className="route-instruction">- Take Train to Chapter 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Find 3 Letters and bring them to Parakarry</dt>
      <dt className="route-instruction-bold">- Get 1 Whacka Bump</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Rock_Climb">Rock Climb</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Early_Seed">Early Seed</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Parakarry</dt>
      <dt className="route-instruction-bold">- Go back and get the Magical Seed if you didn't do Early Seed</dt>
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction">- Go back to Toad Town</dt>
    </dl>

    <h1 className="heading">Sushie</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Blue_House_Skip">Blue House Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce / Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go talk to Kolorado by the volcano</dt>
      <dt className="route-instruction-bold">- Jammin' Jelly behind the tree</dt>
      <dt className="route-instruction-bold">- Get Sushie</dt>
    </dl>

    <h1 className="heading">Bow</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Power Quake</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Quake (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Blue pipe to sewers, go left to Dark Koopas</dt>
      <dt className="route-instruction-bold">- Let them all First Strike you for peril</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x2</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If you get an FP drop, do the 8 FP fight</dt>
      <dt className="route-instruction">- If you get a POW Block, do the POW Block fight</dt>
      <dt className="route-instruction">- If you get neither, eat the Honey Syrup (Up 1) and do the 8 FP fight</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x3 (8 FP)</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x3 (POW Block)</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: POW Block / Swap to Kooper (Down 2)</dd>
      <dd className="route-instruction-bold">T2: Power Quake / Power Shell</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x4</dt>
      <dd className="route-instruction-bold">T0: Don't block (peril)</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Swap to Bombette (Up 2 if Sushie, Down 1 if Kooper)</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Pick up 2 FP drops if possible</dt>
      <dt className="route-instruction">- Take pipe to Boo's Mansion</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Do <Link to="/Record_Skip">Record Skip</Link> or play the record minigame
      </dt>
      <dt className="route-instruction">- Place the Weight</dt>
      <dt className="route-instruction">- Blow up the wall</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1) while the Super Boots chest opens</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Get Boo Portrait</dt>
      <dt className="route-instruction-bold">- Get Bow</dt>
    </dl>

    <h1 className="heading">Chapter 3</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Get a Strange Leaf if you used the Honey Syrup and have less than 9 FP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Stanley_Save">Stanley Save</Link>
      </dt>
      <dt className="route-instruction-bold">- Repel Gel</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go left for the Key</dt>
      <dt className="route-instruction-bold">- Upgrade Parakarry (Up/Down 3)</dt>
      <dt className="route-instruction">- Get caught</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right</dt>
      <dt className="route-instruction-bold">
        - If you have less than 9 FP, eat Honey Syrup (Up 1) or Strange Leaf (Down 1) while unlocking the door
      </dt>
      <dt className="route-instruction">- Get Key in spike room</dt>
      <dt className="route-instruction-bold">- Mega Rush</dt>
      <dt className="route-instruction-bold">- Maple Syrup</dt>
      <dt className="route-instruction">- Get Key in Clubba room</dt>
      <dt className="route-instruction">- Get Yakkey</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Yakkey_Trickshot">Yakkey Trickshot</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Quick_Escape">Quick Escape</Link>
      </dt>
      <dt className="route-instruction">- Make sure you have 9 FP for the Heart</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Right before the fight:</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Mega Rush (Down 1)</dd>
      <dd className="route-instruction-bold">Unequip Speedy Spin (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Z, Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tubba's Heart</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x6 / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x4 (x5 and x5 works too) / Sky Dive if needed</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tubba Blubba</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say Yes to Bow</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Chapter_3_Card_LZS">Chapter 3 Card LZS</Link>
      </dt>
    </dl>

    <h1 className="heading">Chapter 5</h1>

    <dl className="list">
      <dt className="route-instruction">- Head to Chapter 5 through the Blue Pipe</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Raphael_Skip">Raphael Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Fire Shield</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ultra_Hammer_Early">Ultra Hammer Early</Link>
      </dt>
      <dt className="route-instruction-bold">- Ultra Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Peril from Fire Bars</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Flarakarry">Flarakarry</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Sushie (Down 2) while talking to Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lava Piranha - Block 0/3</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">
        T1: Power Bounce x5 / Do Nothing if 5 cap, Belly Flop if 4/3 cap - Life Shroom
      </dd>
      <dd className="route-instruction-bold">
        T2: Power Bounce x5 / Untimed Belly Flop if double 4 cap, Timed Belly Flop if T1 3 cap
      </dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T3: Squirt / Power Bounce x4</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x3</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Quiz: 1, 1, 1, 1, 1, 1, 1, 1, 1, 2</dt>
    </dl>

    <h1 className="heading">Chapter 4</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Volcano Vase from the chest in the jungle</dt>
      <dt className="route-instruction-bold">- Magical Seed from Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Sushie (Up/Down 3)</dt>
      <dt className="route-instruction">- Exit sewers with Sushie</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Up 2)</dt>
      <dt className="route-instruction">- Enter Toy Box</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Right switch to Green Station (Down 1)</dt>
      <dt className="route-instruction-bold">- Right switch to Pink Station (Down 2)</dt>
      <dt className="route-instruction">- Fix tracks</dt>
      <dt className="route-instruction">- Go right in Pink Station</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Pink_Station_Clips">Pink Station Clips</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Frying Pan</dt>
      <dt className="route-instruction-bold">- Left switch to Red Station (Down 1)</dt>
      <dt className="route-instruction-bold">- Heart Block</dt>
      <dt className="route-instruction-bold">- Hidden Volt Shroom</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Big Lantern Ghost - Block 1/2</dt>
      <dd className="route-instruction-bold">T1: Volt Shroom (Mash A) / Do Nothing </dd>
      <dd className="route-instruction-bold">T2: Do Nothing / Swap to Goombario (Up 3)</dd>
      <dd className="route-instruction-bold">T3: Headbonk x2 / Power Bounce x5</dd>
      <dd className="route-instruction-bold">T4: Headbonk x2 / Power Bounce x4</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Upgrade Watt (Up 2)</dt>
      <dt className="route-instruction">- Fight General Guy</dt>
    </dl>
    <dl className="list">
      <dt className="fight">General Guy</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">T1: Jump x2 / Electro Dash</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
      <dd className="route-instruction-bold">T3: Jump x2 / Power Shock</dd>
      <dd className="route-instruction-bold">T4: Jump x2</dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T5: Repel Gel (Down 1) / Electro Dash</dd>
      <dd className="route-instruction-bold">T6: Power Bounce x4 / Electro Dash</dd>
      <dd className="route-instruction-bold">T7: Jump x2 / Electro Dash </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Chapter_4_Card_LZS">Chapter 4 Card LZS</Link>
      </dt>
      <dt className="route-instruction-bold">- Left switch to Blue Station (Down 2)</dt>
    </dl>

    <h1 className="heading">Chapter 2</h1>

    <dl className="list">
      <dt className="route-instruction">Forever Forest</dt>
      <dd className="route-instruction">Right x2</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction-bold">Go left and get the 4th Magical Seed</dd>
      <dd className="route-instruction">Right x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Green pipe to sewers</dt>
      <dt className="route-instruction">- Break the stone block and fight Electro Blooper</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Electro Blooper - Block 0/2</dt>
      <dd className="route-instruction-bold">T1: Do Nothing / Turbo Charge</dd>
      <dd className="route-instruction-bold">T2: Do Nothing / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Jump x1 / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x6 / Switch to Parakarry (Up 2)</dd>
      <dd className="route-instruction-bold">T5: Shell Shot / Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Left pipe</dt>
      <dt className="route-instruction">- Enter Desert</dt>
      <dt className="route-instruction">- Alternate left and up to reach Ruins (start with left)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Key in sand (bottom)</dt>
      <dt className="route-instruction-bold">- Go right for second Key</dt>
      <dt className="route-instruction">- Go to bottom-right room</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike with Jump, beware of heart drops</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Pyramid</dt>
      <dd className="route-instruction-bold">T0: First Strike</dd>
      <dd className="route-instruction-bold">T1: Jump x1 (Star Storm instead if you missed the First Strike)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Open both doors</dt>
      <dt className="route-instruction">- Drop down and go right</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Lunar</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Super Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go down-left and blow up wall</dt>
      <dt className="route-instruction-bold">- Upgrade Bombette (Down 2)</dt>
      <dt className="route-instruction">- Blow up wall</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Diamond</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Pyramid (Up 1), Diamond (Up 1), Lunar (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Tutankoopa</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x4 / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If you get a 3 cap, swap to Parakarry on T1 and Sky Dive T2</dt>
      <dt className="route-instruction">
        - If you had double Fright Jar fail, level up BP and do the top-left mole last
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Chapter_2_Card_LZS">Chapter 2 Card LZS</Link>
      </dt>
      <dt className="route-instruction">- Leave Ruins</dt>
      <dt className="route-instruction">- Alternate right and down to reach Outpost (start with right)</dt>
      <dt className="route-instruction">- Blue pipe</dt>
      <dt className="route-instruction-bold">- Quick Change</dt>
      <dt className="route-instruction-bold">- Place Seeds</dt>
    </dl>

    <h1 className="heading">Chapter 6</h1>

    <dl className="list">
      <dt className="route-instruction">- Mole order: Top left, then clockwise</dt>
      <dt className="route-instruction-bold">- Get 2 Red Berries</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Top Left Mole</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Top Right Mole - Block 0/3</dt>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Right Mole</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Maple Syrup (Down 2)</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
      <dt className="route-instruction-bold">- First strike the bottom left mole with Jump or Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Left Mole</dt>
      <dd className="route-instruction-bold">T0: First Strike</dd>
      <dd className="route-instruction-bold">T1: Shell Shot 2nd Mole / Untimed Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Jump (A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Magical Bean</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Red Berry (Mash A)</dt>
      <dt className="route-instruction-bold">- Get Yellow Berry and give to Yellow Flower</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction-bold">- Get Blue Berry and give to Blue Flower</dt>
      <dt className="route-instruction">
        - The hidden Shooting Star is slightly faster than Star Storm if you can get it quickly
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lakitu Fight</dt>
      <dd className="route-instruction-bold">T1: Shell Shot Lakitu / Star Storm or Shooting Star (Mash A)</dd>
      <dt className="fight">Spinyless - Block 0/1</dt>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
      <dt className="fight">Spiny Spawn</dt>
      <dd className="route-instruction-bold">T2: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Talk to Rosie</dt>
      <dt className="route-instruction">- Talk to Posie</dt>
      <dt className="route-instruction">- Talk to Rosie</dt>
      <dt className="route-instruction-bold">- Dizzy Dial</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction-bold">
        - Bubble Berry or <Link to="/Early_Lakilester">Early Lakilester</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Shooting Star</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Spike Fight - Block 3/3</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Swap to Parakarry (Down 1)</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hidden Thunder Rage</dt>
      <dt className="route-instruction">- Dizzy Dial works 100% on Lakitus, and the Magikoopa can't kill you</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Machine Fight</dt>
      <dd className="route-instruction-bold">T1: Dizzy Dial (Down 2) / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Thunder Rage (Mash A) / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Whacka Bump while planting (Mash A)</dt>
      <dt className="route-instruction-bold">- S. Jump Charge</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Quick Change (Down 3)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Huff N' Puff - Block 1/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Swap to Bow (Up 3) / Outta Sight</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Swap to Parakarry (Up 1) / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-hp">- Level Up: HP (Spiny Spawn)</dt>
      <dt className="route-instruction-bold">15 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Transform into a Koopatrol, talk to the guard at the top, go down and transform into a Clubba, and then head
        back to the top
      </dt>
    </dl>

    <h1 className="heading">Chapter 7</h1>

    <dl className="list">
      <dt className="route-instruction">- Go to sewers through the pipe, break the panel, and go to the Ultra Boots</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Clippy_Boots">Clippy Boots</Link>
      </dt>
      <dt className="route-instruction">- Go to the Volcano and Peach Warp</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Blue Key</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Mirror_Clip">Mirror Clip</Link>
      </dt>
      <dt className="route-instruction">- Break panel</dt>
      <dt className="route-instruction">- Hit Red button</dt>
      <dt className="route-instruction-bold">- Red Key (4th Bombette is real)</dt>
      <dt className="route-instruction">- Hit Blue button</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 1</dt>
      <dd className="route-instruction-bold">T1: Jump x2 / Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-hp">- Level Up: HP (Spinyless)</dt>
      <dt className="route-instruction-bold">15 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 2</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away (Up 1) / Do Nothing</dd>
      <dt className="fight">Spinyless</dt>
      <dd className="route-instruction-bold">T2: Jump x2 / Bomb</dd>
      <dt className="fight">Spiny Spawn or multiple dinks</dt>
      <dd className="route-instruction-bold">T2: Up &amp; Away</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 3</dt>
      <dd className="route-instruction-bold">T1: Shooting Star / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- P-Up D-Down</dt>
      <dt className="route-instruction-bold">- Hidden Jammin' Jelly</dt>
      <dt className="route-instruction-bold">- Palace Key</dt>
      <dt className="route-instruction-bold">- Swap to Watt (Down 4) while opening the lock</dt>
      <dt className="route-instruction-bold">- Hit Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Crystal King - Block 2/4</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Untimed Electro Dash a Crystal Bit</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Electro Dash</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Untimed Electro Dash a Crystal Bit</dd>
      <dd className="route-instruction-bold">T4: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x5 / Electro Dash</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP (Spiny Spawn and 2x Fright Fail)</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>

    <h1 className="heading">Chapter 8</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Upgrade Sushie (Up 2) on the stairs (sewers is comparable speed)</dt>
      <dt className="route-instruction-bold">- Repel Gel behind the tree to the right of the snowmen</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go to Shooting Star Summit</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right from the locked door and fight the Koopatrol</dt>
      <dt className="route-instruction-bold">
        - Do a different fight depending on if you leveled up on Crystal King (You have 25 FP)
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols (Leveled Up)</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols (Not Leveled Up)</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Swap to Bombette (Up/Down 4) / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP (RNG-Dependent)</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Jail Life Shroom from the lower box on the right</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Block_Clip">Block Clip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Fast_Flood_Room">Fast Flood Room</Link>
      </dt>
      <dt className="route-instruction-bold">
        - <Link to="/Cannonless">Cannonless</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Quiz: 1, 1, 2, 1, 1, 3, 2</dt>
      <dt className="route-instruction">- Maze: Up, Down, Down, Up, Down, Up</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block if you don't have full stats</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away (Up 2) / Air Lift as needed</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you had to Air Lift twice or took damage, go back and hit the Heart Block
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 2/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Swap to Bow (Down 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Outta Sight</dd>
      <dd className="route-instruction-bold">T5: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Down 2)</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip P-Up D-Down (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Z)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 (Up 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x5</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Outta Sight</dd>
      <dd className="route-instruction-bold">T4: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Rebadge right before Final Bowser</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Speedy Spin (R)</dd>
      <dd className="route-instruction-bold">Equip Fire Shield (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Always go for the highest bounce possible</dt>
      <dt className="route-instruction">- If your bounce caps are good, you will win earlier than the route shows</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 1</dt>
      <dd className="route-instruction-bold">T1: Power Bounce (Up 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce</dd>
      <dd className="route-instruction-bold">T3: Star Beam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Follow the beamless route, then switch to your fight when Bowser uses Star Shield
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Beamless</dt>
      <dd className="route-instruction-bold">T1: Peach Beam / Water Block - Block</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot - Block</dd>
      <dd className="route-instruction-bold">T3: Power Bounce / Outta Sight</dd>
      <dd className="route-instruction-bold">T4: Repel Gel</dd>
      <dd className="route-instruction-bold">T5: Turbo Charge / Power Bounce</dd>
      <dd className="route-instruction-bold">T6: Power Bounce / Electro Dash - Life Shroom</dd>
      <dd className="route-instruction-bold">T7: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3</dt>
      <dd className="route-instruction-bold">T4: Repel Gel</dd>
      <dd className="route-instruction-bold">T5: Peach Beam / Turbo Charge</dd>
      <dd className="route-instruction-bold">T6: Power Bounce / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 7</dt>
      <dd className="route-instruction-bold">T8: Peach Beam / Electro Dash - Life Shroom</dd>
      <dd className="route-instruction-bold">T9: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4</dt>
      <dd className="route-instruction-bold">T5: Peach Beam / Turbo Charge</dd>
      <dd className="route-instruction-bold">T6: Power Bounce / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 5</dt>
      <dd className="route-instruction-bold">T6: Peach Beam / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 6</dt>
      <dd className="route-instruction-bold">T7: Peach Beam / Electro Dash</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (R)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - <Link to="/Luigi_Skip">Luigi Skip</Link>
      </dt>
    </dl>
  </>
);
