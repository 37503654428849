import { Link } from "react-router-dom";
import { Category } from "../../types/Category";

export const glitchless: Category = {
  name: "Glitchless",
  path: "Glitchless",
  description: (
    <>
      Beat Paper Mario as fast as possible without using any of the following glitches:
      <ul style={{ marginLeft: "1rem" }}>
        <li>
          <Link to="/Out_Of_Bounds">Out Of Bounds</Link>
        </li>
        <li>
          Clipping (<Link to="/Laki_Clip">Laki Clips</Link>, <Link to="/Boots_Clip">Super/Ultra Boots Clips</Link>)
        </li>
        <li>
          <Link to="/Item_Duplication">Item Duplication</Link>
        </li>
        <li>
          <Link to="/Block_Clip">Skipping the Chapter 8 Basement</Link>
        </li>
        <li>
          Overlapping Text Boxes (<Link to="/Yakkey_Trickshot">Yakkey Trickshot</Link>, Goombario Text Glitch)
        </li>
        <li>
          Anything that skips <Link to="/Story_Triggers">Story Triggers</Link>, such as{" "}
          <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
        </li>
      </ul>
      The following techniques ARE allowed:
      <ul style={{ marginLeft: "1rem" }}>
        <li>
          <Link to="/Quick_Jumps">Quick Jumps</Link>
        </li>
        <li>
          <Link to="/Hammer_Cancel">Hammer Canceling</Link>
        </li>
        <li>
          <Link to="/Spin_Cancel">Spin Canceling</Link>
        </li>
        <li>Doing an action while entering a cutscene</li>
        <li>Overlapping two cutscenes</li>
        <li>
          <Link to="/Pie_Jumps">Pie Jumps</Link>
        </li>
        <li>Using Parakarry to reach the bed in Tubba Blubba's Castle</li>
        <li>
          <Link to="/Cannon_Jumps">Cannon Jumps</Link>
        </li>
        <li>
          <Link to="/Luigi_Skip">Luigi Skip</Link>
        </li>
      </ul>
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#Glitchless",
  videoTutorials: [
    {
      author: "JCog's Beginner Glitchless Tutorial",
      url: "https://www.youtube.com/watch?v=tv2y1FF6HAU",
    },
  ],
  routes: [
    {
      level: "Beginner",
      tag: "Extra notes for Beginners",
      external: "https://docs.google.com/document/d/1B5Qr9tGCYRlZ482D1lLgKIQQjM20a_hoDHlrlekAnXE/edit",
    },
    {
      level: "Intermediate",
      tag: "More concise - More difficult strategies",
      external: "https://docs.google.com/document/d/1vIFIDsWAB0KAc1T20tTHAPMhlP4HeUvJkgAg38yPuxM/edit",
    },
    {
      level: "Expert",
      tag: "Risky - Hurricane/Air Raid",
      external: "https://docs.google.com/document/d/1TBxBd8pXuTusPd7f2mh8LpzdkfIRTd5L_1UksrNA_xo/edit?usp=sharing",
    },
    {
      level: "Hybrid",
      tag: "Risky - Spook Ch6, Hurricane Ch7",
      external: "https://docs.google.com/document/d/1CopJ35mBHaXmkAr5uCukmfY9fN7i-lpQiq6upShGpiE/edit?usp=sharing",
    },
    {
      level: "WR Route",
      tag: "Risky - Spook/Mega Shock",
      external: "https://docs.google.com/document/d/1_ePw6iqKvgxQsl6SlvDfN4GZEbPdWXTjjY4YNXKnMJc/edit",
    },
  ],
};
