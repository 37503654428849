import { useEffect } from "react";
import { glitches } from "../data/glitches";
import { techniques } from "../data/techniques";
import { PageProps } from "../types/PageProps";
import { Link, useLocation } from "react-router-dom";
import { Mechanic } from "../types/Mechanic";

export default function MechanicPage({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });
  let { 2: path } = useLocation().pathname.split("/");
  let glitch = glitches.find((glitch) => glitch.path === path);
  let technique = techniques.find((technique) => technique.path === path);
  const mechanic: Mechanic = (glitch || technique) as Mechanic;

  return (
    <>
      {mechanic.name && <h1 className="heading">{mechanic.name}</h1>}
      <dl className="list">
        {mechanic.description && (
          <>
            <dt className="detail-title">Description</dt>
            <dd>{mechanic.description}</dd>
          </>
        )}
        {mechanic.mechanics && (
          <>
            <dt className="detail-title">Mechanics</dt>
            <dd>{mechanic.mechanics}</dd>
          </>
        )}
        {mechanic.versions && (
          <>
            <dt className="detail-title">Versions</dt>
            <dd>{mechanic.versions}</dd>
          </>
        )}
        {mechanic.categories && (
          <>
            <dt className="detail-title">Used in</dt>
            {mechanic.categories.map((category) => (
              <dd key={category.name}>
                <Link to={category.path}>{category.name}</Link>
              </dd>
            ))}
          </>
        )}
        {mechanic.tricks && (
          <>
            <dt className="detail-title">Used for</dt>
            {mechanic.tricks.map((trick) => (
              <dd key={trick.name}>
                <Link to={trick.path}>{trick.name}</Link>
              </dd>
            ))}
          </>
        )}
        {mechanic.discoveredBy && (
          <>
            <dt className="detail-title">Discovered by</dt>
            <dd>{mechanic.discoveredBy}</dd>
          </>
        )}
        {mechanic.videos && (
          <>
            <dt className="detail-title">Videos</dt>
            {mechanic.videos.map((video, i) => (
              <dd key={`${i}.${video.author}`}>
                <a target="_blank" rel="noopener noreferrer" href={video.url}>
                  {video.author}
                </a>
              </dd>
            ))}
          </>
        )}
        {mechanic.videoTutorials && (
          <>
            <dt className="detail-title">Video Tutorials</dt>
            {mechanic.videoTutorials.map((video, i) => (
              <dd key={`${i}.${video.author}`}>
                <a target="_blank" rel="noopener noreferrer" href={video.url}>
                  {video.author}
                </a>
              </dd>
            ))}
          </>
        )}
      </dl>
      {mechanic.content}
    </>
  );
}
