import { Link } from "react-router-dom";

type SidebarProps = {
  active: string;
  style: string;
  changeStyle: () => void;
};

function Sidebar({ active, style, changeStyle }: SidebarProps) {
  const links = [
    {
      name: "Home",
      to: "/Home",
    },
    {
      name: "Tips",
      to: "/Tips",
    },
    {
      name: "Tricks",
      to: "/Tricks",
    },
    {
      name: "Categories",
      to: "/Categories",
    },
    {
      name: "Routes",
      to: "/Routes",
    },
    // {
    //   name: "Route Library",
    //   to: "/Route_Library",
    // },
    {
      name: "Bingo",
      to: "/Bingo",
    },
    {
      name: "Mechanics",
      to: "/Mechanics",
    },
    {
      name: "Resources",
      to: "/Resources",
    },
  ];

  return (
    <div className="sidebar">
      {links.map((link, i) => (
        <Link key={i} className={link.name === active ? "active" : ""} to={link.to}>
          {link.name}
        </Link>
      ))}
      <button className="style-button" onClick={changeStyle}>
        {style === "light" ? "Dark Mode" : "Light Mode"}
      </button>
    </div>
  );
}

export default Sidebar;
