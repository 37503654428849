import { Link } from "react-router-dom";

export type BingoSquare = {
  name: string;
  types: string[];
  info: JSX.Element;
};

let bingoList: BingoSquare[][] = [];

bingoList[1] = [
  {
    name: "Dolly",
    types: ["dolly"],
    info: (
      <div>
        <p className="bingo-hint">
          The Dolly is found in the rightmost tree in the playground. Be sure to say no when Goombaria asks for it back!
        </p>
      </div>
    ),
  },
  {
    name: "Dried Shroom",
    types: ["driedshroom"],
    info: (
      <div>
        <p className="bingo-hint">
          Dried Shroom can be found in a bush on the left side of the first screen of Koopa Village. It can also be
          purchased in Dry Dry Outpost for 2 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Egg",
    types: ["egg"],
    info: (
      <div>
        <p className="bingo-hint">This is found in a bush near the train station in Mt. Rugged.</p>
      </div>
    ),
  },
  {
    name: "Fire Flower",
    types: ["fireflower"],
    info: (
      <div>
        <p className="bingo-hint">
          A Fire Flower can be found at the top of the spring outside the playground or in a hidden block on the right
          side of the bridge to Koopa Bros Fortress. Alternatively, they can be bought in Shroom Grocery for 10 coins
          and Koopa's Shop for 8 coins.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/a/a8/PMHiddenBlock2.png"
          alt="Fire Flower"
        ></img>
      </div>
    ),
  },
  {
    name: "Fright Jar",
    types: ["frightjar"],
    info: (
      <div>
        <p className="bingo-hint">
          A Fright Jar can be found in Pleasant Path in the last ? block of the first screen. More Fright Jars can be
          bought at Shroom Grocery for 5 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Honey Syrup",
    types: ["honeysyrup"],
    info: (
      <div>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village. It can also be purchased in various stores.
        </p>
      </div>
    ),
  },
  {
    name: "Koopa Leaf",
    types: ["koopaleaf"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Leaf can be found in a bush in front of Kolorado's house in Koopa Village.</p>
      </div>
    ),
  },
  {
    name: "POW Block",
    types: ["powblock"],
    info: (
      <div>
        <p className="bingo-hint">
          POW Block can be found in the ? block before the first switch bridge on Pleasant Path. It can also be
          purchased at Shroom Grocery for 5 coins and at Koopa Village for 4 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Sleepy Sheep",
    types: ["sleepysheep"],
    info: (
      <div>
        <p className="bingo-hint">
          Sleepy Sheep can be found in a ? block outside Toad Town in the room with Hammer Throw, on Pleasant Path after
          the first switch bridge, in a ? block just to the right of Whacka, and in a ? block outside of the dark room
          in east Red Station. They can also be purchased from Shroom Grocery for 10 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Super Shroom",
    types: ["supershroom"],
    info: (
      <div>
        <p className="bingo-hint">
          A Super Shroom can be bought at Harry's Shop for 20 coins or at Yoshi's Shop for 15 coins.{" "}
        </p>
        <p className="bingo-hint">
          Another can be found just past Goomba King's Castle by breaking the block on the ground.{" "}
        </p>
        <p className="bingo-hint">
          A third can be found in the leftmost ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>{" "}
          room.
        </p>
        <p className="bingo-hint">A fourth is in a hidden block in the room east of the entrance in the Sewers.</p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/f/ff/PMHiddenBlock7.png"
          alt="Super Shroom"
        ></img>
        <p className="bingo-hint">A fifth is in a hidden block near the train station in in Red Station.</p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/9/9f/PMHiddenBlock22.png"
          alt="Super Shroom"
        ></img>
      </div>
    ),
  },
  {
    name: "Tasty Tonic",
    types: ["tastytonic"],
    info: (
      <div>
        <p className="bingo-hint">
          Tasty Tonic can be found behind a bush to the left of the Oasis. It can also be bought at Harry's Shop for 5
          coins and at Yoshi Village for 2 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Volt Shroom",
    types: ["voltshroom"],
    info: (
      <div>
        <p className="bingo-hint">
          Volt Shroom can be found in the Storeroom in Harry's Shop and in a ? block outside the gate to Boo's Mansion.
        </p>
        <p className="bingo-hint">They can be purchased at Harry's Shop for 10 coins and Koopa Village for 15 coins.</p>
        <p className="bingo-hint">
          They can also be found in hidden ? blocks in east Red Station, deep in Jade Jungle, and in the Ultra Boots
          area of the Sewers.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/6/6a/PMHiddenBlock23.png"
          alt="Volt Shroom"
        ></img>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/4/40/PMHiddenBlock31.png"
          alt="Volt Shroom"
        ></img>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/b/b0/PMHiddenBlock11.png"
          alt="Volt Shroom"
        ></img>
      </div>
    ),
  },
  {
    name: "Whacka's Bump",
    types: ["wakkabump"],
    info: (
      <div>
        <p className="bingo-hint">Whacka's Bump can be obtained by jumping on or hammering Whacka on Mt. Rugged.</p>
      </div>
    ),
  },
  {
    name: "Attack FX B",
    types: ["attackfxb"],
    info: (
      <div>
        <p className="bingo-hint">
          This can be found outside Koopa Village by hitting the blocks on the floor in the order Left, Right, Middle.
        </p>
      </div>
    ),
  },
  {
    name: "Close Call",
    types: ["closecall"],
    info: (
      <div>
        <p className="bingo-hint">This can be found after leaving Goomba Village in a red ? block.</p>
      </div>
    ),
  },
  {
    name: "Damage Dodge",
    types: ["damagedodge"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Damage Dodge is found in a chest in Mt. Rugged behind the spring that leads to the leftmost letter.{" "}
        </p>
        <p className="bingo-hint">
          The second can be bought from Rowf's Badge Shop after Chapter 5 for 150 coins. Make sure to give the Volcano
          Vase to Kolorado to ensure you are in a post-Chapter 5 state.
        </p>
      </div>
    ),
  },
  {
    name: "Dizzy Attack",
    types: ["dizzyattack"],
    info: (
      <div>
        <p className="bingo-hint">This is found in a red ? block in the first room of Pleasant Path.</p>
      </div>
    ),
  },
  {
    name: "Hammer Throw",
    types: ["hammerthrow"],
    info: (
      <div>
        <p className="bingo-hint">
          Hammer Throw is found outside Toad Town when coming from Goomba Village. You need to hit the tree to reveal a
          spring and access the chest with the badge.
        </p>
      </div>
    ),
  },
  {
    name: "Snowman Doll",
    types: ["snowmandoll"],
    info: (
      <div>
        <p className="bingo-hint">
          Snowman Doll can be found in the Storeroom of Harry's Shop and in a hidden block outside Big Lantern Ghost's
          room. They can also be purchased at Boo's Mansion for 15 coins and Yoshi Village for 15 coins.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/8/8e/PMHiddenBlock25.png"
          alt="Snowman Doll"
        ></img>
      </div>
    ),
  },
  {
    name: "Dizzy Dial",
    types: ["dizzydial"],
    info: (
      <div>
        <p className="bingo-hint">
          There is a free one in the Storeroom of Harry's Shop available starting in a mid-chapter 4 state.
          Alternatively, you can purchase one for 15 coins at Harry's Shop, you can purchase one for 10 coins at Koopa's
          Shop, or cook one with a strange leaf.
        </p>
      </div>
    ),
  },
  {
    name: "Coconut",
    types: ["jungle"],
    info: (
      <div>
        <p className="bingo-hint">This can be found in the tree to the right of the Toad House in Yoshi Village.</p>
      </div>
    ),
  },
  {
    name: "HP Plus",
    types: ["hp"],
    info: (
      <div>
        <p className="bingo-hint">
          The first HP Plus can be collected with Kooper on the way back from the Fuzzy minigame.{" "}
        </p>
        <p className="bingo-hint">The second can be found in Forever Forest. </p>
        <p className="bingo-hint">- From Toad Town, you can reach it by going R2, L1, R1, R1, L. </p>
        <p className="bingo-hint">- From Boo's Mansion, go L1, L1, R2, L. </p>
        <p className="bingo-hint">- Watch out for Chapter 3 Jr Troopa if you are in a post-Super Boots state! </p>
        <p className="bingo-hint">
          The third HP Plus can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 150 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
];
bingoList[2] = [
  {
    name: "Quake Hammer",
    types: ["quakehammer"],
    info: (
      <div>
        <p className="bingo-hint">
          Quake Hammer is found on Mt. Rugged near the letter that requires Kooper's ability. You will need to use
          Parakarry to reach it.
        </p>
      </div>
    ),
  },
  {
    name: "Super Soda",
    types: ["supersoda"],
    info: (
      <div>
        <p className="bingo-hint">Super Soda can be obtained by cooking a Honey Syrup.</p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.{" "}
        </p>
        <p className="bingo-hint">
          There is also a Super Soda in a hidden block in the northwestern corner of Gourmet Guy's room in Pink Station.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/3/36/PMHiddenBlock18.png"
          alt="Super Soda"
        ></img>
        <p className="bingo-hint">Additional recipes include:</p>
        <p className="bingo-hint">- Maple Syrup, Apple, Blue Berry, Yellow Berry, Red Berry, Jammin' Jelly</p>
      </div>
    ),
  },
  {
    name: "Mistake",
    types: ["mistake"],
    info: (
      <div>
        <p className="bingo-hint">
          Mistake is obtained by cooking any uncookable item. Examples of uncookable items include POW Blocks, Fright
          Jars, and Snowman Dolls.
        </p>
      </div>
    ),
  },
  {
    name: "Maple Syrup",
    types: ["maplesyrup"],
    info: (
      <div>
        <p className="bingo-hint">
          Maple Syrup can be found in the second ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>{" "}
          room, on the left side of the Cannon room in Koopa Bros Fortress, in a ? block past the locked door on the
          second floor of Tubba Blubba's Castle, in a hidden ? block near the Anti Guy, in the leftmost room in Blue
          Station by defeating the Shy Guy holding it, and bought at the shop in Boo's Mansion for 25 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Koopa Legends",
    types: ["koopakoot"],
    info: (
      <div>
        <p className="bingo-hint">
          Speak with Koopa Koot, then go to Kolorado's house on the left side of that screen and speak with Kolorado's
          wife.
        </p>
      </div>
    ),
  },
  {
    name: "FP Plus",
    types: ["fp"],
    info: (
      <div>
        <p className="bingo-hint">
          The first FP Plus can be collected by breaking the wall in the room before Koopa Bros Fortress or doing{" "}
          <Link to="/FP_Plus_Early">FP Plus Early</Link>.{" "}
        </p>
        <p className="bingo-hint">The second is in Forever Forest. </p>
        <p className="bingo-hint">
          - From Boo's mansion, go R1, then L until you see the path to the badge in the center of the room.{" "}
        </p>
        <p className="bingo-hint">
          - If entering from Toad Town, head to Boo's Mansion and follow the directions from Boo's Mansion.{" "}
        </p>
        <p className="bingo-hint">- Don't forget about Chapter 3 Jr Troopa if you're in a post-Super Boots state! </p>
        <p className="bingo-hint">
          The third FP Plus can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 150 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
  {
    name: "Defeat a Cleft",
    types: ["cleft"],
    info: (
      <div>
        <p className="bingo-hint">
          Clefts can be found throughout Mt. Rugged. The best Cleft to kill is usually the one by the rightmost letter.
          Bombette's First Strike and Bomb attack, POW Blocks, and Thunder Bolts/Rages are good options for defeating
          Clefts.
        </p>
      </div>
    ),
  },
  {
    name: "Toy Train",
    types: ["toytrain"],
    info: (
      <div>
        <p className="bingo-hint">
          The Toy Train is found in the Storeroom of Harry's Shop. It is unlocked when your story progress is past the
          point when you would normally get the Toy Train. If you need to get it from an earlier state, do{" "}
          <Link to="/Early_Train">Early Train</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Fried Egg",
    types: ["friedegg"],
    info: (
      <div>
        <p className="bingo-hint">
          Collect an Egg from the bush by the train at Mt. Rugged, then cook it at Tayce T.'s house.
        </p>
      </div>
    ),
  },
  {
    name: "Fried Shroom",
    types: ["friedshroom"],
    info: (
      <div>
        <p className="bingo-hint">
          Collect a Mushroom from the tree outside the Playground or various shops, and then cook it at Tayce T.'s
          house.
        </p>
      </div>
    ),
  },
  {
    name: "Koopa Tea",
    types: ["koopatea"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Tea can be cooked by giving a Koopa Leaf to Tayce T.</p>
        <p className="bingo-hint">
          Koopa Leaf can be found in a bush on the right side of the first screen in Koopa Village.
        </p>
      </div>
    ),
  },
  {
    name: "Hot Shroom",
    types: ["hotshroom"],
    info: (
      <div>
        <p className="bingo-hint">Hot Shroom is cooked by combining a Honey Syrup and a Fire Flower. </p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.{" "}
        </p>
        <p className="bingo-hint">
          A Fire Flower can be found between 2 ? blocks just past the Kooper switch bridge after leaving Koopa Village.
          They can also be purchased at Shroom Grocery for 10 coins and at Koopa Village for 8 coins.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Nutty Cake",
    types: ["nuttycake"],
    info: (
      <div>
        <p className="bingo-hint">Nutty Cake is cooked by combining a Goomnut and a Cake Mix. </p>
        <p className="bingo-hint">Goomnut can be found in the tree in Goomba Village.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Spicy Soup",
    types: ["spicysoup"],
    info: (
      <div>
        <p className="bingo-hint">Spicy Soup can be obtained by cooking a Fire Flower.</p>
        <p className="bingo-hint">
          A Fire Flower can be found between 2 ? blocks just past the Kooper switch bridge after leaving Koopa Village.
          They can also be purchased at Shroom Grocery for 10 coins and at Koopa Village for 8 coins.{" "}
        </p>
      </div>
    ),
  },
  {
    name: "Lyrics",
    types: ["fxd"],
    info: (
      <div>
        <p className="bingo-hint">
          In a post-Chapter 2 state, speak with Simon in Club 64. Leave the Docks, then go speak to him again to receive
          the Lyrics.{" "}
        </p>
      </div>
    ),
  },
  {
    name: "Fire Shield",
    types: ["volcano"],
    info: (
      <div>
        <p className="bingo-hint">
          Fire Shield can be obtained by jumping off the lower zipline in the large zipline room in Mt. Lavalava.
        </p>
      </div>
    ),
  },
  {
    name: "Thunder Bolt",
    types: ["thunderbolt"],
    info: (
      <div>
        <p className="bingo-hint">
          Thunder Bolt can be found on a block on Pleasant Path and in a ? block in the far east of Blue Station. They
          can also be bought at Dry Dry Outpost for 5 coins.
        </p>
      </div>
    ),
  },
];
bingoList[3] = [
  {
    name: "Refund",
    types: ["refund"],
    info: (
      <div>
        <p className="bingo-hint">
          Refund is found by blowing up the outside wall on the right side of Koopa Bros Fortress.
        </p>
      </div>
    ),
  },
  {
    name: "Attack FX A",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">
          This can be found outside Koopa Village by hitting the blocks on the floor in the order Left, Right, Middle.
        </p>
      </div>
    ),
  },
  {
    name: "Pay-Off",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 1 Star Piece.</p>
      </div>
    ),
  },
  {
    name: "Odd Key",
    types: ["bhs"],
    info: (
      <div>
        <p className="bingo-hint">
          The Odd Key is the key to the Blue House. If you have this objective, get Sushie so that you can exit Chapter
          5 through the sewers.
        </p>
      </div>
    ),
  },
  {
    name: "First-Degree Card",
    types: ["dojo"],
    info: (
      <div>
        <p className="bingo-hint">For the First-Degree Card, you will need to defeat Chan.</p>
        <p className="bingo-hint">
          Chan has 15 HP, 2 Attack, and 2 Defense. He is easily defeated by flipping him like any Buzzy Beetle, because
          his defense is 0 while he is flipped.
        </p>
      </div>
    ),
  },
  {
    name: "Jammin' Jelly",
    types: ["jamminjelly"],
    info: (
      <div>
        <p className="bingo-hint">The first Jammin' Jelly is found behind the tree in front of the volcano. </p>
        <p className="bingo-hint">The second is found in a tree in Lily's room in Flower Fields. </p>
        <p className="bingo-hint">
          There is alternatively a Jammin' Jelly given to Peach if she wins the quiz show after Chapter 5, but you will
          have to do another Peach cutscene to place it in the chest, and then you will have to pick it up with Mario.
          This is not recommended.
        </p>
      </div>
    ),
  },
  {
    name: "Repel Gel",
    types: ["repelgel"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Repel Gel can be found outside the Playground in a hidden block above a stone block.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/b/b9/PMHiddenBlock1.png"
          alt="Repel Gel"
        ></img>
        <p className="bingo-hint">
          The second can be found in Gusty Gulch in a ? block on the screen to the right of where Stanley gets eaten.{" "}
        </p>
        <p className="bingo-hint">
          The third can be found on the beach to the left of Yoshi Village. It is in a hidden block near the bell plant
          between the plant and the rocks with the letter.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/d/d4/PMHiddenBlock27.png"
          alt="Repel Gel"
        ></img>
        <p className="bingo-hint">
          Alternatively, you can <Link to="/Item_Duplication">dupe items</Link> to get these, but this will require{" "}
          <Link to="/Early_Lakilester">Lakilester</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Mystery?",
    types: ["mystery"],
    info: (
      <div>
        <p className="bingo-hint">
          Mystery? can be found in a hidden block on the left side of the calculator room in Blue Station, or in a
          hidden block on the right side of the beach with the Yellow Fuzzies. They can also be bought from the shop in
          Boo's Mansion for 1 coin.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/8/8e/PMHiddenBlock16.png"
          alt="Mystery?"
        ></img>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/5/5a/PMHiddenBlock28.png"
          alt="Mystery?"
        ></img>
      </div>
    ),
  },
  {
    name: "Stop Watch",
    types: ["stopwatch"],
    info: (
      <div>
        <p className="bingo-hint">
          Stop Watch can be found behind a bush by the Whale in Chapter 5 and in a hidden block in the Sewers in the
          room with the Spinies before the Ultra Boots. They can also be purchased in Boo's Mansion for 25 coins.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/1/1e/PMHiddenBlock10.png"
          alt="Stop Watch"
        ></img>
      </div>
    ),
  },
  {
    name: "Stone Cap",
    types: ["stonecap"],
    info: (
      <div>
        <p className="bingo-hint">
          Stone Cap can be found in a hidden block on the right side of the station at Blue Station. They can also be
          purchased at Harry's Shop for 30 coins.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/5/52/PMHiddenBlock14.png"
          alt="Stone Cap"
        ></img>
      </div>
    ),
  },
  {
    name: "Thunder Rage",
    types: ["thunderrage"],
    info: (
      <div>
        <p className="bingo-hint">
          Thunder Rage can be found in hidden blocks two screens north of the entrance screen of Dry Dry Desert, near
          the railroad switch in Pink Station, near the north exit of the southwesternmost screen of Jade Jungle, and
          near the east exit of the second screen on the northeastern road of Flower Fields.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/8/88/PMHiddenBlock3.png"
          alt="Thunder Rage"
        ></img>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/6/61/PMHiddenBlock20.png"
          alt="Thunder Rage"
        ></img>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/b/bf/PMHiddenBlock29.png"
          alt="Thunder Rage"
        ></img>
        <p className="bingo-hint">
          They can also be purchased at Harry's Shop for 20 coins and at Yoshi Village for 15 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Silver Credit",
    types: ["koopakoot"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
      </div>
    ),
  },
  {
    name: "Chill Out (Badge)",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 3 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Dizzy Stomp",
    types: ["volcano"],
    info: (
      <div>
        <p className="bingo-hint">
          Dizzy Stomp is found in the volcano if you take the stairs downwards on the right side in the room with{" "}
          <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "1 Quizmo",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">
          In Toad Town, Quizmo can be by Merlon's House, southeast of Club 64, and near Harry's Shop.{" "}
        </p>
        <p className="bingo-hint">
          Quizmo can also appear in Goomba Village on the left side, and in both screens of Koopa Village.{" "}
        </p>
        <p className="bingo-hint">
          If Quizmo is hard to find, the best way to summon him is to go back and forth between Goomba Village and
          Mario's landing site, then to Koopa Village and back once you find him. His first question is the third
          option.
        </p>
      </div>
    ),
  },
  {
    name: "Yummy Meal",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Yummy Meal is obtained by cooking a Whacka Bump.</p>
      </div>
    ),
  },
];
bingoList[4] = [
  {
    name: "At Least 5 Letters",
    types: ["letters"],
    info: (
      <div>
        <p className="bingo-hint">
          See{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://shrines.rpgclassics.com/n64/papermario/letters.shtml"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Jungle Fuzzy",
    types: ["jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          Jungle Fuzzies can be found on the beach west of Yoshi Village. Because they can multiply and steal HP, and
          have 7 HP each, they can get out of hand very quickly. It is recommended to defeat them in 1 turn with a
          Thunder Rage and Power Shell or with Star Storm.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Hurt Plant",
    types: ["jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          The fastest Hurt Plant is in Jade Jungle right next to the entrance to Yoshi Village. There are two of them in
          the fight, and they have 8 HP each. They deal 2 damage each and poison you if you don't block. They are weak
          to the sleep status.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Spike Top",
    types: ["spiketop"],
    info: (
      <div>
        <p className="bingo-hint">
          There is a Spike Top in the Town Town Sewers in the room before you fight Blooper. Fight the leftmost Dark
          Koopa and use a Thunder Rage and Shell Shot.
        </p>
      </div>
    ),
  },
  {
    name: "Life Shroom",
    types: ["rip", "desert", "volcano"],
    info: (
      <div>
        <p className="bingo-hint">
          Life Shroom can be found in a hidden block on the right side of the room before the Ultra Hammer.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png"
          alt="Life Shroom"
        ></img>
        <p className="bingo-hint">
          Another can be found in a hidden block in Dry Dry Desert, one room down from the entrance to Dry Dry Outpost.
          The hidden block is on top of the block in the center of the room.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/0/04/PMHiddenBlock6.png"
          alt="Life Shroom"
        ></img>
        <p className="bingo-hint">
          A third can be found in a hidden block between the ? blocks in the sewers in the room before the Ultra Boots,
          but this requires Lakilester to reach.
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/c/c1/PMHiddenBlock12.png"
          alt="Life Shroom"
        ></img>
        <p className="bingo-hint">
          Additional Life Shrooms can be purchased from the shop in Boo's Mansion for 50 coins.
        </p>
      </div>
    ),
  },
  {
    name: "D-Down Pound",
    types: ["rowf"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Rowf's Badge Shop for 75 coins.</p>
      </div>
    ),
  },
  {
    name: "Feeling Fine",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 5 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "First Attack",
    types: ["rowf"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Rowf's Badge Shop for 75 coins.</p>
      </div>
    ),
  },
  {
    name: "Pretty Lucky",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 5 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Kill Whacka",
    types: ["wakkabump"],
    info: (
      <div>
        <p className="bingo-hint">
          To kill Whacka, you will need to hammer or bomb him until he poofs into coins. Hammering him deals 1 damage,
          bombing him deals 3 damage, and he has 8 HP. Bombing Whacka doesn't give any bumps, so be careful!
        </p>
      </div>
    ),
  },
  {
    name: "Multibounce",
    types: ["rowf"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Rowf's Badge Shop for 75 coins.</p>
      </div>
    ),
  },
];
bingoList[5] = [
  {
    name: "Dried Pasta",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">Dried Pasta can be purchased in Dry Dry Outpost for 3 coins.</p>
      </div>
    ),
  },
  {
    name: "Shooting Star",
    types: ["shootingstar"],
    info: (
      <div>
        <p className="bingo-hint">
          Shooting Star can be found on the veranda in Goomba Village after visiting Shooting Star Summit in Prologue.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Bandit",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          When entering Dry Dry Desert from Mt. Rugged, Bandits can be found by going up 1. When entering from Dry Dry
          Outpost, they can be found by going down 1.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Groove Guy",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          A Groove Guy can be found in the room with Anti Guy in the Toy Box. The fight in that room has 2 Groove Guys,
          and they have 7 HP each.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Sky Guy",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          The fastest Sky Guy is in east Blue Station. The formation includes 2 Sky Guys and a Shy Guy. Thunder Rage and
          basic attacks or Star Storm are a good options.
        </p>
      </div>
    ),
  },
  {
    name: "Dusty Hammer",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">Dusty Hammer can be purchased in Dry Dry Outpost for 2 coins.</p>
      </div>
    ),
  },
  {
    name: "Lemon",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          Lemon can be found in the leftmost tree in the Oasis in Dry Dry Desert. The Oasis is located on the far-right
          side, two rooms down from the path.
        </p>
      </div>
    ),
  },
  {
    name: "Calculator",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          Calculator can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.{" "}
        </p>
        <p className="bingo-hint">
          Alternatively, you can defeat any other Shy Guy, leave the room, then re-enter to find the Calculator in the
          center of the room.
        </p>
      </div>
    ),
  },
  {
    name: "Mailbag",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">The Mailbag can be found in a chest by the train in Pink Station.</p>
      </div>
    ),
  },
  {
    name: "Lime",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          Lime can be found in the rightmost tree in the Oasis in Dry Dry Desert. The Oasis is located on the far-right
          side, two rooms down from the path.
        </p>
      </div>
    ),
  },
  {
    name: "Cake Mix",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
      </div>
    ),
  },
  {
    name: "Cookbook",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          In a post-chapter 4 state, go left in pink station until you reach it. Don't give it to Tayce T.!
        </p>
      </div>
    ),
  },
  {
    name: "Runaway Pay",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          Runaway Pay is located in a hidden block between three palm trees in Dry Dry Desert.
        </p>
        <p className="bingo-hint">- From Mt. Rugged, go right 4 times, then up.</p>
        <p className="bingo-hint">- From Dry Dry Outpost, go left, up, left.</p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/6/6b/PMHiddenBlock5.png"
          alt="Runaway Pay"
        ></img>
      </div>
    ),
  },
  {
    name: "Defend Plus",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          Defend Plus is found in the rightmost room of Pink Station. It is in a chest behind the entrance to the room.
          These Sky Guys can sometimes first strike you for free, so make sure you save or come prepared!
        </p>
      </div>
    ),
  },
  {
    name: "Happy Heart",
    types: ["jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Happy Heart can be found in the giant tree where Raphael lives in the Jade Jungle. It is halfway up
          the tree on the outside portion if you continue past the door.{" "}
        </p>
        <p className="bingo-hint">The second Happy Heart bought from Merlow for 8 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "3 Quizmos",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">
          In Toad Town, Quizmo can be by Merlon's House, southeast of Club 64, and near Harry's Shop.{" "}
        </p>
        <p className="bingo-hint">
          Quizmo can also appear in Goomba Village on the left side, and in both screens of Koopa Village.{" "}
        </p>
        <p className="bingo-hint">
          If Quizmo is hard to find, the best way to summon him is to go back and forth between Goomba Village and
          Mario's landing site, then to Koopa Village and back once you find him.
        </p>
      </div>
    ),
  },
  {
    name: "Power Quake",
    types: ["jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          Power Quake is in a red ? block at the center of the first room of Jade Jungle. You will need Sushie to reach
          it.
        </p>
      </div>
    ),
  },
];
bingoList[6] = [
  {
    name: "Second-Degree Card",
    types: ["dojo"],
    info: (
      <div>
        <p className="bingo-hint">For the Second-Degree Card, you will need to defeat Chan and Lee.</p>
        <p className="bingo-hint">
          Chan has 15 HP, 2 Attack, and 2 Defense. He is easily defeated by flipping him like any Buzzy Beetle, because
          his defense is 0 while he is flipped.
        </p>
        <p className="bingo-hint">
          Lee has 20 HP, 5 Attack, and 0 Defense. He can turn into your partners, so if you start the fight with Kooper,
          you can flip him like any Koopa and defeat him easily.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Pyro Guy",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          The fastest Pyro Guys are found in west Red Station. That fight has 2 Pyro Guys and a Spy Guy, who all have 7
          HP. Thunder Rage and Shell Shot or Star Storm can defeat them quickly.
        </p>
      </div>
    ),
  },
  {
    name: "Deep Focus",
    types: ["toybox", "ch2", "ch3", "ch4", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Deep Focus is in an invisible block between two ? blocks outside of Big Lantern Ghost's room in Red
          Station.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/d/da/PMHiddenBlock24.png"
          alt="Deep Focus"
        ></img>
        <p className="bingo-hint">
          The second can be most easily collected during the post-chapter 5 Peach cutscene. It is found in the rightmost
          room on the bottom floor.{" "}
        </p>
        <p className="bingo-hint">
          - Don't forget to go and pick it up at Merluvlee's house near Shooting Star Summit.
        </p>
      </div>
    ),
  },
  {
    name: "Storeroom Key",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          The Storeroom Key is located in east Blue Station. It is guarded by a Goomba. Collecting it will set your
          story progress back to an early Chapter 4 state, so be careful!
        </p>
      </div>
    ),
  },
  {
    name: "Bump Attack",
    types: ["rip"],
    info: (
      <div>
        <p className="bingo-hint">
          This is the third item from Rip Cheato under the Blue House. You will need 192 coins to buy this from him.
        </p>
      </div>
    ),
  },
  {
    name: "Spin Attack",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          Spin Attack is located in Dry Dry Desert. In the room with the blue cactus, let the twister take you to reach
          the badge.
        </p>
        <p className="bingo-hint">
          - When entering from Mt. Rugged, go right twice, then alternate down and right until you reach it.
        </p>
        <p className="bingo-hint">- When entering from Dry Dry Outpost, alternate down and left until you reach it.</p>
      </div>
    ),
  },
  {
    name: "Spaghetti",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">Spaghetti can be made by cooking a Dried Pasta.</p>
        <p className="bingo-hint">Dried Pasta can be purchased in Dry Dry Outpost for 3 coins.</p>
      </div>
    ),
  },
  {
    name: "Cake",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">Cake can by cooked by giving a Cake Mix to Tayce T. </p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Alternatively, you can deliver the Frying Pan to get a Cake.</p>
      </div>
    ),
  },
  {
    name: "I Spy",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          I Spy is given to you by Rowf as a reward for returning the Calculator. The Calculator can be found in the
          leftmost room in Blue Station by defeating the Shy Guy holding it. Alternatively, you can defeat any other Shy
          Guy, leave the room, then re-enter to find the Calculator in the center of the room.
        </p>
      </div>
    ),
  },
  {
    name: "Ice Power",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          Ice Power is located in a chest in the east room of Pink Station. See{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Spike Shield",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">Spike Shield can be found in the coffin of a Pokey Mummy inside Dry Dry Ruins.</p>
      </div>
    ),
  },
  {
    name: "Ruins Key",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">Ruins Keys can be found in Dry Dry Ruins.</p>
      </div>
    ),
  },
  {
    name: "Attack FX C",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          This can be found in Dry Dry Desert. When entering from Mt. Rugged, go D, R, D until you reach it. When
          entering from Dry Dry Outpost, go D, L, D, L, D, L, L, L until you reach it. It is in a hidden block on top of
          the rock in the center of the room. Watch out for Bandits and Pokeys!
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/a/af/PMHiddenBlock4.png"
          alt="Attack FX C"
        ></img>
      </div>
    ),
  },
  {
    name: "Power Smash",
    types: ["blooper", "hammer"],
    info: (
      <div>
        <p className="bingo-hint">
          Power Smash is found in the Sewers, past the pipes to Chapters 1 and 2. Head left until you reach it.
        </p>
      </div>
    ),
  },
  {
    name: "2x HP Plus",
    types: ["hp"],
    info: (
      <div>
        <p className="bingo-hint">
          The first HP Plus can be collected with Kooper on the way back from the Fuzzy minigame.{" "}
        </p>
        <p className="bingo-hint">The second can be found in Forever Forest. </p>
        <p className="bingo-hint">- From Toad Town, you can reach it by going R2, L1, R1, R1, L. </p>
        <p className="bingo-hint">- From Boo's Mansion, go L1, L1, R2, L. </p>
        <p className="bingo-hint">- Watch out for Chapter 3 Jr Troopa if you are in a post-Super Boots state! </p>
        <p className="bingo-hint">
          The third HP Plus can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 150 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
];
bingoList[7] = [
  {
    name: "Dodge Master",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          Dodge Master can be bought from Rowf's Badge Shop in a post-Chapter 2 state for 100 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Tape",
    types: ["koopakoot"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
      </div>
    ),
  },
  {
    name: "Double Dip",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          Double Dip can be bought from Rowf's Badge Shop in a post-Chapter 2 state for 100 coins.
        </p>
      </div>
    ),
  },
  {
    name: "Melody",
    types: ["fxd", "desert"],
    info: (
      <div>
        <p className="bingo-hint">
          In a post-Chapter 2 state, speak with Simon in Club 64. Leave the Docks, then go speak to him again to receive
          the Lyrics.{" "}
        </p>
        <p className="bingo-hint">
          Go to Dry Dry Outpost and speak to the Toad in the house by the blue pipe to get the Melody.{" "}
        </p>
      </div>
    ),
  },
  {
    name: "Sleep Stomp",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">Sleep Stomp can be bought from Rowf's Badge Shop after Chapter 2 for 75 coins.</p>
      </div>
    ),
  },
  {
    name: "Defeat a Lava Bubble",
    types: ["volcano"],
    info: (
      <div>
        <p className="bingo-hint">
          Lava Bubbles can be found in the volcano. The first ones you see have 2 in the fight. They have 9 HP and deal
          4, and they can target your partners. Squirt, the Ultra Hammer, and Thunder Rages are good options for
          defeating these.
        </p>
      </div>
    ),
  },
  {
    name: "Strange Leaf",
    types: ["mansion"],
    info: (
      <div>
        <p className="bingo-hint">Strange Leaf can be found in a bush in front of Boo's Mansion. </p>
      </div>
    ),
  },
  {
    name: "Frying Pan",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          The Frying Pan is located in the east room of Pink Station. See{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>. It is guarded by 4 Fuzzies, which have 3 HP each.
          Collecting it will set your story progress to a Chapter 4 state, so be careful!{" "}
        </p>
      </div>
    ),
  },
];
bingoList[8] = [
  {
    name: "Bland Meal",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">This can be cooked by combining a Koopa Leaf and a Goomnut. </p>
        <p className="bingo-hint">
          Koopa Leaf can be found in a bush on the right side of the first screen in Koopa Village.
        </p>
        <p className="bingo-hint">Goomnut can be found in the tree in Goomba Village.</p>
        <p className="bingo-hint">Additional recipes include: </p>
        <p className="bingo-hint">Koopa Leaf + Yellow Berry, Red Berry, Blue Berry, Goomnut </p>
        <p className="bingo-hint">Strange Leaf + Goomnut, Dried Pasta</p>
        <p className="bingo-hint">
          Iced Potato + Mushroom, Volt Shroom, Life Shroom, Super Shroom, Cake Mix, Egg, Coconut
        </p>
        <p className="bingo-hint">Fire Flower + Volt Shroom, Life Shroom, Super Shroom, Goomnut</p>
        <p className="bingo-hint">Potato Salad + Fried Shroom</p>
        <p className="bingo-hint">Egg + Mushroom, Volt Shroom, Life Shroom, Super Shroom</p>
        <p className="bingo-hint">
          Dried Pasta + Yellow Berry, Red Berry, Egg, Goomnut, Blue Berry, Volt Shroom, Life Shroom, Super Shroom,
          Coconut
        </p>
      </div>
    ),
  },
  {
    name: "Group Focus",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          Group Focus can be bought from Rowf's Badge Shop in a post-Chapter 3 state for 100 coins.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
  {
    name: "Egg Missile",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Egg Missile can be cooked by combining an Egg and a Fire Flower.</p>
        <p className="bingo-hint">
          Collect an Egg from the bush by the train at Mt. Rugged and a Fire Flower from Shroom Grocery, Koopa's Shop,
          or the hidden block near the bridge to Koopa Bros Fortress. This can be cooked at Tayce T's house after
          delivering the Cookbook.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra Stone",
    types: ["upgrades", "jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Spin Smash",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">Spin Smash can be bought at Rowf's Badge Shop after Chapter 3 for 75 coins.</p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
  {
    name: "Honey Shroom",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Honey Shroom is cooked by combining a Honey Syrup and a Mushroom. </p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.{" "}
        </p>
        <p className="bingo-hint">
          A Mushroom can be found in the tree outside the Playground or bought at most stores.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Ultra Shroom",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          An Ultra Shroom can be found by hitting the block in the desert 100 times. This block is in the room north of
          the entrance to Mt. Rugged.{" "}
        </p>
      </div>
    ),
  },
  {
    name: "Honey Super",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Honey Super is cooked by combining a Honey Syrup and a Super Shroom. </p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.{" "}
        </p>
        <p className="bingo-hint">
          A Super Shroom can be bought at Harry's Shop for 20 coins or at Yoshi's Shop for 15 coins. Another can be
          found just past Goomba King's Castle by breaking the block on the ground. A third can be found in the leftmost
          ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link> room.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Maple Shroom",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Maple Shroom is cooked by combining a Maple Syrup and a Mushroom. </p>
        <p className="bingo-hint">
          Maple Syrup can be found in the second ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>{" "}
          room, on the left side of the Cannon room in Koopa Bros Fortress, in a ? block past the locked door on the
          second floor of Tubba Blubba's Castle, in a hidden ? block near the Anti Guy, in the leftmost room in Blue
          Station by defeating the Shy Guy holding it, and bought at the shop in Boo's Mansion for 25 coins.
        </p>
        <p className="bingo-hint">
          A Mushroom can be found in the tree outside the Playground or bought at most stores.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Happy Flower",
    types: ["starpiece", "flowerfields"],
    info: (
      <div>
        <p className="bingo-hint">Happy Flower can be bought from Merlow for 8 Star Pieces. </p>
        <p className="bingo-hint">
          A second Happy Flower can be found in Flower Fields on the east path, by hitting the trees in the order of
          middle, right, left.
        </p>
      </div>
    ),
  },
  {
    name: "Maple Super",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Maple Super is cooked by combining a Maple Syrup and a Super Shroom. </p>
        <p className="bingo-hint">
          Maple Syrup can be found in the second ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>{" "}
          room, on the left side of the Cannon room in Koopa Bros Fortress, in a ? block past the locked door on the
          second floor of Tubba Blubba's Castle, in a hidden ? block near the Anti Guy, in the leftmost room in Blue
          Station by defeating the Shy Guy holding it, and bought at the shop in Boo's Mansion for 25 coins.
        </p>
        <p className="bingo-hint">
          A Super Shroom can be bought at Harry's Shop for 20 coins or at Yoshi's Shop for 15 coins. Another can be
          found just past Goomba King's Castle by breaking the block on the ground. A third can be found in the leftmost
          ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link> room.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Jump Charge",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">Jump Charge can be bought at Rowf's Badge Shop after Chapter 3 for 50 coins.</p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
];
bingoList[9] = [
  {
    name: "Big Cookie",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it. This can be
          combined with an Egg, Goomnut, Blue Berry, Red Berry, or Yellow Berry at Tayce T's house after delivering the
          Cookbook.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat Chapter 5 Jr. Troopa",
    types: ["jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          To trigger Chapter 5 Jr. Troopa, you have to collect Sushie or progress past the point where you would
          normally collect her via <Link to="/Raphael_Skip">Raphael Skip</Link>.{" "}
        </p>
        <p className="bingo-hint">
          He only has 20 HP, but he does have 1 defense and cannot be jumped on without Spike Shield. Consider using
          Hammer Throw with the Ultra Hammer and Super Rank Shell Shot to defeat him easily.
        </p>
      </div>
    ),
  },
  {
    name: "Honey Candy",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">Honey Candy is cooked by combining a Cake Mix and a Honey Syrup.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.{" "}
        </p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Electro Pop",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it. This can be
          combined with a Volt Shroom (found/bought in Harry's Shop's Storeroom/Shop or in west Red Station) at Tayce
          T's house after delivering the Cookbook.
        </p>
      </div>
    ),
  },
  {
    name: "Fire Pop",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">Fire Pops are cooked by combining a Cake Mix with a Fire Flower.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">
          A Fire Flower can be found at the top of the spring outside the playground or in a hidden block on the right
          side of the bridge to Koopa Bros Fortress. Alternatively, they can be bought in Shroom Grocery for 10 coins
          and Koopa's Shop for 8 coins.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Shroom Steak",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Shroom Steak is cooked by combining a Mushroom and a Life Shroom. </p>
        <p className="bingo-hint">
          A Mushroom can be found in the tree outside the Playground or bought at most stores.{" "}
        </p>
        <p className="bingo-hint">
          Life Shroom can be found in a hidden block on the right side of the room before the Ultra Hammer, in a hidden
          block in Dry Dry Desert (one room down from the entrance to Dry Dry Outpost), and in a hidden block between
          the ? blocks in the sewers in the room before the Ultra Boots.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
        <p className="bingo-hint">Additional recipes include: </p>
        <p className="bingo-hint">Life Shroom + Dried Shroom, Super Shroom</p>
        <p className="bingo-hint">
          Ultra Shroom + Nothing, Dried Shroom, Mushroom, Super Shroom, Life Shroom, Volt Shroom
        </p>
      </div>
    ),
  },
  {
    name: "Jelly Shroom",
    types: ["cookbook", "jamminjelly"],
    info: (
      <div>
        <p className="bingo-hint">Jelly Shroom can be cooked by combining a Jammin' Jelly and a Mushroom.</p>
        <p className="bingo-hint">
          Jammin' Jelly is found behind the tree in front of the volcano and in a tree in Lily's room in Flower Fields.
        </p>
        <p className="bingo-hint">
          A Mushroom can be found in the tree outside the Playground or bought at most stores.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Shroom Cake",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">Shroom Cake is cooked by combining a Mushroom or Super Shroom with a Cake Mix. </p>
        <p className="bingo-hint">
          A Mushroom can be found in the tree outside the Playground or bought at most stores.{" "}
        </p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Jelly Super",
    types: ["cookbook", "jamminjelly"],
    info: (
      <div>
        <p className="bingo-hint">Jelly Super can be cooked by combining a Jammin' Jelly and a Super Shroom.</p>
        <p className="bingo-hint">
          Jammin' Jelly is found behind the tree in front of the volcano and in a tree in Lily's room in Flower Fields.
        </p>
        <p className="bingo-hint">
          A Super Shroom can be bought at Harry's Shop for 20 coins or at Yoshi's Shop for 15 coins. Another can be
          found just past Goomba King's Castle by breaking the block on the ground. A third can be found in the leftmost
          ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link> room.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "All or Nothing",
    types: ["calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          All or Nothing can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 100 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
  {
    name: "Attack FX D",
    types: ["fxd", "desert"],
    info: (
      <div>
        <p className="bingo-hint">
          In a post-Chapter 2 state, speak with Simon in Club 64. Leave the Docks, then go speak to him again to receive
          the Lyrics.{" "}
        </p>
        <p className="bingo-hint">
          Go to Dry Dry Outpost and speak to the Toad in the house by the blue pipe to get the Melody.{" "}
        </p>
        <p className="bingo-hint">Return to Simon to get Attack FX D.</p>
      </div>
    ),
  },
  {
    name: "Dried Fruit",
    types: ["desert"],
    info: (
      <div>
        <p className="bingo-hint">
          Dried Fruits can be randomly acquired from Pokeys in Dry Dry Desert. It is recommended to get Power Quake to
          deal with them efficiently. There are a large group of pokeys if you go U, L, U from Dry Dry Outpost.
        </p>
      </div>
    ),
  },
  {
    name: "Smash Charge",
    types: ["ch1"],
    info: (
      <div>
        <p className="bingo-hint">
          Smash Charge is found by following the ramp upwards before the Cannon room in Koopa Bros Fortress. See{" "}
          <Link to="/Smash_Charge_KSJ">Smash Charge KSJ</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Hyper Goomba",
    types: ["gulch"],
    info: (
      <div>
        <p className="bingo-hint">
          Hyper Goombas can be created by jumping on any Hyper Paragoomba. They have 7 HP and deal 8 damage when
          charged, but only 1 damage otherwise.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Hyper Cleft",
    types: ["gulch"],
    info: (
      <div>
        <p className="bingo-hint">
          Hyper Clefts are found in Gusty Gulch. Thunder Rages, Thunder Bolts, Power Quake, and POW Blocks are good
          options for defeating Hyper Clefts.
        </p>
      </div>
    ),
  },
  {
    name: "Coco Pop",
    types: ["cookbook", "toybox", "jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.{" "}
        </p>
        <p className="bingo-hint">
          A Coconut can be found in the tree to the right of the Toad House in Yoshi Village. Don't forget to deliver
          the Cookbook!
        </p>
      </div>
    ),
  },
  {
    name: "Record",
    types: ["mansion", "bow", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          The Record is found in Boo's Mansion by playing the minigame with the boos on the second floor. It is fastest
          to do <Link to="/Record_Skip">Record Skip</Link>, then go to the room with the Record to skip having to play
          the minigame.
        </p>
      </div>
    ),
  },
  {
    name: "Weight",
    types: ["mansion", "bow", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          The Weight is in the chest guarded by a Boo in the upper floor of Boo's Mansion. You can play the Record
          minigame or do <Link to="/Record_Skip">Record Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Kookie Cookie",
    types: ["cookbook", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">Kookie Cookie can be cooked by combining a Cake Mix and a Koopa Leaf.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Koopa Leaf can be found in a bush in front of Kolorado's house in Koopa Village.</p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
];
bingoList[10] = [
  {
    name: "Ultra-Rank Bombette",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Bombette, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank Parakarry",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Parakarry, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Pulse Stone",
    types: ["desert", "ruins"],
    info: (
      <div>
        <p className="bingo-hint">
          The Pulse Stone is obtained by playing through Chapter 2's Dry Dry Outpost segment up to the point where Mario
          speaks with Moustafa.{" "}
        </p>
        <p className="bingo-hint">
          When entering from a post-chapter 2 state, you can enter Dry Dry Desert from the left side to trigger a
          Chapter 2 state, once per file.{" "}
        </p>
        <p className="bingo-hint">
          - The items you have to buy for the Moustafa sequence are Dried Shroom and Dusty Hammer, in that order.
        </p>
      </div>
    ),
  },
  {
    name: "Jelly Pop",
    types: ["cookbook", "toybox", "jamminjelly"],
    info: (
      <div>
        <p className="bingo-hint">Jelly Pop can be cooked by combining a Jammin' Jelly and a Cake Mix.</p>
        <p className="bingo-hint">
          Jammin' Jelly is found behind the tree in front of the volcano and in a tree in Lily's room in Flower Fields.
        </p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Special Shake",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Special Shake can be cooked by combining a Jammin' Jelly and a Honey Syrup. </p>
        <p className="bingo-hint">
          Jammin' Jelly is found behind the tree in front of the volcano and in a tree in Lily's room in Flower Fields.
        </p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Sweet Shroom",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Sweet Shroom can be made by combining a Cake Mix and a Life Shroom.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">
          Life Shroom can be found in a hidden block on the right side of the room before the Ultra Hammer, in a hidden
          block in Dry Dry Desert (one room down from the entrance to Dry Dry Outpost), and in a hidden block between
          the ? blocks in the sewers in the room before the Ultra Boots.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
        <p className="bingo-hint">Additional recipes include:</p>
        <p className="bingo-hint">- Cake Mix + Ultra Shroom</p>
      </div>
    ),
  },
  {
    name: "2x FP Plus",
    types: ["fp"],
    info: (
      <div>
        <p className="bingo-hint">
          The first FP Plus can be collected by breaking the wall in the room before Koopa Bros Fortress or doing{" "}
          <Link to="/FP_Plus_Early">FP Plus Early</Link>.{" "}
        </p>
        <p className="bingo-hint">The second is in Forever Forest. </p>
        <p className="bingo-hint">
          - From Boo's mansion, go R1, then L until you see the path to the badge in the center of the room.{" "}
        </p>
        <p className="bingo-hint">
          - If entering from Toad Town, head to Boo's Mansion and follow the directions from Boo's Mansion.{" "}
        </p>
        <p className="bingo-hint">- Don't forget about Chapter 3 Jr Troopa if you're in a post-Super Boots state! </p>
        <p className="bingo-hint">
          The third FP Plus can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 150 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
  {
    name: "Peekaboo",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 10 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Zap Tap",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">Zap Tap can be bought from Merlow for 10 Star Pieces.</p>
      </div>
    ),
  },
];
bingoList[11] = [
  {
    name: "Ultra-Rank Goombario",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Goombario, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank Kooper",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Kooper, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank Sushie",
    types: ["upgrades", "sushie"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Sushie, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "2x Happy Heart",
    types: ["starpiece", "jungle"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Happy Heart can be found in the giant tree where Raphael lives in the Jade Jungle. It is halfway up
          the tree on the outside portion if you continue past the door.{" "}
        </p>
        <p className="bingo-hint">The second Happy Heart bought from Merlow for 8 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Super Boots",
    types: ["mansion", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          The Super Boots are in Boo's Mansion. Entering the mansion for the first time will set your story progress to
          the proper state to get the Super Boots. See <Link to="/Record_Skip">Record Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Apple",
    types: ["mansion"],
    info: (
      <div>
        <p className="bingo-hint">
          Apples can be found in Boo's Mansion by Spin Jumping on the crates in the rightmost room on the floor with the
          giant sofa. To get the Super Boots quickly, you may want to do <Link to="/Record_Skip">Record Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Volcano Vase",
    types: ["ch5"],
    info: (
      <div>
        <p className="bingo-hint">Complete Chapter 5, then get the Volcano Vase from the chest in Jade Jungle.</p>
      </div>
    ),
  },
  {
    name: "Koopasta",
    types: ["cookbook", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Koopasta can be cooked by combining a Koopa Leaf and a Dried Pasta.</p>
        <p className="bingo-hint">
          Koopa Leaf can be found in a bush on the right side of the first screen in Koopa Village.
        </p>
        <p className="bingo-hint">Dried Pasta can be purchased in Dry Dry Outpost for 3 coins.</p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "3 Repel Gels",
    types: ["gulch", "repelgel"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Repel Gel can be found outside the Playground in a hidden block above a stone block.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/b/b9/PMHiddenBlock1.png"
          alt="Repel Gel"
        ></img>
        <p className="bingo-hint">
          The second can be found in Gusty Gulch in a ? block on the screen to the right of where Stanley gets eaten.{" "}
        </p>
        <p className="bingo-hint">
          The third can be found on the beach to the left of Yoshi Village. It is in a hidden block near the bell plant
          between the plant and the rocks with the letter.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/d/d4/PMHiddenBlock27.png"
          alt="Repel Gel"
        ></img>
        <p className="bingo-hint">
          Alternatively, you can <Link to="/Item_Duplication">dupe items</Link> to get these, but this will require{" "}
          <Link to="/Early_Lakilester">Lakilester</Link>.
        </p>
      </div>
    ),
  },
];
bingoList[12] = [
  {
    name: "Defeat Buzzar",
    types: ["buzzar"],
    info: (
      <div>
        <p className="bingo-hint">
          It is not recommended to fight Buzzar during your first visit to Mt. Rugged. With Shell Shot and Ultra Hammer
          Hammer Throw, you can make quick work of Buzzar. If you have Watt, Power Shock trivializes this fight. Buzzar
          has 40 HP and generally deals 3 damage per attack.
        </p>
      </div>
    ),
  },
  {
    name: "Lemon Candy",
    types: ["cookbook", "toybox", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Lemon Candy can be made by combining a Cake Mix and a Lemon.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">
          Lemon can be found in the leftmost tree in the Oasis in Dry Dry Desert. The Oasis is located on the far-right
          side, two rooms down from the path.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Luigi's Autograph",
    types: ["koopakoot"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
      </div>
    ),
  },
  {
    name: "Boo's Portrait",
    types: ["mansion", "bow", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          Go to Boo's Mansion and progress through it until getting the Boo's Portrait. You may want to do{" "}
          <Link to="/Record_Skip">Record Skip</Link>. You cannot collect Bow without giving up the Boo's Portrait, so be
          careful.
        </p>
      </div>
    ),
  },
  {
    name: "Honey Ultra",
    types: ["cookbook", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Honey Ultra is cooked by combining a Honey Syrup and an Ultra Shroom. </p>
        <p className="bingo-hint">
          A Honey Syrup can be found behind a pillar on Pleasant Path near the bridge above the entrance to Koopa
          Village.{" "}
        </p>
        <p className="bingo-hint">
          An Ultra Shroom can be found by hitting the block in the desert 100 times. This block is in the room north of
          the entrance to Mt. Rugged.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Lime Candy",
    types: ["cookbook", "toybox", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Lime Candy can be made by combining a Cake Mix and a Lime.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">
          Lime can be found in the rightmost tree in the Oasis in Dry Dry Desert. The Oasis is located on the far-right
          side, two rooms down from the path.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Boiled Egg",
    types: ["cookbook", "mansion"],
    info: (
      <div>
        <p className="bingo-hint">
          Collect an Egg from the bush by the train at Mt. Rugged and a Stange Leaf from the bush outside Boo's Mansion.
          This can be cooked at Tayce T's house after delivering the Cookbook.
        </p>
      </div>
    ),
  },
  {
    name: "Flower Finder",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 12 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Heart Finder",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 12 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Pyramid Stone",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">The Pyramid Stone is located in Dry Dry Ruins.</p>
        <p className="bingo-hint">
          To get the Pyramid Stone quickly, enter the Dry Dry Ruins in a completed state. Head to the lower-right door
          in the first large hub room, and challenge the Stone Chomps.
        </p>
        <p className="bingo-hint">
          Stone Chomps have 4 HP, 3 Attack, and 1 Defense. They are easily defeated with the Ultra Hammer and various
          partner abilities.
        </p>
      </div>
    ),
  },
  {
    name: "Artifact",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">
          The Artifact is found in the Dry Dry Ruins in the room with the rotating staircase and the three Pokeys.
        </p>
      </div>
    ),
  },
  {
    name: "Melon",
    types: ["ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Melon can be obtained by trading any item cooked by Tayce T. to the adult yellow Yoshi in Yoshi Village after
          completing Chapter 5.
        </p>
      </div>
    ),
  },
  {
    name: "Maple Ultra",
    types: ["cookbook", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Maple Ultra is cooked by combining a Maple Syrup and an Ultra Shroom. </p>
        <p className="bingo-hint">
          Maple Syrup can be found in the second ? block in the <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>{" "}
          room, on the left side of the Cannon room in Koopa Bros Fortress, in a ? block past the locked door on the
          second floor of Tubba Blubba's Castle, in a hidden ? block near the Anti Guy, in the leftmost room in Blue
          Station by defeating the Shy Guy holding it, and bought at the shop in Boo's Mansion for 25 coins.
        </p>
        <p className="bingo-hint">
          An Ultra Shroom can be found by hitting the block in the desert 100 times. This block is in the room north of
          the entrance to Mt. Rugged.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
];
bingoList[13] = [
  {
    name: "Defeat an Anti Guy",
    types: ["toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          This enemy can be found guarding Power Plus on the left side of Blue Station. To defeat Anti Guy, you can use
          Stone Caps bought at Harry's Shop for 30 coins. There is a free one in a hidden block to the right of the
          train station in Blue Station. While under the effects of Stone Caps, an upgraded Parakarry's Shell Shot can
          deal lots of damage. Anti Guy has 50 HP and deals 10 or 12 damage per attack, so be prepared!
        </p>
      </div>
    ),
  },
  {
    name: "Power Plus",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Power Plus can be found in the chest guarded by an Anti Guy on the left side of Blue Station.{" "}
        </p>
        <p className="bingo-hint">
          - To defeat Anti Guy, you can use Stone Caps bought at Harry's Shop for 30 coins. There is a free one in a
          hidden block to the right of the train station in Blue Station. While under the effects of Stone Caps, an
          upgraded Parakarry's Shell Shot can deal lots of damage. Anti Guy has 50 HP and deals 10 or 12 damage per
          attack, so be prepared!{" "}
        </p>
        <p className="bingo-hint">The second Power Plus can be bought from Merlow for 25 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Deluxe Feast",
    types: ["cookbook", "mansion"],
    info: (
      <div>
        <p className="bingo-hint">
          Get a Whacka Bump at Mt. Rugged and a Stange Leaf from the bush outside Boo's Mansion. This can be cooked at
          Tayce T's house after delivering the Cookbook.
        </p>
      </div>
    ),
  },
  {
    name: "Jelly Ultra",
    types: ["cookbook", "desert", "jamminjelly"],
    info: (
      <div>
        <p className="bingo-hint">Jelly Shroom can be cooked by combining a Jammin' Jelly and an Ultra Shroom.</p>
        <p className="bingo-hint">
          Jammin' Jelly is found behind the tree in front of the volcano and in a tree in Lily's room in Flower Fields.
        </p>
        <p className="bingo-hint">
          An Ultra Shroom can be found by hitting the block in the desert 100 times. This block is in the room north of
          the entrance to Mt. Rugged.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Diamond Stone",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">The Diamond Stone is located deep in Dry Dry Ruins.</p>
        <p className="bingo-hint">
          To get the Diamond Stone quickly, enter the Dry Dry Ruins in a completed state. Grab both keys and use one to
          unlock the door in the Artifact room. Go left and bomb the lower wall, then bomb the next wall and defeat the
          Stone Chomps.
        </p>
        <p className="bingo-hint">
          Stone Chomps have 4 HP, 3 Attack, and 1 Defense. They are easily defeated with the Ultra Hammer and various
          partner abilities.
        </p>
      </div>
    ),
  },
  {
    name: "Slow Go",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">
          Slow Go can be found in the Super Hammer room by solving the puzzle, falling onto the edges above the Super
          Hammer chest, and following the secret tunnel on the left wall. See{" "}
          <Link to="/Slow_Go_Early">Slow Go Early</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Bow",
    types: ["mansion", "bow"],
    info: (
      <div>
        <p className="bingo-hint">
          Go to Boo's Mansion and progress through it until getting Bow. You may want to do{" "}
          <Link to="/Record_Skip">Record Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Healthy Juice",
    types: ["cookbook"],
    info: (
      <div>
        <p className="bingo-hint">Healthy Juice can be cooked by combining a Special Shake and a Strange Leaf. </p>
        <p className="bingo-hint">
          The easiest way to make a Special Shake is to combine the Jammin' Jelly in front of the volcano with the Honey
          Syrup on Pleasant Path.{" "}
        </p>
        <p className="bingo-hint">Strange Leaf can be found in a bush in front of Boo's Mansion. </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Lunar Stone",
    types: ["ruins"],
    info: (
      <div>
        <p className="bingo-hint">The Lunar Stone is located deep in Dry Dry Ruins.</p>
        <p className="bingo-hint">
          To get the Lunar Stone quickly, enter the Dry Dry Ruins in a completed state. Grab both keys and use them to
          unlock both doors through the Artifact room. Go right through the lower door in the room with the statues, and
          defeat the Stone Chomps.
        </p>
        <p className="bingo-hint">
          Stone Chomps have 4 HP, 3 Attack, and 1 Defense. They are easily defeated with the Ultra Hammer and various
          partner abilities.
        </p>
      </div>
    ),
  },
];
bingoList[14] = [
  {
    name: "Super Hammer",
    types: ["ruins", "hammer", "ch2"],
    info: (
      <div>
        <p className="bingo-hint">
          The Super Hammer is found in Dry Dry Ruins. It is recommended to get it after getting the Ultra Hammer when
          Chapter 2 is in a completed state. Note that collecting the Super Hammer will revert your story progress to a
          Chapter 2 state.
        </p>
      </div>
    ),
  },
  {
    name: "Quick Change",
    types: ["boots"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Quick Change, get the Super or Ultra Boots, then Spin or Tornado jump in Merlon's house three times.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank 2 Partners",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get 2 Ultra-Rank Partners, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path and upgrade partners that will help you reach your objectives.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Wallet",
    types: ["koopakoot"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
        <p className="bingo-hint">After Chapter 2:</p>
        <p className="bingo-hint">
          6. Get the Wallet from the bush near the right side loading zone of the left screen in Koopa Village.
        </p>
      </div>
    ),
  },
  {
    name: "All 4 Magical Seeds",
    types: ["ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          The first seed is found in Toad Town in front of the bridge leading to Forever Forest.{" "}
        </p>
        <p className="bingo-hint">
          The second is in Mt. Rugged (see <Link to="/Early_Seed">Early Seed</Link>).{" "}
        </p>
        <p className="bingo-hint">The third is in Forever Forest (R2 L1 L1 L). </p>
        <p className="bingo-hint">The fourth is collected by trading the Volcano Vase to Kolorado after Chapter 5.</p>
      </div>
    ),
  },
  {
    name: "Defeat Kent C. Koopa",
    types: ["ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Kent C. Koopa shows up after completing Chapter 5 and giving the Volcano Vase to Kolorado.{" "}
        </p>
        <p className="bingo-hint">
          To defeat him, use Lullaby to put him to sleep, and attack his tail when he is knocked over. It is recommended
          to save before fighting him, because Lullaby can fail.{" "}
        </p>
      </div>
    ),
  },
  {
    name: "3x HP Plus",
    types: ["hp"],
    info: (
      <div>
        <p className="bingo-hint">
          The first HP Plus can be collected with Kooper on the way back from the Fuzzy minigame.{" "}
        </p>
        <p className="bingo-hint">The second can be found in Forever Forest. </p>
        <p className="bingo-hint">- From Toad Town, you can reach it by going R2, L1, R1, R1, L. </p>
        <p className="bingo-hint">- From Boo's Mansion, go L1, L1, R2, L. </p>
        <p className="bingo-hint">- Watch out for Chapter 3 Jr Troopa if you are in a post-Super Boots state! </p>
        <p className="bingo-hint">
          The third HP Plus can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 150 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
];
bingoList[15] = [
  {
    name: "Strange Cake",
    types: ["cookbook", "mansion", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">Strange Cake can be made by combining a Cake Mix and a Strange Leaf.</p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Strange Leaf can be found in a bush in front of Boo's Mansion. </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "HP Drain",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">This can be bought from Merlow for 15 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "Tubba Blubba's Castle Key",
    types: ["gulch", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          The fastest Key in Tubba Blubba's Castle is found by going left until reaching the large table, then taking
          the small door in the back and breaking the panel. To get the Super Boots quickly, you may want to do{" "}
          <Link to="/Record_Skip">Record Skip</Link>. Also, see <Link to="/Bow_Skip">Bow Skip</Link> and{" "}
          <Link to="/Stanley_Save">Stanley Save</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "D-Down Jump",
    types: ["gulch", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          In Tubba's castle, use Bombette on the second floor to break the wall behind the sleeping Clubba. Break the
          rightmost panel and use Parakarry to get D-Down Jump. You will need a boots upgrade for this, so prepare
          accordingly!
        </p>
      </div>
    ),
  },
  {
    name: "At Least 25 HP",
    types: ["levels", "hp"],
    info: (
      <div>
        <p className="bingo-hint">
          Find the highest level enemies your row or column can access, and defeat them quickly. Some good ways to deal
          lots of damage include upgraded Bombette, upgraded Parakarry, Power Quake, Thunder Rages, and Star Storm. You
          will want to save 39 coins and upgrade BP and FP each once, then use Chet Rippo's services near Harry's Shop
          to convert 5 FP and 3 BP to 10 extra HP. You can equip HP Plus badges to reach this number, but they must be
          equipped in order for this objective to be completed.
        </p>
      </div>
    ),
  },
  {
    name: "At Least 25 FP",
    types: ["levels", "fp"],
    info: (
      <div>
        <p className="bingo-hint">
          Find the highest level enemies your row or column can access, and defeat them quickly. Some good ways to deal
          lots of damage include upgraded Bombette, upgraded Parakarry, Power Quake, Thunder Rages, and Star Storm. You
          will want to save 39 coins and upgrade BP once, then use Chet Rippo's services near Harry's Shop to convert 5
          HP and 3 BP into 10 FP. You can equip FP Plus badges to reach this number, but they must be equipped in order
          for this objective to be completed.
        </p>
      </div>
    ),
  },
];
bingoList[16] = [
  {
    name: "Defeat Lava Piranha",
    types: ["volcano", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          To defeat Lava Piranha, consider upgrading Sushie for Water Block and equipping Fire Shield. Hammer Throw,
          Shell Shot, and Squirt are good damage options. Thunder Rage and a partner attack that hits all enemies is a
          good strategy for eliminating the Lava Buds in phase 1. Snowman Dolls are a useful item to have for Phase 2,
          as it will knock out Lava Piranha and the Lava Buds. Consider doing{" "}
          <Link to="/Lava_Piranha_Out_Of_Bounds">Lava Piranha Out Of Bounds</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "At Least 8 Letters",
    types: ["desert", "letters"],
    info: (
      <div>
        <p className="bingo-hint">
          See{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://shrines.rpgclassics.com/n64/papermario/letters.shtml"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    name: "3x FP Plus",
    types: ["fp", "calculator"],
    info: (
      <div>
        <p className="bingo-hint">
          The first FP Plus can be collected by breaking the wall in the room before Koopa Bros Fortress or doing{" "}
          <Link to="/FP_Plus_Early">FP Plus Early</Link>.{" "}
        </p>
        <p className="bingo-hint">The second is in Forever Forest. </p>
        <p className="bingo-hint">
          - From Boo's mansion, go R1, then L until you see the path to the badge in the center of the room.{" "}
        </p>
        <p className="bingo-hint">
          - If entering from Toad Town, head to Boo's Mansion and follow the directions from Boo's Mansion.{" "}
        </p>
        <p className="bingo-hint">- Don't forget about Chapter 3 Jr Troopa if you're in a post-Super Boots state! </p>
        <p className="bingo-hint">
          The third FP Plus can be bought from Rowf's Badge Shop in a post-Chapter 4 state for 150 coins.{" "}
        </p>
        <p className="bingo-hint">- Don't forget to deliver the Calculator!</p>
      </div>
    ),
  },
  {
    name: "Stinky Herb",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Stinky Herb can be found by walking against the vines in Flower Fields. The northwest, east, and southeast
          paths have easily accessible vines for getting Stinky Herbs.
        </p>
      </div>
    ),
  },
  {
    name: "Red Berry",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">The Red Berry is located in the tree in Petunia's room in Flower Fields (East).</p>
      </div>
    ),
  },
  {
    name: "Defeat Super Blooper",
    types: ["blooper", "hammer"],
    info: (
      <div>
        <p className="bingo-hint">
          To defeat Super Blooper, you will first need to defeat Electro Blooper and get a hammer upgrade. You will want
          Ultra Rank Parakarry or Star Storm for the Blooper Babies, because they have 6 HP each. Super Blooper has 70
          HP and can charge to increase its damage output from 5 to 4 x2. Hammer Throw and Shell Shot are excellent
          choices for this fight. Bringing extra Whacka Bumps is recommended.
        </p>
      </div>
    ),
  },
  {
    name: "At Least 15 BP",
    types: ["levels"],
    info: (
      <div>
        <p className="bingo-hint">
          Find the highest level enemies your row or column can access, and defeat them quickly. Some good ways to deal
          lots of damage include upgraded Bombette, upgraded Parakarry, Power Quake, Thunder Rages, and Star Storm. You
          will want to save 39 coins and upgrade FP once, then use Chet Rippo's services near Harry's Shop to convert 5
          HP and 5 FP to 6 extra BP.
        </p>
      </div>
    ),
  },
];
bingoList[17] = [
  {
    name: "Complete Chapter 5",
    types: ["volcano", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          To defeat Lava Piranha, consider upgrading Sushie for Water Block and equipping Fire Shield. Hammer Throw,
          Shell Shot, and Squirt are good damage options. Thunder Rage and a partner attack that hits all enemies is a
          good strategy for eliminating the Lava Buds in phase 1. Snowman Dolls are a useful item to have for Phase 2,
          as it will knock out Lava Piranha and the Lava Buds. Since you need to collect the cards and see the chapter
          complete screen to satisfy this objective, you cannot do{" "}
          <Link to="/Lava_Piranha_Out_Of_Bounds">Lava Piranha Out Of Bounds</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Watt",
    types: ["watt", "toybox", "ch4"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Watt, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat Lantern Ghost",
    types: ["watt", "toybox", "ch4"],
    info: (
      <div>
        <p className="bingo-hint">
          First, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
      </div>
    ),
  },
  {
    name: "Power Rush",
    types: ["ch2", "ch3", "ch4", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Power Rush is located in the Library in Peach's Castle. It can be collected in any Peach cutscene aside from
          the one in Chapter 1. It must be placed in the chest in the rightmost room of the upper floor of the castle,
          then collected at Merluvlee's house near Shooting Star Summit.
        </p>
      </div>
    ),
  },
  {
    name: "Crystal Ball",
    types: ["koopakoot", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf.)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
        <p className="bingo-hint">After Chapter 2:</p>
        <p className="bingo-hint">
          6. Get the Wallet from the bush near the right side loading zone of the left screen in Koopa Village.
        </p>
        <p className="bingo-hint">
          7. Give a Tasty Tonic to Koopa Koot. (Harry's Shop for 5 coins, Yoshi Village for 2 coins or behind a bush
          outside the oasis on the left side.)
        </p>
        <p className="bingo-hint">
          8. Speak with Merluvlee, then go to Merlee in the back alley of Dry Dry Outpost to get the Crystal Ball.
        </p>
      </div>
    ),
  },
  {
    name: "2 Jammin’ Jellies",
    types: ["jamminjelly", "flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">The first Jammin' Jelly is found behind the tree in front of the volcano. </p>
        <p className="bingo-hint">The second is found in a tree in Lily's room in Flower Fields. </p>
        <p className="bingo-hint">
          There is alternatively a Jammin' Jelly given to Peach if she wins the quiz show after Chapter 5, but you will
          have to do another Peach cutscene to place it in the chest, and then you will have to pick it up with Mario.
          This is not recommended.
        </p>
      </div>
    ),
  },
  {
    name: "Money Money",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">Money Money can be bought from Merlow for 20 Star Pieces.</p>
      </div>
    ),
  },
];
bingoList[18] = [
  {
    name: "Defeat a Chain Chomp",
    types: ["ruins", "ch2"],
    info: (
      <div>
        <p className="bingo-hint">
          This objective requires that you fight Tutankoopa and defeat his pet Chain Chomp before winning the fight.
          Tutankoopa has 30 HP, 3 Attack, and 0 Defense and falls in 3 turns to Power Jump and upgraded Parakarry's
          Shell Shot if you have 15 FP. The Chain Chomp has 4 HP, 3 Attack, and 3 Defense. It is easiest to complete
          Chapter 2 by entering from the sewers and collecting some stones and keys in a completed state. To retrigger
          Chapter 2, get the Super Hammer. You will, however, need to defeat Electro Blooper to unlock the blue pipe to
          Dry Dry Outpost.
        </p>
      </div>
    ),
  },
  {
    name: "Mystical Key",
    types: ["gulch", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          The Mystical Key is obtained by opening the chest by Tubba Blubba's bed. You will need the Super Boots to get
          it.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank Bow",
    types: ["bow", "upgrades", "mansion"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Bow, you will need to get the Ultra Stone and Bow. Be sure to pick Upgrade Blocks that are
          on your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
        <p className="bingo-hint">
          To get Bow, Go to Boo's Mansion and progress through it until getting Bow. You may want to do{" "}
          <Link to="/Record_Skip">Record Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Mystery Note",
    types: ["bow", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          The Mystery Note is in the east room of Green Station. You will need Bow to reach it, and you will need to
          fight 4 Shy Guys. Star Storm or Spook can be used to easily defeat these Shy Guys.
        </p>
      </div>
    ),
  },
  {
    name: "Fourth-Degree Card",
    types: ["dojo"],
    info: (
      <div>
        <p className="bingo-hint">
          For the Fourth-Degree Card, you will need to defeat Chan, Lee, Master 1, and Master 2.
        </p>
        <p className="bingo-hint">
          Chan has 15 HP, 2 Attack, and 2 Defense. He is easily defeated by flipping him like any Buzzy Beetle, because
          his defense is 0 while he is flipped.
        </p>
        <p className="bingo-hint">
          Lee has 20 HP, 5 Attack, and 0 Defense. He can turn into your partners, so if you start the fight with Kooper,
          you can flip him like any Koopa and defeat him easily.
        </p>
        <p className="bingo-hint">
          Master 1 has 50 HP, 6 Attack, and 0 Defense. The fight is straightforward, but be prepared! You may want extra
          HP and some Whacka Bumps, or Chill Out. If you have a strong Ultra-Rank partner, Stone Caps might be a good
          idea.
        </p>
        <p className="bingo-hint">
          Master 2 has 75 HP, 8 Attack, and 0 Defense. Master 2 has a multi-hit attack, which can be stopped by using
          Volt Shrooms or the Zap Tap badge or mitigated with Chill Out. Ultra-Rank partners like Goombario, Parakarry,
          and Bow can deal large amounts of damage each turn. As usual, bring Whacka Bumps or other strong healing
          items!
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank 3 Partners",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get 3 Ultra-Rank Partners, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path and upgrade partners that will help you reach your objectives.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Last Stand",
    types: ["ch4", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          During the post-chapter 5 Peach cutscene, go to the room that is second from the right on the lower floor of
          the castle to find Last Stand. Bring it to the rightmost room on the upper floor and put it in the chest.
        </p>
        <p className="bingo-hint">Don't forget to pick it up from Merluvlee's house near Shooting Star Summit!</p>
      </div>
    ),
  },
];
bingoList[19] = [
  {
    name: "Complete Chapter 2",
    types: ["ruins", "ch2"],
    info: (
      <div>
        <p className="bingo-hint">
          Tutankoopa has 30 HP, 3 Attack, and 0 Defense and falls in 3 turns to Power Jump and upgraded Parakarry's
          Shell Shot if you have 15 FP. The Chain Chomp has 4 HP, 3 Attack, and 3 Defense. It is easiest to complete
          Chapter 2 by entering from the sewers and collecting some stones and keys in a completed state. To retrigger
          Chapter 2, get the Super Hammer. You will, however, need to defeat Electro Blooper to unlock the blue pipe to
          Dry Dry Outpost.
        </p>
      </div>
    ),
  },
  {
    name: "Dictionary",
    types: ["bow", "toybox"],
    info: (
      <div>
        <p className="bingo-hint">
          The dictionary is found in Green Station. You will need Bow to get to it, so plan accordingly.
        </p>
      </div>
    ),
  },
  {
    name: "Merluvlee's Autograph",
    types: ["koopakoot", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf.)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
        <p className="bingo-hint">After Chapter 2:</p>
        <p className="bingo-hint">
          6. Get the Wallet from the bush near the right side loading zone of the left screen in Koopa Village.
        </p>
        <p className="bingo-hint">
          7. Give a Tasty Tonic to Koopa Koot. (Harry's Shop for 5 coins, Yoshi Village for 2 coins or behind a bush
          outside the oasis on the left side.)
        </p>
        <p className="bingo-hint">
          8. Speak with Merluvlee, then go to Merlee in the back alley of Dry Dry Outpost to get the Crystal Ball.
          Deliver that to Merluvlee for the autograph.
        </p>
      </div>
    ),
  },
  {
    name: "2x Deep Focus",
    types: ["toybox", "ch2", "ch3", "ch4", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Deep Focus is in an invisible block between two ? blocks outside of Big Lantern Ghost's room in Red
          Station.{" "}
        </p>
        <img
          className="bingo-image"
          src="https://mario.wiki.gallery/images/d/da/PMHiddenBlock24.png"
          alt="Deep Focus"
        ></img>
        <p className="bingo-hint">
          The second can be most easily collected during the post-chapter 5 Peach cutscene. It is found in the rightmost
          room on the bottom floor.{" "}
        </p>
        <p className="bingo-hint">
          - Don't forget to go and pick it up at Merluvlee's house near Shooting Star Summit.
        </p>
      </div>
    ),
  },
  {
    name: "Flower Saver",
    types: ["flowerfields", "starpiece", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">One can be bought from Merlow for 25 Star Pieces. </p>
        <p className="bingo-hint">
          The other can be obtained by throwing a Blue Berry in the well behind Petunia's room in Flower Fields. This is
          done most quickly by performing <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link> to avoid having to
          collect the other berries.
        </p>
      </div>
    ),
  },
  {
    name: "Apple Pie",
    types: ["cookbook", "mansion", "toybox", "fakupie"],
    info: (
      <div>
        <p className="bingo-hint">Apple Pie can be cooked by combining an Apple and a Cake Mix.</p>
        <p className="bingo-hint">
          Apples can be found in Boo's Mansion by Spin Jumping on the crates in the rightmost room on the floor with the
          giant sofa. To get the Super Boots quickly, you may want to do <Link to="/Record_Skip">Record Skip</Link>.{" "}
        </p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.{" "}
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "Defeat Fuzzipede",
    types: ["toybox", "watt", "ch4"],
    info: (
      <div>
        <p className="bingo-hint">
          First, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
        <p className="bingo-hint">
          After getting Watt, leave the Toy Box and do <Link to="/Early_Whale">Early Whale</Link> to challenge
          Fuzzipede. If you can't do Early Whale, you can either push the Toad to the left of the docks to get onto the
          water, or defeat General Guy and watch the Peach cutscene.
        </p>
        <p className="bingo-hint">
          Fuzzipede has 20 HP, 3 Attack, and 0 Defense. Watt must stay out during this fight. Electro Dash and Power
          Shock can be used to deal consistent damage and stun Fuzzipede. With Mario, Hammer Throw is a good way to deal
          large amounts of damage because of Fuzzipede's ability to stand on the ceiling. Fuzzipede's attacks can take
          your actions away, so be careful!
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank Watt",
    types: ["watt", "upgrades", "toybox", "ch4"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Watt, you will need to get the Ultra Stone and Watt. Be sure to pick Upgrade Blocks that are
          on your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
        <p className="bingo-hint">
          To get Watt, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat General Guy",
    types: ["toybox", "watt", "ch4"],
    info: (
      <div>
        <p className="bingo-hint">
          First, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
        <p className="bingo-hint">After getting Watt, leave the Toy Box and go east to challenge General Guy.</p>
        <p className="bingo-hint">
          The Shy Squad has 15 HP, 1 Attack, and 0 Defense, but attacks a number of times equal to its remaining HP.
        </p>
        <p className="bingo-hint">The Stilt Guys have 7 HP, 4 Attack, and 0 Defense.</p>
        <p className="bingo-hint">
          The Shy Stacks have 10 HP, 1 Attack, and 0 Defense, and they shoot a rock at you for each Shy Guy in the stack
          (for a total of 4 Attack).
        </p>
        <p className="bingo-hint">
          General Guy has 30 HP, 4 Attack, and 2 Defense. His Lightning attack deals 5 to Mario and 2 to his partner
          unless that partner is Watt. You can attack the Bulb, which has 8 HP and 1 Defense and will prevent General
          Guy from using Lightning. Super-Rank Watt and the Ultra Hammer are good tools for defeating General Guy. Be
          sure to pack some healing items!
        </p>
      </div>
    ),
  },
  {
    name: "Yellow Berry",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          In Flower Fields, go east to grab a Red Berry from Petunia's room, then head southwest and feed the Red Berry
          to the Red Plant to get to the Yellow Berry tree.{" "}
        </p>
      </div>
    ),
  },
];
bingoList[20] = [
  {
    name: "Magical Bean",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Defeat the moles to save Petunia, then go speak with her to receive the Magical Bean.{" "}
        </p>
        <p className="bingo-hint">
          Moles have 12 HP, deal 3 damage, and have no defense. Power Bomb and Star Storm are recommended to defeat
          them.
        </p>
      </div>
    ),
  },
  {
    name: "Complete Chapter 4",
    types: ["toybox", "watt", "ch4"],
    info: (
      <div>
        <p className="bingo-hint">
          First, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
        <p className="bingo-hint">After getting Watt, leave the Toy Box and go east to challenge General Guy.</p>
        <p className="bingo-hint">
          The Shy Squad has 15 HP, 1 Attack, and 0 Defense, but attacks a number of times equal to its remaining HP.
        </p>
        <p className="bingo-hint">The Stilt Guys have 7 HP, 4 Attack, and 0 Defense.</p>
        <p className="bingo-hint">
          The Shy Stacks have 10 HP, 1 Attack, and 0 Defense, and they shoot a rock at you for each Shy Guy in the stack
          (for a total of 4 Attack).
        </p>
        <p className="bingo-hint">
          General Guy has 30 HP, 4 Attack, and 2 Defense. His Lightning attack deals 5 to Mario and 2 to his partner
          unless that partner is Watt. You can attack the Bulb, which has 8 HP and 1 Defense and will prevent General
          Guy from using Lightning. Super-Rank Watt and the Ultra Hammer are good tools for defeating General Guy. Be
          sure to pack some healing items!
        </p>
      </div>
    ),
  },
  {
    name: "Fertile Soil",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Defeat the moles to save Petunia, then grab a Red Berry and head southwest to speak with Posie to receive the
          Fertile Soil.{" "}
        </p>
        <p className="bingo-hint">
          Moles have 12 HP, deal 3 damage, and have no defense. Power Bomb and Star Storm are recommended to defeat
          them.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat an Amazee Dazee",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Reload the room with the Yellow Flower until you see an Amazee Dazee. Dizzy Attack allows you to get a free
          set of attacks on it. It has 20 HP and 1 defense. Alternatively, it will always run from a Fright Jar and
          Spook is very easy against it.
        </p>
      </div>
    ),
  },
  {
    name: "Mega Quake",
    types: ["calculator", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">Mega Quake can be bought from Rowf's Badge Shop after Chapter 5 for 200 coins. </p>
        <p className="bingo-hint">
          - Make sure to give the Volcano Vase to Kolorado to ensure you are in a post-Chapter 5 state.
        </p>
      </div>
    ),
  },
  {
    name: "S. Smash Charge",
    types: ["calculator", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          S. Smash Charge can be bought from Rowf's Badge Shop after Chapter 5 for 100 coins.{" "}
        </p>
        <p className="bingo-hint">
          - Make sure to give the Volcano Vase to Kolorado to ensure you are in a post-Chapter 5 state.
        </p>
      </div>
    ),
  },
  {
    name: "2x Damage Dodge",
    types: ["calculator", "damagedodge", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Damage Dodge is found in a chest in Mt. Rugged behind the spring that leads to the leftmost letter.{" "}
        </p>
        <p className="bingo-hint">
          The second can be bought from Rowf's Badge Shop after Chapter 5 for 150 coins. Make sure to give the Volcano
          Vase to Kolorado to ensure you are in a post-Chapter 5 state.
        </p>
      </div>
    ),
  },
  {
    name: "Yoshi Cookie",
    types: ["cookbook", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">Yoshi Cookie is cooked by combining a Melon and a Cake Mix. </p>
        <p className="bingo-hint">
          Melon can be obtained by trading any item cooked by Tayce T. to the adult yellow Yoshi in Yoshi Village after
          completing Chapter 5.
        </p>
        <p className="bingo-hint">
          Cake Mix can be found in the leftmost room in Blue Station by defeating the Shy Guy holding it.
        </p>
        <p className="bingo-hint">Don't forget to deliver the Cookbook!</p>
      </div>
    ),
  },
  {
    name: "30 Star Pieces",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">
          Collect every star piece you can find. Hitting panels with the Ultra Hammer or the Super Boots is recommended.
          You can also deliver letters, if needed, or do some Koopa Koot favors for a few extra. Make sure to do
          Quizmo's quizzes whenever you see him!
        </p>
      </div>
    ),
  },
];
bingoList[21] = [
  {
    name: "Defeat Lakilester",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Lakilester, enter Chapter 6 using the 4 Magical Seeds, then perform{" "}
          <Link to="/Early_Lakilester">Early Lakilester</Link>.
        </p>
        <p className="bingo-hint">
          Lakilester has 50 HP, 4 Attack, and 0 Defense. It is recommended to Hammer Throw and Shell Shot, and pack some
          Whacka Bumps.
        </p>
      </div>
    ),
  },
  {
    name: "Gold Credit",
    types: ["koopakoot", "desert"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf.)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
        <p className="bingo-hint">After Chapter 2:</p>
        <p className="bingo-hint">
          6. Get the Wallet from the bush near the right side loading zone of the left screen in Koopa Village.
        </p>
        <p className="bingo-hint">
          7. Give a Tasty Tonic to Koopa Koot. (Harry's Shop for 5 coins, Yoshi Village for 2 coins or behind a bush
          outside the oasis on the left side.)
        </p>
        <p className="bingo-hint">
          8. Speak with Merluvlee, then go to Merlee in the back alley of Dry Dry Outpost to get the Crystal Ball.
          Deliver that to Merluvlee for the autograph.
        </p>
        <p className="bingo-hint">After Chapter 3:</p>
        <p className="bingo-hint">9. Read the front and back of the sign in front of Merlon's house.</p>
        <p className="bingo-hint">10. Give a Life Shroom to Koopa Koot.</p>
      </div>
    ),
  },
  {
    name: "Defeat Tubba Blubba",
    types: ["gulch", "boots", "ch3"],
    info: (
      <div>
        <p className="bingo-hint">
          To defeat Tubba Blubba, you may want to get peril from the Clubbas (3 damage each unblocked) and pick up Mega
          Rush. Mega Rush combined with Power Bounce along with Shell Shot will allow you to make quick work of Tubba's
          Heart. Entering from a completed state will allow you to retrigger Chapter 3 by opening the Yakkey chest. You
          will need a boots upgrade to complete this chapter, so plan accordingly. Also, see{" "}
          <Link to="/Bow_Skip">Bow Skip</Link>, <Link to="/Stanley_Save">Stanley Save</Link>, and{" "}
          <Link to="/Quick_Escape">Quick Escape</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank 4 Partners",
    types: ["upgrades"],
    info: (
      <div>
        <p className="bingo-hint">
          To get 4 Ultra-Rank Partners, you will need to get the Ultra Stone. Be sure to pick Upgrade Blocks that are on
          your path and upgrade partners that will help you reach your objectives.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
      </div>
    ),
  },
  {
    name: "Complete Chapter 1",
    types: ["ch1"],
    info: (
      <div>
        <p className="bingo-hint">
          For the Cannons, you can Bomb first strike, Thunder Bolt, then Bomb. You can also Bomb first strike, do
          nothing/Bomb, then Jump x2/Bomb. Alternatively, you can POW Block twice. For the Koopa Bros, use Power
          Jump/Bomb/Power Jump, then Bomb, POW Block, Fire Flower. Use Power Jump and Bomb repeatedly to beat Jr Troopa
          on the way back.
        </p>
      </div>
    ),
  },
];
bingoList[22] = [
  {
    name: "Crystal Berry",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Progress through Chapter 6 normally. You will need to fight the moles and the maze Lakitus. Speak to Petunia,
          Lily, Rosie, then Posie. You may want to do <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "2x Power Plus",
    types: ["starpiece"],
    info: (
      <div>
        <p className="bingo-hint">
          The first Power Plus can be found in the chest guarded by an Anti Guy on the left side of Blue Station.{" "}
        </p>
        <p className="bingo-hint">
          - To defeat Anti Guy, you can use Stone Caps bought at Harry's Shop for 30 coins. There is a free one in a
          hidden block to the right of the train station in Blue Station. While under the effects of Stone Caps, an
          upgraded Parakarry's Shell Shot can deal lots of damage. Anti Guy has 50 HP and deals 10 or 12 damage per
          attack, so be prepared!{" "}
        </p>
        <p className="bingo-hint">The second Power Plus can be bought from Merlow for 25 Star Pieces.</p>
      </div>
    ),
  },
  {
    name: "2x Flower Saver",
    types: ["flowerfields", "starpiece", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">One can be bought from Merlow for 25 Star Pieces. </p>
        <p className="bingo-hint">
          The other can be obtained by throwing a Blue Berry in the well behind Petunia's room in Flower Fields. This is
          done most quickly by performing <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link> to avoid having to
          collect the other berries.
        </p>
      </div>
    ),
  },
  {
    name: "Complete Chapter 3",
    types: ["gulch", "boots", "ch3"],
    info: (
      <div>
        <p className="bingo-hint">
          To complete Chapter 3, you may want to get peril from the Clubbas (3 damage each unblocked) and pick up Mega
          Rush. Mega Rush combined with Power Bounce along with Shell Shot will allow you to make quick work of Tubba's
          Heart. Entering from a completed state will allow you to retrigger Chapter 3 by opening the Yakkey chest. You
          will need a boots upgrade to complete this chapter, so plan accordingly. Also, see{" "}
          <Link to="/Bow_Skip">Bow Skip</Link>, <Link to="/Stanley_Save">Stanley Save</Link>, and{" "}
          <Link to="/Quick_Escape">Quick Escape</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra Boots",
    types: ["flowerfields", "boots", "sushie", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Enter Flower Fields using the 4 Magical Seeds, then do <Link to="/Early_Lakilester">Early Lakilester</Link>{" "}
          and defeat Lakilester.
        </p>
        <p className="bingo-hint">
          Go northeast in Flower Fields and defeat the 3 Lakitus and 1 Magikoopa to break the Puff-Puff Machine. You
          will need a boots upgrade to reach this area, so either come with the Super Boots or go get the Ultra Boots
          with Lakilester.
        </p>
      </div>
    ),
  },
  {
    name: "Ultra-Rank Lakilester",
    types: ["flowerfields", "upgrades", "sushie", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          To get Ultra-Rank Lakilester, you will need to get the Ultra Stone and Lakilester. Be sure to pick Upgrade
          Blocks that are on your path.
        </p>
        <p className="bingo-hint">
          To get the Ultra Stone, do <Link to="/Raphael_Skip">Yoshi Skip</Link> and head to the top of the tree to speak
          with Raphael. Continue speaking with him until he gives you the Ultra Stone.
        </p>
        <p className="bingo-hint">
          To get Lakilester, enter Chapter 6 using the 4 Magical Seeds, then perform{" "}
          <Link to="/Early_Lakilester">Early Lakilester</Link>.{" "}
        </p>
        <p className="bingo-hint">
          Lakilester has 50 HP, 4 Attack, and 0 Defense. It is recommended to Hammer Throw and Shell Shot, and pack some
          Whacka Bumps.
        </p>
      </div>
    ),
  },
];
bingoList[23] = [
  {
    name: "Bubble Berry",
    types: ["flowerfields", "sushie", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Bubble Berry can be obtained by hitting the bubble tree near Lily in Flower Fields.
        </p>
        <p className="bingo-hint">
          If entering from an uncompleted state, do <Link to="/Early_Lakilester">Early Lakilester</Link> and get
          Lakilester so that the water is raised.
        </p>
        <p className="bingo-hint">
          Lakilester has 50 HP, 4 Attack, and 0 Defense. It is recommended to Hammer Throw and Shell Shot, and pack some
          Whacka Bumps.
        </p>
        <p className="bingo-hint">
          Once the water is raised (or if entering from a completed state), do{" "}
          <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link> or <Link to="/Dayzee_Bypass">Dayzee Bypass</Link> to
          get past the yellow flower gate, or go collect berries (red first, yellow second) to unlock the gate.
        </p>
        <p className="bingo-hint">
          If you do not have Sushie, you can use Lakilester to clip out at the back-left corner of the Sushie panel.
          Then, move back and forth at the front-left corner of the same panel until Lakilester is raised, and get off
          of him. Then, you can clip at either corner of the bubble tree panel. It is recommended to do this objective
          last so that you don't have to leave the bubble tree.
        </p>
      </div>
    ),
  },
  {
    name: "Water Stone",
    types: ["flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Progress through Chapter 6 normally. You will need to fight the moles and the maze Lakitus. Speak to Petunia,
          Lily, Rosie, Posie, then Rosie again. You may want to do{" "}
          <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link>.
        </p>
      </div>
    ),
  },
  {
    name: "Old Photo",
    types: ["koopakoot", "desert", "mansion"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf.)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
        <p className="bingo-hint">After Chapter 2:</p>
        <p className="bingo-hint">
          6. Get the Wallet from the bush near the right side loading zone of the left screen in Koopa Village.
        </p>
        <p className="bingo-hint">
          7. Give a Tasty Tonic to Koopa Koot. (Harry's Shop for 5 coins, Yoshi Village for 2 coins or behind a bush
          outside the oasis on the left side.)
        </p>
        <p className="bingo-hint">
          8. Speak with Merluvlee, then go to Merlee in the back alley of Dry Dry Outpost to get the Crystal Ball.
          Deliver that to Merluvlee for the autograph.
        </p>
        <p className="bingo-hint">After Chapter 3:</p>
        <p className="bingo-hint">9. Read the front and back of the sign in front of Merlon's house.</p>
        <p className="bingo-hint">10. Give a Life Shroom to Koopa Koot.</p>
        <p className="bingo-hint">11. Give a Nutty Cake to Koopa Koot. (Cook a Goomnut.)</p>
        <p className="bingo-hint">After Chapter 4:</p>
        <p className="bingo-hint">
          12. Speak to the Bob-ombs in the house next door with Bombette as your active partner.
        </p>
        <p className="bingo-hint">13. Speak with the Boo in the main room of Boo's Mansion for the Old Photo.</p>
      </div>
    ),
  },
  {
    name: "Defeat Jr. Troopa 4 Times",
    types: ["jungle", "ch1"],
    info: (
      <div>
        <p className="bingo-hint">
          The first four Jr. Troopas can be found in the playground in Prologue, on Pleasant Path after beating Chapter
          1, in Forever Forest by the Boo's Mansion entrance in a post-Super Boots state, and by taking the Whale back
          to Toad Town in a post-Sushie state.
        </p>
      </div>
    ),
  },
  {
    name: "S. Jump Charge",
    types: ["flowerfields", "sushie", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          To get S. Jump Charge, you will need to reach Chapter 6 by collecting all the Magical Seeds,{" "}
          <Link to="/Early_Lakilester">get Lakilester</Link>, then do{" "}
          <Link to="/Lakilester_Peach_Warp">Peach Warp</Link>. Alternatively, you can get Sushie and the Super Boots,
          and then do <Link to="/Sushie_Peach_Warp">Sushie Peach Warp</Link> after completing Chapter 5. After Peach
          Warping, take the beanstalk to Cloudy Climb and get S. Jump Charge.
        </p>
      </div>
    ),
  },
];
bingoList[24] = [
  {
    name: "Break the Puff-Puff Machine",
    types: ["boots", "flowerfields", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Enter Flower Fields using the 4 Magical Seeds, then do <Link to="/Early_Lakilester">Early Lakilester</Link>{" "}
          and defeat Lakilester.{" "}
        </p>
        <p className="bingo-hint">
          Go northeast in Flower Fields and defeat the 3 Lakitus and 1 Magikoopa to break the Puff-Puff Machine. You
          will need a boots upgrade to reach this area, so either come with the Super Boots or go get the Ultra Boots
          with Lakilester.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat 2 Different Chapter Bosses",
    types: ["ch1", "ch2", "ch3", "ch4", "volcano", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">
          Choose the most optimal bosses for your other objectives, and defeat them. If there is no synergy, the Koopa
          Bros, Tutankoopa, and Lava Piranha are usually good choices. Choosing Tubba Blubba gets you Mega Rush, which
          could have some value too.
        </p>
      </div>
    ),
  },
  {
    name: "Obtain a Letter for Frost T.",
    types: ["mansion", "desert"],
    info: (
      <div>
        <p className="bingo-hint">
          See bottom of{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://shrines.rpgclassics.com/n64/papermario/letters.shtml"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    name: "Obtain All Partners",
    types: ["bow", "watt", "ch5", "flowerfields"],
    info: (
      <div>
        <p className="bingo-hint">
          The only partners that are more difficult to obtain that simply going to where you get them with the correct
          story progress are Watt and Lakilester.
        </p>
        <p className="bingo-hint">To get Watt, you will first need to defeat Big Lantern Ghost.</p>
        <p className="bingo-hint">
          First, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
        <p className="bingo-hint">
          To get Lakilester, enter Chapter 6 using the 4 Magical Seeds, then perform{" "}
          <Link to="/Early_Lakilester">Early Lakilester</Link>.{" "}
        </p>
        <p className="bingo-hint">
          Lakilester has 50 HP, 4 Attack, and 0 Defense. It is recommended to Hammer Throw and Shell Shot, and pack some
          Whacka Bumps.
        </p>
      </div>
    ),
  },
  {
    name: "Defeat a Koopatrol",
    types: ["boots", "toybox", "ch3"],
    info: (
      <div>
        <p className="bingo-hint">
          The fastest Koopatrol is in the Toy Box, but you need to watch the post-Chapter 3 cutscene to activate it. Do
          Complete Chapter 3, then answer 2 for the 3rd question. The Koopatrol can be found in Green Station, and you
          will need Bow to reach it. There are two of them, and they each have 8 HP and 3 defense while standing. Power
          Quake and POW Blocks are powerful options to keep them flipped. If they're standing, they can summon more
          Koopatrols, so watch out!
        </p>
      </div>
    ),
  },
];
bingoList[25] = [
  {
    name: "Jade Raven",
    types: ["jungle", "watt", "FigureFresh"],
    info: (
      <div>
        <p className="bingo-hint">
          To get the Jade Raven, you will first need to defeat Big Lantern Ghost and get Watt.
        </p>
        <p className="bingo-hint">
          First, you will need to trigger a Chapter 4 state. The best way to do this is to take the train to Green
          Station, then take the train rightwards again to Pink Station. Pull the lever when the train stops, then
          continue to Pink Station and collect the Frying Pan (see{" "}
          <Link to="/Pink_Station_Clips">Pink Station Clips</Link>). Then, go left to Red Station and fight Big Lantern
          Ghost.
        </p>
        <p className="bingo-hint">
          Big Lantern Ghost has 40 HP, 5 Attack, and 0 Defense. You will need to hit his lantern twice each turn in
          order to target him. Goombario and Bow are the best partners for this because of their multi-hit attacks. With
          Mario, Ultra Hammer Hammer Throw is a good way to deal lots of damage. Alternatively, if you have a powerful
          Ultra-Rank partner, you can use Mario to hit the lantern instead.
        </p>
        <p className="bingo-hint">
          After obtaining Watt, head to the Jade Jungle and talk to Kolorado by the volcano. Then, get Sushie and save
          the Yoshi kids. The M. Bushes have 8 HP, 3 Attack, and 0 Defense. The Putrid Piranhas have 12 HP, 3 Attack,
          and 0 Defense.
        </p>
      </div>
    ),
  },
  {
    name: "Diploma",
    types: ["dojo"],
    info: (
      <div>
        <p className="bingo-hint">
          For the Diploma, you will need to defeat Chan, Lee, Master 1, Master 2, and Master 3.
        </p>
        <p className="bingo-hint">
          Chan has 15 HP, 2 Attack, and 2 Defense. He is easily defeated by flipping him like any Buzzy Beetle, because
          his defense is 0 while he is flipped.
        </p>
        <p className="bingo-hint">
          Lee has 20 HP, 5 Attack, and 0 Defense. He can turn into your partners, so if you start the fight with Kooper,
          you can flip him like any Koopa and defeat him easily.
        </p>
        <p className="bingo-hint">
          Master 1 has 50 HP, 6 Attack, and 0 Defense. The fight is straightforward, but be prepared! You may want extra
          HP and some Whacka Bumps, or Chill Out. If you have a strong Ultra-Rank partner, Stone Caps might be a good
          idea.
        </p>
        <p className="bingo-hint">
          Master 2 has 75 HP, 8 Attack, and 0 Defense. Master 2 has a multi-hit attack, which can be stopped by using
          Volt Shrooms or the Zap Tap badge or mitigated with Chill Out. Ultra-Rank partners like Goombario, Parakarry,
          and Bow can deal large amounts of damage each turn. As usual, bring Whacka Bumps or other strong healing
          items!
        </p>
        <p className="bingo-hint">
          Master 3 has 99 HP, 10 Attack, and 1 Defense. Master 3 has a multi-hit attack, which can be stopped by using
          Volt Shrooms or the Zap Tap badge or mitigated with Chill Out. Master 3 is immensely powerful, so it is
          recommended to enter with high stats and lots of healing items. Using Stone Caps doing large damage with
          Ultra-Rank Goombario's Charge and Multibonk, or doing consistent damage with Ultra-Rank Parakarry's Shell Shot
          or Watt's Electro Dash are some potential strategies.
        </p>
      </div>
    ),
  },
  {
    name: "Glasses",
    types: ["koopakoot", "desert", "mansion", "ch5"],
    info: (
      <div>
        <p className="bingo-hint">Koopa Koot Favors</p>
        <p className="bingo-hint">After Kooper:</p>
        <p className="bingo-hint">1. Get Koopa Legends from Kolorado's wife.</p>
        <p className="bingo-hint">2. Give a Sleepy Sheep to Koopa Koot.</p>
        <p className="bingo-hint">After Chapter 1:</p>
        <p className="bingo-hint">3. Get the Tape from Goompa.</p>
        <p className="bingo-hint">4. Give a Koopa Tea to Koopa Koot. (Cook a Koopa Leaf.)</p>
        <p className="bingo-hint">5. Get Luigi's Autograph from Luigi.</p>
        <p className="bingo-hint">After Chapter 2:</p>
        <p className="bingo-hint">
          6. Get the Wallet from the bush near the right side loading zone of the left screen in Koopa Village.
        </p>
        <p className="bingo-hint">
          7. Give a Tasty Tonic to Koopa Koot. (Harry's Shop for 5 coins, Yoshi Village for 2 coins or behind a bush
          outside the oasis on the left side.)
        </p>
        <p className="bingo-hint">
          8. Speak with Merluvlee, then go to Merlee in the back alley of Dry Dry Outpost to get the Crystal Ball.
          Deliver that to Merluvlee for the autograph.
        </p>
        <p className="bingo-hint">After Chapter 3:</p>
        <p className="bingo-hint">9. Read the front and back of the sign in front of Merlon's house.</p>
        <p className="bingo-hint">10. Give a Life Shroom to Koopa Koot.</p>
        <p className="bingo-hint">11. Give a Nutty Cake to Koopa Koot. (Cook a Goomnut.)</p>
        <p className="bingo-hint">After Chapter 4:</p>
        <p className="bingo-hint">
          12. Speak to the Bob-ombs in the house next door with Bombette as your active partner.
        </p>
        <p className="bingo-hint">13. Speak with the Boo in the main room of Boo's Mansion for the Old Photo.</p>
        <p className="bingo-hint">
          14. Give a Koopasta to Koopa Koot. (Cook a Koopa Leaf and a Dried Pasta together, and don't forget the
          Cookbook!)
        </p>
        <p className="bingo-hint">After Chapter 5:</p>
        <p className="bingo-hint">
          15. Check a bush with a white flower in the left screen of Koopa Village for the Glasses.
        </p>
      </div>
    ),
  },
  {
    name: "At Least 10 Letters",
    types: ["letters", "ch5", "flowerfields", "boots"],
    info: (
      <div>
        <p className="bingo-hint">
          See{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://shrines.rpgclassics.com/n64/papermario/letters.shtml"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    name: "Iced Potato",
    types: ["ch5"],
    info: (
      <div>
        <p className="bingo-hint">Iced Potato is found by sleeping in the inn in Shiver City.</p>
        <p className="bingo-hint">
          To reach Shiver City, you will need to reach Chapter 6 by collecting all the Magical Seeds,{" "}
          <Link to="/Early_Lakilester">get Lakilester</Link>, then do{" "}
          <Link to="/Lakilester_Peach_Warp">Peach Warp</Link>. Talk to Merlon, then go get the Ultra Boots. Finally, go
          to Shiver City and claim your Iced Potato.
        </p>
      </div>
    ),
  },
];

const bingoSquares: BingoSquare[][] = bingoList;
export default bingoSquares;
