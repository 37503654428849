import { Link } from "react-router-dom";

export const anyNoAceBeginner = (
  <>
    <h1 className="heading">Prologue</h1>

    <dl className="list">
      <dt className="route-instruction">
        - Do <Link to="/Quick_Jumps">Quick Jumps</Link> on Bowser by pressing A when Mario squats before jumping
      </dt>
      <dt className="route-instruction">- Talk to Goompa on the balcony</dt>
      <dt className="route-instruction">- Talk to Goompapa by the gate</dt>
      <dt className="route-instruction">- Head to the balcony</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 3 bush coins from 3 different bushes before the hammer. [3 coins]</dt>
      <dt className="route-instruction">
        - Hammer from the leftmost bush (get it from the front of the bush, it's faster)
      </dt>
      <dt className="route-instruction-bold">- 2 coins from the top bush [5 coins]</dt>
      <dt className="route-instruction-bold">- 10 coins from the brick block on the ground. [15 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Defeat Jr. Troopa by spamming Jump. (Don't forget your <Link to="/Quick_Jumps">Quick Jumps</Link>!)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hammer coin block for 1 coin. [16 coins]</dt>
      <dt className="route-instruction-bold">- Fire Flower and 4 trampoline coins. [20 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Power Jump Tutorial</dt>
      <dt className="route-instruction-bold">- Equip Power Jump immediately</dt>
      <dt className="route-instruction-bold">- Two coin blocks on the next screen. [22 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump Red / Headbonk Red</dd>
      <dd className="route-instruction-bold">T2: Power Jump Blue / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba King</dt>
      <dd className="route-instruction-bold">T1: Power Jump King / Headbonk Tree</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Headbonk</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Shooting Star Summit</dt>
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Action Command Tutorial</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Magikoopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Do Nothing - (Blocking saves a few frames!)</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Black_Toad_Skip">Black Toad Skip</Link> or <Link to="/Merlon_Skip">Merlon Skip</Link>
      </dt>
      <dt className="route-instruction">
        - Alternatively, go speak to the black toads, then go speak to Merlon, then go speak to the black toads again
      </dt>
    </dl>

    <h1 className="heading">Chapter 1</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 1 coin block [23 coins]</dt>
      <dt className="route-instruction-bold">- Fright Jar</dt>
      <dt className="route-instruction-bold">- POW Block</dt>
      <dt className="route-instruction-bold">- 10 coin block [33 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Koopa Village and go to the 5th house</dt>
      <dt className="route-instruction">
        - It helps to look at the fuzzies and count the amount of bounces that they do to ensure you never fail the
        minigame.{" "}
      </dt>
      <dd className="route-instruction">
        If they bounce an even number of times, it's guaranteed to not be in the tree to the left or right of its
        original position.{" "}
      </dd>
      <dd className="route-instruction">
        If they bounce and odd number of times, it's guaranteed to be to the left or right of its original position,
        unless it bounces from the far left to the far right or vise versa
      </dd>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">
        T1: Fright Jar (Down 1) / Kill any remaining fuzzies with basic attacks
      </dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Mash A and B after the fight ends if you gained 0 star points. (It's faster!)
      </dt>
      <dt className="route-instruction-bold">- HP Plus</dt>
      <dt className="route-instruction">- Leave Koopa Village and heads towards the Fortress</dt>
      <dt className="route-instruction-bold">- 5 coins on ground [38 coins]</dt>
      <dt className="route-instruction-bold">- Thunder Bolt</dt>
      <dt className="route-instruction-bold">- Hidden Fire Flower</dt>
      <img className="image" src="https://mario.wiki.gallery/images/a/a8/PMHiddenBlock2.png" alt="Fire Flower"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Koopa with a jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Key Fight</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">
        T1: Power Jump / Untimed Shell Toss (Use untimed Power Shell instead if you missed the first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Bounce</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Staircase_Skip">Staircase Skip</Link>
      </dt>
      <dt className="route-instruction">- Fall for the trap</dt>
      <dt className="route-instruction">- Blow up the wall</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jail Fight</dt>
      <dd className="route-instruction-bold">
        T1: Fire Flower (Mash A) / Bomb (Body Slam instead if you missed the Koopa first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 9 coins from Jail Fight [47 coins]</dt>
      <dt className="route-instruction">- Go to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you can't do <Link to="/Cannonless">Cannonless</Link>, get Dizzy Attack. (Badge directions may be
        different!)
      </dt>
      <dt className="route-instruction-bold">
        - <Link to="/Log_Skip">Log Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Get 1st Magical Seed from the Bub-ulb in Toad Town</dt>
      <dt className="route-instruction">- Take Train to Chapter 2</dt>
    </dl>

    <h1 className="heading">Blue House Skip</h1>

    <dl className="list">
      <dt className="route-instruction">- Find 3 Letters and bring them to Parakarry</dt>
      <dt className="route-instruction-bold">- Get 3 Whacka Bumps in total</dt>
      <dt className="route-instruction-bold">- 3 coins from slide. [50 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Rock_Climb">Rock Climb</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Early_Seed">Early Seed</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Get an extra Whacka if you don't have 50 coins and sell it at Shroom Grocery
      </dt>
      <dt className="route-instruction">
        - Get an extra Whacka if you can't get high caps for Power Bounce on Blooper
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Parakarry</dt>
      <dt className="route-instruction-bold">
        - Go back and get the 2nd Magical Seed using Parakarry if you didn't do <Link to="/Early_Seed">Early Seed</Link>
      </dt>
      <dt className="route-instruction">- Go back to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy Speedy Spin</dt>
      <dt className="route-instruction">
        - If Speedy Spin is not at the shop, go down 1 room, then left 1 room, then return to the shop
      </dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If you are doing safe Blooper, keep Power Jump on instead of Power Bounce</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Blue_House_Skip">Blue House Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce / Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="fight">No Power Bounce Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Whacka Bump (Mash A) / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Power Jump / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Take the pipe to Chapter 5</dt>
    </dl>

    <h1 className="heading">Chapter 5</h1>

    <dl className="list">
      <dt className="route-instruction">- Head right</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Raphael_Skip">Raphael Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Volcano_Jump">Volcano Jump</Link>
      </dt>
      <dt className="route-instruction">
        - If you're not comfortable blocking Bowser's fire, get Fire Shield. (Badge directions may be different!)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ultra_Hammer_Early">Ultra Hammer Early</Link>
      </dt>
      <dt className="route-instruction-bold">- Ultra Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Fall down on the zipline in the middle of the room (White glowing wall)</dt>
      <dt className="route-instruction-bold">- Upgrade Parakarry (Up 1)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Flarakarry">Flarakarry</Link>
      </dt>
      <dt className="route-instruction-bold">
        - <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Hit the switch on the wall (right of fireplace), run south to the lowest door, and go into the leftmost door.
        Watch out for the guards
      </dt>
      <dt className="route-instruction">- Quiz: 1, 1, 1, 1, 1, 1, 1, 1, 1, 2</dt>
    </dl>

    <h1 className="heading">Peach Warp</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Jammin' Jelly behind the tree</dt>
      <dt className="route-instruction-bold">- Volcano Vase from the chest in the jungle</dt>
      <dt className="route-instruction-bold">- 3rd Magical Seed from Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you're not comfortable blocking Bowser's wave, get the beach Repel Gel. (Item directions may be different!)
      </dt>
      <img className="image" src="https://mario.wiki.gallery/images/d/d4/PMHiddenBlock27.png" alt="Repel Gel"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Take the blue pipe and head towards the blue house</dt>
      <dt className="route-instruction">- Go to Forever Forest</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Forever Forest</dt>
      <dd className="route-instruction">Right x2</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction-bold">Go left and get the 4th Magical Seed</dd>
      <dd className="route-instruction">Right x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Give all the seeds to Minh T</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Early_Lakilester">Early Lakilester</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Shooting Star</dt>
      <dt className="route-instruction">- Blow up the rock</dt>
      <dt className="route-instruction">- Talk to the Sun</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Spike Fight (Blocking is faster)</dt>
      <dd className="route-instruction-bold">T1: Thunder Bolt (Up 2) / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Shooting Star (Mash A) / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Whacka Bump (Up 2) / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Shell Shot / Star Storm (Up/Down 3)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Answer the 1st option to Lakilulu's question and answer the 2nd option to Lakilester's question
      </dt>
      <dt className="route-instruction">- Head to the Volcano through the Blue House</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Lakilester_Peach_Warp">Peach Warp</Link>
      </dt>
      <dt className="route-instruction">
        - When you do <Link to="/Lakilester_Peach_Warp">Peach Warp</Link> the first time, hold down after you clip with
        Lakilester to avoid a softlock
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Transform into a Koopatrol, talk to the guard at the top, go down and transform into a Clubba, and then head
        back to the top
      </dt>
    </dl>

    <h1 className="heading">Peach Warp 2</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Climb the beanstalk and get S. Jump Charge</dt>
      <dt className="route-instruction">- Go through the Blue House and ride Lakilester across the spikes</dt>
      <dt className="route-instruction-bold">- Ultra Boots</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/c/c1/PMHiddenBlock12.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go to the Volcano and Peach Warp</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>

    <h1 className="heading">Chapter 8</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Shooting Star from the pillar</dt>
      <dt className="route-instruction-bold">- Upgrade Goombario (Mash A) on the stairs</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you lost a Life Shroom, you can get the hidden Ultra Shroom and sell it at Shiver City to buy another Life
        Shroom
      </dt>
      <img className="image" src="https://mario.wiki.gallery/images/d/da/PMHiddenBlock38.png" alt="Ultra Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Repel Gel behind the tree</dt>
      <dt className="route-instruction-bold">- Upgrade Bombette (Down 2) in the sewers</dt>
      <dt className="route-instruction">- Head to Shooting Star Summit</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols</dt>
      <dd className="route-instruction-bold">T1: POW Block (Up 1) / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say yes (1st option) to the door</dt>
      <dt className="route-instruction-bold">- Jail Life Shroom</dt>
      <dt className="route-instruction-bold">- Island Life Shroom</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Block_Clip">Block Clip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Use Lakilester to <Link to="/Laki_Clip">Laki Clip</Link> at any corner in the flood room
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Fast_Flood_Room">Fast Flood Room</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- We will now grind for star points on the Bullet Bills</dt>
      <dt className="route-instruction-bold">
        - Always first strike the bullet bills. If you fail, you can use Star Storm
      </dt>
      <dt className="route-instruction">- Spiny Flip can be sped up by pressing A while the cursor is on an enemy</dt>
    </dl>
    <dl className="list">
      <dt className="fight">1st Bullet Bill</dt>
      <dd className="route-instruction-bold">T1: Jump x1 / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-hp">- Level Up: HP</dt>
      <dt className="route-instruction-bold">15 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">2nd Bullet Bill</dt>
      <dd className="route-instruction-bold">T1: Jump x1 / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="fight">3rd Bullet Bill</dt>
      <dd className="route-instruction-bold">T1: Jump x1 / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="fight">4th Bullet Bill</dt>
      <dd className="route-instruction-bold">T1: Jump x1 / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">15 HP | 10 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">5th Bullet Bill</dt>
      <dd className="route-instruction-bold">T1: Jump x1 / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="fight">6th Bullet Bill</dt>
      <dd className="route-instruction-bold">T1: Jump x1 / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Cannonless">Cannonless</Link>
      </dt>
      <dt className="route-instruction">
        - If you got Dizzy Attack instead, equip that, encounter bullet bills, and use the post-battle invincibility to
        get past the Cannons. Don't forget to take it off!
      </dt>
      <dt className="route-instruction">
        - Push 3rd block, 1st block, then 2nd block to get the key. Return to the second block room and use the key
      </dt>
      <dt className="route-instruction">- Answer 1st option, then 2nd option (A, then B)</dt>
      <dt className="route-instruction">- Quiz: 1, 1, 2, 1, 1, 3, 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the top floor, hit the blue switch with Kooper and get the key</dt>
      <dt className="route-instruction">- Maze: Up, Down, Down, Up, Down, Up</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip HP Plus (Down 3)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts - Block 2/4</dt>
      <dd className="route-instruction-bold">T1: Power Shell / Swap to Bombette (Down 1)</dd>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">15 HP | 10 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - If you did no Power Bounce Blooper, take off Power Jump and equip Power Bounce
      </dt>
      <dt className="route-instruction-bold">- Swap to Goombario (Up 2)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 6/6</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge (Up 1) / Charge (Up 1)</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Charge</dd>
      <dd className="route-instruction-bold">T3: Whacka Bump (Up 1) / Charge</dd>
      <dd className="route-instruction-bold">T4: S. Jump Charge / Charge</dd>
      <dd className="route-instruction-bold">T5: Whacka Bump (Up 1) / Charge</dd>
      <dd className="route-instruction-bold">T6: S. Jump Charge / Charge</dd>
      <dd className="route-instruction-bold">T7: Bounce x3 (Down 1) / Headbonk x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
      <dt className="route-instruction">- Head to the Library (upper floor, first room on the left)</dt>
      <dt className="route-instruction-bold">- Life Shroom</dt>
      <dt className="route-instruction-bold">- Power Rush</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Rush (Down 4)</dd>
      <dd className="route-instruction-bold">Unequip HP Plus (Up 1)</dd>
      <dd className="route-instruction-bold">Press B</dd>
      <dd className="route-instruction-bold">Equip HP Plus (A, A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Parakarry (Up 2)</dt>
      <dt className="route-instruction">- You will win if you hit every action command</dt>
      <dt className="route-instruction">
        - Follow the beamless route, then switch to your fight when Bowser uses Star Shield
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser: Beamless</dt>
      <dd className="route-instruction-bold">T1: Star Storm / Sky Dive</dd>
      <dd className="route-instruction-bold">T2: Star Storm / Sky Dive - Star Shield</dd>
      <dd className="route-instruction-bold">T3: Star Beam / Sky Dive</dd>
      <dd className="route-instruction-bold">T4: Jump x2 / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T5: Do Nothing / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Jump x2 if Danger, Star Storm otherwise / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser: 4</dt>
      <dd className="route-instruction-bold">T5: Star Beam / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T6: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser: 5</dt>
      <dd className="route-instruction-bold">T6: Star Beam / Do Nothing</dd>
      <dd className="route-instruction-bold">T7: Shell Shot / Star Storm</dd>
      <dd className="route-instruction-bold">T8: Hammer if you didn't block wave with your partner</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">15 HP | 15 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - You can take off Power Bounce and equip Fire Shield if you picked it up in Chapter 5
      </dt>
      <dt className="route-instruction">- Continue to Final Bowser</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - It is impossible to miss Shell Shot on Final Bowser, so just flick the stick to go fast
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 1</dt>
      <dd className="route-instruction-bold">T1: Power Jump (Down 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Star Beam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Bowser will use Star Shield randomly, so you will have to scroll to find the correct fight based on his Star
        Shield pattern
      </dt>
      <dt className="route-instruction">
        - Follow the beamless route, then switch to your fight when Bowser uses Star Shield
      </dt>
      <dt className="route-instruction">- When Bowser uses Star Storm, immediately use Peach Beam</dt>
      <dt className="route-instruction">- Always follow fight directions in the order they are written</dt>
      <dt className="route-instruction">
        - If you miss a wave block and have the extra Repel Gel from the beach, use the second Repel Gel as soon as the
        first one wears off. For attacks, use Jump x2 when in danger. Otherwise, use Star Storm, and use Hammer as a
        last resort
      </dt>
      <dt className="route-instruction-bold">
        - IMPORTANT: Do not block Claw on Turn 1 or Turn 2. Always block otherwise
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Beamless</dt>
      <dd className="route-instruction-bold">T1: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Star Storm / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Jump x2 / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T4: Star Storm / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Shell Shot / Jammin' Jelly (Up 2) - Life Shroom</dd>
      <dd className="route-instruction-bold">T6: Shooting Star / Shell Shot</dd>
      <dd className="route-instruction-bold">T7: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T8: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Star Storm / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T10: Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3</dt>
      <dd className="route-instruction-bold">T4: Peach Beam / Shell Shot- Life Shroom</dd>
      <dd className="route-instruction-bold">T5: Shell Shot / Jammin' Jelly (Up 2)</dd>
      <dd className="route-instruction-bold">T6: Star Storm / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T7: Shooting Star / Shell Shot</dd>
      <dd className="route-instruction-bold">T8: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 6</dt>
      <dd className="route-instruction-bold">T7: Peach Beam / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Shooting Star / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 6, 9</dt>
      <dd className="route-instruction-bold">T10: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T11: Jump x2 / Do Nothing - Life Shroom</dd>
      <dd className="route-instruction-bold">T12: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 6, 10</dt>
      <dd className="route-instruction-bold">T11: Peach Beam / Do Nothing</dd>
      <dd className="route-instruction-bold">T12: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 7</dt>
      <dd className="route-instruction-bold">T8: Peach Beam / Do Nothing</dd>
      <dd className="route-instruction-bold">T9: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T11: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 7, 10</dt>
      <dd className="route-instruction-bold">T11: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T12: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 8</dt>
      <dd className="route-instruction-bold">T9: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Jump x2 / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 9</dt>
      <dd className="route-instruction-bold">T10: Peach Beam / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4</dt>
      <dd className="route-instruction-bold">T5: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Jammin' Jelly (Up 2) / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T7: Shooting Star / Shell Shot</dd>
      <dd className="route-instruction-bold">T8: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4, 7</dt>
      <dd className="route-instruction-bold">T8: Peach Beam / Do Nothing</dd>
      <dd className="route-instruction-bold">T9: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Jump x2 / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T11: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4, 8</dt>
      <dd className="route-instruction-bold">T9: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Jump x2 / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4, 9</dt>
      <dd className="route-instruction-bold">T10: Peach Beam / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 5</dt>
      <dd className="route-instruction-bold">T6: Peach Beam / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T7: Shooting Star / Shell Shot</dd>
      <dd className="route-instruction-bold">T8: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 5, 8</dt>
      <dd className="route-instruction-bold">T9: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 5, 9</dt>
      <dd className="route-instruction-bold">T10: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 6</dt>
      <dd className="route-instruction-bold">T7: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T8: Repel Gel / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T10: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 6, 9</dt>
      <dd className="route-instruction-bold">T10: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T11: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 7</dt>
      <dd className="route-instruction-bold">T8: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T9: Jump x2 / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T10: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 8</dt>
      <dd className="route-instruction-bold">T9: Peach Beam / Shell Shot - Life Shroom</dd>
      <dd className="route-instruction-bold">T10: Shell Shot / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 9</dt>
      <dd className="route-instruction-bold">T10: Peach Beam / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - <Link to="/Luigi_Skip">Luigi Skip</Link>
      </dt>
    </dl>
  </>
);
