import { Fragment, useState } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import "./styles/style.scss";

// COMPONENTS
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import TrickPage from "./components/TrickPage";
import MechanicPage from "./components/MechanicPage";
import CategoryPage from "./components/CategoryPage";
import RoutePage from "./components/RoutePage";

// MAIN PAGES
import Home from "./pages/Home";
import Tips from "./pages/Tips";
import Tricks from "./pages/Tricks";
import Categories from "./pages/Categories";
import RoutesPage from "./pages/Routes";
import Bingo from "./pages/Bingo";
import Mechanics from "./pages/Mechanics";
import Resources from "./pages/Resources";

// MISC PAGES
import NotFound from "./pages/NotFound";
import StoryTriggers from "./pages/StoryTriggers";
import BingoGenerator from "./pages/BingoGenerator";
import Changelog from "./pages/Changelog";
import BackupCoins from "./pages/BackupCoins";
import RouteEditor from "./pages/RouteEditor";
import RouteLibrary from "./pages/RouteLibrary";
import RouteEditorGuide from "./pages/RouteEditorGuide";

// DATA
import { tricks } from "./data/tricks";
import { techniques } from "./data/techniques";
import { glitches } from "./data/glitches";
import { categories } from "./data/categories";

function App() {
  const [mode, setMode] = useState<string>(localStorage?.getItem("mode") ?? "light");
  const [active, setActive] = useState<string>("Home");

  const changeStyle = () => {
    if (mode && mode === "dark") {
      localStorage.setItem("mode", "light");
      setMode("light");
    } else {
      localStorage.setItem("mode", "dark");
      setMode("dark");
    }
  };

  return (
    <div className={`app ${mode}`}>
      <BrowserRouter>
        <Sidebar active={active} changeStyle={changeStyle} style={mode} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Navigate to="/Home" replace={true} />} />
            <Route path="/Home" element={<Home setActive={setActive} />} />
            <Route path="/Tips" element={<Tips setActive={setActive} />} />
            <Route path="/Tricks" element={<Tricks setActive={setActive} />} />
            <Route path="/Categories" element={<Categories setActive={setActive} />} />
            <Route path="/Routes" element={<RoutesPage setActive={setActive} />} />
            <Route path="/Bingo" element={<Bingo setActive={setActive} />} />
            <Route path="/Mechanics" element={<Mechanics setActive={setActive} />} />
            <Route path="/Resources" element={<Resources setActive={setActive} />} />
            <Route path="/Bingo_Generator" element={<BingoGenerator setActive={setActive} />} />
            <Route path="/Story_Triggers" element={<StoryTriggers setActive={setActive} />} />
            <Route path="/Changelog" element={<Changelog setActive={setActive} />} />
            <Route path="/Backup_Coins" element={<BackupCoins setActive={setActive} />} />
            <Route path="/404" element={<NotFound setActive={setActive} />} />

            {tricks.map((trick) => (
              <Fragment key={`${trick.path}`}>
                <Route
                  key={`${trick.path}-1`}
                  path={`/Tricks/${trick.path}`}
                  element={<TrickPage setActive={setActive} />}
                />
                <Route
                  key={`${trick.path}-2`}
                  path={`/${trick.path}`}
                  element={<Navigate to={`/Tricks/${trick.path}`} replace={true} />}
                />
              </Fragment>
            ))}

            {techniques.map((technique) => (
              <Fragment key={`${technique.path}`}>
                <Route
                  key={`${technique.path}-1`}
                  path={`/Techniques/${technique.path}`}
                  element={<MechanicPage setActive={setActive} />}
                />
                <Route
                  key={`${technique.path}-2`}
                  path={`/${technique.path}`}
                  element={<Navigate to={`/Techniques/${technique.path}`} replace={true} />}
                />
              </Fragment>
            ))}

            {glitches.map((glitch) => (
              <Fragment key={`${glitch.path}`}>
                <Route path={`/Glitches/${glitch.path}`} element={<MechanicPage setActive={setActive} />} />
                <Route path={`/${glitch.path}`} element={<Navigate to={`/Glitches/${glitch.path}`} replace={true} />} />
              </Fragment>
            ))}

            {categories.map((category) => (
              <Fragment key={`${category.path}`}>
                <Route
                  key={`${category.path}-1`}
                  path={`/Categories/${category.path}`}
                  element={<CategoryPage setActive={setActive} />}
                />
                <Route
                  key={`${category.path}-2`}
                  path={`/${category.path}`}
                  element={<Navigate to={`/Categories/${category.path}`} replace={true} />}
                />
                {category.routes.map((route) => (
                  <Fragment key={`${category.path}-${route.path}`}>
                    <Route
                      key={`${category.path}-${route.path}-1`}
                      path={`/Categories/${category.path}/${route.path}`}
                      element={<RoutePage setActive={setActive} />}
                    />
                    <Route
                      key={`${category.path}-${route.path}-2`}
                      path={`/${category.path}/${route.path}`}
                      element={<Navigate to={`/Categories/${category.path}/${route.path}`} replace={true} />}
                    />
                  </Fragment>
                ))}
              </Fragment>
            ))}

            <Route path="/Route_Editor" element={<RouteEditor setActive={setActive} />} />
            <Route path="/Route_Library" element={<RouteLibrary setActive={setActive} />} />
            <Route path="/Route_Editor_Guide" element={<RouteEditorGuide setActive={setActive} />} />

            <Route path="/*" element={<Navigate to="/404" replace={true} />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
