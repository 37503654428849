import { InstructionTypes, RouteComponentProps } from "../../types/RouteComponents";
import { RouteFight } from "./RouteFight";
import { RouteHeading } from "./RouteHeading";
import { RouteLevelUp } from "./RouteLevelUp";
import { RouteRebadge } from "./RouteRebadge";
import { RouteText } from "./RouteText";
import { RouteImage } from "./RouteImage";

export const RouteInstruction = ({ instruction }: RouteComponentProps) => {
  const { type } = instruction;

  return (
    <>
      {type === InstructionTypes.Text && <RouteText instruction={instruction} />}
      {type === InstructionTypes.Heading && <RouteHeading instruction={instruction} />}
      {type === InstructionTypes.Fight && <RouteFight instruction={instruction} />}
      {type === InstructionTypes.LevelUp && <RouteLevelUp instruction={instruction} />}
      {type === InstructionTypes.Rebadge && <RouteRebadge instruction={instruction} />}
      {type === InstructionTypes.Image && <RouteImage instruction={instruction} />}
    </>
  );
};
