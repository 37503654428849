import { Link } from "react-router-dom";
import { useEffect } from "react";
import { glitches } from "../data/glitches";
import { techniques } from "../data/techniques";
import { PageProps } from "../types/PageProps";

export default function Mechanics({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Mechanics");
  });
  return (
    <>
      <h1 className="heading">Mechanics</h1>

      <p className="page-description">All Paper Mario techniques and glitches listed alphabetically.</p>

      <h1 className="heading">Techniques</h1>

      <ul className="index-list">
        {techniques.map((technique, i) => (
          <li key={`t${i}`}>
            <Link to={`/Techniques/${technique.path}`}>{technique.name}</Link>
          </li>
        ))}
      </ul>

      <h1 className="heading">Glitches</h1>

      <ul className="index-list">
        {glitches.map((glitch, i) => (
          <li key={`g${i}`}>
            <Link to={`/Glitches/${glitch.path}`}>{glitch.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
}
