import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { UserRoute } from "../../types/RouteComponents";
import { RouteInstruction } from "./RouteInstruction";

type RoutePreviewProps = {
  route: UserRoute;
};

export const RoutePreview = ({ route }: RoutePreviewProps) => {
  const { instructions, name, variables } = route;

  const [toggles, setToggles] = useState<{ [key: string]: boolean }>(
    variables
      ? Object.fromEntries(
          variables.map((variable) => [variable, localStorage.getItem(`toggle-${name}-${variable}`) === "true"])
        )
      : {}
  );

  const handleToggle = (variable: string) => {
    let newToggles = { ...toggles };
    newToggles[variable] = !toggles[variable];
    localStorage.setItem(`toggle-${name}-${variable}`, `${!toggles[variable]}`);
    setToggles(newToggles);
  };

  return (
    <div className="route-preview">
      {toggles && (
        <dl>
          {Object.entries(toggles).map(([name, value]) => (
            <dt className="detail-title" style={{ margin: "0 0 0.5rem 0" }} key={`toggle-${name}`}>
              <label className="switch">
                <input type="checkbox" onChange={() => handleToggle(name)} checked={value}></input>
                <span className="slider round"></span>
              </label>
              &nbsp;&nbsp;{name}
            </dt>
          ))}
        </dl>
      )}
      {instructions.map((instruction, index) => {
        return (
          <Fragment key={`instruction-preview-${index}`}>
            {(!instruction.conditions ||
              Object.entries(instruction.conditions).every(([key, value]) => toggles[key] === value)) && (
              <RouteInstruction instruction={instruction} />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
