import { RouteComponentProps } from "../../types/RouteComponents";

export const RouteLevelUp = ({ instruction }: RouteComponentProps) => {
  const { stat, hp, fp, bp } = instruction;

  return (
    <>
      <p className={`level-up-${stat}`}>- Level Up: {stat?.toUpperCase()}</p>
      <p className="level-up-instruction">
        {hp} | {fp} | {bp}
      </p>
    </>
  );
};
