import { useEffect } from "react";
import { Link } from "react-router-dom";
import { PageProps } from "../types/PageProps";

export default function RouteEditorGuide({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });

  return (
    <>
      <h1 className="heading">Route Editor Guide</h1>
      <p>
        <Link to="/Route_Library">Back to Route Library</Link>
      </p>
      <dl className="list">
        <div>
          <dt className="detail-title">Instruction Types</dt>
          <dt>
            There are 6 types of instructions: Text, Heading, Fight, Level Up,
            Rebadge, and Image.
          </dt>
          <dt>
            Most of them are straightforward. For images, just submit the url of
            the image you want to use.
          </dt>
        </div>
        <br/>
        <div>
          <dt className="detail-title">Italics</dt>
          <dt>
            If you want to italics individual phrases, wrap them *like this*.
          </dt>
        </div>
        <br/>
        <div>
          <dt className="detail-title">Bold</dt>
          <dt>
            If you want to bold individual phrases, wrap them **like this**.
          </dt>
        </div>
        <br/>
        <div>
          <dt className="detail-title">Italics and Bold</dt>
          <dt>
            If you want to use italics and bold on individual phrases, wrap them
            ***like this***.
          </dt>
        </div>
        <br/>
        <div>
          <dt className="detail-title">Links</dt>
          <dt>
            If you want to link to another page on the website, wrap it{" "}
            {"{{/Page_You_Want_To_Link_To}}[like this]"}.
          </dt>
        </div>
      </dl>
    </>
  );
}
