import { RouteComponentProps } from "../../types/RouteComponents";

export const RouteImage = ({ instruction }: RouteComponentProps) => {
  const { content } = instruction;

  return (
    <>
      <img className="route-image" src={content} alt=""></img>
    </>
  );
};