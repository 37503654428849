import { Link } from "react-router-dom";

/* 
  Currently there are no tricks that start with the letters 'H', 'N', 'X', or 'Z'. 
  If you add one, add its letter to the array in '/src/main/tricks.js' and update this accordingly.
*/

export const tricks = [
  {
    name: "Artifact Jump",
    path: "Artifact_Jump",
    description: (
      <>
        Artifact Jump is a <Link to="/Laki_Jump">Laki Jump</Link> that lets Mario reach the Artifact in Dry Dry Ruins
        without moving the stairs or using the Super Hammer in <Link to="/Reverse_All_Cards">Reverse All Cards</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    discoveredBy: "hambrgr333",
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/z0lDfFuh_Fw",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Artifact Jump</h1>

        <dl className="list">
          <dt className="detail-title">
            Stand in front of the doorway, move up (you can tap jump for precision) until Mario is pushed out by the
            door and his nose is just barely to the right of the vertical line on the bricks, then perform a{" "}
            <Link to="/Laki_Jump">Laki Jump</Link>.
          </dt>
          <dd>
            To perform a <Link to="/Laki_Jump">Laki Jump</Link>, use Lakilester's ability, press c-down and c-right at
            the same time, and then swap to a different partner.
          </dd>
          <dd>
            Most positions where the end of Mario's nose is between the vertical brick line and the line of the doorway
            will work, but try to be closer to the brick line.
          </dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/ArtifactJump.gif" alt="Artifact Jump"></img>
      </>
    ),
  },
  {
    name: "Attack FX E Early",
    path: "Attack_FX_E_Early",
    description: (
      <>
        Attack FX E Early is a <Link to="/Laki_Clip">Laki Clip</Link> that lets the player reach Attack FX E much faster
        than doing <Link to="/Shovel_Jump">Shovel Jump</Link> or entering the house normally.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "100%",
        path: "/100",
      },
    ],
    discoveredBy: false,
    videoTutorials: false,
    content: (
      <>
        <h1 className="heading">How To Perform Attack FX E Early</h1>

        <dl className="list">
          <dt className="detail-title">
            Move into the corner of the building and use Lakilester's ability. Immediately get off of Laki. When the
            Mayor's house covers up the screen, hold up and open the chest.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/AttackFXEEarly1.gif" alt="Attack FX E Early"></img>

        <dl className="list">
          <dt className="detail-title">Spin down-left, hold up and jump 4 times, then spin right to exit.</dt>
          <dd>This is done blind, so the graphic shows what is actually happening inside.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/AttackFXEEarly2.gif" alt="Attack FX E Early"></img>
      </>
    ),
  },
  {
    name: "Basement Skip",
    path: "Basement_Skip",
    description: (
      <>
        Basement Skip is a trick in Chapter 8 that allows the player to skip having to complete the lava puzzles and
        dark basement sections of Bowser's Castle. If you have Parakarry and the Ultra Boots, you should do{" "}
        <Link to="/Block_Clip">Block Clip</Link>, the easiest version of this trick.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: false,
    videoTutorials: false,
    content: (
      <>
        <h1 className="heading">How To Perform Basement Skip without Parakarry and the Ultra Boots</h1>

        <dl className="list">
          <dt className="detail-title">
            From below the intersection of the two walls, walk right into the corner, then slowly roll down (or up) and
            return to neutral when the screen shifts.
          </dt>
          <dd>
            If you start from the top right corner and move down to the starting position, Lakilester won't obstruct
            your view during the rest of the trick.
          </dd>
        </dl>
        <img className="image" src="https://s12.gifyu.com/images/S5bhN.gif" alt="Basement Skip"></img>

        <dl className="list">
          <dt>
            For this trick, we're going to be using a concept called "full walking". This means that we only want Mario
            to be moving while the stick is fully in one direction. We'll be using hammers to buffer the full stick
            input and also to return to neutral to stop moving, but you can also use the partner menu if you prefer (it
            is slower but easier).
          </dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            Hammer, hold left, and hammer again at the spot shown below. Look for the white circle on Mario's hat to be
            on the left side of the vertical line on the wall behind him. The right side of the circle should almost be
            touching that line.
          </dt>
          <dd>
            You can retry moving left and right to get this position, but you need to be full walking the whole time.
          </dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/S5bhH.gif" alt="Basement Skip"></img>
        <img className="image" src="https://s12.gifyu.com/images/S5bhx.png" alt="Basement Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Full walk down until you can see two horizontal lines of brown pixels at the bottom-left of the screen.
          </dt>
          <dd>
            You can retry moving up and down to get this position, but you need to be full walking the whole time.
          </dd>
        </dl>
        <img className="image" src="https://s12.gifyu.com/images/S5bh6.gif" alt="Basement Skip"></img>
        <img className="image" src="https://s12.gifyu.com/images/S5bhs.png" alt="Basement Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump on Laki, hold down, and use Laki again to <Link to="/Laki_Teleport">Laki Teleport</Link> through the
            wall.
          </dt>
          <dd></dd>
        </dl>
        <img className="image" src="https://s12.gifyu.com/images/S5bhK.gif" alt="Basement Skip"></img>
      </>
    ),
  },
  {
    name: "Black Toad Skip",
    path: "Black_Toad_Skip",
    description: (
      <>
        Black Toad Skip, also known as Lucky Star Skip, is a glitch that uses{" "}
        <Link to="/NPC_Clipping">NPC Clipping</Link> to get <Link to="/Out_Of_Bounds">out of bounds</Link> in Toad Town,
        allowing the player to reach Pleasant Path (Chapter 1) while the Black Toads are blocking the Loading Zone. If
        this trick is performed after going to Shooting Star Summit and getting the Lucky Star from Twink, it is called
        Black Toad Skip. If it is performed before getting the Lucky Star, it is called Lucky Star Skip. Note that the
        only way to get the Lucky Star once it is skipped using this method is to perform{" "}
        <Link to="/Retrigger_Prologue">Retrigger Prologue</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: false,
    videoTutorials: [
      {
        author: "iycewynd",
        url: "https://www.youtube.com/watch?v=U-KbGVg1snk",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Black Toad Skip/Lucky Star Skip</h1>

        <dl className="list">
          <dt className="detail-title">Clip out of bounds with the green Toad.</dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/BlackToadSkip1.gif" alt="Black Toad Skip"></img>

        <dl className="list">
          <dt className="detail-title">Walk down to the location shown below.</dt>
          <dd>
            A common visual cue is to line up the horizontal line at the top of Mario's glove with the top of the white
            step in front of him.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/BlackToadSkipLocation.png" alt="Black Toad Skip"></img>

        <dl className="list">
          <dt className="detail-title">Fall right to land in the flower box.</dt>
          <dd>It is recommended to buffer your right input with a hammer.</dd>
          <dd>Be sure to return the stick to neutral after falling.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/BlackToadSkip2.gif" alt="Black Toad Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Spin, jump up, then roll the stick to up-right and right. When Mario flies up off screen, roll to down-right
            then down.
          </dt>
          <dd>
            Alternatively, you can continue holding down-right instead of switching to down and take an extra fall. When
            the screen shifts, switch to down.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/BlackToadSkip3.gif" alt="Black Toad Skip"></img>
      </>
    ),
  },
  {
    name: "Block Clip",
    path: "Block_Clip",
    description: (
      <>
        Block Clip, a variant of <Link to="/Basement_Skip">Basement Skip</Link>, is an{" "}
        <Link to="/Boots_Clip">Ultra Boots Clip</Link> in Chapter 8 that allows the player to skip having to complete
        the lava puzzles and dark basement sections of Bowser's Castle.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: false,
    videoTutorials: false,
    content: (
      <>
        <h1 className="heading">How To Perform Block Clip</h1>

        <dl className="list">
          <dt className="detail-title">
            Stand below the block with the Deep Focus badge, then do a Tornado Jump. When Mario clips on top of the
            block, face right and use Parakarry's ability.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlockClip.gif" alt="Block Clip"></img>
      </>
    ),
  },
  {
    name: "Blue House Skip",
    path: "Blue_House_Skip",
    description: (
      <>
        Blue House Skip is a trick that allows Mario to get inside the Blue House next to Tayce T's House in Toad Town.
        This trick allows the player to reach the later areas of the sewers much earlier than intended, granting access
        to the Chapter 5 blue pipe with only Bombette and the Chapter 3 blue pipe without having the Super Boots. This
        trick is used in every glitched category, making it a high priority to learn for new players. However, the trick
        is very difficult and relies completely on muscle memory, so it is recommended to start learning it as soon as
        possible. There are four known methods of performing Blue House Skip: the Jump method, the{" "}
        <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> method, the{" "}
        <Link to="/NPC_Luring">NPC Luring</Link> method, and the <Link to="/NPC_Pushing">NPC Pushing</Link> method. The
        Jump method is the fastest and most common method, and it is recommended to start learning this method as soon
        as possible. However, all methods are detailed below for the sake of documentation in order of fastest to
        slowest.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: "bonecrusher1022, r0bd0g (Jump Method)",
    videoTutorials: [
      {
        author: "Starry_Arie (NPC Luring)",
        url: "https://youtu.be/EP2wSE2RAKI",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Blue House Skip (Jump)</h1>

        <dl className="list">
          <dt className="detail-title">Line up Mario next to the green Toad.</dt>
          <dd>
            You will know you're in a good location if Mario is standing next to the Toad but his hammer appears behind
            the Toad.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipJumpPosition.png" alt="BHS"></img>

        <dl className="list">
          <dt className="detail-title">
            Spin, jump Left, return to Neutral, Jump off the side of the Blue House, then roll the stick from Up-Right
            to Up-Left.
          </dt>
          <dd>
            These inputs are done very quickly, and there is no time to react to getting the second Jump off the side of
            the house, so you should do all the inputs every time.
          </dd>
          <dd>The second Jump is frame-perfect and only works if the stick is in a neutral position.</dd>
          <dd>Both Jumps should be full Jumps (don't release A too quickly).</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipJump.gif" alt="BHS"></img>

        <dl className="list">
          <dt className="detail-title">Tips for learning Blue House Skip:</dt>
          <dd>
            When learning Blue House Skip, it is recommended to take it in steps. First, practice jumping left such that
            Mario hits the collision on the side of the building. Then, start trying to get the second jump. Once you
            can get the second jump with some consistency, start adding the Up-Right to Up-Left movement after the
            second jump. (Author's Note: it took me 3 months to learn this trick, but I know people who learned it in a
            day. Don't give up hope!)
          </dd>
        </dl>
        <br />

        <h1 className="heading">How To Perform Blue House Skip (Loading Zone Storage)</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform Loading Zone Storage Jumps to the location shown below. If done right, Mario will land inside the
            Blue House.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipLZS.gif" alt="BHS"></img>

        <br />

        <h1 className="heading">How To Perform Blue House Skip (NPC Luring)</h1>

        <dl className="list">
          <dt className="detail-title">Lure both of the Toads in front of Tayce T's house over to the left side.</dt>
          <dd>
            To do this, walk close to them until you see the speech bubble above their heads, then walk in the direction
            you want them to move.
          </dd>
          <dd>The goal is to get one of the Toads to push the other out of its wandering radius.</dd>
          <dd>
            Their wandering radius ends at the square on the floor by the step in front of Tayce T's house. If you see
            one move past this point, it can be led to the Blue House.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipLure1.gif" alt="BHS"></img>

        <dl className="list">
          <dt className="detail-title">
            Perform an <Link to="/NPC_Clipping">NPC Clip</Link> between the Toad and the ledge, then hold the direction
            of the Blue House.
          </dt>
          <dd>You will need to lure the Toad forwards or backwards until you're able to clip behind it.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipLure2.gif" alt="BHS"></img>
        <br />

        <h1 className="heading">How To Perform Blue House Skip (NPC Pushing)</h1>

        <dl className="list">
          <dt className="detail-title">
            Do <Link to="/NPC_Pushing">NPC Pushes</Link> on the Toad directly left until it is in the location shown
            below. Then, use it to <Link to="/NPC_Clipping">NPC Clip</Link> inside.
          </dt>
          <dd>
            It is recommended to attempt this clip from the front of the screen to the back, because you can softlock
            while trying to clip in behind the Toad.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/BlueHouseSkipPush.gif" alt="BHS"></img>
      </>
    ),
  },
  {
    name: "Blue Switch Jailbreak",
    path: "Blue_Switch_Jailbreak",
    description: (
      <>
        Blue Switch Jailbreak is a <Link to="/Laki_Teleport">Laki Teleport</Link> and a{" "}
        <Link to="/Laki_Jump">Laki Jump</Link> that lets Mario hit the Blue Switch in Blue Station without taking any
        trains in <Link to="/Reverse_All_Cards">Reverse All Cards</Link>. Since the discovery of{" "}
        <Link to="/Seed_Duplication">Seed Duplication</Link>, it has become useful in other categories as well.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    discoveredBy: "NaterTater",
    content: (
      <>
        <h1 className="heading">How To Perform Blue Switch Jailbreak</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up with the line on the ground and the Save Block, then do a{" "}
            <Link to="/Laki_Teleport">Laki Teleport</Link> while holding left or up-left.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s4.gifyu.com/images/BlueSwitchJailbreak1.gif"
          alt="Blue Switch Jailbreak"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            To escape, step just inside the invisible train and do a <Link to="/Laki_Jump">Laki Jump</Link> to clip
            upwards.Then, walk right until Mario's nose is just inside the spinning flower in the background.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s5.gifyu.com/images/BlueSwitchJailbreak2.gif"
          alt="Blue Switch Jailbreak"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Hold down-right and jump. Mario will fall out of bounds and land near the Save Block.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s4.gifyu.com/images/BlueSwitchJailbreak3.gif"
          alt="Blue Switch Jailbreak"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            If you fail this, you can go to either end of the tracks until Mario falls, then hold down-left or
            down-right towards the in bounds area to get back in bounds.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s5.gifyu.com/images/BlueSwitchJailbreak4.gif"
          alt="Blue Switch Jailbreak"
        ></img>
        <img
          className="image"
          src="https://s5.gifyu.com/images/BlueSwitchJailbreak5.gif"
          alt="Blue Switch Jailbreak"
        ></img>
      </>
    ),
  },
  {
    name: "Bowless Green Station",
    path: "Bowless_Green_Station",
    description: (
      <>
        Bowless Green Station is a trick that allows the player to reach the east side of Green Station without using
        Bow to get through the fence.
      </>
    ),
    versions: "All",
    discoveredBy: "r0bd0g, setup by JCog",
    content: (
      <>
        <h1 className="heading">How To Perform Bowless Green Station</h1>

        <dl className="list">
          <dt className="detail-title">
            Do a <Link to="/Laki_Clip">Laki Clip</Link> at the corner of the pillar in the back-left of the room to get{" "}
            <Link to="/Out_Of_Bounds">out of bounds</Link>.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/BowlessGreenStation1.gif"
          alt="Bowless Green Station"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Move right until both of Mario's shoes are visible. Tap jump up until the bottom of Mario's left shoe is no
            longer visible.
          </dt>
          <dd>You may want to tap jump to the right to avoid clipping in bounds.</dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/BowlessGreenStation2.gif"
          alt="Bowless Green Station"
        ></img>

        <dl className="list">
          <dt className="detail-title">Fall right.</dt>
          <dd>
            Buffering with a hammer is recommended. You can continue holding right to go right into the next step.
          </dd>
          <dd>
            If Mario steps in bounds instead of falling, you were too far forward. If he ends up back where he started,
            you might have been too far back.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/BowlessGreenStation3.gif"
          alt="Bowless Green Station"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Walk right, then jump before Mario touches the downwards conveyor belt. When Mario is close to the fence,
            move slightly up of right to fall, then hold down-right.
          </dt>
          <dd>If you jump too early or too late, you will end up in bounds and will have to start over.</dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/BowlessGreenStation4.gif"
          alt="Bowless Green Station"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Get <Link to="/Clippy">Clippy</Link>.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/BowlessGreenStation5.gif"
          alt="Bowless Green Station"
        ></img>

        <dl className="list">
          <dt className="detail-title">Walk onto a leftward conveyor belt, then tap down to go through the fence.</dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/BowlessGreenStation6.gif"
          alt="Bowless Green Station"
        ></img>
      </>
    ),
  },
  {
    name: "Bow Skip",
    path: "Bow_Skip",
    description: (
      <>
        Bow Skip is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> trick that allows the player to reach
        Gusty Gulch without obtaining Bow.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
    ],
    discoveredBy: "r0bd0g (Lakilester method)",
    content: (
      <>
        <h1 className="heading">How To Perform Bow Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> Jumps to the location shown below, then
            hold right.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/BowSkip.gif" alt="Bow Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            If you have Lakilester, you can <Link to="/Laki_Teleport">Laki Teleport</Link> through the gate.
          </dt>
        </dl>
        <img className="image" src="https://s5.gifyu.com/images/LakiBowSkip.gif" alt="Bow Skip"></img>
      </>
    ),
  },
  {
    name: "Buzzar Skip",
    path: "Buzzar_Skip",
    description: (
      <>
        Buzzar Skip is a trick that skips the conversation with Buzzar when going to Dry Dry Desert from Mt. Rugged by
        jumping around an invisible wall and walking along the front seam of the bridge, then falling onto the area past
        Buzzar. Buzzar Skip was used in old <Link to="/All_Cards">All Cards</Link> routes before{" "}
        <Link to="/Clippy_Boots">Clippy Boots</Link> was discovered.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/v7ynquiWNos",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Buzzar Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Stand in the bottom-right corner of the area before the bridge, then jump right and roll to up to get around
            the invisible wall. Then, move carefully up to the bridge, spin across it, and jump down on the other side.
          </dt>
          <dd>
            If Mario appears to be hitting a wall while moving along the bridge, move up a tiny bit and try again.
          </dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/BuzzarSkip.gif" alt="Buzzar Skip"></img>
      </>
    ),
  },
  {
    name: "Cannonless",
    path: "Cannonless",
    description: (
      <>
        Cannonless is a trick that uses a <Link to="/Laki_Clip">Laki Clip</Link> to get{" "}
        <Link to="/Out_Of_Bounds">out of bounds</Link> in the room with the gold Bill Blasters in Bowser's Castle. From
        there, the player can safely and quickly move past the cannons and reach the next room.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "realblopez",
        url: "https://youtu.be/xxLxzoL6JTs",
      },
    ],
    discoveredBy: "bonecrusher1022",
    content: (
      <>
        <h1 className="heading">How To Perform Cannonless</h1>

        <dl className="list">
          <dt className="detail-title">Move Mario to the position shown below, then use Lakilester's ability.</dt>
          <dd>
            The shine on both the front and back of Mario's shoe is a good visual cue to ensure you're in the proper
            location.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/Cannonless1.gif" alt="Cannonless"></img>

        <dl className="list">
          <dt className="detail-title">
            Once out of bounds, move up to the top wall, then fall right. While falling, tap up-right twice (returning
            to right each time), then continue holding right.
          </dt>
          <dd>
            Once you land on the seam at the top, spin and jump right until you reach the door at the end of the hall.
          </dd>
          <dd>Some controllers may want to tap up-right three times instead of two.</dd>
          <dd>
            Tapping up-right for too long will cause Mario to reappear at the bottom. Not tapping it long enough will
            cause him to appear in bounds at the top.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/Cannonless2.gif" alt="Cannonless"></img>
      </>
    ),
  },
  {
    name: "Chapter 2 Card LZS",
    path: "Chapter_2_Card_LZS",
    description: (
      <>
        Chapter 2 Card LZS is the <Link to="/Card_LZS">Card LZS</Link> for the Star Spirit card in the Dry Dry Ruins.
        This trick is notorious for being one of the more difficult Card LZS tricks because of the low ceiling above the
        Loading Zone, even though the card is only 2-3 jumps away.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 2 Card LZS</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps such that Mario touches the Star Spirit card while descending.
          </dt>
          <dd>
            You can either short hop in and out of the Loading Zone then return to normal LZS rhythm or jump into the
            wall above it to keep normal LZS rhythm throughout the trick.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/Chapter2CardLZS.gif" alt="Chapter 2 Card LZS"></img>
      </>
    ),
  },
  {
    name: "Chapter 2 Clippy",
    path: "Chapter_2_Clippy",
    description: (
      <>
        Chapter 2 Clippy is a trick used to get past the second locked door in Dry Dry Ruins without using a Ruins Key.
        In old <Link to="/Reverse_All_Cards">Reverse All Cards</Link> routes, this was also done in the room with the
        Artifact to skip using another Ruins Key.
      </>
    ),
    versions: "All",
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 2 Clippy</h1>

        <dl className="list">
          <dt className="detail-title">
            Get <Link to="/Clippy">Clippy</Link>.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/Chapter2Clippy1.gif" alt="Chapter 2 Clippy"></img>

        <dl className="list">
          <dt className="detail-title">
            Hold left in the bottom left corner, then slowly rotate upwards. When Mario clips out, return to neutral.
            When he respawns, jump and hold up at the same time.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/Chapter2Clippy2.gif" alt="Chapter 2 Clippy"></img>
      </>
    ),
  },
  {
    name: "Chapter 3 Card LZS",
    path: "Chapter_3_Card_LZS",
    description: (
      <>
        Chapter 3 Card LZS is the <Link to="/Card_LZS">Card LZS</Link> for the Star Spirit card in the Gusty Gulch.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 3 Card LZS</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps such that Mario touches the Star Spirit card while descending.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/Chapter3CardLZS.gif" alt="Chapter 3 Card LZS"></img>
      </>
    ),
  },
  {
    name: "Chapter 4 Card LZS",
    path: "Chapter_4_Card_LZS",
    description: (
      <>
        Chapter 4 Card LZS is the <Link to="/Card_LZS">Card LZS</Link> for the Star Spirit card in the Toy Box.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 4 Card LZS</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps such that Mario touches the Star Spirit card while descending.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/Chapter4CardLZS.gif" alt="Chapter 4 Card LZS"></img>
      </>
    ),
  },
  {
    name: "Chapter 4 Early",
    path: "Chapter_4_Early",
    description: (
      <>
        Chapter 4 Early is a trick that uses an <Link to="/NPC_Lure">NPC Lure</Link> to{" "}
        <Link to="/NPC_Clip">NPC Clip</Link> <Link to="/Out_Of_Bounds">out of bounds</Link> and reach the Toy Box
        without Bow.
      </>
    ),
    versions: "All",
    discoveredBy: "r0bd0g",
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 4 Early</h1>

        <dl className="list">
          <dt className="detail-title">
            Lure the blue toad and hammer to clip out of bounds. Carefully walk past the door towards the Toy Box.
          </dt>
        </dl>

        <img className="image" src="https://s5.gifyu.com/images/Chapter4Early1.gif" alt="Chapter 4 Early"></img>

        <dl className="list">
          <dt className="detail-title">
            Fall slightly left of the up-left notch to land in the area with the Toy Box.
          </dt>
          <dd>If you move down towards the front of the room and spin, you can find Mario's location more easily.</dd>
        </dl>

        <img className="image" src="https://s5.gifyu.com/images/Chapter4Early2.gif" alt="Chapter 4 Early"></img>
      </>
    ),
  },
  {
    name: "Chapter 5 Card LZS",
    path: "Chapter_5_Card_LZS",
    description: (
      <>
        Chapter 5 Card LZS is the <Link to="/Card_LZS">Card LZS</Link> for the Star Spirit card in Mt. Lavalava.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 5 Card LZS</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps such that Mario touches the Star Spirit card while descending.
          </dt>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/Chapter5CardLZS.gif" alt="Chapter 5 Card LZS"></img>
      </>
    ),
  },
  {
    name: "Chapter 6 Card LZS",
    path: "Chapter_6_Card_LZS",
    description: (
      <>
        Chapter 6 Card LZS is the <Link to="/Card_LZS">Card LZS</Link> for the Star Spirit card in Cloudy Climb. It is
        the hardest <Link to="/Card_LZS">Card LZS</Link> due to the many changes in incline and lag.
      </>
    ),
    versions: "All",
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 6 Card LZS</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps such that Mario touches the Star Spirit card while descending.
          </dt>
        </dl>

        <img className="image" src="https://s7.gifyu.com/images/Chapter6CardLZS.gif" alt="Chapter 6 Card LZS"></img>
      </>
    ),
  },
  {
    name: "Chapter 7 Sushie Glitch",
    path: "Chapter_7_Sushie_Glitch",
    description: (
      <>
        Chapter 7 Sushie Glitch is an application of <Link to="/Sushie_Glitch">Sushie Glitch</Link> in Chapter 7 that
        skips having to get the scarf and bucket, and also skips fighting the Duplighost at the Kooper switch. This
        trick combined with <Link to="/Murder_Solved_Early">Murder Solved Early</Link> and{" "}
        <Link to="Ice_Staircase_Skip">Ice Staircase Skip</Link> allows us to skip large portions of Chapter 7 in
        glitched categories.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Chapter 7 Sushie Glitch</h1>

        <dl className="list">
          <dt className="detail-title">Hold up and spam c-right until you get Sushie Glitch.</dt>
          <dd>
            In the next room, go east and fight Jr Troopa. Do not switch from Sushie. Leave to the right after the
            fight.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/Chapter7SushieGlitch1.gif"
          alt="Chapter 7 Sushie Glitch"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            (All Bosses) If you need to defeat Monstar, take the right loading zone in the next room, then swim and dive
            to the foreground and travel right until you fight Monstar.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/Chapter7SushieGlitch2.gif"
          alt="Chapter 7 Sushie Glitch"
        ></img>

        <dl className="list">
          <dt className="detail-title">(All Bosses) Hold left and mash Z after beating Monstar to leave quickly.</dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/Chapter7SushieGlitch3.gif"
          alt="Chapter 7 Sushie Glitch"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Dive behind the wall to the left or right of the snowmen, then travel to the loading zone from behind the
            wall.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/Chapter7SushieGlitch4.gif"
          alt="Chapter 7 Sushie Glitch"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Dive to the foreground, then head right and encounter the Gulpit. Run from the encounter, then hug the top
            wall as you leave to the right.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/Chapter7SushieGlitch5.gif"
          alt="Chapter 7 Sushie Glitch"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Dive to the background, then swim past the puzzle and dive to go up the stairs. Then, encounter the White
            Clubba. Swap to Lakilester with Sushie's turn, then run with Mario.
          </dt>
          <dd>When going up the stairs, you should aim to take 2 steps at a time.</dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/Chapter7SushieGlitch6.gif"
          alt="Chapter 7 Sushie Glitch"
        ></img>
      </>
    ),
  },
  {
    name: "Clippy Boots",
    path: "Clippy_Boots",
    description: (
      <>
        Clippy Boots is a trick used to collect the Ultra Boots without using the Ultra Hammer. It uses{" "}
        <Link to="/Clippy">Clippy</Link> state to get on top of the large blocks blocking the doors. In the second room,
        a <Link to="/Laki_Teleport">Laki Teleport</Link> is required to get to the Loading Zone. If the player has the
        Super Hammer, then only the Dark Koopa <Link to="/Clippy">Clippy</Link> is necessary.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "morpheus080",
        url: "https://youtu.be/fJ-Duo4Tf14",
      },
    ],
    discoveredBy: "r0bd0g",
    content: (
      <>
        <h1 className="heading">How To Perform Clippy Boots in the Spiny room</h1>

        <dl className="list">
          <dt className="detail-title">
            Get <Link to="/Clippy">Clippy</Link> state from the last Spiny.
          </dt>
        </dl>
        <img className="image" src="https://s9.gifyu.com/images/FirstClippy1.gif" alt="Clippy Boots"></img>

        <dl className="list">
          <dt className="detail-title">
            Hold slightly up of left and jump to get onto the stone block, then jump up-left into the wall, and fall
            down-left onto the loading zone.
          </dt>
        </dl>
        <img className="image" src="https://s3.gifyu.com/images/FirstClippy2.gif" alt="Clippy Boots"></img>
        <br />

        <h1 className="heading">How To Perform Clippy Boots in the Dark Koopa room</h1>

        <dl className="list">
          <dt className="detail-title">
            Get <Link to="/Clippy">Clippy</Link> state from the Dark Koopa on the right.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/ClippyBoots1.gif" alt="Clippy Boots"></img>

        <dl className="list">
          <dt className="detail-title">
            Switch back to Lakilester, then get on top of the ultra block by holding slightly up from the left notch and
            jumping.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/ClippyBoots2.gif" alt="Clippy Boots"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump onto the door frame and hold up-left such that the bill of Mario's hat is just below the dark line on
            the wall.
          </dt>
          <dd>
            Make sure Lakilester doesn't fly up above the door frame. If he does, you'll have to go down and try this
            again.
          </dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/ClippyBoots3.gif" alt="Clippy Boots"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump, then roll the stick towards the up notch. When Mario is clipped into the top wall, use Lakilester's
            ability.
          </dt>
          <dd>The stick rotation is slow, and you should never actually reach the up notch.</dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/ClippyBoots4.gif" alt="Clippy Boots"></img>
      </>
    ),
  },
  {
    name: "Dayzee Bypass",
    path: "Dayzee_Bypass",
    description: (
      <>
        Dayzee Bypass is a glitch that allows the player to reach the Upgrade Block in the room with the Yellow Flower
        gatekeeper without fighting any Crazee Dayzees. Unfortunately, there is no way to avoid going past the Crazee
        Dayzees on the way back.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "100%",
        path: "/100",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Dayzee Bypass</h1>

        <dl className="list">
          <dt className="detail-title">
            While on Laki, slowly roll the stick from right to down-right, then get off of Laki when you are clipped
            into the wall and switch to up-left.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/DayzeeBypass.gif" alt="Dayzee Bypass"></img>
      </>
    ),
  },
  {
    name: "Early Fuzzipede",
    path: "Early_Fuzzipede",
    description: <>Early Fuzzipede is a trick that allows Mario to encounter Fuzzipede quickly.</>,
    versions: "All",
    categories: [
      {
        name: "Glitchless",
        path: "/Glitchless",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Early Fuzzipede</h1>

        <dl className="list">
          <dt className="detail-title">
            Do a short spin right and jump, then hold up-right and activate Watt immediately. Then, hold right while
            Fuzzipede talks, and jump right when you regain control of Mario.
          </dt>
        </dl>
        <img className="image" src="https://s4.gifyu.com/images/EarlyFuzzipede.gif" alt="Early Fuzzipede"></img>
      </>
    ),
  },
  {
    name: "Early Lakilester",
    path: "Early_Lakilester",
    description: (
      <>
        Early Lakilester refers to three tricks that allow the player to access northwest Flower Fields without giving a
        bubble berry to the bubble plant. Notably, this trick can be performed at any time during Chapter 6, but if it
        is performed before giving Lily the Water Stone, Chapter 6 will no longer be possible to complete by normal
        means, and the player must use another trick such as <Link to="/Lakilester_Peach_Warp">Peach Warp</Link> in
        order to continue. This trick is only required for Any% (No ACE), but is faster than getting and using the
        bubble berry if done first try for many other categories.
        <br />
        <br />
        The first and fastest method to perform Early Lakilester uses{" "}
        <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> to skew Mario's entrance angle such that Mario
        lands <Link to="/Out_Of_Bounds">out of bounds</Link> on the foreground seam. From there, the player can navigate
        around the right-side seam and fall <Link to="/Out_Of_Bounds">out of bounds</Link> to maneuver around the
        Loading Zone and land on the ledge behind the bubble plant.
        <br />
        <br />
        The second fastest method to perform Early Lakilester, called the Tree Clip method, is similar to the standard
        version of <Link to="/Log_Skip">Log Skip</Link>, in that it uses the infinite height property of Loading Zones
        to touch and store a Loading Zone <Link to="/Out_Of_Bounds">out of bounds</Link>, then falls out of bounds again
        to have Mario land in a position that will skew his entrance angle in the next room to land on the foreground
        seam. This method is very difficult, and as such it no longer sees use in speedruns, but when done optimally, it
        is only slower than the LZS method by a few seconds.
        <br />
        <br />
        The last method for Early Lakilester uses <Link to="/NPC_Pushing">NPC Pushing</Link> to move the bubble plant
        downwards so that Mario can use the bubble plant to{" "}
        <a target="_blank" rel="noopener noreferrer" href="NPC_Clipping">
          NPC Clip
        </a>{" "}
        onto the seam. This method is significantly slower than the other two methods, but it is very easy to perform,
        making it the recommended method for beginners.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "realblopez (NPC Push)",
        url: "https://youtu.be/bEMa_4hoq-Y",
      },
      {
        author: "Giga DB (Tree Clip)",
        url: "https://www.youtube.com/watch?v=4aUh8jLg-RE",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Early Lakilester (LZS)</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps to the location shown below. When Mario takes the Loading Zone, hold up-left
            until Mario rises off screen.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EarlyLakilesterLZS.gif" alt="Early Lakilester"></img>

        <dl className="list">
          <dt className="detail-title">
            Once on the foreground seam, walk right until Mario is on the edge of the room. Then, jump, tap right, then
            tap up to land on the right-side seam.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EarlyLakilesterOOB1.gif" alt="Early Lakilester"></img>

        <dl className="list">
          <dt className="detail-title">
            Once on the right-side seam, hold up, jump, switch to up-right to fall off, then immediately switch to left.
          </dt>
          <dd>
            Alternatively, you can open the partner menu and buffer an angle just right of up, then swap to left when
            Mario falls.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EarlyLakilesterOOB2.gif" alt="Early Lakilester"></img>
        <br />

        <h1 className="heading">How To Perform Early Lakilester (Tree Clip)</h1>

        <dl className="list">
          <dt className="detail-title">
            Hold up-right and jump onto the tree, then switch to up and press A until Mario jumps into the tree.{" "}
          </dt>
          <dd>
            You need to land on the left seam of the area behind the door. If you jump while Mario is above the left
            edge of the door, you should land in the right spot.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyLakilesterTree1.gif" alt="Early Lakilester"></img>

        <dl className="list">
          <dt className="detail-title">
            Move up until the green in front of the tree is no longer visible. Jump and tap left 6-7 times.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyLakilesterTree2.gif" alt="Early Lakilester"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump slightly left of up to maneuver around the out of bounds area. Walk up to the edge, then jump and tap
            up and left to land on the back seam.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyLakilesterTree3.gif" alt="Early Lakilester"></img>

        <dl className="list">
          <dt className="detail-title">
            Fall right. When Mario respawns, tap jump up until you can see a space between Mario's shoe and the floor.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyLakilesterTree4.gif" alt="Early Lakilester"></img>

        <dl className="list">
          <dt className="detail-title">
            Open the partner menu to buffer slightly up of left. When the floor disappears, switch to down. When the
            camera slows down, switch to right, and when the camera shifts, switch to down. In the next room, hold
            up-left until Mario flies offscreen.
          </dt>
          <dd>If you hit the loading zone early, you are probably switching to down too early.</dd>
          <dd>
            If you do not hit the loading zone, you either switched to down too late or your initial angle was too far
            up.
          </dd>
          <dd>If you don't land in the right spot in the next room, you might have swapped to down too late.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyLakilesterTree5.gif" alt="Early Lakilester"></img>
        <br />

        <h1 className="heading">How To Perform Early Lakilester (NPC Push)</h1>

        <dl className="list">
          <dt className="detail-title">
            Use Bombette to push the plant towards the foreground seam. After about 15-20 pushes, perform an NPC Clip to
            get onto the foreground seam.
          </dt>
          <dd>
            Once on the foreground seam, follow the directions in the LZS method section for getting around the Loading
            Zone.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/EarlyLakilesterNPCPush1.gif"
          alt="Early Lakilester"
        ></img>
        <img
          className="image"
          src="https://s8.gifyu.com/images/EarlyLakilesterNPCPush2.gif"
          alt="Early Lakilester"
        ></img>
      </>
    ),
  },
  {
    name: "Early Seed",
    path: "Early_Seed",
    description: (
      <>
        Early Seed, also known as Parakarryless Seed, is a trick that takes advantage of faulty collision to get{" "}
        <Link to="/Out_Of_Bounds">Out Of Bounds</Link> and get the Mt. Rugged Magical Seed without Parakarry. It saves
        about a minute in glitched categories that leave Mt. Rugged after getting Parakarry and is usually done
        alongside <Link to="/Rock_Climb">Rock Climb</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "mx_four",
        url: "https://youtu.be/Eq1UyjAYIw8",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Early Seed</h1>

        <dl className="list">
          <dt className="detail-title">
            Move to the position shown below, then hold slightly left of the up notch and remap your control stick.
          </dt>
          <dd>
            On an OEM (Nintendo 64) or Hori controller, you can remap the control stick by pressing L, R, and START at
            the same time.
          </dd>
          <dd>
            On a Gamecube controller, you can remap the control stick by pressing X, Y, and START at the same time and
            hold for 3 seconds.
          </dd>
          <dd>
            The ideal angle is around 5 degrees left of the Up notch (see the angle setup in Video Tutorials above for
            more information)
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EarlySeed1.gif" alt="Early Seed"></img>

        <dl className="list">
          <dt className="detail-title">
            Move to the position shown below, then jump and tap left. When Mario is in a good position, spin, then hold
            left after one frame. Hold up-left when Mario falls, then open the partner menu and remap your control stick
            back to neutral.
          </dt>
          <dd>
            You will know Mario is in a good position if his shadow becomes more visible on the right side of the hole
            between the two rocks in front of him.
          </dd>
          <dd>You can mash c-right to open partner menu open immediately.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EarlySeed2.gif" alt="Early Seed"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk right, then tap jump up onto the seam next to the Monty Mole. Once Mario lands below, jump, tap left,
            and hold right to reach the Bub-ulb.
          </dt>
          <dd>
            You can instead just hold up until Mario falls behind the rock, which is slower than jumping but very safe.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EarlySeed3.gif" alt="Early Seed"></img>
      </>
    ),
  },
  {
    name: "Early Star Piece",
    path: "Early_Star_Piece",
    description: (
      <>
        Early Star Piece is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> trick used to collect the
        star piece outside of the Playground. Don't do this.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Bingo",
        path: "/Bingo",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Early Star Piece</h1>

        <dl className="list">
          <dt className="detail-title">Clear the cutscene with Goompa in the room with the star piece.</dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            Do <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> jumps to the right of the stone block, then
            hold down-right in the next room.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyStarPiece1.gif" alt="Early Star Piece"></img>

        <dl className="list">
          <dt className="detail-title">
            Move right until you can see the front corner of the second step on the stairs at the front of the screen.
            Jump and tap right, then down-right.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyStarPiece2.gif" alt="Early Star Piece"></img>
      </>
    ),
  },
  {
    name: "Early Train",
    path: "Early_Train",
    description: (
      <>
        Early Train is a <Link to="/Parakarry_Clip">Parakarry Clip</Link> and{" "}
        <Link to="/Parakarry_Height_Gain">Parakarry Height Gain</Link> that allows the player to get the Toy Train
        without the Storeroom Key.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "RandomSauce",
        url: "https://youtu.be/cHIw02L5tXE",
      },
      {
        author: "Giga DB",
        url: "https://www.youtube.com/watch?v=JJtoYASHwq4",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Early Train</h1>

        <dl className="list">
          <dt className="detail-title">
            Tap jump up into the corner of the Shop until Mario's shoe is inside the corner. Then, use Parakarry's
            ability.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyTrain1.gif" alt="Early Train"></img>

        <dl className="list">
          <dt className="detail-title">
            Tap up-right and let the screen shift. Then, do a full spin to the right, fall up-right into the storeroom
            and hold up to pick up the Toy Train.
          </dt>
          <dd>The order of the items from left to right is Snowman Doll, Volt Shroom, Toy Train, Dizzy Dial.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyTrain2.gif" alt="Early Train"></img>

        <dl className="list">
          <dt className="detail-title">
            Hold up-left for about 2 seconds, then hold right. Return to neutral, then use Parakarry's ability for the
            full duration twice. Then, use Parakarry again and cancel the ability after about 6 "flapping" sounds.
          </dt>
          <dd>This section is done blind, so the graphic shows what is actually going on.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyTrain3.gif" alt="Early Train"></img>

        <dl className="list">
          <dt className="detail-title">
            Once the screen shifts, fall to the right, then hold down. Exit the shop by going left.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyTrain4.gif" alt="Early Train"></img>

        <dl className="list">
          <dt className="detail-title">
            You can also get into the storeroom by <Link to="/NPC_Lure">NPC Luring</Link> the blue toad and{" "}
            <Link to="/NPC_Clip">NPC Clipping</Link>, then holding between up and up-right.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyTrain5.gif" alt="Early Train"></img>
      </>
    ),
  },
  {
    name: "Early Whale",
    path: "Early_Whale",
    description: (
      <>
        Early Whale is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> trick that sets the{" "}
        <Link to="/Story_Triggers">Story Value</Link> to a pre-Chapter 5 state. This trick is unique in that it is
        repeatable and can set any save file to this story position as many times as desired.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Early Whale</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform Loading Zone Storage Jumps to the left of the square on the floor shown below, then hold up.
            Continue walking up until Mario falls, then hold right until Mario falls again.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyWhale1.gif" alt="Early Whale"></img>

        <dl className="list">
          <dt className="detail-title">
            Continue walking up until Mario falls, then hold right until Mario falls again.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyWhale2.gif" alt="Early Whale"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk down-right or do small jumps to move down-right along the seam until Mario is past the boxes.
          </dt>
          <dd>Some controllers can hold down-right to walk along the seam and some cannot.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyWhale3.gif" alt="Early Whale"></img>

        <dl className="list">
          <dt className="detail-title">
            Open the menu to buffer down-right, then close it and jump down-right, then immediately move slightly right
            of the down-right notch, then move slightly down of the down-right notch, and finally return to down-right.
          </dt>
          <dd>Mario should land on the seam on the other side of the Loading Zone.</dd>
          <dd>
            If Mario does not reappear on the other side, you did not hold down of down-right long enough. Return to
            neutral and try again.
          </dd>
          <dd>
            If Mario lands in bounds, you held down of down-right too long and must redo the LZS portion of the trick.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyWhale4.gif" alt="Early Whale"></img>

        <dl className="list">
          <dt className="detail-title">
            Hold down-right or do small jumps down-right until Mario falls. Then, hold down to go down along the
            right-side seam and get onto the water.
          </dt>
          <dd>From here, go inside the back of the whale and jump into its mouth.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/EarlyWhale5.gif" alt="Early Whale"></img>
      </>
    ),
  },
  {
    name: "Fast Flood Room",
    path: "Fast_Flood_Room",
    description: (
      <>
        Fast Flood Room is a series of <Link to="/Laki_Clip">Laki Clips</Link> and a{" "}
        <Link to="/Kooper_Super_Jump">Kooper Super Jump</Link> that allow the player to get the Castle Key without
        completing the entire water puzzle in Bowser's Castle.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Fast Flood Room</h1>

        <dl className="list">
          <dt className="detail-title">Use Lakilester to clip at any corner in the flood room.</dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom1.gif" alt="Fast Flood Room"></img>

        <br />
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom2.gif" alt="Fast Flood Room"></img>

        <dl className="list">
          <dt className="detail-title">
            After hitting the first blue switch, clip back onto the water and clip in again.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom3.gif" alt="Fast Flood Room"></img>

        <dl className="list">
          <dt className="detail-title">
            Fall upwards, then quickly switch to down to land in the area below. Then, switch to Kooper and fall upwards
            again to land on the back seam.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom4.gif" alt="Fast Flood Room"></img>

        <dl className="list">
          <dt className="detail-title">
            Move left along the back seam until you reach the area below the Castle Key. Then, jump and tap up, then tap
            down.
          </dt>
          <dd>Be careful not to go in bounds while moving along the seam, because you will softlock.</dd>
          <dd>
            Do not jump after falling out of bounds this time, because you are currently storing a{" "}
            <Link to="/Kooper_Super_Jump">Kooper Super Jump</Link>.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom5.gif" alt="Fast Flood Room"></img>

        <dl className="list">
          <dt className="detail-title">
            Hold up-left to move into the corner, then tap down-right 3 times. Use Kooper to get the Castle Key, then
            clear the item message.
          </dt>
          <dd>After getting the Castle Key, you can walk right to get back onto the water.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom6.gif" alt="Fast Flood Room"></img>

        <dl className="list">
          <dt className="detail-title">
            Use Laki to get under the area with the spring again. This time, dismount Laki and hold right until Mario
            falls. Then, hold down to reach the Loading Zone.
          </dt>
          <dd>
            Do not spin to fall right, this will make Mario's respawn position unreliable, and you can softlock by going
            in bounds.
          </dd>
          <dd>In the next room, unlock the door and continue to the cannon room.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom7.gif" alt="Fast Flood Room"></img>

        <dl className="list">
          <dt className="detail-title">
            (Advanced Method) Instead of swapping partners immediately, dismount, fall up, then spin left and jump. In
            the air, hold up-left, then switch to down when right Mario goes below the floor. Spam c-right until the
            partner menu comes up. Swap to Kooper and you should be directly under the key.
          </dt>
          <dd>
            If you can see Mario's face in the window when he falls, then you're in a good position. If you see more of
            him, you might need to adjust left, and vice versa if you don't see him at all.
          </dd>
        </dl>
        <img className="image" src="https://s3.gifyu.com/images/FastFloodRoomAdvanced.gif" alt="Fast Flood Room"></img>
      </>
    ),
  },
  {
    name: "Flarakarry",
    path: "Flarakarry",
    description: (
      <>
        Flarakarry is a trick used to skip the spike ball puzzle in Mt. Lavalava. Flarakarry uses a{" "}
        <Link to="/Parakarry_Clip">Parakarry Clip</Link> to touch the Loading Zone to the next room through the rock
        wall and skip the cutscene with Kolorado. It is named after Flare, who discovered it.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: "Flare",
    content: (
      <>
        <h1 className="heading">How To Perform Flarakarry</h1>

        <dl className="list">
          <dt className="detail-title">Move to the position shown below, then use Parakarry's ability.</dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/Flarakarry1.gif" alt="Flarakarry"></img>

        <dl className="list">
          <dt className="detail-title">
            If you fail the cutscene skip, walk up against the rock wall, then use Parakarry's ability.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/Flarakarry2.gif" alt="Flarakarry"></img>
      </>
    ),
  },
  {
    name: "FP Plus Early",
    path: "FP_Plus_Early",
    description: (
      <>
        FP Plus Early is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> trick that allows the player to
        reach the FP Plus outside Koopa Bros Fortress quickly and without using Bombette.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "100%",
        path: "/100",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform FP Plus Early</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> Jumps to the location shown below, then
            hold up-right.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/FPPlusEarly.gif" alt="FP Plus Early"></img>
      </>
    ),
  },
  {
    name: "Gate Clip",
    path: "Gate_Clip",
    description: (
      <>
        Gate Clip is a <Link to="/Laki_Teleport">Laki Teleport</Link> that allows the player to return to the Toad Town
        Sewers after doing <Link to="/Retrigger_Prologue">Retrigger Prologue</Link>. It skips the cutscene where
        Goombario joins the party, which would otherwise softlock because Goompa is not the active partner.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Gate Clip</h1>

        <dl className="list">
          <dt className="detail-title">
            Do a <Link to="/Laki_Teleport">Laki Teleport</Link> from the left side of the fence to the right side,
            holding down or down-right during the teleport.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/GateClip.gif" alt="Gate Clip"></img>
      </>
    ),
  },
  {
    name: "Gourmet Guy Skip",
    path: "Gourmet_Guy_Skip",
    description: (
      <>
        Gourmet Guy Skip is a trick that allows the player to fix the tracks in Pink Station without giving a Cake to
        Gourmet Guy or giving the Frying Pan to Tayce T.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/oHSnIh5GkCQ",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Gourmet Guy Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Stand to the front-right of the save block and tap jump up until the block pushes Mario to the right.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/GourmetGuySkip1.gif" alt="Gourmet Guy Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Make sure Mario is facing the center of the save block, then use Parakarry's ability 7 times.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/GourmetGuySkip2.gif" alt="Gourmet Guy Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Move up past the save block, and use Parakarry's ability 3 times, moving up between them such that Mario
            doesn't bump into any walls.
          </dt>
          <dd>Make sure your up angle is perfect. Any variation in the angle will ruin the setup</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/GourmetGuySkip3.gif" alt="Gourmet Guy Skip"></img>

        <dl className="list">
          <dt className="detail-title">Spin up, then hold up-right when Mario falls.</dt>
          <dd>I recommend buffering this with a spin.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/GourmetGuySkip4.gif" alt="Gourmet Guy Skip"></img>
      </>
    ),
  },
  {
    name: "Ice Staircase Skip",
    path: "Ice_Staircase_Skip",
    description: (
      <>
        Ice Staircase Skip is a <Link to="/Laki_Clip">Laki Clip</Link> that skips having to talk to Merlar to get the
        Crystal Star and raise the ice staircase. There are two versions, one that visits the platform with the Save
        Block, and another that is slower but visits the platform with the White Clubba and Mega Jump.
      </>
    ),
    versions: "NTSC-J",
    categories: [
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "iycewynd",
        url: "https://www.youtube.com/watch?v=3u4-vvl0ajw",
      },
    ],
    discoveredBy: "Headstrong1290",
    content: (
      <>
        <h1 className="heading">How To Perform Ice Staircase Skip</h1>
        <dl className="list">
          <dt className="detail-title">
            Move Mario right while hugging the back of the wall, then step down. Then, use Lakilester's ability to clip
            out, and hold up-right until Mario reappears at the top.
          </dt>
          <dd>
            This part of the trick is extremely inconsistent. It is based mainly on position and the up-right angle.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip1.gif" alt="Ice Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            If you don't need Mega Jump, hold up-left and switch to up-right when Mario falls.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip2.gif" alt="Ice Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Then, go the top right part of the area with the save block, and when the camera moves, jump and tap right
            and up so that Mario lands on the seam below.
          </dt>
          <dd>
            From the background seam below, spin right until Mario falls, then take the loading zone to the Crystal
            Palace.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip3.gif" alt="Ice Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            If you do need Mega Jump, you need to get onto the foreground seam through 1 of 2 methods.
          </dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            (Method 1) Walk down until Mario falls, then return to neutral. When he reappears, move down-right to get
            onto the foreground seam.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip4.gif" alt="Ice Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            (Method 2) Walk down until you can see Mario's shadow through the wall, then jump and tap down to line up
            against an invisible wall. Jump, tap left, then tap down. When Mario respawns, fall right to land on the
            foreground seam.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip5.gif" alt="Ice Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Move right along the foreground seam until Mario falls, then hold up-right. Watch out for the White Clubba
            and get Mega Jump.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip6.gif" alt="Ice Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk up into the up-left corner of the platform with the White Clubba (don't run), then when Mario falls,
            hold down-right.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/IceStaircaseSkip7.gif" alt="Ice Staircase Skip"></img>
      </>
    ),
  },
  {
    name: "Invisible Bridge Clip",
    path: "Invisible_Bridge_Clip",
    description: (
      <>
        Invisible Bridge Clip is a <Link to="/Laki_Clip">Laki Clip</Link> that allows Mario to get into the eastern
        bridge on Pleasant Path without using Kooper to hit the blue switch. Because it requires Lakilester, it is only
        done in <Link to="/Reverse_All_Cards">Reverse All Cards</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Invisible Bridge Clip</h1>

        <dl className="list">
          <dt className="detail-title">
            Stand behind the left corner of the fence, tap jump down a few times, and use Lakilester's ability while
            facing left.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/InvisibleBridgeClip.gif"
          alt="Invisible Bridge Clip"
        ></img>
      </>
    ),
  },
  {
    name: "Jr Skip",
    path: "Jr_Skip",
    description: (
      <>
        Jr Skip is a glitch that uses <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> in Jr. Troopa's
        Playground during Prologue to get Goompa's Hammer from the bush at the same time that Mario takes the Loading
        Zone. Performing this glitch skips the fight with Jr. Troopa that would normally occur when leaving the
        playground after collecting the Hammer. Due to its difficulty, it is usually only performed in Expert-level
        routes. This trick saves approximately 1 minute over fighting Jr Troopa.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Jr Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps over to the bush containing the hammer, then press A while holding up when
            descending to activate the bush.
          </dt>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/JrSkip.gif" alt="Jr Skip"></img>
      </>
    ),
  },
  {
    name: "Kooper Puzzle Skip",
    path: "Kooper_Puzzle_Skip",
    description: (
      <>
        Kooper Puzzle Skip is a trick that skips the "puzzle" where the Duplighosts poorly impersonate Kooper in Crystal
        Palace.
      </>
    ),
    versions: "NTSC-J",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "GigaDB",
        url: "https://www.youtube.com/watch?v=tn53rZCkd_0",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Kooper Puzzle Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up Mario against the bottom wall such that his nose is perfectly in line with the design on the wall
            behind him.
          </dt>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/KooperPuzzleSkip1.png" alt="Kooper Puzzle Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk up until you can see a white pixel at the bill of Mario's hat, then use Kooper's ability.
          </dt>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/KooperPuzzleSkip2.png" alt="Kooper Puzzle Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            After the cutscene, spin in place and hold right while mashing Z to take the Loading Zone.
          </dt>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/KooperPuzzleSkip3.gif" alt="Kooper Puzzle Skip"></img>
      </>
    ),
  },
  {
    name: "Laki Jailbreak",
    path: "Laki_Jailbreak",
    description: (
      <>
        Laki Jailbreak is a <Link to="/Laki_Teleport">Laki Teleport</Link> and a <Link to="/Laki_Jump">Laki Jump</Link>{" "}
        that lets Mario get to the Koopa Bros without having to collect all of the Fortress Keys to unlock the doors in
        the <Link to="/Staircase_Skip">Staircase Skip</Link> room in{" "}
        <Link to="/Reverse_All_Cards">Reverse All Cards</Link>. It was obsoleted by the discovery of{" "}
        <Link to="/Water_Staircase_Skip">Water Staircase Skip</Link>.{" "}
      </>
    ),
    versions: "All",
    discoveredBy: "hambrgr333, JCog",
    content: (
      <>
        <h1 className="heading">How To Perform Laki Jailbreak</h1>

        <dl className="list">
          <dt className="detail-title">
            Lure the Koopa Troopa to the bottom left, then stand at the corner of the 2nd platform and do a{" "}
            <Link to="/Laki_Teleport">Laki Teleport</Link> while holding left.
          </dt>
          <dd>Be careful not to grab the key after the teleport.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/LakiJailbreak1.gif" alt="Laki Jailbreak"></img>

        <dl className="list">
          <dt className="detail-title">
            Go to the top of the stairs and do a <Link to="/Laki_Jump">Laki Jump</Link> to clip upwards.
          </dt>
          <dd>
            To do a <Link to="/Laki_Jump">Laki Jump</Link>, use Lakilester's ability, press c-down and c-right at the
            same time, and then switch partners.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/LakiJailbreak2.gif" alt="Laki Jailbreak"></img>
      </>
    ),
  },
  {
    name: "Lakilester Peach Warp",
    path: "Lakilester_Peach_Warp",
    description: (
      <>
        Lakilester Peach Warp is a trick that uses <Link to="/Laki_Clip">Laki Clips</Link> and{" "}
        <Link to="/Laki_Teleport">Laki Teleports</Link> to reach the volcano escape cutscene in a post-Chapter 5 state,
        triggering the next Peach cutscene. For more about the glitch Peach Warp, see{" "}
        <Link to="/Peach_Warp">Peach Warp</Link>. For the version of the trick that uses Sushie, see{" "}
        <Link to="/Sushie_Peach_Warp">Sushie Peach Warp</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "morpheus080",
        url: "https://youtu.be/TKGgzjWNZQk",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Lakilester Peach Warp</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up Mario in the location shown below, spin and hammer left to face left, then use Lakilester's ability.
            Hold down on the first time you clip to avoid a softlock.
          </dt>
          <dd>
            It is easiest to reach this position by approaching from the left, then facing left by spinning and
            hammering left.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/LakilesterPeachWarp1.gif"
          alt="Lakilester Peach Warp"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            The second time you use Lakilester's ability to clip, hold left. When Mario reappears at the top, quickly
            switch to down-right.
          </dt>
          <dd>
            Be careful not to pre-empt switching from left to down-right. You should have enough time to react to it.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s7.gifyu.com/images/LakilesterPeachWarp2.gif"
          alt="Lakilester Peach Warp"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Do a Laki Teleport by dismounting, moving right, and using Lakilester's ability again as shown below.
          </dt>
          <dd>The teleport is frame-perfect, but can be retried as many times as necessary.</dd>
        </dl>
        <img
          className="image"
          src="https://s7.gifyu.com/images/LakilesterPeachWarp3.gif"
          alt="Lakilester Peach Warp"
        ></img>

        <dl className="list">
          <dt className="detail-title">Fall right, then hold up-right.</dt>
          <dd>
            By falling here, we avoid a softlock near where the Star Spirit Card would normally be. Do not approach that
            area.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s7.gifyu.com/images/LakilesterPeachWarp4.gif"
          alt="Lakilester Peach Warp"
        ></img>
      </>
    ),
  },
  {
    name: "Lava Piranha Out of Bounds",
    path: "Lava_Piranha_Out_Of_Bounds",
    description: (
      <>
        Lava Piranha Out Of Bounds is a trick used in <Link to="/All_Bosses">All Bosses</Link> to fight Lava Piranha
        without triggering the Star Spirit card to spawn. Because unused actors are held 100 units below the origin of
        the room, we can go <Link to="/Out_Of_Bounds">out of bounds</Link> and first strike Lava Piranha to start the
        fight.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
    ],
    videoTutorials: [
      {
        author: "mx_four",
        url: "https://youtu.be/X71kfsCr31k",
      },
    ],
    discoveredBy: "r0bd0g",
    content: (
      <>
        <h1 className="heading">How To Perform Lava Piranha Out Of Bounds</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform an NPC Clip with Kolorado by spinning down-right and hammering. Once out of bounds, walk down until
            you can see Mario's shadow, then do small tap jumps downwards until Mario falls.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/LavaPiranhaOutOfBounds1.gif"
          alt="Lava Piranha Out Of Bounds"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Go left until you reach the dip in the background to the left of the two yellow blocks. Line up Mario to the
            right of the dip under the rocks, then tap down.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/LavaPiranhaOutOfBounds2.gif"
          alt="Lava Piranha Out Of Bounds"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Once in the out of bounds area below, walk right until Mario disappears behind the background rock. Then,
            hold right and immediately switch to down.
          </dt>
          <dd>You can release down when Mario teleports back up.</dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/LavaPiranhaOutOfBounds3.gif"
          alt="Lava Piranha Out Of Bounds"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            Once in the foreground out of bounds area, go to the right side and walk right until Mario falls off, then
            hold up and switch to up-left.
          </dt>
          <dd>When you hold up, Mario should jitter against the rock a bit.</dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/LavaPiranhaOutOfBounds4.gif"
          alt="Lava Piranha Out Of Bounds"
        ></img>
      </>
    ),
  },
  {
    name: "Lava Piranha Skip",
    path: "Lava_Piranha_Skip",
    description: (
      <>
        Lava Piranha Skip is a trick that uses <Link to="/NPC_Clipping">NPC Clipping</Link> to reach the volcano escape
        cutscene without defeating Lava Piranha or getting the Star Spirit card.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Bingo",
        path: "/Bingo",
      },
    ],
    videoTutorials: [
      {
        author: "realblopez",
        url: "https://youtu.be/xNraaTHnEog",
      },
    ],
    discoveredBy: "bonecrusher1022, r0bd0g",
    content: (
      <>
        <h1 className="heading">How To Perform Lava Piranha Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform an <Link to="/NPC_Clip">NPC Clip</Link> using Kolorado and the spike ball. Hold down-right, then
            switch to down-left when Mario falls.
          </dt>
          <dd>If you land in the area below, just take the Loading Zone on the right.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/LavaPiranhaSkip.gif" alt="Lava Piranha Skip"></img>
      </>
    ),
  },
  {
    name: "Lava Puzzle Skip",
    path: "Lava_Puzzle_Skip",
    description: (
      <>
        Lava Puzzle Skip, formerly known as Half-Baked Pie Jump, is a technique that allows the player to skip
        completing the block puzzle in the room before the Ultra Hammer in Mt. Lavalava. The complete Lava Puzzle Skip
        is not allowed in <Link to="/Glitchless">Glitchless</Link> because it skips a{" "}
        <Link to="/Story_Triggers">Story Trigger</Link>, but it is allowed after completing the puzzle, as shown at the
        bottom of the page.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Glitchless",
        path: "/Glitchless",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Lava Puzzle Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up Mario on the right side of the lava river. Use Parakarry's ability, hold down-left, then deactivate
            Parakarry when Mario's boots are above the floor on the other side.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/LavaPuzzleSkip1.gif" alt="Lava Puzzle Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            If Mario ends up on the left side, line up on the left side of the lava river, open the partner menu to
            buffer down-left, and then close the partner menu.
          </dt>
          <dd>
            You can also jump around the edge on the left side by jumping down and switching to left, but it is much
            harder.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/LavaPuzzleSkip2.gif" alt="Lava Puzzle Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            (Glitchless) Complete the puzzle, then spin down from the position shown below to land on the bottom left
            side.
          </dt>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/LavaPuzzleSkipG.gif" alt="Lava Puzzle Skip"></img>
      </>
    ),
  },
  {
    name: "Log Skip",
    path: "Log_Skip",
    description: (
      <>
        Log Skip is an <Link to="/Out_Of_Bounds">out of bounds</Link> trick in Toad Town that allows the player to get
        past the logs that block the path next to Tayce T's house during Prologue and Chapter 1. This lets the player
        reach the rest of Toad Town earlier than intended, granting access to Chapter 2 without defeating the Koopa
        Bros. Log Skip uses <Link to="/NPC_Clipping">NPC Clipping</Link> to get into the area behind Rowf's Badge Shop,
        then uses <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> to skew Mario's entrance angle in the
        room with the logs such that Mario lands in the area past the logs on the other side. This trick is possible
        using out of bounds because Loading Zones extend infinitely upwards and downwards combined with the fact that
        Mario will not take a Loading Zone unless he is on the the ground for more than 1 frame. Notably, it is also
        possible to perform Log Skip using Loading Zone Storage jumps, which is significantly more difficult but faster.
        The LZS version of the trick is unreasonable on the Nintendo 64 version of the game due to lag, but is viable on
        Wii U.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://www.youtube.com/watch?v=dXYWlRVSijQ&ab_channel=Starry_Arie",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Log Skip</h1>

        <dl className="list">
          <dt className="detail-title">Get Mario into the area behind Rowf's Badge Shop.</dt>
          <dd>Walk up against the top wall, then hold left.</dd>
          <dd>
            When Rowf begins to walk into Mario, while still holding left, do two full jumps, hammer, then jump left.
          </dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/LogSkip1.gif" alt="Log Skip"></img>

        <dl className="list">
          <dt className="detail-title">Line up Mario on the edge of the room shown below.</dt>
          <dd>
            Common visual cues include the dark patch on the ground and lining up the M on Mario's hat with the emblem
            on the sign in front of Merlon's house.
          </dd>
          <dd>Note that the location is not precise, and the trick will work in a large range of positions.</dd>
          <dd>To make sure Mario is on the edge, you can hold down to take an extra fall.</dd>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/LogSkipLocation.png" alt="Log Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Fall slightly down of the down-right notch, then immediately switch to up-right. When the Bub-ulb lags
            around the middle of the screen, switch to down.
          </dt>
          <dd>It is recommended to pull up the partner menu to buffer this angle.</dd>
          <dd>The down angle can be held as long as you want, which is helpful when learning this trick.</dd>
          <dd>
            The Bub-ulb visual cue can sometimes be unreliable, so it is recommended to go through all the motions even
            if you don't see it.
          </dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/LogSkip2.gif" alt="Log Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            When Mario is on screen, switch to an angle between up and up-left. Then, when the camera shifts,
            immediately switch to up-right.
          </dt>
          <dd>
            If all went correctly, Mario should take the Loading Zone and immediately fall out of bounds in the next
            room, landing in the area past the logs.
          </dd>
          <dd>
            A faster Log Skip, called "unbuffered Log Skip," skips the down angle in the previous step and goes directly
            from up-right to between left and up-left.
          </dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/LogSkip3.gif" alt="Log Skip"></img>

        <p className="normal-text">
          All of the stick inputs for the trick, as well as a helpful graphic, are shown below.
        </p>

        <ul>
          <li>down-down-right</li>
          <li>up-right</li>
          <li>down</li>
          <li>left-up-left</li>
          <li>up-right</li>
        </ul>
        <img className="image" src="https://s6.gifyu.com/images/Log_Skip_Inputs.png" alt="Log Skip"></img>

        <p className="normal-text">
          If you fail the trick and accidentally take the Loading Zone, you can spin left and do full jumps into Rowf
          until Mario lands on Rowf's left side, then hammer to get out of bounds without waiting for the entire cycle.
        </p>
        <img className="image" src="https://s7.gifyu.com/images/RowfClip.gif" alt="Log Skip"></img>
        <br />

        <h1 className="heading">Fail Conditions</h1>

        <dl className="list">
          <dt className="detail-title">I did not take the Loading Zone.</dt>
          <dd>Check your down-down-right angle. The angle you need is just slightly down of the down-right notch.</dd>
          <dd>Check your initial position. You may be too far to the left.</dd>
          <dd>Make sure you're switching to up-right as soon as Mario falls. It might be faster than you thought.</dd>
          <dt className="detail-title">I landed on the Loading Zone.</dt>
          <dd>
            Typically this happens when your starting location is too far to the right, or Mario walks too far before
            falling because the down-right-down angle was too close to the down-right notch.
          </dd>
          <dt className="detail-title">I took the Loading Zone just as I swapped to up-right for the final input.</dt>
          <dd>Your left-up-left angle was too close to the up-left notch.</dd>
          <dt className="detail-title">
            I did all of the inputs, but then didn't fall out of bounds in the next room.
          </dt>
          <dd>
            This can happen when your left-up-left angle is too close to the left notch. The angle you're aiming for is
            just slightly left up the up-left notch.
          </dd>
          <dd>
            This can also happen if you don't switch to up-right fast enough. You want to switch as soon as the camera
            shifts.
          </dd>
        </dl>
        <h1 className="heading">How To Perform Log Skip (LZS)</h1>

        <dl className="list">
          <dt className="detail-title">
            Do <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> jumps to the left of Merlon's house.
          </dt>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/LogSkipLZS.gif" alt="Log Skip"></img>
      </>
    ),
  },
  {
    name: "Luigi Skip",
    path: "Luigi_Skip",
    description: (
      <>
        Luigi Skip is a glitch that skips the cutscene where Luigi speaks to Mario after taking the green pipe to Toad
        Town to go to the Castle party after defeating Bowser and taking back the Star Rod. For some unknown reason, the
        green pipe is still active during the cutscene, and taking it will break the cutscene and allow Mario to go
        straight to the castle without having to watch the cutscene with Luigi in the next room.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Glitchless",
        path: "/Glitchless",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Luigi Skip</h1>

        <p className="normal-text">Hold down when Mario jumps on the green pipe.</p>
        <img className="image" src="https://s8.gifyu.com/images/LuigiSkip.gif" alt="Luigi Skip"></img>
      </>
    ),
  },
  {
    name: "Mega Smash Early",
    path: "Mega_Smash_Early",
    description: <>Mega Smash Early is a trick that lets Mario reach Mega Smash without using Bombette.</>,
    versions: "All",
    categories: [
      {
        name: "100%",
        path: "/100",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Mega Smash Early</h1>

        <dl className="list">
          <dt className="detail-title">
            From the top step, hold slightly down of left, then switch to up-left when Mario falls.
          </dt>
        </dl>
        <img className="image" src="https://s5.gifyu.com/images/MegaSmashEarly1.gif" alt="Mega Smash Early"></img>

        <dl className="list">
          <dt className="detail-title">
            Alternatively, stand on the edge above the badge, then jump, tap slightly down, then hold up to fall
            directly into the area.
          </dt>
        </dl>
        <img className="image" src="https://s5.gifyu.com/images/MegaSmashEarly2.gif" alt="Mega Smash Early"></img>
      </>
    ),
  },
  {
    name: "Merlon Skip",
    path: "Merlon_Skip",
    description: (
      <>
        Merlon Skip is a slower, easier alternative to <Link to="/Black_Toad_Skip">Black Toad Skip</Link>.
      </>
    ),
    versions: "All",
    content: (
      <>
        <h1 className="heading">How To Perform Merlon Skip</h1>

        <dl className="list">
          <dt className="detail-title">Talk to the black Toads.</dt>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/MerlonSkip1.gif" alt="Merlon Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Perform an <Link to="/NPC_Clipping">NPC Clip</Link> with the green toad on the left side.
          </dt>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/MerlonSkip2.gif" alt="Merlon Skip"></img>

        <dl className="list">
          <dt className="detail-title">Fall left.</dt>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/MerlonSkip3.gif" alt="Merlon Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Tap down-right and talk to Merlon. Clear the text, then hold left and exit when the prompt appears.
          </dt>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/MerlonSkip4.gif" alt="Merlon Skip"></img>
      </>
    ),
  },
  {
    name: "Mirror Clip",
    path: "Mirror_Clip",
    description: (
      <>
        Mirror Clip is a <Link to="/Laki_Teleport">Laki Teleport</Link> that lets Mario get from the front side of the
        glass to the back side in the first room of Crystal Palace without having to defeat the two Duplighosts.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "morpheus080",
        url: "https://youtu.be/V3wHEJuw-0g",
      },
    ],
    discoveredBy: "bonecrusher1022, Sockfolder, r0bd0g",
    content: (
      <>
        <h1 className="heading">How To Perform Mirror Clip</h1>

        <dl className="list">
          <dt className="detail-title">
            Move across the top to the left column, then push Lakilester over to the right column by walking into him.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/MirrorClip1.gif" alt="Mirror Clip"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk right (don't run), then walk up. When Lakilester goes flying to the left, use his ability (while still
            walking up).
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/MirrorClip2.gif" alt="Mirror Clip"></img>
      </>
    ),
  },
  {
    name: "Murder Solved Early",
    path: "Murder_Solved_Early",
    description: (
      <>
        Murder Solved Early is a Laki Clip that lets Mario bring Herringway to the Bumpty Mayor's house without starting
        the murder mystery sequence by finding the Mayor dead, skipping the first half of the murder mystery and
        skipping the Warehouse Key.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Murder Solved Early</h1>

        <dl className="list">
          <dt className="detail-title">
            Use Laki to clip inside the Warehouse, then jump up the stairs (6 jumps). Once at the top, step down then
            spin right, then jump down onto the spring.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/MurderSolvedEarly.gif" alt="Murder Solved Early"></img>
      </>
    ),
  },
  {
    name: "Outpost Jump",
    path: "Outpost_Jump",
    description: (
      <>
        Outpost Jump is a trick that allow the player to reach the rooftops in eastern Dry Dry Outpost without having to
        go through the rightmost house.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Glitchless",
        path: "/Glitchless",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "mx_four",
        url: "https://www.youtube.com/watch?v=rndU-tvUJPY",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Outpost Jump</h1>

        <dl className="list">
          <dt className="detail-title">
            Walk against the leftmost house, walk down for 1 frame, then jump and tap right to land on the pole. Once on
            the pole, spin, hold left, and jump.
          </dt>
          <dd>
            The Koopa here, not-so-affectionately nicknamed "Tanner" after the popular copypasta, can sometimes get in
            your way. I'm sorry.
          </dd>
        </dl>

        <img className="image" src="https://s1.gifyu.com/images/OutpostJump1.gif" alt="Outpost Jump"></img>

        <dl className="list">
          <dt className="detail-title">
            If you accidentally walk down for 2 frames, you will land on the lower section of the pole. If this happens,
            spin, hold down left, then jump and immediately switch to up.
          </dt>
        </dl>

        <img className="image" src="https://s1.gifyu.com/images/OutpostJump2.gif" alt="Outpost Jump"></img>
      </>
    ),
  },
  {
    name: "Peach Warp (Lakilester)",
    path: "Lakilester_Peach_Warp",
  },
  {
    name: "Peach Warp (Sushie)",
    path: "Sushie_Peach_Warp",
  },
  {
    name: "Pie Jumps",
    path: "Pie_Jumps",
    description: (
      <>
        Pie Jumps are a trick used to skip part of the Koopa Bros Fortress Kooper puzzle by jumping around some
        collision. It is not considered a glitch, and is thus allowed in <Link to="/Glitchless">Glitchless</Link>. It is
        named after iateyourpie, who discovered it.
      </>
    ),
    versions: "",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Glitchless",
        path: "/Glitchless",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: "iateyourpie",
    content: (
      <>
        <h1 className="heading">How To Perform Pie Jumps</h1>

        <dl className="list">
          <dt className="detail-title">Move down until the camera shifts. Then, jump left and switch to up-left.</dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/PieJumps.gif" alt="Pie Jumps"></img>
      </>
    ),
  },
  {
    name: "Pink Station Clips",
    path: "Pink_Station_Clips",
    description: (
      <>
        The Pink Station Clips are a series of <Link to="/Parakarry_Clip">Parakarry Clips</Link> that allow the player
        to get the Frying Pan in Pink Station quickly. The second of the two clips is actually slower than luring the
        Sky Guy, but is included here for documentation's sake.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/JGgzrowpso4",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Pink Station Clips</h1>

        <dl className="list">
          <dt className="detail-title">
            (First Clip) Walk into the corner shown below, then spin and hammer left. Then, use Parakarry's ability and
            hold up-right.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/PinkStationClips1.gif" alt="Pink Station Clips"></img>

        <dl className="list">
          <dt className="detail-title">
            (Second Clip) Walk into the corner shown below, then spin and hammer left. Then, use Parakarry's ability and
            hold left.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/PinkStationClips2.gif" alt="Pink Station Clips"></img>

        <dl className="list">
          <dt className="detail-title">
            If you are playing <Link to="/Reverse_All_Cards">Reverse All Cards</Link>, you can do a{" "}
            <Link to="/Laki_Clip">Laki Clip</Link> in this corner instead.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s5.gifyu.com/images/PinkStationClipsLaki.gif"
          alt="Pink Station Clips"
        ></img>
      </>
    ),
  },
  {
    name: "Quick Escape",
    path: "Quick_Escape",
    description: (
      <>
        Quick Escape is a <Link to="/Parakarry_Height_Gain">Parakarry Height Gain</Link> that allows Mario to leave
        Tubba's Castle quickly during the Tubba escape sequence. It does go{" "}
        <Link to="/Out_Of_Bounds">out of bounds</Link>, so it is not allowed in <Link to="/Glitchless">Glitchless</Link>
        .
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "RandomSauce",
        url: "https://youtu.be/02_w9Qkzig4",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Quick Escape</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up Mario against the top wall, such that you can only see the tip of Mario's nose. Then, use
            Parakarry's ability.
          </dt>
          <dd>Follow the movement to avoid Tubba Blubba every time (spin down-left, then spin up-left and jump).</dd>
          <dd>
            Once on top of the broken bridge, spin down-left or just down of down-left and fall down to the first floor.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/QuickEscape.gif" alt="Quick Escape"></img>
      </>
    ),
  },
  {
    name: "Raphael Skip",
    path: "Raphael_Skip",
    description: (
      <>
        Raphael Skip, also known as Raph Skip or Yoshi Skip, is a trick performed in the room between Mt. Lavalava and
        Yoshi Village that allows Mario to reach the volcano entrance without saving the Yoshi kids or receiving the
        Ultra Stone from Raphael, skipping a large part of Chapter 5. The first half of this trick can be used if the
        player wishes to skip saving the Yoshi kids, but still wants to get the Ultra Stone from Raphael. Typically, the
        trick is called Raphael Skip when the player goes straight to Mt. Lavalava, and it is called Yoshi Skip when the
        player goes to get the Ultra Stone. The fastest method uses a <Link to="/Parakarry_Clip">Parakarry Clip</Link>{" "}
        to get <Link to="/Out_Of_Bounds">out of bounds</Link>, but is only possible on the Japanese version of the game.
        Two other methods, the Tree Hop method and the <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link>{" "}
        method, are possible on all versions of the game, but are significantly harder.
      </>
    ),
    versions: "NTSC-J (Parakarry Clip), All (Tree Hop, LZS)",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    discoveredBy: "Flare (Parakarry Clip), TacoMafia (Tree Hop)",
    videoTutorials: [
      {
        author: "Rigby234 (Tree Hop)",
        url: "https://www.youtube.com/watch?v=gqBH0h7PALg",
      },
      {
        author: "CSight (Tree Hop)",
        url: "https://www.youtube.com/watch?v=rndU-tvUJPY",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Raphael Skip (Parakarry Clip)</h1>

        <dl className="list">
          <dt className="detail-title">
            Get next to the tree behind the bush, then hold down, hold left, then use Parakarry's ability and return to
            neutral.
          </dt>
          <dd>
            You can also do this by moving up against the front of the bush on the right side until it pushes Mario out,
            then move left and use Parakarry as normal.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/RaphaelSkip1.gif" alt="Raphael Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Hold up-left until Mario falls out of bounds. When Mario reappears on the ground, move right and jump, then
            tap up-right, switch to down-right, then switch to right.
          </dt>
          <dd>
            You want to hold up-right and down-right for the same amount of time so that Mario will land close to the
            background seam. It's okay to hold down-right for longer than you held up-right, but not the other way
            around.
          </dd>
          <dd>
            A popular set of visual cues is holding up-right until the ground goes completely off-screen, then holding
            down-right until Mario flies upwards.
          </dd>
          <dd>Falling out of bounds here is optional but recommended, especially when learning this trick.</dd>
          <dd>
            (Yoshi Skip) If you want to go to Raphael to get the Ultra Stone, spin right from the background seam to
            take the Loading Zone.
          </dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/RaphaelSkip2.gif" alt="Raphael Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Check that Mario is on the back edge by looking at his feet in this position. You can fall upwards instead
            if you're unsure.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/RaphaelSkip3.gif" alt="Raphael Skip"></img>

        <dl className="list">
          <dt className="detail-title">Spin right, then hold up briefly to avoid the lava, then hold down-right.</dt>
          <dd>If you end up in bounds, you were not on the background seam.</dd>
          <dd>If you land in the lava, you did not hold up long enough.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/RaphaelSkip4.gif" alt="Raphael Skip"></img>
        <br />

        <h1 className="heading">How To Perform Raphael Skip (Tree Hop)</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up with the bush in front of the tree, then tap jump up until Mario lands on the tree. Continue tap
            jumping up until Mario's hat is visible above both vines. Then, jump up-left to land out of bounds.
          </dt>
          <dd>
            If Mario seems too far right, use Parakarry's ability and quickly cancel it with B. This should usually be
            done about halfway up the tree when Mario is not obscured by the vines.
          </dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/RaphaelSkipTreeHop.gif" alt="Raphael Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            If you're not playing on NTSC-J, there is a wall preventing you from falling out of bounds. To avoid this,
            hold up until Mario is stuck at the top right of the area behind the tree, then slowly rotate the stick to
            the right until Mario clips out of bounds. Mario will reappear on the background seam.
          </dt>
          <dd>
            Proceed with the above instructions to finish the trick. You may need to move left to jump around the
            Loading Zone.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/RaphaelSkipTree2.gif" alt="Raphael Skip"></img>
        <br />

        <h1 className="heading">How To Perform Raphael Skip (LZS)</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform Loading Zone Storage jumps to the location shown below, then hold down-right until Mario flies up in
            the next room.
          </dt>
          <dd>Then, follow the directions in the Parakarry Clip section for what to do once out of bounds.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/RaphaelSkipLZS.gif" alt="Raphael Skip"></img>
      </>
    ),
  },
  {
    name: "Record Skip",
    path: "Record_Skip",
    description: (
      <>
        Record Skip is a glitch that allows you to skip the minigame with the Boos where you get the Record in Boo's
        Mansion.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "mx_four",
        url: "https://youtu.be/xahYVsamlHg",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Record Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up Mario against the wall down-right from the chest. Then hammer, hold up-left, and spin and jump such
            that Mario lands between the Boo and the chest.
          </dt>
          <dt className="detail-title">
            Press A precisely the frame before Mario touches the ground to open the chest.
          </dt>
          <dd>
            This trick can be made significantly easier by <Link to="/NPC_Pushing">NPC Pushing</Link> him with Bombette.
          </dd>
          <dd>Make sure to hold up-left throughout the entire trick.</dd>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/RecordSkip.gif" alt="Record Skip"></img>
      </>
    ),
  },
  {
    name: "Refight Goomba King",
    path: "Refight_Goomba_King",
    description: (
      <>
        Refight Goomba King is a trick used to fight Goomba King twice. It also skips part of the bridge cutscene by
        allowing control of Mario during it. This trick works because one of the goomba's hitboxes is used to move the
        blue switch from the bush to the ground. Touching that hitbox by eating an item at the same time the blue switch
        flies out will cause the Goomba King battle to begin again.
      </>
    ),
    versions: "All",
    discoveredBy: "Unknown, setup by Erin Rose",
    content: (
      <>
        <h1 className="heading">How To Perform Refight Goomba King</h1>

        <dl className="list">
          <dt className="detail-title">
            Interact with the bush and open the item menu at the same time. Continue mashing the item menu until the
            third time it opens, then eat an item.
          </dt>
          <dd>Alternatively, you can try to nail the frame perfect item menu and eat the item.</dd>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/RefightGoombaKing1.gif" alt="Refight Goomba King"></img>

        <dl className="list">
          <dt className="detail-title">
            Hit the button, then leave the room. When you re-enter the room, the cutscene will already be completed.
          </dt>
        </dl>

        <img className="image" src="https://s8.gifyu.com/images/RefightGoombaKing2.gif" alt="Refight Goomba King"></img>
      </>
    ),
  },
  {
    name: "Retrigger Prologue",
    path: "Retrigger_Prologue",
    description: (
      <>
        Retrigger Prologue is a trick that allows the player to return to a Prologue{" "}
        <Link to="/Story_Triggers">story state</Link> by entering the fight with Jr Troopa under the floor of the
        playground. To do this, one must collect 32 Key Items using various skips to get{" "}
        <Link to="/Key_Item_Overflow">Key Item Overflow</Link>, do{" "}
        <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> to fall out of bounds in the playground, pick up
        and toss the Dolly until it lands on top of the save block, then do a{" "}
        <Link to="/Kooper_Super_Jump">Kooper Super Jump</Link> under the save block. After waiting a certain amount of
        time and closing the menu that comes up, Mario will encounter Jr Troopa under the floor because he is stored a
        hundred units below the origin of the room, which is coincidentally right below the save block. Mario only clips
        through the floor if he is falling fast enough, which only happens during specific windows due to Paper Mario's
        strange gravity function.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    discoveredBy: "bonecrusher1022, r0bd0g",
    content: (
      <>
        <h1 className="heading">How To Perform Retrigger Prologue</h1>

        <dl className="list">
          <dt className="detail-title">
            Collect 32 Key Items, then do <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> jumps downwards
            to where the fence meets the front of the room.
          </dt>
          <dd>
            It is recommended to save before attempting this trick, as it can only be performed once per file and can
            become impossible if you have bad RNG.
          </dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/RetriggerPrologue1.gif" alt="Retrigger Prologue"></img>

        <dl className="list">
          <dt className="detail-title">
            Pick up and throw the Dolly until it lands on top of the save block, then do a{" "}
            <Link to="/Kooper_Super_Jump">Kooper Super Jump</Link> under the save block.
          </dt>
          <dd>
            You can use the hammer to pick up the Dolly from a good location. The dolly will never be thrown towards the
            back of the room, so if it goes too far in front of the save block, you may want to reset and try again.
          </dd>
          <dd>
            Be sure not to jump once you enter the room, because it will remove your stored{" "}
            <Link to="/Kooper_Super_Jump">Kooper Super Jump</Link>.
          </dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/RetriggerPrologue2.gif" alt="Retrigger Prologue"></img>

        <dl className="list">
          <dt className="detail-title">Close the menu by throwing any item after 5 or 15 seconds.</dt>
          <dd>The 15 second window is a much larger and safer window, at the cost of 10 seconds.</dd>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/RetriggerPrologue3.gif" alt="Retrigger Prologue"></img>
      </>
    ),
  },
  {
    name: "Reverse Staircase Skip",
    path: "Reverse_Staircase_Skip",
    description: (
      <>
        Reverse Staircase Skip is an obsolete variant of <Link to="/Staircase_Skip">Staircase Skip</Link> that was used
        in <Link to="/Reverse_All_Cards">Reverse All Cards</Link> that lands inside the left door instead of the right
        one. After the discovery of
        <Link to="/Laki_Jump">Laki Jumps</Link> by hambrgr333 in mid-2021,{" "}
        <Link to="Laki_Jailbreak">Laki Jailbreak</Link> became possible and Reverse All Cards routes no longer used
        Reverse Staircase Skip.
        <br />
        It is important to note that before doing Reverse Staircase Skip, the bottom lock must be opened and the room
        must be reloaded. Otherwise, Mario will not be able to interact with the top lock.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Reverse Staircase Skip</h1>

        <dl className="list">
          <dt className="detail-title">Fall through the gap in front of the stairs.</dt>
          <dd>
            Position Mario at the top of the staircase in the room where you would normally fight a Koopa Troopa to
            reveal a Blue Switch.
          </dd>
          <dd>Then, jump, tap down, then tap up so that Mario weaves in between the staircase and the fence below.</dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/StaircaseSkip1.gif" alt="Reverse Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Spin left into the wall, jump, then hold up-left until you can see the black background out of bounds.
          </dt>
          <dd>
            Mario should land behind the door at the top. From here, you can unlock the door and continue through Koopa
            Bros Fortress.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s8.gifyu.com/images/ReverseStaircaseSkip.gif"
          alt="Reverse Staircase Skip"
        ></img>
      </>
    ),
  },
  {
    name: "Rock Climb",
    path: "Rock_Climb",
    description: (
      <>
        Rock Climb is a glitch that allows the player to reach the leftmost letter in Mt. Rugged without taking the
        slide. This saves a lot of time in categories that do <Link to="/Early_Seed">Early Seed</Link>, and 2.5 seconds
        in categories that don't.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "realblopez",
        url: "https://youtu.be/l-2JIpp9dY8",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Rock Climb</h1>

        <dl className="list">
          <dt className="detail-title">Jump up-right-up onto the rocks.</dt>
        </dl>

        <img className="image" src="https://s7.gifyu.com/images/RockClimb1.gif" alt="Rock Climb"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump and tap left until Mario has climbed high enough. Then, jump, tap up-right, and switch to up-left to
            land in the area above.
          </dt>
        </dl>

        <img className="image" src="https://s7.gifyu.com/images/RockClimb2.gif" alt="Rock Climb"></img>
      </>
    ),
  },
  {
    name: "Ruins Clip",
    path: "Ruins_Clip",
    description: (
      <>
        Ruins Clip is an <Link to="/Boots_Clip">Ultra Boots Clip</Link> that allows the player to get to the Super
        Hammer room without using Parakarry.
      </>
    ),
    versions: "",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Ruins Clip</h1>

        <dl className="list">
          <dt className="detail-title">
            Stand on the second step, walk up, then jump up-left and perform a Tornado Jump.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/RuinsClip.gif" alt="Ruins Clip"></img>
      </>
    ),
  },
  {
    name: "Ruins Jump",
    path: "Ruins_Jump",
    description: (
      <>
        Ruins Jump allows the player to reach the Super Hammer without using Parakarry to cross the gap. The curved
        walls of the ruins allow Mario to get extra distance by jumping at specific angles.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Glitchless",
        path: "/Glitchless",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Ruins Jump</h1>

        <dl className="list">
          <dt className="detail-title">Hold up-left as you enter the room, and jump at the edge of the platform.</dt>
          <dd>
            For some controllers this angle is notched, but for my controller (and many others) the angle is slightly
            left of the up-left notch.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/RuinsJump.gif" alt="Ruins Jump"></img>
      </>
    ),
  },
  {
    name: "Sand Clip",
    path: "Sand_Clip",
    description: (
      <>
        Sand Clip is a clip in the Dry Dry Ruins that allows Mario to get the Ruins key underneath the sand in the room
        to the right of the statue room while the Ruins are in a completed state. Because it only saves about 3 seconds
        over a normal Dry Dry Ruins route on an average first attempt, it is not currently attempted by most{" "}
        <Link to="/Reverse_All_Cards">Reverse All Cards</Link> runners.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    discoveredBy: "r0bd0g, setups found by RandomSauce and Erin Rose",
    content: (
      <>
        <h1 className="heading">How To Perform Sand Clip</h1>

        <dl className="list">
          <dt className="detail-title">
            Use Lakilester's ability and move into the top-right corner. Move up-left (you can buffer this input with
            the partner menu, which makes it easier and more consistent). Mario should end up below the sand.
          </dt>
          <dd>If Mario falls immediately, hold down until he reappears. This avoids a softlock.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SandClip1.gif" alt="Sand Clip"></img>

        <dl className="list">
          <dt className="detail-title">
            Once under the sand, tap down. When Mario reappears, hold neutral and jump. Continue jumping until Mario
            falls. When he does, hold right for about a second, then switch to down-right.
          </dt>
          <dd>
            Be very careful to follow these directions exactly. It is very easy to accidentally softlock by doing the
            wrong thing.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SandClip2.gif" alt="Sand Clip"></img>

        <dl className="list">
          <dt className="detail-title">
            In the next room, spin down-right and hammer the block in the front-right corner, then jump on the switch.
            Spin up-left, then hold up-right and jump until Mario grabs the Ruins Key. Jump again to escape.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SandClip3.gif" alt="Sand Clip"></img>
      </>
    ),
  },
  {
    name: "Seed Duplication",
    path: "Seed_Duplication",
    description: (
      <>
        Seed Duplication uses <Link to="/Toad_Town_Sushie_Glitch">Toad Town Sushie Glitch</Link> and enemy encounters to
        manipulate Mario's flags and allow movement during the Bub-ulb's conversation. This allows the player to
        duplicate Magical Seeds by leaving the room after receiving the Magical Seed but before the flag is set for
        completing the Bub-ulb's conversation, and then returning to start the conversation over again.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Seed Duplication</h1>

        <dl className="list">
          <dt className="detail-title">
            Get <Link to="/Toad_Town_Sushie_Glitch">Toad Town Sushie Glitch</Link>, then go to the area with the Bub-ulb
            and encounter the fuzzy. Run away, then encounter it again. This time, swap partners with the partner's turn
            before running away.
          </dt>
          <dd>
            Your camera might be in a different position than what is shown here. Forever Forest's camera is weird
            during Sushie Glitch, so this can happen sometimes.
          </dd>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            Go to the Bub-ulb, pause, then unpause and hit A right when the pause menu disappears. If you time this
            properly, you will gain control of Mario during the cutscene. Leave the room after you get the Magical Seed,
            but do not close the text boxes that appear afterwards!
          </dt>
          <dd>
            If you talk to the Bub-ulb and your movement is locked, then you pressed A too late. If nothing happens, you
            pressed A too early.
          </dd>
        </dl>
        <img
          className="image"
          src="https://s9.gifyu.com/images/MagicalSeedDuplication.gif"
          alt="Seed Duplication"
        ></img>

        <dl className="list">
          <dt className="detail-title">
            (Optional) You can save about 2 seconds by doing the same pause, unpause and A input combination on the
            closest gate. When you press A, immediately hold towards the center of the room and press Z to spin.
          </dt>
        </dl>
        <img className="image" src="https://gifyu.com/images/SeedDuplicationGateClip.gif" alt="Seed Duplication"></img>
      </>
    ),
  },
  {
    name: "Shovel Jump",
    path: "Shovel_Jump",
    description: (
      <>
        Shovel Jump is a trick that allows you to reach the window to Attack FX E without going through the house. It is
        slower and significantly harder than <Link to="/Attack_FX_E_Early">Attack FX E Early</Link>.
      </>
    ),
    versions: "All",
    content: (
      <>
        <h1 className="heading">How To Perform Shovel Jump</h1>

        <dl className="list">
          <dt className="detail-title">Jump onto the shovel, then jump down-right onto the house.</dt>
          <dd>This is way harder than it looks. Don't do this.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/ShovelJump.gif" alt="Shovel Jump"></img>
      </>
    ),
  },
  {
    name: "Slow Go Early",
    path: "Slow_Go_Early",
    description: (
      <>
        Slow Go Early is a <Link to="/Boots_Clip">Super Boots Clip</Link> that lets the player get Slow Go without
        having to do the stairs puzzle in <Link to="/100">100%</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "100%",
        path: "/100",
      },
    ],
    videoTutorials: [
      {
        author: "mx_four",
        url: "https://youtu.be/stIXMmRl38A",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Slow Go Early</h1>

        <dl className="list">
          <dt className="detail-title">
            Get on top of the Super Hammer chest by jumping down while standing behind the back-right corner.
          </dt>
          <dd>You can also get on top of the chest from the front-left corner.</dd>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/dumb_green_one.gif" alt="Slow Go Early"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump down-left and perform a spin jump while Mario is clipped into the side of the platform.
          </dt>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/dumb_green_one.gif" alt="Slow Go Early"></img>
      </>
    ),
  },
  {
    name: "Small Statue Skip",
    path: "Small_Statue_Skip",
    description: (
      <>
        Small Statue Skip is an <Link to="/Boots_Clip">Ultra Boots clip</Link> that allows you to skip pushing the small
        dino statue in the Crystal Palace. Due to its high difficulty, it is not recommended to do this glitch in runs
        outside of world record attempts.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
    ],
    discoveredBy: "NaterTater",
    content: (
      <>
        <h1 className="heading">How To Perform Small Statue Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up with the front of the statue, move to the right, and tap up twice to get in position. Then, jump,
            move left, and do a Tornado Jump.
          </dt>
          <dd>This part can be tricky, so make sure you practice and find something that works for you.</dd>
          <dd>
            Once you're on the statue, you can jump and roll from up to left to get to the top, or you can just walk
            left.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SmallStatueSkip1.gif" alt="Small Statue Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Line up such that the front of Mario's boot is in the eye, then walk down and jump right before Mario would
            fall. Do a Tornado Jump with precise timing to land on the seam.
          </dt>
          <dd>
            To get a consistent position, you can walk down until Mario's boot is visible, then tap jump up until it is
            behind the eye.
          </dd>
          <dd>If you jump and Mario falls in bounds, you probably jumped too early.</dd>
          <dd>If you Tornado Jump and Mario falls out of bounds, you did your Tornado Jump too late.</dd>
          <dd>
            The jump timing is likely later than you think, and the Tornado Jump timing is likely earlier than you
            think.
          </dd>
          <dd>
            You will know that you have landed on the seam if you see the camera move and you didn't fall out of bounds.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SmallStatueSkip2.gif" alt="Small Statue Skip"></img>

        <dl className="list">
          <dt className="detail-title">Jump, move down-right, then switch to up-right.</dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SmallStatueSkip3.gif" alt="Small Statue Skip"></img>
      </>
    ),
  },
  {
    name: "Smash Charge KSJ",
    path: "Smash_Charge_KSJ",
    description: (
      <>
        Smash Charge KSJ (Kooper Super Jump) is a trick that uses a{" "}
        <Link to="/Kooper_Super_Jump">Kooper Super Jump</Link> to get Hammer Charge from below the platform.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Bingo",
        path: "/Bingo",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Smash Charge KSJ</h1>

        <dl className="list">
          <dt className="detail-title">
            Walk up at the corner of the chain to get out of bounds, then walk left and jump up the steps until you
            reach the top step.
          </dt>
          <dd>Be careful not to walk too far up.</dd>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            Jump and tap up to fall out of bounds, then hold down briefly so that Mario will reappear on the top step.
            Then, walk to the middle and use Kooper's ability.
          </dt>
          <dd>
            Sometimes Mario will clip through the floor and sometimes he won't. This is because Mario's velocity changes
            based on how long you wait to close to item menu.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SmashChargeKSJ.gif" alt="Smash Charge KSJ"></img>
      </>
    ),
  },
  {
    name: "Staircase Skip",
    path: "Staircase_Skip",
    description: (
      <>
        Staircase Skip is an <Link to="/Out_Of_Bounds">out of bounds</Link> trick in Koopa Bros Fortress that allows the
        player to get Bombette before doing the Pit fights. It is possible due to the lack of collision that would
        prevent Mario from falling between the stairs and the fence.{" "}
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/VkNGV1UYd8E",
      },
    ],
    discoveredBy: "r0bd0g, bonecrusher1022",
    content: (
      <>
        <h1 className="heading">How To Perform Staircase Skip</h1>

        <dl className="list">
          <dt className="detail-title">Fall through the gap in front of the stairs.</dt>
          <dd>
            Position Mario at the top of the staircase in the room where you would normally fight a Koopa Troopa to
            reveal a Blue Switch.
          </dd>
          <dd>
            Then, jump, tap down, tap up, then tap down again briefly so that Mario weaves in between the staircase and
            the fence below.
          </dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/StaircaseSkip1.gif" alt="Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">Move right to the location shown below.</dt>
          <dd>A good visual cue is the third brick from the right in the background.</dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/StaircaseSkipPosition.png" alt="Staircase Skip"></img>

        <dl className="list">
          <dt className="detail-title">Fall so that Mario teleports up to the top door.</dt>
          <dd>Jump right, then immediately switch to up-right. Mario should land behind the top door.</dd>
          <dd>
            Alternatively, use the hammer to buffer up-right, then hold down-right. Mario should land behind the top
            door.
          </dd>
          <dd>Once at the top, walk left to get back in bounds and open the door.</dd>
        </dl>
        <img className="image" src="https://s10.gifyu.com/images/StaircaseSkip2Jump.gif" alt="Staircase Skip"></img>
        <img className="image" src="https://s10.gifyu.com/images/StaircaseSkip2Hammer.gif" alt="Staircase Skip"></img>
      </>
    ),
  },
  {
    name: "Stanley Save",
    path: "Stanley_Save",
    description: (
      <>
        Stanley Save, formerly known as Brown House Skip, is an <Link to="/Out_Of_Bounds">out of bounds</Link> trick
        that allows the player to skip the cutscene in Gusty Gulch where Stanley gets eaten by Tubba Blubba. By using{" "}
        <Link to="/Parakarry_Height_Gain">Parakarry to gain height</Link>, it is possible to get inside the brown house
        and fall out of bounds to reach the Loading Zone without triggering the cutscene.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "realblopez",
        url: "https://youtu.be/QgD-KyxOyzI",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Stanley Save</h1>

        <dl className="list">
          <dt className="detail-title">
            Walk up-right into the leftmost house, then hold right. When Mario stops moving, jump, then hammer,
            returning to neutral while hammering. Do a full jump, then use Parakarry's ability. Use Parakarry's ability
            again when he rises to the top of the house to land on the roof.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/StanleySave1.gif" alt="Stanley Save"></img>

        <dl className="list">
          <dt className="detail-title">
            Roll from up to right. When Mario falls, switch to down-right, then back to right when the floor goes
            offscreen.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/StanleySave2.gif" alt="Stanley Save"></img>

        <dl className="list">
          <dt className="detail-title">
            When Mario reappears on the right, spin to the right wall, then hold down-right and spam Z to spin into the
            loading zone.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/StanleySave3.gif" alt="Stanley Save"></img>

        <dl className="list">
          <dt className="detail-title">
            If you didn't hold down-right for long enough, Mario will fall again on the right side. If this happens,
            wait for a moment after the floor disappears, then hold down-right.
          </dt>
          <dd>If you switch to down-right too early, Stanley will not be saved.</dd>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/StanleySave4.gif" alt="Stanley Save"></img>
      </>
    ),
  },
  {
    name: "Sun Tower Skip",
    path: "Sun_Tower_Skip",
    description: (
      <>
        Sun Tower Skip is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> trick that allows the player to
        talk to the Sun in Chapter 6 without using Bombette to fix the stairs. Due to its insane difficulty, it is not
        normally performed in speedruns, but is faster in every category that allows Loading Zone Storage.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Sun Tower Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Do Loading Zone Storage Jumps to the back of the Loading Zone, and get on to the back seam. Then, go left
            and fall out of bounds such that Mario falls in front of the Sun, and talk to him.
          </dt>
        </dl>
        <img className="image" src="https://s6.gifyu.com/images/dumb_green_one.gif" alt="Sun Tower Skip"></img>
      </>
    ),
  },
  {
    name: "Sushie Peach Warp",
    path: "Sushie_Peach_Warp",
    description: (
      <>
        Sushie Peach Warp is an alternate version of <Link to="/Peach_Warp">Peach Warp</Link> that uses Sushie and the
        Super/Ultra Boots to get <Link to="/Sushie_Glitch">Sushie Glitch</Link> through{" "}
        <Link to="/Menu_Storage">Menu Storage</Link> and trigger the next Peach intermission.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/lK4eFORo19U",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Sushie Peach Warp</h1>

        <dl className="list">
          <dt className="detail-title">
            Make sure Bombette is out, fall down-right onto the second spring, and then do a spin/tornado jump into the
            save block.
          </dt>
          <dd>It is recommended to save before performing this glitch due to its many softlock conditions.</dd>
          <dd>Spin/tornado jumping without hitting the save block will cause a softlock.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SushiePeachWarp1.gif" alt="Sushie Peach Warp"></img>
        <img className="image" src="https://s8.gifyu.com/images/SushiePeachWarp2.gif" alt="Sushie Peach Warp"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk towards the loading zone on the right, then open the partner menu. Press A to clear the save block text
            while on the equipped partner. When the partner menu returns, move down to Sushie's name. Use Bombette's
            ability, then quickly spin/tornado jump, which will also switch to Sushie. Hold right to take the loading
            zone.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SushiePeachWarp3.gif" alt="Sushie Peach Warp"></img>

        <dl className="list">
          <dt className="detail-title">
            Head right through the volcano until reaching the end of the room with the spike ball, then take the loading
            zone to the room behind Lava Piranha.
          </dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            If you are NOT on the Japanese version, hold left in the room where the spike ball chases Mario.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SushiePeachWarp4.gif" alt="Sushie Peach Warp"></img>

        <dl className="list">
          <dt className="detail-title">
            In the last room of the volcano, go to the front of the stairs, then swim upwards, dive, hold right and
            continue diving when Mario is visible on the stairs until you reach the top and the cutscene begins.
          </dt>
          <dd>Failing to start the cutscene before the lava rises all the way usually results in a softlock.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/SushiePeachWarp5.gif" alt="Sushie Peach Warp"></img>
      </>
    ),
  },
  {
    name: "Toad Town Sushie Glitch",
    path: "Toad_Town_Sushie_Glitch",
    description: (
      <>
        Toad Town Sushie Glitch is a setup that allows the player to get <Link to="/Sushie_Glitch">Sushie Glitch</Link>{" "}
        in Toad Town. By setting Mario's internal angle (the direction he is facing) towards the down-left edge of the
        Sushie panel, but having Mario's position be closest to the up-left edge of the Sushie panel, we can trick the
        game into placing Sushie at the wrong side of the dock, causing this glitch to occur.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    discoveredBy: "MrCheeze",
    videoTutorials: [
      {
        author: "MrCheeze",
        url: "https://www.youtube.com/watch?v=sL_bXF4XWCc",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Toad Town Sushie Glitch</h1>

        <dl className="list">
          <dt className="detail-title">
            From the lower side of the Sushie panel, hold left into the corner until Mario stops moving. Then, tap down.
          </dt>
          <dt className="detail-title">Walk right, then turn left at the position shown below.</dt>
          <dd>Holding down for too much can mess up your position and is the easiest way to fail this trick.</dd>
          <dd>I recommend using Mario's hat against the fence as a visual for the position.</dd>
        </dl>
        <img className="image" src="https://s9.gifyu.com/images/TTSushieGlitch1.gif" alt="TT Sushie Glitch"></img>

        <dl className="list">
          <dt className="detail-title">
            Spin, hold down-left, then hammer and release the stick. Then, use Sushie's ability. If nothing happens, do
            small tap jumps left until it works.
          </dt>
          <dd>
            The spin and hammer are used to set Mario's internal angle without changing his position. Be sure to never
            move the stick while Mario is grounded after setting this angle!
          </dd>
          <dd>If you ended up in the water on the top side, you probably did not properly set your internal angle.</dd>
          <dd>
            If you ended up in the water on the bottom side, you either held down for too long, or you were too far to
            the left when you used Sushie's ability.
          </dd>
        </dl>
        <img className="image" src="https://s3.gifyu.com/images/TTSushieGlitch2.gif" alt="TT Sushie Glitch"></img>
      </>
    ),
  },
  {
    name: "Ultra Hammer Early",
    path: "Ultra_Hammer_Early",
    description: (
      <>
        Ultra Hammer Early is an <Link to="/Out_Of_Bounds">out of bounds</Link> glitch that allows the player to reach
        the Ultra Hammer chest without moving the blue block. It saves about 10 seconds over doing the puzzle normally
        and is very difficult to pull off quickly. As such, it is only attempted by a few top runners.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    videoTutorials: [
      {
        author: "mx_four",
        url: "https://youtu.be/hTQJR6rb8TY",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Ultra Hammer Early</h1>

        <p className="normal-text">
          Line up as far left as possible while staying on the top step, then jump and move up-left, then immediately
          rotate to an angle between up and up-right. When Mario falls, hold left to land in front of the chest.
        </p>

        <img className="image" src="https://s7.gifyu.com/images/UltraHammerEarly.gif" alt="Ultra Hammer Early"></img>
      </>
    ),
  },
  {
    name: "Ultra Hammer Skip",
    path: "Ultra_Hammer_Skip",
    description: (
      <>
        Ultra Hammer Skip is a trick that allows the player to continue through Chapter 5 without getting the Ultra
        Hammer.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/Xu_VedhBY3s",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Ultra Hammer Skip (New Method)</h1>

        <dl className="list">
          <dt className="detail-title">
            Do <Link to="/Volcano_Jump">Volcano Jump</Link> or use Lakilester to clip into the out of bounds area to the
            left of the spring.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/UltraHammerSkip1.gif" alt="Ultra Hammer Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Slowly walk right until Mario falls, then immediately switch to up-right and return to neutral when Mario
            flies off screen.
          </dt>
        </dl>
        <img className="image" src="https://s5.gifyu.com/images/UltraHammerSkipNew1.gif" alt="Ultra Hammer Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Walk left until Mario falls onto the lower area, then move right until Mario's nose touches the wall.
          </dt>
        </dl>
        <img className="image" src="https://s5.gifyu.com/images/UltraHammerSkipNew2.gif" alt="Ultra Hammer Skip"></img>

        <dl className="list">
          <dt className="detail-title">Jump, hold right, then hold down-right.</dt>
        </dl>
        <img className="image" src="https://s4.gifyu.com/images/UltraHammerSkipNew3.gif" alt="Ultra Hammer Skip"></img>
        <br />

        <h1 className="heading">Old Method</h1>

        <dl className="list">
          <dt className="detail-title">
            Move right against the right wall until Mario's boots are visible, then keep tapping slight right while
            watching Mario's shadow on the small wall in front of him. Stop moving when the shadow grows the second
            time. Fall to the left, then go neutral.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/UltraHammerSkip2.gif" alt="Ultra Hammer Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            Jump, tap left, then hold right. Spin right along the bottom seam, then hold down-right when Mario falls.
          </dt>
        </dl>
        <img className="image" src="https://s1.gifyu.com/images/UltraHammerSkip3.gif" alt="Ultra Hammer Skip"></img>
      </>
    ),
  },
  {
    name: "Volcano Jump",
    path: "Volcano_Jump",
    description: (
      <>
        Volcano Jump is an <Link to="/Out_Of_Bounds">out of bounds</Link> glitch in the large room with the springs in
        Mt. Lavalava that allows the player to jump down to the Loading Zone that leads to the Ultra Hammer from the top
        of the room, skipping having to navigate across the room to get there. Volcano Jump saves a few seconds over
        moving through the room normally.{" "}
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "Starry_Arie",
        url: "https://youtu.be/Xu_VedhBY3s",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Volcano Jump</h1>

        <dl className="list">
          <dt className="detail-title">
            Move to the position shown below, then run and immediately jump down, switch to down-left, then roll back
            towards down.
          </dt>
        </dl>
        <img className="image" src="https://s7.gifyu.com/images/EpicSkip5_0.gif" alt="Volcano Jump"></img>
      </>
    ),
  },
  {
    name: "Water Staircase Skip",
    path: "Water_Staircase_Skip",
    description: (
      <>
        Water Staircase Skip is a trick that skips all of the Fortress Keys in Koopa Bros Fortress by using a{" "}
        <Link to="/Laki_Teleport">Laki Teleport</Link> to get <Link to="/Out_Of_Bounds">out of bounds</Link> and reach
        the cannon room.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    discoveredBy: "NaterTater",
    content: (
      <>
        <h1 className="heading">How To Perform Water Staircase Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Do a <Link to="/Laki_Teleport">Laki Teleport</Link> at the location shown below, then drop to the second
            step (counting left to right), fall up-right, then switch to down-right.
          </dt>
        </dl>
        <img
          className="image"
          src="https://s7.gifyu.com/images/WaterStaircaseSkip.gif"
          alt="Water Staircase Skip"
        ></img>
      </>
    ),
  },
  {
    name: "Whalebreak",
    path: "Whalebreak",
    description: (
      <>
        Whalebreak is a variation of <Link to="/Early_Whale">Early Whale</Link> that takes advantage of buffered spins
        and Jr. Troopa's bomb textbox to gain control of Mario at the peak of a tornado jump, allowing us to clear the
        invisible wall and enter the Whale. Because Jr. Troopa must be defeated in order to do this, this is only used
        in <Link to="/All_Bosses">All Bosses</Link>.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Whalebreak</h1>

        <dl className="list">
          <dt className="detail-title">
            Line up to the as shown below, then do the following inputs: C-Down, spin right, press Z and immediately
            begin holding C-Down. While holding C-Down, do a tornado jump such that Mario is at the maximum height when
            Bombette explodes, then release C-Down, hold B, hold right, and jump over the wall.
          </dt>
          <dd>
            The buffered spin is very important to getting this trick. If Mario did not spin in midair, you did not
            buffer the spin properly.
          </dd>
        </dl>
        <img className="image" src="https://s9.gifyu.com/images/Whalebreak.gif" alt="Whalebreak"></img>
      </>
    ),
  },
  {
    name: "Yakkey Trickshot",
    path: "Yakkey_Trickshot",
    description: (
      <>
        Yakkey Trickshot is a cutscene overlap in Tubba Blubba's Castle that occurs when you open the chest with the
        Mystical Key while also starting the cutscene with Tubba Blubba entering the room.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
    ],
    videoTutorials: [
      {
        author: "realblopez",
        url: "https://youtu.be/DSokAk89kHI",
      },
    ],
    content: (
      <>
        <dl className="list">
          <dt className="detail-title">
            Line up in the location shown below, then buffer up and jump, pressing A again on the last frame before
            touching the ground.
          </dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">Hold B to clear the text, then answer yes and wait.</dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">
            When Tubba starts talking, press B twice so that the text box with ~~ in the middle is the open text box.
          </dt>
          <dd>If you scroll too far, you can use Z to go back up to this text box.</dd>
        </dl>

        <dl className="list">
          <dt className="detail-title">When Tubba bounces on the bed, press B once.</dt>
        </dl>

        <dl className="list">
          <dt className="detail-title">When the camera zooms in on Mario, you can hold B to clear the text boxes.</dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/YakkeyTrickshot.gif" alt="Yakkey Trickshot"></img>
      </>
    ),
  },
  {
    name: "Yellow Berry Skip",
    path: "Yellow_Berry_Skip",
    description: (
      <>
        Yellow Berry Skip is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> trick that allows the player
        to get past the southeast gate in Flower Fields without feeding a yellow berry to the gatekeeper. It saves 12
        seconds over getting the Yellow Berry. Due to the high difficulty of the trick and the small amount of time it
        saves, it is only attempted by top runners. There is a much faster and easier method that can be done if you
        have Lakilester.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "No Major Sequence Breaks",
        path: "/No_Major_Sequence_Breaks",
      },
    ],
    content: (
      <>
        <h1 className="heading">How To Perform Yellow Berry Skip</h1>

        <dl className="list">
          <dt className="detail-title">
            Perform LZS Jumps to the location shown below. During the room transition, hold up-right.
          </dt>
          <dd>
            The location you want to be in is the upper left of the east loading zone, slightly inside but not close
            enough to take it.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/YellowBerrySkip1.gif" alt="Yellow Berry Skip"></img>

        <dl className="list">
          <dt className="detail-title">
            When Mario flies off screen, return to neutral. Then, walk right, jump at the corner of the fence and switch
            to up to get in bounds.
          </dt>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/YellowBerrySkip2.gif" alt="Yellow Berry Skip"></img>

        <h1 className="heading">How To Perform Yellow Berry Skip with Lakilester</h1>

        <dl className="list">
          <dt className="detail-title">
            Get on Lakilester, then hold right into the Yellow Flower. Slowly roll the control stick down until the
            grass is in front of Mario, then press C-Down and release the control stick.
          </dt>
        </dl>
        <img className="image" src="https://s3.gifyu.com/images/YBSLakiMethod.gif" alt="Yellow Berry Skip"></img>
      </>
    ),
  },
];
