import { Link } from "react-router-dom";

export const allCardsBeginner = (
  <>
    <h1 className="heading">Prologue</h1>

    <dl className="list">
      <dt className="route-instruction">
        - Do <Link to="/Quick_Jumps">Quick Jumps</Link> on Bowser by pressing A when Mario squats before jumping
      </dt>
      <dt className="route-instruction">- Talk to Goompa on the balcony</dt>
      <dt className="route-instruction">- Talk to Goompapa by the gate</dt>
      <dt className="route-instruction">- Head to the balcony</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 3 bush coins from 3 different bushes before the hammer. [3 coins]</dt>
      <dt className="route-instruction">
        - Hammer from the leftmost bush (get it from the front of the bush, it's faster)
      </dt>
      <dt className="route-instruction-bold">- 10 coins from the brick block on the ground. [13 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Defeat Jr. Troopa by spamming Jump. (Don't forget your <Link to="/Quick_Jumps">Quick Jumps</Link>!)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Fire Flower and 4 trampoline coins. [17 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Power Jump Tutorial</dt>
      <dt className="route-instruction-bold">- Equip Power Jump immediately</dt>
      <dt className="route-instruction-bold">- Two coin blocks on the next screen. [19 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump Red / Headbonk Red</dd>
      <dd className="route-instruction-bold">T2: Power Jump Blue / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba King</dt>
      <dd className="route-instruction-bold">T1: Power Jump King / Headbonk Tree</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Headbonk</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the shop in Toad Town</dt>
      <dt className="route-instruction-bold">- Buy a Fire Flower and a Fright Jar (Fire Flower first) [4 coins]</dt>
      <dt className="route-instruction">- Go to Shooting Star Summit</dt>
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Action Command Tutorial</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Magikoopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Do Nothing - (Blocking saves a few frames!)</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Black_Toad_Skip">Black Toad Skip</Link> or <Link to="/Merlon_Skip">Merlon Skip</Link>
      </dt>
      <dt className="route-instruction">
        - Alternatively, go speak to the black toads, then go speak to Merlon, then go speak to the black toads again
      </dt>
    </dl>

    <h1 className="heading">Chapter 1</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 1 coin block [5 coins]</dt>
      <dt className="route-instruction-bold">- Fright Jar</dt>
      <dt className="route-instruction-bold">- POW Block</dt>
      <dt className="route-instruction-bold">- 10 coin block [15 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Koopa Village and go to the 5th house</dt>
      <dt className="route-instruction">
        - It helps to look at the fuzzies and count the amount of bounces that they do to ensure you never fail the
        minigame.{" "}
      </dt>
      <dd className="route-instruction">
        If they bounce an even number of times, it's guaranteed to not be in the tree to the left or right of its
        original position.{" "}
      </dd>
      <dd className="route-instruction">
        If they bounce and odd number of times, it's guaranteed to be to the left or right of its original position,
        unless it bounces from the far left to the far right or vise versa
      </dd>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: Fire Flower (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Get a coin from the bush left of the Save Block while leaving Koopa Village. [16 coins]
      </dt>
      <dt className="route-instruction">- Head towards the Fortress</dt>
      <dt className="route-instruction-bold">- 5 coins on ground [21 coins]</dt>
      <dt className="route-instruction-bold">- Thunder Bolt</dt>
      <dt className="route-instruction-bold">- Hidden Fire Flower</dt>
      <img className="image" src="https://mario.wiki.gallery/images/a/a8/PMHiddenBlock2.png" alt="Fire Flower"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Koopa with a jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Key Fight</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">
        T1: Power Jump / Untimed Shell Toss (Use untimed Power Shell instead if you missed the first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Bounce</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Staircase_Skip">Staircase Skip</Link>
      </dt>
      <dt className="route-instruction">- Fall for the trap</dt>
      <dt className="route-instruction">- Blow up the wall</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jail Fight</dt>
      <dd className="route-instruction-bold">T1: Fright Jar (Up 1) / Bomb the Koopa or Untimed Body Slam the Bob-omb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 9 coins from Jail Fight [30 coins]</dt>
      <dt className="route-instruction">- Head left into the Pit</dt>
      <dt className="route-instruction-bold">- First strike the Paratroopa</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 1</dt>
      <dd className="route-instruction-bold">T0: First Strike</dd>
      <dd className="route-instruction-bold">T1: Bomb / Power Jump</dd>
      <dd className="route-instruction-bold">T2: Jump x1</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 1 (BACKUP: Missed First Strike)</dt>
      <dd className="route-instruction-bold">T1: Bomb / Jump x2</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 2</dt>
      <dd className="route-instruction-bold">T1: Fright Jar / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First strike the 3rd Koopa with Bomb</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3</dt>
      <dd className="route-instruction-bold">T0: First Strike</dd>
      <dd className="route-instruction-bold">T1: Power Jump / Untimed Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Pit Fight 3 (BACKUP: Missed First Strike)</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Untimed Body Slam</dd>
      <dd className="route-instruction-bold">T2: Jump x2 / Body Slam</dd>
      <dd className="route-instruction-bold">T3: Jump x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 2nd fire bar coins [40 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go upstairs and left to get the key behind the cracked wall</dt>
      <dt className="route-instruction">- Go right and upstairs, then head left to the locked door</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Swap to Kooper (Up 1) while the lock is falling off the door</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Pie_Jumps">Pie Jumps</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Jump over the Bullet Bills</dt>
      <dt className="route-instruction-bold">- If you have less than 3 FP, hit the heart block before the Cannons</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike the Cannons with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Cannons</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Thunder Bolt / Bomb (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 10 cannon coins [50 coins]</dt>
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Do not use the second Bomb if you miss an action command. Instead, Do Nothing with Bombette and use Hammer
        until the fake Bowser is destroyed
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T3: POW Block [Down 1] / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Fire Flower [Mash A]</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Yes to all 3 questions</dt>
    </dl>

    <h1 className="heading">Parakarry</h1>

    <dl className="list">
      <dt className="route-instruction">- Say No to both tutorials</dt>
      <dt className="route-instruction">
        - If you don't have 50 coins, get <Link to="/Backup_Coins">backup coins</Link>.
      </dt>
      <dt className="route-instruction-bold">- Honey Syrup</dt>
      <dt className="route-instruction">- Say Yes to Jr Troopa</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr Troopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Bomb</dd>
      <dd className="route-instruction-bold">T3: Hammer</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy Speedy Spin</dt>
      <dt className="route-instruction">
        - If Speedy Spin is not at the shop, go down 1 room, then left 1 room, then return to the shop
      </dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 1st Magical Seed</dt>
      <dt className="route-instruction">- Take Train to Chapter 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Find 3 Letters and bring them to Parakarry</dt>
      <dt className="route-instruction-bold">- Get 2 Whacka Bumps as you pass by him</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Parakarry</dt>
      <dt className="route-instruction-bold">- Head towards Dry Dry Desert</dt>
      <dt className="route-instruction">
        - If you got 2 Fright Jar fails and plan to do <Link to="/Chapter_4_Card_LZS">Chapter 4 Card LZS</Link> OR you
        had 4 Fright Jar fails and aren't doing <Link to="/Chapter_4_Card_LZS">Chapter 4 Card LZS</Link>, get a 3rd
        Whacka Bump
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Cross the bridge (answer 2nd option) or do <Link to="/Buzzar_Skip">Buzzar Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Enter the desert</dt>
      <dt className="route-instruction-bold">- Immediately leave and head back to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Use Parakarry to get the Magical Seed on the way back</dt>
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction">- Take the train to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        (Entering the desert may look like it does nothing, but it activates an important story trigger that will let us
        enter the Ruins in an already completed state later. Do not skip this!)
      </dt>
    </dl>

    <h1 className="heading">Sushie</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Blue_House_Skip">Blue House Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce / Sky Dive</dd>
      <dd className="route-instruction-bold">
        T3: Keep attacking until Blooper is dead (Use Refresh if you are going to die)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go talk to Kolorado by the volcano</dt>
      <dt className="route-instruction-bold">- Jammin' Jelly behind the tree</dt>
      <dt className="route-instruction-bold">- Get Sushie</dt>
    </dl>

    <h1 className="heading">Bow</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Power Quake</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Quake (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Blue pipe to sewers, go left to Dark Koopas</dt>
      <dt className="route-instruction-bold">- Let them all First Strike you for peril</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x2</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If you get an FP drop, do the 8 FP fight</dt>
      <dt className="route-instruction">- If you get a POW Block, do the POW Block fight</dt>
      <dt className="route-instruction">- If you get neither, eat the Honey Syrup (Up 1) and do the 8 FP fight</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x3 (8 FP)</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x3 (POW Block)</dt>
      <dd className="route-instruction-bold">T0: Don't block</dd>
      <dd className="route-instruction-bold">T1: POW Block / Swap to Kooper (Down 2)</dd>
      <dd className="route-instruction-bold">T2: Power Quake / Do Nothing</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Dark Koopa x4</dt>
      <dd className="route-instruction-bold">T0: Don't block (peril)</dd>
      <dd className="route-instruction-bold">T1: Power Quake / Swap to Bombette (Up 2 if Sushie, Down 1 if Kooper)</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Pick up 2 FP drops if possible</dt>
      <dt className="route-instruction">- Take pipe to Boo's Mansion</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Do <Link to="/Record_Skip">Record Skip</Link> or do the Record minigame and use it to get the Weight
      </dt>
      <dt className="route-instruction">- Place the Weight</dt>
      <dt className="route-instruction">- Blow up the wall</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1) while the Super Boots chest opens</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Get Boo Portrait</dt>
      <dt className="route-instruction-bold">- Get Bow</dt>
    </dl>

    <h1 className="heading">Chapter 3</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Stanley_Save">Stanley Save</Link>
      </dt>
      <dt className="route-instruction-bold">- Repel Gel</dt>
      <dt className="route-instruction-bold">- Enter Tubba's Mansion</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go left for the Key</dt>
      <dt className="route-instruction-bold">- Upgrade Parakarry (Up/Down 3)</dt>
      <dt className="route-instruction">- Get caught</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right</dt>
      <dt className="route-instruction">- Get Key in spike room</dt>
      <dt className="route-instruction-bold">- Mega Rush</dt>
      <dt className="route-instruction-bold">- If you used your Honey Syrup, get the Maple Syrup</dt>
      <dt className="route-instruction">- Get Key in Clubba room</dt>
      <dt className="route-instruction-bold">
        - Eat the Honey Syrup (Up 1) or the Maple Syrup (Mash A) while unlocking the door
      </dt>
      <dt className="route-instruction">- Get Yakkey</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Quick_Escape">Quick Escape</Link>
      </dt>
      <dt className="route-instruction">- Make sure you have 9 FP for the Heart</dt>
      <dt className="route-instruction">- Head to the windmill</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Right before the fight:</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Unequip Speedy Spin (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Mega Rush (Up 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Up 2)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tubba's Heart</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x6 / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x4 (x5 and x5 works too) / Shell Shot or Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Tubba Blubba</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say Yes to Bow</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Quake (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Chapter_3_Card_LZS">Chapter 3 Card LZS</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Answer 1, 3, 1</dt>
    </dl>

    <h1 className="heading">Chapter 2</h1>

    <dl className="list">
      <dt className="route-instruction">- Head to Toad Town through the Blue Pipe</dt>
      <dt className="route-instruction">- Take the train</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Cross the bridge (answer 2nd option) or do <Link to="/Buzzar_Skip">Buzzar Skip</Link>
      </dt>
      <dt className="route-instruction">- Enter the desert</dt>
      <dt className="route-instruction-bold">- Up 2, get the hidden Thunder Rage</dt>
      <img className="image" src="https://mario.wiki.gallery/images/8/88/PMHiddenBlock3.png" alt="Thunder Rage"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Right, Up, Right and enter Ruins</dt>
      <dt className="route-instruction-bold">- Get Key in sand (bottom)</dt>
      <dt className="route-instruction-bold">- Go right for second Key</dt>
      <dt className="route-instruction">- Drop down, go left and open both doors</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Super Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Fall down and blow up the wall, then backtrack to the Pyramid Stone</dt>
      <dt className="route-instruction">- First Strike with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Pyramid (Block 0/1)</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Do Nothing / Do Nothing - don't block!</dd>
      <dd className="route-instruction-bold">T2: Bomb</dd>
      <dt className="fight">If you missed the first strike:</dt>
      <dd className="route-instruction-bold">T1: Swap to Parakarry / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Don't get any HP drops</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go back towards the Super Hammer, then drop down and go right</dt>
      <dt className="route-instruction">- Fall down and get the Ruins Key</dt>
      <dt className="route-instruction">- Go left</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Diamond (Block 0/2)</dt>
      <dd className="route-instruction-bold">T1: Do Nothing / Do Nothing - get peril</dd>
      <dd className="route-instruction-bold">T2: Untimed Power Quake</dd>
      <dt className="fight">If you don't have 7 HP:</dt>
      <dd className="route-instruction-bold">T1: Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Upgrade Bombette (Down 2)</dt>
      <dt className="route-instruction">- Take spring, head right to unlock door</dt>
      <dt className="route-instruction-bold">- Pyramid Stone in first pillar, Diamond Stone in middle pillar</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Stone Chomps: Lunar</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake (Power Bomb if not in peril)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Lunar Stone in right pillar</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you don't have 1 HP and at least 3 FP, hit the Heart Block, unequip Power Bounce and equip Power Jump, then
        swap to Parakarry
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Tutankoopa</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x4 / Swap to Parakarry</dd>
      <dd className="route-instruction-bold">T2: Jump x2 / Sky Dive</dd>
      <dt className="fight">If you don't have 7 HP:</dt>
      <dd className="route-instruction-bold">T1: Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If you hit the Heart Block, unequip Power Jump and equip Power Bounce</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Chapter_2_Card_LZS">Chapter 2 Card LZS</Link>, then hit the Heart Block and leave the
        Ruins
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Enter bottom left room and sneak through the library</dt>
    </dl>

    <h1 className="heading">Chapter 5</h1>

    <dl className="list">
      <dt className="route-instruction">- Alternate down and left to reach Mt. Rugged</dt>
      <dt className="route-instruction">- Take the train</dt>
      <dt className="route-instruction">- Enter Sewers through the green pipe</dt>
      <dt className="route-instruction">- Break the panel and swim to the Chapter 5 Blue Pipe</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Raphael_Skip">Raphael Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Fire Shield</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Ultra Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Get Peril from Fire Bars by spamming c-down</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Flarakarry">Flarakarry</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Sushie (Down 2) while talking to Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- You will lose a Life Shroom on turn 1</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lava Piranha - Block 0/3</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 / Belly Flop</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x5 / Belly Flop</dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T1: Squirt / Power Bounce x4</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x3</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Quiz: 1, 1, 1, 1, 1, 1, 1, 1, 1, 2</dt>
    </dl>

    <h1 className="heading">Chapter 4</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Volcano Vase from the chest in the jungle</dt>
      <dt className="route-instruction-bold">- Magical Seed from Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Sushie (Up/Down 3)</dt>
      <dt className="route-instruction">- Exit sewers with Sushie</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Up 2)</dt>
      <dt className="route-instruction">- Enter Toy Box</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Right switch to Green Station (Down 1)</dt>
      <dt className="route-instruction-bold">- Right switch to Pink Station (Down 2)</dt>
      <dt className="route-instruction">- Fix tracks</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right in Pink Station</dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Pink_Station_Clips">Pink Station Clips</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">
        Fuzzy Fight (Ignore this if you didn't do <Link to="/Chapter_3_Card_LZS">Chapter 3 Card LZS</Link>)
      </dt>
      <dd className="route-instruction-bold">T1: Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Frying Pan</dt>
      <dt className="route-instruction-bold">- Left switch to Red Station (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hidden Volt Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/6/6a/PMHiddenBlock23.png" alt="Volt Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="fight">Big Lantern Ghost - Block 1/2</dt>
      <dd className="route-instruction-bold">T1: Volt Shroom (Mash A) / Do Nothing </dd>
      <dd className="route-instruction-bold">T2: Do Nothing / Swap to Goombario (Up 3)</dd>
      <dd className="route-instruction-bold">T3: Headbonk Lantern x2 / Power Bounce Big Lantern Ghost x5</dd>
      <dd className="route-instruction-bold">T4: Headbonk Lantern x2 / Power Bounce Big Lantern Ghost x4</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Upgrade Watt (Up 2)</dt>
      <dt className="route-instruction">- DO NOT hit the Heart Block</dt>
      <dt className="route-instruction">- Fight General Guy</dt>
    </dl>
    <dl className="list">
      <dt className="fight">General Guy</dt>
      <dt className="fight">Phase 1:</dt>
      <dd className="route-instruction-bold">T1: Jump x2 / Electro Dash</dd>
      <dd className="route-instruction-bold">T2: Power Quake</dd>
      <dd className="route-instruction-bold">T3: Jump x2 / Power Shock</dd>
      <dd className="route-instruction-bold">T4: Jump x2</dd>
      <dt className="fight">Phase 2:</dt>
      <dd className="route-instruction-bold">T1: Repel Gel (Down 1) / Electro Dash</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x4 / Electro Dash</dd>
      <dd className="route-instruction-bold">T3: Jump x2 / Electro Dash </dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Chapter_4_Card_LZS">Chapter 4 Card LZS</Link>, then hit the Heart Block, then hit the
        left switch to Blue Station (Down 2) and leave the Toy Box
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Lower right room, then head downstairs and take the door to the right of the stairs
      </dt>
      <dt className="route-instruction">- Sugar (2nd from the left), Eggs, talk to Twink</dt>
      <dt className="route-instruction">
        - Flour (far left), Butter (far right, not including water and cleanser), talk to Twink
      </dt>
      <dt className="route-instruction">- Bake for at least 23 seconds from when you can hear the ticking</dt>
      <dt className="route-instruction">
        - Cream (2nd from the right, not including water and cleanser), Strawberries (3rd from right), talk to Twink
      </dt>
      <dt className="route-instruction">- Take the Cake and leave</dt>
    </dl>

    <h1 className="heading">Chapter 6</h1>

    <dl className="list">
      <dt className="route-instruction">Forever Forest</dt>
      <dd className="route-instruction">Right x2</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction-bold">Go left and get the 4th Magical Seed</dd>
      <dd className="route-instruction">Right x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Quick Change</dt>
      <dt className="route-instruction">- Place Seeds</dt>
      <dt className="route-instruction">- Go right and talk to Petunia</dt>
      <dt className="route-instruction-bold">- Mole order: Bottom right, then counterclockwise</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Right Mole</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Top Right Mole - Block 0/3</dt>
      <dd className="route-instruction-bold">T1: Do Nothing / Power Bomb</dd>
      <dd className="route-instruction-bold">T2: Untimed Power Quake</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Top Left Mole</dt>
      <dd className="route-instruction-bold">T1: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Red Berry from tree</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Left Mole</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 3)</dd>
      <dd className="route-instruction-bold">Equip Quick Change (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat a Whacka Bump (Up 1)</dt>
      <dt className="route-instruction">- Talk to Petunia</dt>
      <dt className="route-instruction-bold">- Go down-left, get Yellow Berry</dt>
      <dt className="route-instruction">- Go down-right, give Yellow Berry to Yellow Flower</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction-bold">- Get Blue Berry on the way back to the hub</dt>
      <dt className="route-instruction">- Go left, give Blue Berry to Blue Flower</dt>
      <dt className="route-instruction">- Hit the hidden block to the right of the spring on the way</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lakitu Fight</dt>
      <dd className="route-instruction-bold">T1: Shell Shot Lakitu / Star Storm</dd>
      <dd className="route-instruction-bold">T2: Thunder Rage if Spiny Spawn, otherwise Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP (RNG-Dependent)</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- If you didn't level up, eat a Whacka Bump (Up 1)</dt>
      <dt className="route-instruction">- Talk to Rosie</dt>
      <dt className="route-instruction">- Get the hidden Shooting Star by taking the spring</dt>
      <img
        className="image"
        src="https://mario.wiki.gallery/images/1/1e/PMHiddenBlock35.png"
        alt="Shooting Star"
      ></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Talk to Posie (down-left)</dt>
      <dt className="route-instruction">- Talk to Rosie (left again)</dt>
      <dt className="route-instruction-bold">- Dizzy Dial in the left block in the Bubble Berry room</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction">- Place the Water Stone</dt>
      <dt className="route-instruction-bold">- Bubble Berry (you can get and eat the second one if you took damage)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go up-left, give the Bubble Berry to the Bubble Plant</dt>
      <dt className="route-instruction">
        - If you get first struck, leave and get 2 Bubble Berries. Eat one and use the other to try again
      </dt>
      <dt className="route-instruction-bold">- Shooting Star</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Spike Fight - Block 3/3</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Swap to Parakarry (Down 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP (RNG-Dependent)</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go up-right</dt>
      <dt className="route-instruction-bold">- Hidden Thunder Rage if you had to use it on the Maze Fight</dt>
      <img className="image" src="https://mario.wiki.gallery/images/5/51/PMHiddenBlock36.png" alt="Thunder Rage"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Dizzy Dial works 100% on Lakitus, and the Magikoopa can't kill you</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Machine Fight</dt>
      <dd className="route-instruction-bold">T1: Dizzy Dial / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Thunder Rage / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- If you don't have full stats, eat a Whacka Bump while planting (Up 1)</dt>
      <dt className="route-instruction-bold">- S. Jump Charge</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Quake (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Huff N' Puff - Block 1/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Swap to Bow (Up 3) / Outta Sight</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Swap to Parakarry (Up 1) / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Transform into a Koopatrol, talk to the guard at the top, go down and transform into a Clubba, and then head
        back to the top
      </dt>
    </dl>

    <h1 className="heading">Chapter 7</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Go to sewers through the green pipe and go get the Ultra Boots</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/c/c1/PMHiddenBlock12.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Go to the Volcano and <Link to="/Lakilester_Peach_Warp">Peach Warp</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go through the bottom door and get the Blue Key</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Mirror_Clip">Mirror Clip</Link>
      </dt>
      <dt className="route-instruction">- Go through the top door and break the panel</dt>
      <dt className="route-instruction">- Hit Red button</dt>
      <dt className="route-instruction">- Go through the blue door and head to the front</dt>
      <dt className="route-instruction-bold">- Fall down and get the Red Key (4th Bombette is real)</dt>
      <dt className="route-instruction">- Hit Blue button and go right</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 1</dt>
      <dd className="route-instruction-bold">T1: Jump x2 Clubba / Bomb (Magikoopa will run away)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 2</dt>
      <dd className="route-instruction-bold">T1: Shooting Star / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-hp">- Level Up: HP</dt>
      <dt className="route-instruction-bold">15 HP | 20 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 3</dt>
      <dd className="route-instruction-bold">T1: Shooting Star / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Use Bombette to hit the switch and take the revolving door to the foreground
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Kooper_Puzzle_Skip">Kooper Puzzle Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right, Kooper the switch, head to the background</dt>
      <dt className="route-instruction">- Push the statue, then fall and go right</dt>
      <dt className="route-instruction-bold">- P-Up D-Down</dt>
      <dt className="route-instruction-bold">- Hidden Jammin' Jelly</dt>
      <img
        className="image"
        src="https://mario.wiki.gallery/images/5/5a/PMHiddenBlock39.png"
        alt="Jammin' Jelly"
      ></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Head back to the front and fall down</dt>
      <dt className="route-instruction-bold">- Palace Key</dt>
      <dt className="route-instruction-bold">- Swap to Watt (Down 4) while opening the lock</dt>
      <dt className="route-instruction-bold">- Hit Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Crystal King - Block 2/4</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Untimed Electro Dash a Crystal Bit</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Electro Dash</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Untimed Electro Dash a Crystal Bit</dd>
      <dd className="route-instruction-bold">T4: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x5 / Electro Dash</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP (RNG-Dependent)</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>

    <h1 className="heading">Chapter 8</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Repel Gel behind the tree</dt>
      <dt className="route-instruction-bold">- Upgrade Sushie (Up 2) in the sewers</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Do a different fight depending on if you leveled up on Crystal King (You have 25 FP)
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols (Leveled Up)</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols (Not Leveled Up)</dt>
      <dd className="route-instruction-bold">T1: Untimed Power Quake / Swap to Bombette (Up/Down 4) / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 12 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Get the key, unlock the locked door, and go talk to the Bowser Door</dt>
      <dt className="route-instruction-bold">- Jail Life Shroom</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Block_Clip">Block Clip</Link>
      </dt>
      <dt className="route-instruction">- Return to the Bowser Door</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Fast_Flood_Room">Fast Flood Room</Link>
      </dt>
      <dt className="route-instruction-bold">
        - <Link to="/Cannonless">Cannonless</Link>
      </dt>
      <dt className="route-instruction">
        - Push 3rd block, 1st block, then 2nd block to get the key. Return to the second block room and use the key
      </dt>
      <dt className="route-instruction">- Quiz: 1, 1, 2, 1, 1, 3, 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the top floor, hit the blue switch with Kooper and get the key</dt>
      <dt className="route-instruction">- Maze: Up, Down, Down, Up, Down, Up</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block if you don't have full stats</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away (Up 2) / Air Lift as needed</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - If you had to Air Lift twice or took damage, go back and hit the Heart Block
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 2/2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Swap to Bow (Down 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Outta Sight</dd>
      <dd className="route-instruction-bold">T5: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Jammin' Jelly</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Up 1)</dd>
      <dd className="route-instruction-bold">Unequip Power Quake (Down 3)</dd>
      <dd className="route-instruction-bold">Equip P-Up D-Down (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser</dt>
      <dd className="route-instruction-bold">T1: Power Bounce x5 (Up 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x5</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Outta Sight</dd>
      <dd className="route-instruction-bold">T4: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Rebadge right before Final Bowser</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Speedy Spin (R)</dd>
      <dd className="route-instruction-bold">Equip Fire Shield (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Always go for the highest bounce possible</dt>
      <dt className="route-instruction">- If your bounce caps are good, you will win earlier than the route shows</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 1</dt>
      <dd className="route-instruction-bold">T1: Power Bounce (Up 1) / Outta Sight</dd>
      <dd className="route-instruction-bold">T2: Power Bounce</dd>
      <dd className="route-instruction-bold">T3: Star Beam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Follow the beamless route, then switch to your fight when Bowser uses Star Shield
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Beamless</dt>
      <dd className="route-instruction-bold">T1: Peach Beam / Water Block - Block</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot - Block</dd>
      <dd className="route-instruction-bold">T3: Power Bounce / Outta Sight</dd>
      <dd className="route-instruction-bold">T4: Repel Gel</dd>
      <dd className="route-instruction-bold">T5: Turbo Charge / Power Bounce</dd>
      <dd className="route-instruction-bold">T6: Power Bounce / Electro Dash - Life Shroom</dd>
      <dd className="route-instruction-bold">T7: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3</dt>
      <dd className="route-instruction-bold">T4: Repel Gel</dd>
      <dd className="route-instruction-bold">T5: Peach Beam / Turbo Charge</dd>
      <dd className="route-instruction-bold">T6: Power Bounce / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 7</dt>
      <dd className="route-instruction-bold">T8: Peach Beam / Electro Dash - Life Shroom</dd>
      <dd className="route-instruction-bold">T9: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4</dt>
      <dd className="route-instruction-bold">T5: Peach Beam / Turbo Charge</dd>
      <dd className="route-instruction-bold">T6: Power Bounce / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 5</dt>
      <dd className="route-instruction-bold">T6: Peach Beam / Outta Sight</dd>
      <dd className="route-instruction-bold">T7: Power Bounce - Life Shroom</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 6</dt>
      <dd className="route-instruction-bold">T7: Peach Beam / Electro Dash</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (R)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - <Link to="/Luigi_Skip">Luigi Skip</Link>
      </dt>
    </dl>
  </>
);
