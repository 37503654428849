import { Link } from "react-router-dom";
import { useEffect } from "react";
import { PageProps } from "../types/PageProps";

export default function Resources({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Resources");
  });
  return (
    <>
      <h1 className="heading">Community Resources</h1>

      <p className="tricks-list-title">
        <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/3RmB33dxDH">
          Paper Mario Speedrunning Discord Server
        </a>
      </p>
      <p className="tricks-list-title">
        <a target="_blank" rel="noopener noreferrer" href="https://www.speedrun.com/pm64">
          Speedrun.com Leaderboards
        </a>
      </p>
      <p className="tricks-list-title">
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/JCog/fp">
          Practice Rom
        </a>
      </p>

      <h1 className="heading">Game Resources</h1>

      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/vjA2vq">
          Enemy Statistics by Clover
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/Zn3eW5">
          Final Bowser AI
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/xZXn1M">
          Hallway Bowser AI
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2MziiZ5">
          Item Drop Rates by Jdaster64
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2KdrEa0">
          Star Point Amounts
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2W7yOlO">
          Star Point Calculator by JCog
        </a>
      </p>
      <p className="list">
        <Link to="/Story_Triggers">Story Triggers</Link>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/5cSzG9">
          Power Bounce Caps
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/Ax2xGC">
          Power Bounce Caps Calculation
        </a>
      </p>
      <p className="list">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/document/d/1urhJqmbJdyKYUWAKr2r9MOqiWWY-HFsTLSarCdhhsp0/edit"
        >
          Room/Group Values
        </a>
      </p>

      <h1 className="heading">Special Thanks</h1>

      <p className="normal-text">
        Go check out all of these awesome people on Twitch! This project would not have been possible without them.
      </p>

      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/mx_four">
          mx_four
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/JCog">
          JCog
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/realblopez">
          realblopez
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/morpheus080">
          morpheus080
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/Rigby234">
          Rigby234
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/Spinthra">
          Spinthra
        </a>
      </p>
      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/Phantom5800">
          Phantom5800
        </a>
      </p>

      <p className="normal-text">And you can find me here:</p>

      <p className="list">
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/Starry_Arie">
          Starry_Arie
        </a>
      </p>
    </>
  );
}
