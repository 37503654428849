import { Link } from "react-router-dom";
import { Glitch } from "../types/Glitch";

export const glitches: Glitch[] = [
  {
    name: "Arbitrary Code Execution",
    path: "Arbitrary_Code_Execution",
    description: (
      <>
        Arbitrary Code Execution is a glitch where the player is able to create and run code using only controller
        inputs (no outside tools). Using only Paper Mario, this was previously only possible on emulator due to the
        extremely precise sub-pixel positioning required.
        <br />
        <br />
        In 2021, it was discovered that by first doing Arbitrary Code Execution in another game such as The Legend of
        Zelda: Ocarina of Time to write code to the Nintendo 64 Expansion Pak, then quickly swapping cartridges, it is
        possible to write and run code that warps Mario to the credits sequence. This method, called Stop n' Swop, uses{" "}
        <Link to="/Menu_Storage">Menu Storage</Link> after fighting the Goomba King to cause an{" "}
        <Link to="/Effects_Overflow">Effects Overflow</Link> by walking and hammering a specific amount of times. This
        results in the game executing an instruction to begin reading code from the Nintendo 64 Expansion Pak, which
        contains a warp instruction to the credits that was written using Ocarina of Time.
        <br />
        <br />
        It was discovered in early 2022 that this could be done without the help of an outside game. The current method
        uses <Link to="/Menu_Storage">Menu Storage</Link> in the Mt. Lavalava central hub. It requires reaching a
        specific location by spinning and hammering precisely, then hammering to cause an{" "}
        <Link to="/Effects_Overflow">Effects Overflow</Link>. This combined with specific inputs on the player 3
        controller leads to a warp instruction to the credits.
      </>
    ),
    versions: "NTSC-J (Stop n' Swop), NTSC-U (Mt. Lavalava)",
    discoveredBy: "Rainchus, with help from MrCheeze and Fray following a related game crash found by morpheus080",
    videos: [
      {
        author: "Abyssoft (Explanation and History of Stop n' Swop)",
        url: "https://www.youtube.com/watch?v=O9dTmzRAL_4",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s6.gifyu.com/images/dumb_green_one.gif" alt="-"></img>
      </>
    ),
  },
  {
    name: "Boots Clip",
    path: "Boots_Clip",
    description: (
      <>
        A Super/Ultra Boots Clip is a clip that involves using a Spin Jump or a Tornado Jump, usually to move upwards
        through some faulty collision.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Block Clip",
        path: "/Tricks/Block_Clip",
      },
      {
        name: "Ruins Clip",
        path: "/Tricks/Ruins_Clip",
      },
      {
        name: "Slow Go Early",
        path: "/Slow_Go_Early",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s7.gifyu.com/images/BlockClip.gif" alt="Block Clip"></img>
        <p className="image-description">Using an Ultra Boots Clip to skip the dark basement in Chapter 8.</p>
      </>
    ),
  },
  {
    name: "Card LZS",
    path: "Card_LZS",
    description: (
      <>
        Card LZS is a <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> glitch in which Mario touches a Star
        Spirit card while descending from a jump while storing a Loading Zone. It is possible in Chapters 2, 3, 4, 5,
        and 6, but only 2, 3, 4, and 5 are feasible for speedruns. Card LZS fulfills the "touch all Star Spirit Cards"
        requirement for <Link to="/All_Cards">All Cards</Link>, <Link to="/Reverse_All_Cards">Reverse All Cards</Link>,
        and <Link to="/100">100%</Link> and is only used in those categories.
      </>
    ),
    mechanics: (
      <>
        Performing Card LZS increments the <Link to="/Story_Triggers">Story Value</Link> by 1 and interrupts the End of
        Chapter sequence by taking the Loading Zone.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Chapter 2 Card LZS",
        path: "/Tricks/Chapter_2_Card_LZS",
      },
      {
        name: "Chapter 3 Card LZS",
        path: "/Tricks/Chapter_3_Card_LZS",
      },
      {
        name: "Chapter 4 Card LZS",
        path: "/Tricks/Chapter_4_Card_LZS",
      },
      {
        name: "Chapter 5 Card LZS",
        path: "/Tricks/Chapter_5_Card_LZS",
      },
      {
        name: "Chapter 6 Card LZS",
        path: "/Tricks/Chapter_6_Card_LZS",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s8.gifyu.com/images/Chapter3CardLZS.gif" alt="Card LZS"></img>
        <p className="image-description">Doing Card LZS in Chapter 3.</p>
      </>
    ),
  },
  {
    name: "Clippy",
    path: "Clippy",
    description: (
      <>
        Clippy refers to a state where Mario's hitbox changes to match the hitbox used while using Parakarry's,
        Sushie's, and Lakilester's abilities. The Clippy hitbox was never meant to be applied to Mario, causing Mario to
        clip onto and around objects and walls very easily, hence the name, Clippy.
      </>
    ),
    mechanics: (
      <>
        Clippy state is achieved by using Lakilester's ability and opening the Partner Menu on the second frame of the
        encounter. Then, just before the battle starts or immediately after the battle ends, the player must switch to
        another partner. A softlock occurs if the player switches partners too quickly before the battle starts. The
        player should run from the fight immediately or defeat all enemies. Opening the partner or item menus during the
        fight will cause a game crash after the fight. After the battle, Mario will have the Clippy hitbox. Clippy state
        ends when Mario takes a Loading Zone. A softlock occurs if Mario gets off of Lakilester while in Clippy state.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Bowless Green Station",
        path: "/Tricks/Bowless_Green_Station",
      },
      {
        name: "Chapter 2 Clippy",
        path: "/Tricks/Chapter_2_Clippy",
      },
      {
        name: "Clippy Boots",
        path: "/Tricks/Clippy_Boots",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s8.gifyu.com/images/ClippyBoots1.gif" alt="Clippy"></img>
        <p className="image-description">Opening the Partner Menu to get Clippy state.</p>

        <img className="image" src="https://s8.gifyu.com/images/Chapter2Clippy2.gif" alt="Clippy"></img>
        <p className="image-description">Using Clippy state to fall out of bounds in Chapter 2.</p>
      </>
    ),
  },
  {
    name: "Effects Overflow",
    path: "Effects_Overflow",
    description: (
      <>
        Effects Overflow occurs when the queue that stores effects such as the dust from Mario walking or hammering
        becomes full and overflows into other parts of memory. This usually results in a game crash, but is also an
        entry point for <Link to="/Arbitrary_Code_Execution">Arbitrary Code Execution</Link>. Under normal
        circumstances, this queue is constantly being emptied as effects appear on screen, but glitches such as{" "}
        <Link to="/Menu_Storage">Menu Storage</Link> put the game in a state where effects are stored but never loaded,
        allowing the player to fill up the Effects Queue.
      </>
    ),
    versions: "NTSC-J (Shooting Star Summit), All (Volcano, Shiver Mountain)",
    tricks: [
      {
        name: "Arbitrary Code Execution",
        path: "Arbitrary_Code_Execution",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s12.gifyu.com/images/EffectsStorage.gif" alt="Effects Overflow"></img>
        <p className="image-description">
          Unleashing stored effects near Shooting Star Summit after leaving the Partner Menu open for 40 seconds.
        </p>
      </>
    ),
  },
  {
    name: "Item Duplication",
    path: "Item_Duplication",
    description: (
      <>
        Item Duplication is a glitch only possible on the Japanese version of the game that allows the player to
        duplicate items. It is possible without any partners, but is made very easy by using Lakilester.
      </>
    ),
    mechanics: (
      <>
        The game will toss the item you want to duplicate, but delete the item above it instead. This happens because
        the game's pointers select the wrong item to toss when there are only 9 items in the menu.
      </>
    ),
    versions: "NTSC-J",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    videoTutorials: [
      {
        author: "morpheus080",
        url: "https://www.youtube.com/watch?v=99wkn5kgsJ4",
      },
      {
        author: "Giga DB (Without Lakilester)",
        url: "https://www.youtube.com/watch?v=9vcP3NgYQIE",
      },
    ],
    discoveredBy: "r0bd0g (without Lakilester)",
    content: (
      <>
        <h1 className="heading">How To Perform Item Duplication With Lakilester</h1>

        <dl className="list">
          <dt className="detail-title">
            Get 10 items, including the item you want to duplicate, an item that will be deleted, and an item that you
            can eat. Make sure that the item you want to delete is directly above the item you want to duplicate.
          </dt>
          <dd>
            For this example, I have 1 Lemon, 7 Pebbles, 1 Iced Potato, and 1 Deluxe Feast, in that order. I will eat
            the Lemon, the Iced Potato will disappear, and I will duplicate the Deluxe Feast.
          </dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/ItemDuplication1.gif" alt="Item Duplication"></img>

        <dl className="list">
          <dt className="detail-title">
            While using Lakilester, get very close to an item without picking it up. Eat the item you don't want while
            holding the direction of the item on the ground. Wait until Mario's eating animation is completed, then toss
            the item you want to duplicate.
          </dt>
          <dd>Wait until the sparkling effect is done before pressing anything.</dd>
        </dl>
        <img className="image" src="https://s8.gifyu.com/images/ItemDuplication2.gif" alt="Item Duplication"></img>
      </>
    ),
  },
  {
    name: "Key Item Overflow",
    path: "Key_Item_Overflow",
    description: <>Key Item Overflow occurs when the player exceeds the 32 Key Item limit.</>,
    mechanics: (
      <>
        Because the Key Items are stored directly above regular Items in memory, the 33rd Key Item will be placed in the
        regular Items instead. This allows the player to throw and eat items that were never intended to be interacted
        with in that way. Eating Key Items has no effect. Only Key Items picked up on the floor will cause Key Item
        Overflow. Key Items that are given by NPCs or obtained from chests will not overflow into the regular items and
        will instead disappear forever.
        <br />
        <br />
        Under normal gameplay circumstances, the limit of 32 Key Items could never be reached, but tricks like{" "}
        <Link to="/Early_Whale">Early Whale</Link> allow the player to keep collecting Key Items such as the Ultra
        Stone, and tricks like <Link to="/Record_Skip">Record Skip</Link> allow the player to skip using some Key Items.
        The only useful application of this glitch appears in <Link to="/Reverse_All_Cards">Reverse All Cards</Link>{" "}
        during the trick <Link to="/Retrigger_Prologue">Retrigger Prologue</Link>.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Retrigger Prologue",
        path: "/Tricks/Retrigger_Prologue",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s1.gifyu.com/images/RetriggerPrologue2.gif" alt="Key Item Overflow"></img>
        <p className="image-description">Key Items are treated like normal items when you have 32.</p>
      </>
    ),
  },
  {
    name: "Kooper Super Jump",
    path: "Kooper_Super_Jump",
    description: (
      <>
        Kooper Super Jump is a glitch that allows Mario to gain incredible height when using Kooper's ability after
        falling <Link to="/Out_Of_Bounds">out of bounds</Link>.
      </>
    ),
    mechanics: (
      <>
        When Mario falls out of bounds, the game improperly stores Mario's Y-Axis velocity. Using Kooper's ability calls
        this Y-Axis velocity, causing Mario to fly upwards. This Y-Axis velocity will be properly stored by jumping or
        falling, removing any stored Kooper Super Jumps. This glitch is unique in Paper Mario in that it is the only
        glitch that allows Mario to gain more height than a Tornado Jump in any location. As such, it can be used to get
        items on platforms above Mario in any room where Mario can fall out of bounds, like in{" "}
        <Link to="/Fast_Flood_Room">Fast Flood Room</Link>. Interestingly, tiny Kooper Super Jumps can be stored by
        pressing A too late while falling and failing to perform a Tornado Jump.
      </>
    ),
    versions: "NTSC-U, NTSC-J",
    tricks: [
      {
        name: "Fast Flood Room",
        path: "/Tricks/Fast_Flood_Room",
      },
      {
        name: "Retrigger Prologue",
        path: "/Tricks/Retrigger_Prologue",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s11.gifyu.com/images/KooperSuperJump.gif" alt="Kooper Super Jump"></img>
        <p className="image-description">The three different heights of shell toss.</p>
      </>
    ),
  },
  {
    name: "Laki Clip",
    path: "Laki_Clip",
    description: (
      <>
        Laki Clipping is a glitch that occurs when the player activates Lakilester's ability when standing at the
        outside corner of two thin walls, resulting in Mario appearing on the other side of the walls. Laki Clips are
        very easy to perform, and can be performed in many locations. Laki Clips and{" "}
        <Link to="/Laki_Teleport">Laki Teleports</Link> are the reasons why Lakilester is widely considered the most
        broken partner.
      </>
    ),
    versions: "All",
    discoveredBy: "r0bd0g",
    tricks: [
      {
        name: "Cannonless",
        path: "/Tricks/Cannonless",
      },
      {
        name: "Fast Flood Room",
        path: "/Tricks/Fast_Flood_Room",
      },
      {
        name: "Ice Staircase Skip",
        path: "/Tricks/Ice_Staircase_Skip",
      },
      {
        name: "Invisible Bridge Clip",
        path: "/Tricks/Invisible_Bridge_Clip",
      },
      {
        name: "Lakilester Peach Warp",
        path: "/Tricks/Lakilester_Peach_Warp",
      },
      {
        name: "Murder Solved Early",
        path: "/Tricks/Murder_Solved_Early",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s7.gifyu.com/images/FastFloodRoom1.gif" alt="Laki Clip"></img>
        <p className="image-description">Laki Clipping in the Flood Room.</p>
      </>
    ),
  },
  {
    name: "Laki Jump",
    path: "Laki_Jump",
    description: (
      <>Laki Jumping is a glitch that allows Mario to gain height and clip upwards through some platforms.</>
    ),
    mechanics: (
      <>
        Laki Jumping is performed by using Lakilester's ability, pressing c-down and c-right at the same time, and then
        switching to another partner.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Laki Jailbreak",
        path: "/Tricks/Laki_Jailbreak",
      },
      {
        name: "Artifact Jump",
        path: "/Tricks/Artifact_Jump",
      },
    ],
    discoveredBy: "hambrgr333",
    content: (
      <>
        <img className="image" src="https://s1.gifyu.com/images/ArtifactJump.gif" alt="Laki Jump"></img>
        <p className="image-description">Using a Laki Jump to get the Artifact.</p>
      </>
    ),
  },
  {
    name: "Laki Teleport",
    path: "Laki_Teleport",
    description: (
      <>
        A Laki Teleport is a glitch that occurs when the player activates Lakilester's ability at the same time that
        Lakilester begins rushing towards Mario. This displaces Mario's position and can be used to teleport through
        certain barriers. <Link to="/Laki_Clip">Laki Clips</Link> and Laki Teleports are the reasons why Lakilester is
        widely considered the most broken partner.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Lakilester Peach Warp",
        path: "/Tricks/Lakilester_Peach_Warp",
      },
      {
        name: "Mirror Clip",
        path: "/Tricks/Mirror_Clip",
      },
      {
        name: "Laki Jailbreak",
        path: "/Tricks/Laki_Jailbreak",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s7.gifyu.com/images/LakilesterPeachWarp3.gif" alt="Laki Teleport"></img>
        <p className="image-description">A Laki Teleport.</p>
      </>
    ),
  },
  {
    name: "Loading Zone Storage",
    path: "Loading_Zone_Storage",
    description: (
      <>
        Loading Zone Storage (LZS) refers to a property of the Loading Zones that connect rooms in Paper Mario. Because
        Mario will only take Loading Zones if he is on the ground for two consecutive frames, jumping frame-perfectly
        allows the player to change the location where Mario takes the Loading Zone (referred to as Loading Zone Storage
        Jumps). Loading Zones also have a property that uses Mario's entrance position when taking a Loading Zone to
        determine what angle Mario enters the next room from. By performing Loading Zone Storage Jumps, the player can
        skew Mario's entrance angle to fall <Link to="/Out_Of_Bounds">out of bounds</Link> in some rooms. Additionally,
        you can perform actions such as collecting an item, talking to an NPC, or interacting with an object while
        storing a Loading Zone.
        <br />
        To learn Loading Zone Storage Jumps, jump frame-perfectly in a Loading Zone while holding neutral. Once you
        learn the rhythm, begin adding directional inputs at the same time you jump.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Blue House Skip (LZS)",
        path: "/Tricks/Blue_House_Skip",
      },
      {
        name: "Bow Skip",
        path: "/Tricks/Bow_Skip",
      },
      {
        name: "Chapter 2 Card LZS",
        path: "/Tricks/Chapter_2_Card_LZS",
      },
      {
        name: "Chapter 3 Card LZS",
        path: "/Tricks/Chapter 3 Card LZS",
      },
      {
        name: "Chapter 4 Card LZS",
        path: "/Tricks/Chapter 4 Card LZS",
      },
      {
        name: "Chapter 5 Card LZS",
        path: "/Tricks/Chapter 5 Card LZS",
      },
      {
        name: "Chapter 6 Card LZS",
        path: "/Tricks/Chapter 6 Card LZS",
      },
      {
        name: "Early Lakilester",
        path: "/Tricks/Early_Lakilester",
      },
      {
        name: "Early Whale",
        path: "/Tricks/Early_Whale",
      },
      {
        name: "FP Plus Early",
        path: "/Tricks/FP_Plus_Early",
      },
      {
        name: "Jr Skip",
        path: "/Tricks/Jr_Skip",
      },
      {
        name: "Log Skip (LZS)",
        path: "/Tricks/Log_Skip",
      },
      {
        name: "Sun Tower Skip",
        path: "/Tricks/Sun_Tower_Skip",
      },
      {
        name: "Yellow Berry Skip",
        path: "/Tricks/Yellow_Berry_Skip",
      },
    ],
    discoveredBy: "r0bd0g",
    content: (
      <>
        <img className="image" src="https://s10.gifyu.com/images/JrSkip.gif" alt="Jr Skip"></img>
        <p className="image-description">Using Loading Zone Storage to skip the fight with Jr Troopa in Prologue.</p>
      </>
    ),
  },
  {
    name: "Menu Storage",
    path: "Menu_Storage",
    description: (
      <>
        Menu Storage is a state in which Mario is able to move with a text box or menu open. While in this state, many
        effects such as dust from walking or hammering can be stored (see{" "}
        <Link to="/Effects_Overflow">Effects Overflow</Link>).
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Seed Duplication",
        path: "/Tricks/Seed_Duplication",
      },
      {
        name: "Sushie Peach Warp",
        path: "/Tricks/Sushie_Peach_Warp",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s6.gifyu.com/images/dumb_green_one.gif" alt="-"></img>
      </>
    ),
  },
  {
    name: "NPC Clipping",
    path: "NPC_Clipping",
    description: (
      <>
        NPC Clipping is a glitch that allows the player to clip through walls or{" "}
        <Link to="/Out_Of_Bounds">out of bounds</Link> by spinning between an NPC and a wall, then hammering at a
        precise time. Combined with <Link to="/NPC_Pushing">NPC Pushing</Link>, NPC Clipping can be used to do slower
        versions of many difficult tricks.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Black Toad Skip",
        path: "/Tricks/Black_Toad_Skip",
      },
      {
        name: "Merlon Skip",
        path: "/Tricks/Merlon_Skip",
      },
      {
        name: "Blue House Skip",
        path: "/Tricks/Blue_House_Skip",
      },
      {
        name: "Lava Piranha Skip",
        path: "/Tricks/Lava_Piranha_Skip",
      },
      {
        name: "Early Lakilester (NPC Push)",
        path: "/Tricks/Early_Lakilester",
      },
      {
        name: "Early Whale",
        path: "/Tricks/Early_Whale",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s8.gifyu.com/images/BlackToadSkip1.gif" alt="Black Toad Skip"></img>
        <p className="image-description">Performing an NPC Clip in Toad Town.</p>
      </>
    ),
  },
  {
    name: "NPC Pushing",
    path: "NPC_Pushing",
    description: (
      <>
        NPC Pushing, also known as Bombette Pushing, is a glitch that allows the player to move stationary NPCs using
        Bombette's ability. For some reason, when Bombette falls from the sky after exploding, there are a few frames
        where Mario can "push" NPCs by moving into them. NPC Pushing is mainly used to move NPCs to a location where
        Mario can <Link to="/NPC_Clipping">NPC Clip</Link> through walls to bypass locked doors or get{" "}
        <Link to="/Out_Of_Bounds">out of bounds</Link>. Unfortunately, moving NPCs with this method is very slow, and,
        as such, there are very few applications for speedruns.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Blue House Skip (NPC Push)",
        path: "/Tricks/Blue_House_Skip",
      },
      {
        name: "Chapter 3 Early",
        path: "/Tricks/Chapter_3_Early",
      },
      {
        name: "Early Lakilester (NPC Push)",
        path: "/Tricks/Early_Lakilester",
      },
      {
        name: "Record Skip",
        path: "/Tricks/Record_Skip",
      },
    ],
    content: (
      <>
        <img
          className="image"
          src="https://s8.gifyu.com/images/EarlyLakilesterNPCPush1.gif"
          alt="Early Lakilester"
        ></img>
      </>
    ),
  },
  {
    name: "Out Of Bounds",
    path: "Out_Of_Bounds",
    description: (
      <>
        Out Of Bounds refers to any location not intended to be reached by the developers. It also refers to the area
        around and below rooms that Mario can reach through a variety of glitches, such as{" "}
        <Link to="/NPC_Clipping">NPC Clipping</Link>, <Link to="/Laki_Clip">Laki Clipping</Link>, or{" "}
        <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link>.
      </>
    ),
    mechanics: (
      <>
        Paper Mario's gravity is coded such that Mario will start falling upwards once he has been falling for long
        enough. This causes him to "land" on ground above his current location when he falls out of bounds. If Mario
        falls upwards and never hits ground, the game will place him on his last known location. However, this process
        truncates decimal places, causing Mario to sometimes be placed on invalid ground. This is why the game can
        sometimes crash while falling out of bounds in certain places.
      </>
    ),
    versions: "All",
    content: (
      <>
        <img className="image" src="https://s6.gifyu.com/images/OutOfBounds.gif" alt="Out Of Bounds"></img>
        <p className="image-description">Mario walking out of bounds and falling.</p>
      </>
    ),
  },
  {
    name: "Parakarry Clip",
    path: "Parakarry_Clip",
    description: (
      <>
        A Parakarry Clip is a type of clip that uses Parakarry's ability to catch Loading Zones through faulty walls and
        clip <Link to="/Out_Of_Bounds">Out Of Bounds</Link>.
      </>
    ),
    mechanics: (
      <>
        When Mario uses Parakarry's ability, his hitbox changes to the same hitbox used for Sushie's and Lakilester's
        abilities (see <Link to="/Clippy">Clippy</Link>). This altered hitbox is responsible for Parakarry's ability
        being able to clip and catch Loading Zones through some walls.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Raphael Skip (NTSC-J Only)",
        path: "/Tricks/Raphael_Skip",
      },
      {
        name: "Flarakarry",
        path: "/Tricks/Flarakarry",
      },
      {
        name: "Pink Station Clips",
        path: "/Tricks/Pink_Station_Clips",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s8.gifyu.com/images/PinkStationClips1.gif" alt="Pink Station Clip 1"></img>
        <p className="image-description">Using a Parakarry Clip in Pink Station.</p>
      </>
    ),
  },
  {
    name: "Peach Warp",
    path: "Peach_Warp",
    description: (
      <>
        This section is about the glitch Peach Warp. For the two versions of the trick, see:{" "}
        <Link to="/Lakilester_Peach_Warp">Lakilester Peach Warp</Link>,{" "}
        <Link to="/Sushie_Peach_Warp">Sushie Peach Warp</Link>
        <br />
        <br />
        Peach Warp is a glitch used to skip large portions of Chapters 6 and 7 by triggering the end-of-Chapter 5
        cutscene when not in a Chapter 5 state. Since its discovery, it has become an important glitch for various
        speedrun categories. Peach Warp had such a large impact on <Link to="/Any_(No_ACE)">Any%</Link> after its
        discovery that many became unhappy with the new state of <Link to="/Any_(No_ACE)">Any%</Link>, and{" "}
        <Link to="/Any_(No_Peach_Warp)">Any% (No Peach Warp)</Link> was created to be the "old Any%" that runners
        enjoyed.
      </>
    ),
    mechanics: (
      <>
        Triggering the volcano escape cutscene in Mt. Lavalava causes the game to load the next Peach cutscene,
        regardless of what chapter Mario is in. This allows the player to completely skip Chapters 6 and 7. Note that if
        the player wishes to complete the game, they should get Lakilester before Peach Warping past Chapter 6. If this
        glitch is performed while Mario is in a post-Chapter 7 state, there are no Peach cutscenes left to load, and the
        player will be stuck watching Bowser's Castle floating in space indefinitely.
      </>
    ),
    versions: "All",
    categories: [
      {
        name: "Any% (No ACE)",
        path: "/Any_(No_ACE)",
      },
      {
        name: "All Cards",
        path: "/All_Cards",
      },
      {
        name: "Any% (No Peach Warp)",
        path: "/Any_(No_Peach_Warp)",
      },
      {
        name: "All Bosses",
        path: "/All_Bosses",
      },
      {
        name: "100%",
        path: "/100",
      },
      {
        name: "Reverse All Cards",
        path: "/Reverse_All_Cards",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s11.gifyu.com/images/PeachWarpCutscene.gif" alt="Volcano Escape"></img>
      </>
    ),
  },
  {
    name: "Sushie Glitch",
    path: "Sushie_Glitch",
    description: (
      <>
        Sushie Glitch is a glitch that allows Mario to use Sushie's ability on land. Currently, there are 3 known places
        where Sushie Glitch can be performed: the dock in Toad Town, the large room with the springs in Mt. Lavalava and
        the room with the frozen lake in Shiver City. For the Toad Town method, see{" "}
        <Link to="/Toad_Town_Sushie_Glitch">Toad Town Sushie Glitch</Link>. For the method in Mt. Lavalava, see{" "}
        <Link to="/Sushie_Peach_Warp">Sushie Peach Warp</Link>. For the Shiver City method, see{" "}
        <Link to="/Chapter_7_Sushie_Glitch">Chapter 7 Sushie Glitch</Link>.
      </>
    ),
    mechanics: (
      <>
        While using Sushie's ability on land, Mario will snap upwards to the highest piece of land available. To switch
        elevations, the player should dive between the two pieces of land and return to the surface under the land at
        the desired elevation. After entering a fight while on Sushie, Mario will no longer be using Sushie's ability
        outside of battle until the next room is loaded. If the player switches partners in battle before exiting the
        fight, Sushie Glitch will be ended safely upon taking the next Loading Zone.
      </>
    ),
    versions: "All",
    tricks: [
      {
        name: "Toad Town Sushie Glitch",
        path: "/Tricks/Toad_Town_Sushie_Glitch",
      },
      {
        name: "Sushie Peach Warp",
        path: "/Tricks/Sushie_Peach_Warp",
      },
      {
        name: "Chapter 7 Sushie Glitch",
        path: "/Tricks/Chapter_7_Sushie_Glitch",
      },
    ],
    content: (
      <>
        <img className="image" src="https://s3.gifyu.com/images/TTSushieGlitch2.gif" alt="Sushie Glitch"></img>
        <p className="image-description">Sushie Glitch in Toad Town.</p>
      </>
    ),
  },
];

/*
    description: <></>,
    mechanics: <></>,
    versions: '',
    tricks: [{
        name: '',
        link: ''
    }],
    categories: [{
        name: 'Any% (No ACE)',
        link: '/Any_(No_ACE)'
    }, {
        name: 'All Cards',
        link: '/All_Cards'
    }, {
        name: 'Any% (No Peach Warp)',
        link: '/Any_(No_Peach_Warp)'
    }, {
        name: 'All Bosses',
        link: '/All_Bosses'
    }, {
        name: '100%',
        link: '/100'
    }, {
        name: 'Reverse All Cards',
        link: '/Reverse_All_Cards'
    }, {
        name: 'No Major Sequence Breaks',
        link: '/No_Major_Sequence_Breaks'
    }],
    discoveredBy: false,
    videos: false,
    videoTutorials: false,
    content: <>

    </>
*/
