import { useEffect, useState } from "react";

type BingoButtonProps = {
  name: string;
  position: string;
};

export default function BingoButton({ name, position }: BingoButtonProps) {
  const [open, setOpen] = useState(localStorage?.getItem(position) === "true");

  useEffect(() => {
    setOpen(localStorage?.getItem(position) === "true");
  }, [name, position]);

  return (
    <th>
      <button
        onClick={() => {
          localStorage.setItem(position, `${!open}`);
          setOpen(!open);
        }}
        className={open ? "bingo-button-active" : "bingo-button"}
      >
        {name}
      </button>
    </th>
  );
}
