export const isValidRouteJSON = (json: string): boolean => {
  try {
    const route = JSON.parse(json);

    if (!route.name) return false;
    if (!route.instructions) return false;
    if (!Array.isArray(route.instructions)) return false;

    return true;
  } catch {
    return false;
  }
};
