import { Link } from "react-router-dom";
import { useEffect } from "react";
import { PageProps } from "../types/PageProps";

export default function StoryTriggers({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });
  return (
    <>
      <h1 className="heading">Story Triggers</h1>

      <dl className="list">
        <dt className="detail-title">Mechanics</dt>
        <dd>
          Paper Mario keeps track of what part of the story the player is in with a signed integer (ranging from -128 to
          96) commonly referred to as the Story Value or Story Byte. Doing certain things that advance the story will
          cause the Story Value to be set to a new value. Under normal circumstances, the Story Value will only ever
          increase; however, by using glitches, we can cause sequence breaks that greatly increase or decrease it,
          causing the game to think we've gone forwards or backwards in the story.
        </dd>
        <dt className="detail-title">Tricks</dt>
        <dt className="detail-title">
          These tricks will always change the Story Value to a specific value, regardless of the current Story Value.
        </dt>
        <dd>
          <Link to="/Retrigger_Prologue">Retrigger Prologue</Link> (Once per file)
        </dd>
        <dd>
          <Link to="/Early_Whale">Early Whale</Link>
        </dd>
      </dl>

      <h1 className="heading">List of Story Triggers</h1>

      <dl className="list">
        <dt className="detail-title">Prologue</dt>
        <li>-128 (80) Start of Game</li>
        <li>-127 (81) Scene in Prologue talking to Eldstar in the Toad House</li>
        <li>-126 (82) As soon as you're able to walk again after falling from the sky in Prologue</li>
        <li>-124 (84) First time talking to Goompa on the balcony</li>
        <li>-123 (85) After the cutscene with the block falling on the gate finishes</li>
        <li>-122 (86) Falling from the balcony</li>
        <li>-121 (87) After the first time you talk with Goompa after you fell from the balcony</li>
        <li>-120 (88) After Goompa starts talking to you when you enter the room with the wood hammer</li>
        <li>-119 (89) Getting the wood hammer</li>
        <li>-118 (8a) After the battle and cutscene with Jr Troopa ends</li>
        <li>-117 (8b) Hitting the yellow block in the room beside the room you got the wood hammer</li>
        <li>-115 (8d) After the cutscene where you get Goombario finishes</li>
        <li>-114 (8e) Hitting the wood block that fell on the gate in Goomba Village</li>
        <li>-113 (8f) After the fight and cutscene with Red and Blue Goomba</li>
        <li>-112 (90) When Goombario talks to you after the fight with Goomba King</li>
        <li>-111 (91) Using the bush to reveal the switch that destroys Goomba King's castle</li>
        <li>-110 (92) Right before Goomba King says "Oh nooo!!!" while his castle is blowing up</li>
        <li>-109 (93) After the cutscene of Goomba King's castle exploding finishes </li>
        <li>
          -108 (94) As soon as Kammy walks through the door in the first Prologue Peach cutscene (the one with the Koopa
          Bros)
        </li>
        <li>-107 (95) As soon as you enter Toad Town after coming back from Goomba Village </li>
        <li>-106 (96) After the cutscene talking to Eldstar and the other star spirits on Shooting Star Summit ends</li>
        <li>
          -105 (97) As soon as you see Peach in the second Prologue Peach cutscene (The one where she meets Twink)
        </li>
        <li>-104 (98) After the cutscene with twink and the battle with the Magikoopa ends</li>
        <li>
          -103 (99) After the toad stops talking to you when you return to Toad Town after talking to the stars on
          Shooting Star Summit
        </li>
        <li>-102 (9a) After the cutscene with Merlon where you fall asleep (Right before the black toads)</li>
        <li>-101 (9b) When Merlon leaves his house to confront the black toads</li>
        <li>-100 (9c) After the cutscene with the black toads finishes</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 1</dt>
        <li>-98 (9e) Hitting the switch on Pleasant Path that raises the bridge (The one before Koopa Village)</li>
        <li>-97 (9f) Entering Koopa Village being attacked by fuzzies for the first time</li>
        <li>-96 (a0) As soon as you open the door to Kooper's house to start the mission where you find his shell</li>
        <li>-95 (a1) As soon as the fuzzy jumps out of Kooper's house with his shell</li>
        <li>-94 (a2) When you start chasing the fuzzy who stole Kooper's shell down the path</li>
        <li>-93 (a3) The first text bubble that pops up in the room where you get Kooper ("Meeeooork! Meork!")</li>
        <li>-92 (a4) As soon as Kooper joins your party</li>
        <li>-91 (a5) Hitting the switch to raise the second bridge in Pleasant Path (After you get Kooper)</li>
        <li>
          -90 (a6) Entering the room outside Koopa Bros Fortress for the first time through the loading zone on the
          ground
        </li>
        <li>
          -89 (a7) After the cutscene where the black Koopa Bro comes out the door, sees Mario, and then runs back
          inside
        </li>
        <li>-88 (a8) After the cutscene with the green Koopa Bro lowering the gate guarding a key</li>
        <li>
          -87 (a9) When the switch appears that lowers the stairs down to the prison where Bombette is after beating a
          koopa
        </li>
        <li>-86 (aa) Hitting the switch mentioned in the previous trigger</li>
        <li>
          -85 (ab) As soon as the cutscene with yellow Koopa Bro hammering the trap door finishes and Mario enters the
          room
        </li>
        <li>-84 (ac) As soon as Mario enters the jail after falling from the trap door</li>
        <li>-83 (ad) As soon as Bombette joins your party</li>
        <li>
          -82 (ae) After the battle with the koopa and 2 bob-ombs (after you escape the jail with Bombette) finishes
        </li>
        <li>-81 (af) As soon as the second stairway finishes lowering after you hit the second blue switch</li>
        <li>-80 (b0) As soon as you hit the switch that raises the bridge submerged in water</li>
        <li>-79 (b1) When the Koopa Bros start firing the cannons at you</li>
        <li>-78 (b2) When the Koopa Bros fall down into the prison</li>
        <li>-77 (b3) Touching the star card you got from beating the Koopa Bros</li>
        <li>-76 (b4) As soon as Peach appears in the Peach scene after getting the chapter 1 star card</li>
        <li>-75 (b5) As soon as the cutscene with Eldstar outside Koopa Bros Fortress ends</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 2</dt>
        <li>-74 (b6) As soon as the battle with Jr Troopa in Pleasant Path ends (Not the cutscene, just the battle)</li>
        <li>
          -73 (b7) After the cutscene with Twink when you enter Toad Town coming back from Koopa Bros Fortress finishes
        </li>
        <li>
          -72 (b8) As soon as you take the loading zone on the train that goes to the "Chapter 2: The Mystery of Dry Dry
          Ruins" screen
        </li>
        <li>-71 (b9) After the cutscene where Parakarry tells you to go find his letters</li>
        <li>-70 (ba) As soon as Parakarry joins the party</li>
        <li>-69 (bb) Entering Dry Dry Desert for the first time</li>
        <li>-68 (bc) Entering Dry Dry Outpost for the first time</li>
        <li>-67 (bd) As soon as the shop keeper mouse runs off the screen</li>
        <li>-66 (be) As soon as the shop keeper mouse runs off the screen in the next room</li>
        <li>-65 (bf) As soon as the shop keeper mouse runs back into his shop</li>
        <li>
          -64 (c0) When the cutscene with the shop keeper mouse after you buy a dried shroom then a dusty hammer
          finishes
        </li>
        <li>-63 (c1) After you get the pulse stone from Moustafa</li>
        <li>-62 (c2) As soon as you place the Pulse Stone in the rock</li>
        <li>-61 (c3) After draining the sand in the first room where you can drain sand</li>
        <li>-60 (c4) After draining the sand in the second room where you can drain sand</li>
        <li>-59 (c5) Getting the super hammer</li>
        <li>
          -58 (c6) As soon as you drain the sand in the room next to where you put the triangle, diamond, and moon
          stones into the statues
        </li>
        <li>-57 (c7) After the cutscene with the stairs lowering down to the Tutankoopa boss room finishes</li>
        <li>
          -56 (c8) As soon as Tutankoopa and the chain chomp run off the screen after you beat them and the star card
          begins to appear
        </li>
        <li>-55 (c9) As soon as you touch the chapter 2 star card after beating Tutankoopa</li>
        <li>-54 (ca) As soon as Bowser appears in the Peach scene after getting the chapter 2 star card</li>
        <li>-53 (cb) After the cutscene with Mamar outside Dry Dry Ruins finishes</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 3</dt>
        <li>-51 (cd) After the cutscene with Bootler and the toad guard finishes</li>
        <li>-50 (ce) After the cutscene where Bootler invites you to the mansion finishes</li>
        <li>-49 (cf) After the cutscene where Oaklie in forever forest lets you pass through the gate</li>
        <li>-48 (d0) As soon as you enter the room with Boo's Mansion for the first time</li>
        <li>-47 (d1) As soon as you press to open the gate to Boo's Mansion</li>
        <li>
          -46 (d2) Entering Boo's Mansion for the first time (Only if you've hit previous triggers in this chapter)
        </li>
        <li>-45 (d3) The boo jump scare when you go to open the door to the room where you can become 8-bit Mario</li>
        <li>-44 (d4) After the game with the boos where you have to find out which one has the record finishes</li>
        <li>-43 (d5) Putting the record into the record player</li>
        <li>-42 (d6) Opening the chest with the weight</li>
        <li>-41 (d7) Hanging the weight onto the chandelier</li>
        <li>
          -40 (d8) After the game with the boos where you have to find out which one has the super boots (The super
          boots themselves are not a trigger)
        </li>
        <li>-39 (d9) Hitting the big switch in the room with the boo shop</li>
        <li>-38 (da) Getting the Boo's Portrait item</li>
        <li>-37 (db) After the cutscene talking to the boo painting after you put him back in</li>
        <li>-36 (dc) Entering the room at the top floor of the mansion with the Bow and Bootler cutscene</li>
        <li>-35 (dd) After the cutscene where Bow tells the boos to open the gate to go to Gusty Gulch</li>
        <li>-34 (de) After the cutscene talking to the boo that greets you as soon as you enter the boo village</li>
        <li>-33 (df) After the cutscene with Tubba Blubba eating a ghost in the village ends</li>
        <li>-32 (e0) As soon as you enter Tubba Blubba's castle (If you've triggered previous Ch 3 triggers)</li>
        <li>-30 (e2) After the cutscene where Tubba Blubba goes to bed</li>
        <li>-29 (e3) After the cutscene where you steal Yakkey and Tubba Blubba wakes up</li>
        <li>-28 (e4) After the cutscene where Tubba Blubba breaks the bridge</li>
        <li>-27 (e5) As soon as Tubba Blubba enters the next room you go into after he destroys the bridge</li>
        <li>-26 (e6) As soon as Tubba Blubba enters the main room on the ground floor</li>
        <li>-25 (e7) After the cutscene where the bows keep the door to Tubba Blubbas castle closed ends</li>
        <li>-24 (e8) Using the Mystical Key on the windmill lock</li>
        <li>-23 (e9) Ground pounding the panel in the windmill</li>
        <li>-22 (ea) After you beat Tubba Blubba's heart</li>
        <li>-19 (ed) As soon as you see Tubba's heart running away in the room next to the one you beat him in</li>
        <li>-18 (ee) As soon as Tubba's heart uses the spring in the windmill</li>
        <li>-17 (ef) After you see Tubba's heart run outside the windmill</li>
        <li>-16 (f0) As soon as Bow says "Gwaaa ha ha ha." after you beat Tubba Blubba</li>
        <li>-15 (f1) As soon as you touch the chapter 3 star card</li>
        <li>-14 (f2) As soon as Peach appears in the Peach cutscene after getting the chapter 4 star card</li>
        <li>-13 (f3) After the cutscene with Skolar outside the windmill finishes</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 4</dt>
        <li>-12 (f4) Shy guy running out of the Tayce T.'s house with the frying pan</li>
        <li>
          -11 (f5) After the cutscene with twink in the same room that has the toybox and storeroom with the toy train
          finishes
        </li>
        <li>
          -10 (f6) As soon as the pink shy guy goes through the door in the room that has the toybox and reveals the
          door
        </li>
        <li>-9 (f7) First time entering the toy box (If you've triggered previous ch 4 triggers)</li>
        <li>-8 (f8) Obtaining the store room key from the chest</li>
        <li>-7 (f9) After the cutscene that happens after unlocking the door to the storeroom</li>
        <li>*-7 (f9) Obtaining the toy train from the storeroom</li>
        <li>-5 (fb) Putting the toy train into the toybox</li>
        <li>-4 (fc) Opening the chest with the frying pan</li>
        <li>-3 (fd) After the cutscene where Tayce T. gives you a cake for giving her her frying pan back</li>
        <li>-2 (fe) After the cutscene of giving the Gourmet Guy thats blocking the train tracks the cake finishes</li>
        <li>
          -1 (ff) As soon as the cutscene of the train tracks flipping over after pulling the pink switch finishes
        </li>
        <li>0 (00) As soon as the cutscene with the colored train tracks falling over finishes</li>
        <li>1 (01) As soon as the cutscene with Big Lantern Ghost after you beat him ends</li>
        <li>2 (02) After the cutscene with Watt when he joins your party ends</li>
        <li>3 (03) As soon as the shy guys break the wall into General Guy's room</li>
        <li>
          4 (04) As soon as the white ball that turns into the star card appears on screen after beating General Guy
        </li>
        <li>5 (05) As soon as you touch the chapter 4 star card</li>
        <li>6 (06) As soon as Peach appears on screen in the Peach cutscene after getting the chapter 4 star card</li>
        <li>7 (07) After the cutscene with Muskular and Twink in Toad Town ends</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 5</dt>
        <li>8 (08) As soon as the whale opens its mouth to let you inside</li>
        <li>9 (09) As soon as you enter the whale's mouth for the first time</li>
        <li>10 (0a) As soon as the whale shoots you out of its body</li>
        <li>11 (0b) After the cutscene with Kolorado after getting off the whale on Lava Lava Island ends</li>
        <li>
          12 (0c) While Kolorado walks away after getting mad at you for not saving him from the fuzzy on the beach
        </li>
        <li>
          13 (0d) After Kolorado walks away after getting mad at you for not saving him from the spear guy outside the
          volcano
        </li>
        <li>14 (0e) After Kolorado walks away after the cutscene with him looking at the volcano</li>
        <li>15 (0f) After the cutscene where Sushie joins your party finishes</li>
        <li>16 (10) Save all the baby yoshis</li>
        <li>
          17 (11) As soon as Sushie starts talking to you in the cutscene where you get the jade raven from the yoshi
          elder
        </li>
        <li>18 (12) Right after the raven statue stops moving when you put the jade raven in it</li>
        <li>20 (14) After the cinematic of you entering the room with Raphael's tree ends</li>
        <li>21 (15) After all the ravens jump off Raphael's tree</li>
        <li>22 (16) After the cutscene where Raphael raises the tree root to make a path</li>
        <li>23 (17) After the cutscene where all the ravens enter the room where they build the zipline</li>
        <li>24 (18) After the cutscene where the ravens build the zipline</li>
        <li>25 (19) Entering the volcano for the first time (Probably only if you've hit previous ch 5 triggers)</li>
        <li>26 (1a) After the cutscene where Kolorado tries to cross the platforms but falls into the lava</li>
        <li>
          27 (1b) Pushing the 3 blue blocks into the lava to lower the lava level in the room right before where you get
          the ultra hammer
        </li>
        <li>28 (1c) After the cutscene of getting the ultra hammer</li>
        <li>
          29 (1d) Breaking the ultra block next to the zipline that takes you down to the loading zone that goes to
          where you get the ultra hammer
        </li>
        <li>30 (1e) After the cutscene where Kolorado falls down after missing the zipline</li>
        <li>
          31 (1f) After the cutscene where Kolorado runs over to you by the rock you need to break with the spike wheel
          thing to advance to the next room
        </li>
        <li>32 (20) As soon as the spike wheel thing breaks the rock mentioned in trigger 23</li>
        <li>
          33 (21) As soon as Kolorado comes running into the room with the save block where he tells you he senses
          treasure nearby
        </li>
        <li>34 (22) After Kolorado runs into the room where he thinks there's treasure</li>
        <li>35 (23) As soon as the white ball that turns into the star card appears after you beat Lava Piranha</li>
        <li>36 (24) After the cutscene with Misstar in the same room you beat Lava Piranha in ends</li>
        <li>37 (25) As soon as you bomb the wall to escape to the next room in the volcano</li>
        <li>38 (26) As soon as Peach appears in the Peach cutscene that happens after the volcano erupts</li>
        <li>39 (27) As soon as Kolorado walks away sadly after the cutscene with Misstar outside the volcano</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 6</dt>
        <li>
          40 (28) After the cutscene where you trade the volcano vase with Kolorado for the jade jungle bulb-ulb seed
        </li>
        <li>
          41 (29) As soon as Jr Troopa's text comes up when he's swimming to the docks after you get off the whale in
          Toad Town, or entering the section of Toad Town with Tayce T's house
        </li>
        <li>42 (2a) As soon as the cutscene with the flower garden door appearing ends</li>
        <li>
          43 (2b) After the cutscene with Wise Wisterwood in Flower Fields (When you enter Flower Fields for the first
          time) ends
        </li>
        <li>44 (2c) After the cutscene with Petunia asking you to defeat the moles ends</li>
        <li>45 (2d) After the cutscene with Petunia giving you the magical bean ends</li>
        <li>46 (2e) After the cutscene with Posie ends</li>
        <li>47 (2f) After the cutscene where Posie shakes a crystal berry down from the tree ends</li>
        <li>48 (30) After the cutscene with Rosie where she gives you the water stone ends</li>
        <li>49 (31) About the same time when Lily says "Oh, it's water! Sweet water!"</li>
        <li>50 (32) After the cutscene talking to the sun for the first time when its cloudy</li>
        <li>51 (33) After the cutscene where Lakilester joins your party</li>
        <li>52 (34) As soon as the cutscene with the enemies by the Puff-Puff Machine after you beat them ends</li>
        <li>53 (35) When game goes to the sun after you blow up the Puff-Puff Machine</li>
        <li>54 (36) Talking to Wise Wisterwood right after you restore the sunlight to Flower Fields</li>
        <li>55 (37) As soon as the screen goes back to Mario after the bean stalk grows into the sky</li>
        <li>56 (38) As soon as the white ball that turns into the star card after you beat Huff N. Puff appears</li>
        <li>57 (39) Touching the chapter 6 star card</li>
        <li>58 (3a) As soon as Peach appears in the Peach cutscene that happens after touching the ch 6 star card</li>
        <li>
          59 (3b) As soon as Klevar flys into the sky in the cutscene with him after you complete the ch 6 Peach
          cutscene
        </li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 7</dt>
        <li>60 (3c) As soon as you talk to Wise Wisterwood to go back to Toad Town after you just beat chapter 6</li>
        <li>61 (3d) After the cutscene with Merlon and Ninji from Starborn Valley in Merlon's house ends</li>
        <li>62 (3e) Entering Shiver City through the pipe for the first time in chapter 7</li>
        <li>63 (3f) As soon as the cutscene with the Mayor's wife noticing the Mayor is "dead" ends</li>
        <li>64 (40) The third ground pound on the ice in the skating rink</li>
        <li>65 (41) As soon as the cutscene where Herringway leaves his house to go see the "dead" Mayor ends</li>
        <li>66 (42) As soon as Herringway closes the door after entering the Mayor's house</li>
        <li>
          67 (43) As soon as both Herringway and the detective/police Bumpty have left the Mayor's house during the
          cutscene where the Mayor comes back from the "dead"
        </li>
        <li>68 (44) As soon as the battle with Jr Troopa outside Shiver City ends</li>
        <li>69 (45) As soon as the cutscene with Monstar after you beat them ends</li>
        <li>
          70 (46) After the text ends with Merle when he tells you to follow him up to his house in star born valley
        </li>
        <li>71 (47) After the second set of text ends with Merle when following him up to his house</li>
        <li>72 (48) After the cutscene with Merle outside his house after he gave you the scarf ends</li>
        <li>73 (49) After the cutscene where the Mayor gives you the bucket ends</li>
        <li>74 (4a) After the cutscene where the snowmen move out of the way to open the door ends</li>
        <li>75 (4b) When the duplighost that copied Kooper runs o screen after beating him</li>
        <li>76 (4c) After the cutscene with Madam Merlar giving you the star stone ends</li>
        <li>77 (4d) As soon as the ice stairs finish rising</li>
        <li>78 (4e) First time entering Crystal Palace while in chapter 7</li>
        <li>79 (4f) After beating the 2 duplighosts that were pretending to be Mario and Bombette's reflection</li>
        <li>80 (50) After the cutscene where you have to find the real Bombette ends</li>
        <li>81 (51) After beating the 3rd clubba that remove the statues blocking the door when defeated</li>
        <li>82 (52) After defeating the 3 duplighosts that very badly pretend to be Kooper</li>
        <li>83 (53) Hitting the blue switch with Kooper to widen the bridge</li>
        <li>84 (54) Pushing the mini Bowser statue that moves the large Bowser statue on the other side</li>
        <li>85 (55) Unravelling the red stairs that go to the room before Crystal King boss room</li>
        <li>86 (56) As soon as the white ball that turns into the star card after you beat Crystal King appears</li>
        <li>87 (57) As soon as you touch the chapter 7 star card</li>
        <li>
          88 (58) As soon as Peach appears in the Peach cutscene that happens after touching the chapter 7 star card
        </li>
        <li>89 (59) After the cutscene with Kalmar outside Crystal Palace ends</li>
      </dl>

      <dl className="list">
        <dt className="detail-title">Chapter 8</dt>
        <li>90 (5a) After the cutscene with the star beam appearing ends</li>
        <li>91 (5b) Entering Star Haven for the first time in chapter 8</li>
        <li>92 (5c) As soon as the stars start summoning the space ship/bath tub</li>
        <li>
          94 (5e) As soon as Mario gets off the space ship/bath tub in Bowsers castle for the first time (You don't have
          to be in chapter 8 for this to trigger)
        </li>
        <li>
          95 (5f) As soon as Mario enters Peach's courtyard (The outside room thats between the exit to Bowser's castle
          and the entrance to Peach's castle)
        </li>
        <li>
          96 (60) As soon as the cutscene where all the star spirits and Twink go back to Star Haven (This is after you
          beat Bowser) changes to Mario and Luigi back at their house
        </li>
      </dl>
    </>
  );
}
