import { Link } from "react-router-dom";
import { useEffect } from "react";
import { PageProps } from "../types/PageProps";

export default function Categories({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Categories");
  });
  return (
    <>
      <h1 className="heading">Categories</h1>
      <ul>
        <h1 className="tricks-list-title">
          <Link to="/Any_(No_ACE)">Any% (No ACE)</Link>
        </h1>
        <p className="category-description">Beat the game as fast as possible without Arbitrary Code Execution!</p>
        <h1 className="tricks-list-title">
          <Link to="/All_Cards">All Cards</Link>
        </h1>
        <p className="category-description">Beat the game as fast as possible while collecting all 7 Star Spirit cards!</p>
        <h1 className="tricks-list-title">
          <Link to="/Glitchless">Glitchless</Link>
        </h1>
        <p className="category-description">Beat the game as fast as possible without glitches!</p>
        <h1 className="tricks-list-title">
          <Link to="/Any_(No_Peach_Warp)">Any% (No Peach Warp)</Link>
        </h1>
        <p className="category-description">
          Beat the game as fast as possible without <Link to="/Peach_Warp">Peach Warp</Link>!
        </p>
        <h1 className="tricks-list-title">
          <Link to="/All_Bosses">All Bosses</Link>
        </h1>
        <p className="category-description">Beat the game as fast as possible and defeat all bosses!</p>
        <h1 className="tricks-list-title">
          <Link to="/100">100%</Link>
        </h1>
        <p className="category-description">Do everything there is to do in Paper Mario, then beat the game!</p>
        <h1 className="tricks-list-title">
          <Link to="/Reverse_All_Cards">Reverse All Cards</Link>
        </h1>
        <p className="category-description">
          Beat the game as fast as possible while collecting all 7 Star Spirit cards... backwards!
        </p>
        <h1 className="tricks-list-title">
          <Link to="/No_Major_Sequence_Breaks">No Major Sequence Breaks</Link>
        </h1>
        <p className="category-description">Beat the game as fast as possible without breaking it too hard!</p>
      </ul>
    </>
  );
}
