import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { categories } from "../data/categories";
import { PageProps } from "../types/PageProps";
import { Category } from "../types/Category";

export default function CategoryPage({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });

  const path = useLocation().pathname.split("/")[2];
  const category = categories.find((category) => category.path === path) as Category;

  return (
    <>
      {category.name && <h1 className="heading">{category.name}</h1>}
      <dl className="list">
        {category.description && (
          <>
            <dt className="detail-title">Description</dt>
            <dd>{category.description}</dd>
          </>
        )}
        {category.history && (
          <>
            <dt className="detail-title">History</dt>
            <dd>{category.history}</dd>
          </>
        )}
        {category.leaderboards && (
          <>
            <dt className="detail-title">Leaderboards</dt>
            <dd>
              <a target="_blank" rel="noopener noreferrer" href={category.leaderboards}>
                Speedrun.com
              </a>
            </dd>
          </>
        )}
        {category.routes && (
          <>
            <dt className="detail-title">Routes</dt>
            {category.routes.map((route, j) =>
              route.path ? (
                <dd key={`${route.level}${j}`}>
                  <Link to={route.path}>{route.level}</Link> - {route.tag}
                </dd>
              ) : (
                <dd key={`${route.level}${j}`}>
                  <a target="_blank" rel="noopener noreferrer" href={route.external}>
                    {route.level}
                  </a>{" "}
                  - {route.tag}
                </dd>
              )
            )}
          </>
        )}
        {category.videoTutorials && (
          <>
            <dt className="detail-title">Video Tutorials</dt>
            {category.videoTutorials.map((video, i) => (
              <dd key={`${i}.${video.author}`}>
                <a target="_blank" rel="noopener noreferrer" href={video.url}>
                  {video.author}
                </a>
              </dd>
            ))}
          </>
        )}
      </dl>
    </>
  );
}
