import { Link } from "react-router-dom";

export const anyNoAceExpertBoth = (
  <>
    <h1 className="heading">Prologue</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Jr_Skip">Jr Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- 10 coins from the brick block on the ground [10 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hammer coin block for 1 coin [11 coins]</dt>
      <dt className="route-instruction-bold">- Fire Flower and 4 trampoline coins [15 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Power Jump Tutorial</dt>
      <dt className="route-instruction-bold">- Equip Power Jump immediately</dt>
      <dt className="route-instruction-bold">- Goomnut</dt>
      <dt className="route-instruction-bold">- Two coin blocks on the next screen [17 coins]</dt>
      <dt className="route-instruction-bold">- Close Call</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump Red / Headbonk Red</dd>
      <dd className="route-instruction-bold">T2: Power Jump Blue / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Fire Flower (Up/Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Goomnut (Mash A)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba King</dt>
      <dd className="route-instruction-bold">T1: Power Jump King / Headbonk Tree</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Headbonk</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Shooting Star Summit</dt>
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Action Command Tutorial</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Magikoopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Headbonk x2 - (Blocking saves a few frames!)</dd>
      <dd className="route-instruction-bold">T2: Jump x2</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Black_Toad_Skip">Black Toad Skip</Link>
      </dt>
    </dl>

    <h1 className="heading">Chapter 1</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 1 coin block [18 coins]</dt>
      <dt className="route-instruction-bold">- POW Block</dt>
      <dt className="route-instruction-bold">- 10 coin block [28 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: POW Block (Mash A) / Untimed Power Shell</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Bush coin in Koopa Village [29 coins]</dt>
      <dt className="route-instruction-bold">- 5 coins on ground [34 coins]</dt>
      <dt className="route-instruction-bold">- Thunder Bolt</dt>
      <dt className="route-instruction-bold">- Hidden Fire Flower</dt>
      <img className="image" src="https://mario.wiki.gallery/images/a/a8/PMHiddenBlock2.png" alt="Fire Flower"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Koopa with a jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Key Fight</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">
        T1: Power Jump / Untimed Shell Toss (Use untimed Power Shell instead if you missed the first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Bounce</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Staircase_Skip">Staircase Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jail Fight</dt>
      <dd className="route-instruction-bold">T1: Fire Flower (Mash A) / Body Slam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 9 coins from Jail Fight [43 coins]</dt>
      <dt className="route-instruction">- Go to Toad Town</dt>
      <dt className="route-instruction-bold">- Honey Syrup behind the pillar</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Log_Skip">Log Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Get 1st Magical Seed from the Bub-ulb in Toad Town</dt>
      <dt className="route-instruction">- Take Train to Chapter 2</dt>
    </dl>

    <h1 className="heading">Blue House Skip</h1>

    <dl className="list">
      <dt className="route-instruction">- Find 3 Letters and bring them to Parakarry</dt>
      <dt className="route-instruction-bold">- Whacka Bump</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Rock_Climb">Rock Climb</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Early_Seed">Early Seed</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- First Strike the Cleft by the rightmost letter with Bombette</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Cleft</dt>
      <dd className="route-instruction-bold">T0: First Strike Bomb</dd>
      <dd className="route-instruction-bold">T1: Bomb / (Thunder Bolt (Up 1) if you miss the first strike)</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 5-7 coins from Cleft [48-50 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 2nd Whacka Bump</dt>
      <dt className="route-instruction-bold">- Slide coins to get to 50 [50 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Parakarry</dt>
      <dt className="route-instruction-bold">
        - Go back and get the 2nd Magical Seed using Parakarry if you didn't do <Link to="/Early_Seed">Early Seed</Link>
      </dt>
      <dt className="route-instruction">- Go back to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy Speedy Spin</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Blue_House_Skip">Blue House Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce / Sky Dive</dd>
    </dl>

    <h1 className="heading">Chapter 5</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Raphael_Skip">Raphael Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Close Call (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Z)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Fire Shield</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ultra_Hammer_Early">Ultra Hammer Early</Link>
      </dt>
      <dt className="route-instruction-bold">- Ultra Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Fall down on the zipline in the middle of the room (White glowing wall)</dt>
      <dt className="route-instruction-bold">- Upgrade Parakarry (Up 1)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Flarakarry">Flarakarry</Link>
      </dt>
      <dt className="route-instruction-bold">- Maple Syrup (2nd block)</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Hit the switch on the wall (right of fireplace), run south to the lowest door, and go into the leftmost door.
        Watch out for the guards
      </dt>
      <dt className="route-instruction">- Quiz: 1, 1, 1, 1, 1, 1, 1, 1, 1, 2</dt>
    </dl>

    <h1 className="heading">Peach Warp</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Jammin' Jelly behind the tree</dt>
      <dt className="route-instruction-bold">- Volcano Vase from the chest in the jungle</dt>
      <dt className="route-instruction-bold">- 3rd Magical Seed from Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Take the blue pipe and head towards the blue house</dt>
      <dt className="route-instruction">- Go to Forever Forest</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Forever Forest</dt>
      <dd className="route-instruction">Right x2</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction-bold">Go left and get the 4th Magical Seed</dd>
      <dd className="route-instruction">Right x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Give all the seeds to Minh T</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Early_Lakilester">Early Lakilester</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - If you failed the Cleft First Strike, get the Shooting Star and use it instead of the Thunder Bolt
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Shooting Star</dt>
      <dt className="route-instruction">- Blow up the rock</dt>
      <dt className="route-instruction">- Talk to the Sun</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Spike Fight - Blocking is faster</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Whacka Bump (Up 3) / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Thunder Bolt (Up 1) or Shooting Star (Mash A) / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Shell Shot / Star Storm (Up/Down 3)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go through the Blue House and ride Lakilester across the spikes</dt>
      <dt className="route-instruction-bold">- Ultra Boots</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/c/c1/PMHiddenBlock12.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Go to the Volcano and <Link to="/Lakilester_Peach_Warp">Peach Warp</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - When you do <Link to="/Lakilester_Peach_Warp">Peach Warp</Link> the first time, hold down after you clip with
        Lakilester to avoid a softlock
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Transform into a Koopatrol, talk to the guard at the top, go down and transform into a Clubba, and then head
        back to the top
      </dt>
    </dl>

    <h1 className="heading">Peach Warp 2</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Climb the beanstalk to get S. Jump Charge</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Head to the Volcano through the Blue House</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Lakilester_Peach_Warp">Peach Warp</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>

    <h1 className="heading">Chapter 8</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Thunder Rage from pillar if you used the Shooting Star</dt>
      <dt className="route-instruction-bold">- Upgrade Lakilester (Up 1) on the stairs</dt>
      <dt className="route-instruction-bold">- Repel Gel behind the tree</dt>
      <dt className="route-instruction-bold">- Upgrade Bombette (Down 2) in the sewers</dt>
      <dt className="route-instruction">- Head to Shooting Star Summit</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols</dt>
      <dd className="route-instruction-bold">T1: Thunder Rage (Down 1) or Shooting Star (Down 2) / Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Tasty Tonic in the bottom left crate (face right)</dt>
      <dt className="route-instruction-bold">- Jail Life Shroom</dt>
      <dt className="route-instruction-bold">- Island Life Shroom</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Block_Clip">Block Clip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Fast_Flood_Room">Fast Flood Room</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Cannonless">Cannonless</Link>
      </dt>
      <dt className="route-instruction">- Quiz: 1, 1, 2, 1, 1, 3, 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Dupe on the Maple Syrup in the leftmost block after the quiz door</dt>
    </dl>
    <dl className="list">
      <dt className="item-dupe">Item Dupe</dt>
      <dd className="route-instruction-bold">- Eat Honey Syrup (Up 1)</dd>
      <dd className="route-instruction-bold">- Toss Repel Gel</dd>
      <dd className="route-instruction-bold">- Pick up Repel Gel</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Maze: Up, Down, Down, Up, Down, Up</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- If you don't have full HP, hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts - Block 3/4</dt>
      <dd className="route-instruction-bold">T1: Power Shell / Swap to Bombette (Down 1)</dd>
      <dt className="fight">0-2 Transform:</dt>
      <dd className="route-instruction-bold">T2: Power Bomb / Star Storm</dd>
      <dt className="fight">3 Transform:</dt>
      <dd className="route-instruction-bold">T2: Power Bomb / Jump x2</dd>
      <dd className="route-instruction-bold">T3: Jump x1</dd>
      <dt className="fight">4 Transform:</dt>
      <dd className="route-instruction-bold">T2: Power Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block all</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Shell Shot / Whacka Bump (Up 1)</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Shell Shot / Maple Syrup (Down 1)</dd>
      <dd className="route-instruction-bold">T5: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T6: Bounce x6 / Shell Shot</dd>
      <dd className="route-instruction-bold">T7: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - There is a safer but slower Jr. Troopa that you can do. Switch fights on T3 based on your HP
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Extra Safe Backup Jr. Troopa - Block only when in Danger</dt>
      <dd className="route-instruction-bold">T1: Chill Out (Down 4) / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Shell Shot</dd>
      <dt className="fight">1 HP:</dt>
      <dd className="route-instruction-bold">T3: Whacka Bump (Up 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Maple Syrup (Down 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T7: Bounce x6 / Do Nothing</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
      <dt className="fight">5 HP:</dt>
      <dd className="route-instruction-bold">T3: Maple Syrup (Down 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Whacka Bump (Up 1) / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T7: Bounce x6 / Do Nothing</dd>
      <dd className="route-instruction-bold">T8: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Maple Syrup (Up 2)</dt>
      <dt className="route-instruction-bold">- If you are not at 10 HP, get and eat the hidden Ultra Shroom</dt>
      <dt className="route-instruction-bold">- Last Stand (lower floor, first room on the right)</dt>
      <dt className="route-instruction-bold">- Library Life Shroom</dt>
      <dt className="route-instruction-bold">- Power Rush</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Last Stand (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Rush (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Fire Shield (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Follow the beamless route, then switch if Bowser uses Star Shield</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser: Beamless - Block all</dt>
      <dd className="route-instruction-bold">T1: Star Storm / Sky Dive</dd>
      <dd className="route-instruction-bold">T2: Jump x2 / Sky Dive - Star Shield</dd>
      <dd className="route-instruction-bold">T3: Star Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Jump x2 / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Jump x2 / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser: 4</dt>
      <dt className="fight">1-4 HP:</dt>
      <dd className="route-instruction-bold">T5: Star Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Jump x2</dd>
      <dt className="fight">5 HP:</dt>
      <dd className="route-instruction-bold">T5: Star Beam / Do Nothing</dd>
      <dd className="route-instruction-bold">T6: Jump x2 / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Last Stand (Down 3)</dd>
      <dd className="route-instruction-bold">Unequip Fire Shield (Down 3)</dd>
      <dd className="route-instruction-bold">Unequip Speedy Spin (Down 1)</dd>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Z, Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 1 - Block all</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce x3 / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: WITH PARTNER'S TURN: Swap to Lakilester (Down 1) / Star Beam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- If Mario gets hit by Wave, you instantly lose</dt>
      <dt className="route-instruction">
        - 5 cap will win and 4 cap + Close Call or extra Life Shroom will win, but 3 cap will probably lose unless you
        get very lucky
      </dt>
      <dt className="route-instruction">
        - If you reach the final turn and Bowser is not dead, follow the directions depending on if you are in Danger or
        not. Repeat those actions until you win (or lose)
      </dt>
      <dt className="route-instruction">- If Cloud Nine fails all 3 times, switch to the second fight</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 2 - Block all</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Cloud Nine</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Jammin' Jelly / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Repel Gel / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T6: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T7: Repel Gel / Do Nothing</dd>
      <dd className="route-instruction-bold">T8: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T9: Peach Beam / Do Nothing</dd>
      <dd className="route-instruction-bold">T10: Bounce x5 / Do Nothing</dd>
      <dt className="fight">Danger:</dt>
      <dd className="route-instruction-bold">T11+: Jump x2 / Spiny Flip</dd>
      <dt className="fight">Not in Danger:</dt>
      <dd className="route-instruction-bold">T11+: Spiny Flip / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Cloud Nine never works</dt>
      <dd className="route-instruction-bold">T4: Repel Gel / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T6: Repel Gel / Do Nothing</dd>
      <dd className="route-instruction-bold">T7: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T8: Peach Beam / Swap to Parakarry (Up 1)</dd>
      <dd className="route-instruction-bold">T9: Bounce x6 / Shell Shot</dd>
      <dt className="fight">Danger:</dt>
      <dd className="route-instruction-bold">T10+: Jump x2 / Sky Dive</dd>
      <dt className="fight">Not in Danger:</dt>
      <dd className="route-instruction-bold">T10+: Sky Dive / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - <Link to="/Luigi_Skip">Luigi Skip</Link>
      </dt>
    </dl>
  </>
);
