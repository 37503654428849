import { Link } from "react-router-dom";
import { Category } from "../../types/Category";
import { allCardsBeginner } from "../routes/allcardsbeginner";
import { allCardsIntermediate } from "../routes/allcardsintermediate";
import { allCardsExpert } from "../routes/allcardsexpert";

export const allCards: Category = {
  name: "All Cards",
  path: "All_Cards",
  description: <>Beat Paper Mario as fast as possible while also touching all 7 star spirit cards.</>,
  history: (
    <>
      For much of Paper Mario's history, All Cards was the most popular category over{" "}
      <Link to="/Any_(No_ACE)">Any%</Link>.
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#All_Cards",
  routes: [
    {
      level: "Beginner",
      path: "Beginner",
      tag: "No Clippy Boots and LZS is optional",
      external: "https://docs.google.com/document/d/18kBsr0M0F85b_naj49DZa46O-MVi2-LxKoTG2DvSh_A/edit?usp=sharing",
      description: (
        <>
          This is a beginner <Link to="/All_Cards">All Cards</Link> route that does not require any{" "}
          <Link to="/Loading_Zone_Storage">Loading Zone Storage</Link> and has a safe ending. This route completes
          Chapter 2 before Chapter 5 to avoid having to do <Link to="/Clippy_Boots">Clippy Boots</Link>.
        </>
      ),
      versions: "All",
      videoTutorials: [
        {
          author: "Pape Races",
          url: "https://youtu.be/Km5w55cFTO8",
        },
      ],
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lakilester Peach Warp",
          path: "/Tricks/Lakilester_Peach_Warp",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Pie Jumps",
          path: "/Tricks/Pie_Jumps",
        },
        {
          name: "Buzzar Skip",
          path: "/Tricks/Buzzar_Skip",
        },
        {
          name: "Record Skip",
          path: "/Tricks/Record_Skip",
        },
        {
          name: "Stanley_Save",
          path: "/Tricks/Stanley_Save",
        },
        {
          name: "Yakkey Trickshot",
          path: "/Tricks/Yakkey_Trickshot",
        },
        {
          name: "Quick Escape",
          path: "/Tricks/Quick_Escape",
        },
        {
          name: "Chapter 3 Card LZS",
          path: "/Tricks/Chapter_3_Card_LZS",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Pink Station Clips",
          path: "/Tricks/Pink_Station_Clips",
        },
        {
          name: "Chapter 4 Card LZS",
          path: "/Tricks/Chapter_4_Card_LZS",
        },
        {
          name: "Chapter 2 Card LZS",
          path: "/Tricks/Chapter_2_Card_LZS",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
      ],
      content: allCardsBeginner,
    },
    {
      level: "Intermediate",
      path: "Intermediate",
      tag: "Does Clippy Boots and Card LZS - No Jr Skip",
      external: "https://docs.google.com/document/d/1JyUnwXQHSN-jG4wg_CZGja5CPb0_9_DeuT-hF4A2x48/edit?usp=sharing",
      description: (
        <>
          This is a highly optimized <Link to="/All_Cards">All Cards</Link> route that does not do{" "}
          <Link to="/Jr_Skip">Jr Skip</Link> and has a safe ending.{" "}
        </>
      ),
      versions: "All",
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Chapter 3 Card LZS",
          path: "/Tricks/Chapter_3_Card_LZS",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Chapter 4 Card LZS",
          path: "/Tricks/Chapter_4_Card_LZS",
        },
        {
          name: "Chapter 2 Card LZS",
          path: "/Tricks/Chapter_2_Card_LZS",
        },
        {
          name: "Clippy Boots",
          path: "/Tricks/Clippy_Boots",
        },
        {
          name: "Lakilester Peach Warp",
          path: "/Tricks/Lakilester_Peach_Warp",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Pie Jumps",
          path: "/Tricks/Pie_Jumps",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Record Skip",
          path: "/Tricks/Record_Skip",
        },
        {
          name: "Stanley_Save",
          path: "/Tricks/Stanley_Save",
        },
        {
          name: "Yakkey Trickshot",
          path: "/Tricks/Yakkey_Trickshot",
        },
        {
          name: "Quick Escape",
          path: "/Tricks/Quick_Escape",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Pink Station Clips",
          path: "/Tricks/Pink_Station_Clips",
        },
        {
          name: "Yellow Berry Skip",
          path: "/Tricks/Yellow_Berry_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
      ],
      content: allCardsIntermediate,
    },
    {
      level: "Expert",
      path: "Expert",
      tag: "Does Jr Skip",
      external: "https://docs.google.com/document/d/1qwdbiywNxlaDjtSAJzLxu_IlJlpLzarqe7V0zaHh1_4/edit?usp=sharing",
      description: (
        <>
          This is a highly optimized <Link to="/All_Cards">All Cards</Link> route that does{" "}
          <Link to="/Jr_Skip">Jr Skip</Link> and has a safe ending.
        </>
      ),
      versions: "All",
      videoTutorials: [
        {
          author: "Pape Races",
          url: "https://youtu.be/R4O0Cs-Rktk",
        },
      ],
      expectedTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Record Skip",
          path: "/Tricks/Record_Skip",
        },
        {
          name: "Stanley_Save",
          path: "/Tricks/Stanley_Save",
        },
        {
          name: "Quick Escape",
          path: "/Tricks/Quick_Escape",
        },
        {
          name: "Chapter 3 Card LZS",
          path: "/Tricks/Chapter_3_Card_LZS",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Pink Station Clips",
          path: "/Tricks/Pink_Station_Clips",
        },
        {
          name: "Chapter 4 Card LZS",
          path: "/Tricks/Chapter_4_Card_LZS",
        },
        {
          name: "Chapter 2 Card LZS",
          path: "/Tricks/Chapter_2_Card_LZS",
        },
        {
          name: "Clippy Boots",
          path: "/Tricks/Clippy_Boots",
        },
        {
          name: "Lakilester Peach Warp",
          path: "/Tricks/Lakilester_Peach_Warp",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Pie Jumps",
          path: "/Tricks/Pie_Jumps",
        },
        {
          name: "Yakkey Trickshot",
          path: "/Tricks/Yakkey_Trickshot",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Yellow Berry Skip",
          path: "/Tricks/Yellow_Berry_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
      ],
      content: allCardsExpert,
    },
    {
      level: "Expert",
      tag: "Very Risky - Seed Dupe",
      external: "https://docs.google.com/document/d/1pc8yJ8AKOf3HGTIj7iTUbOHalo3KVC8qo9cP7SmFx2E/edit",
    },
    {
      level: "WR Route",
      tag: "Very Risky - Seed Dupe and Parakarry Skip",
      external: "https://docs.google.com/document/d/1QtZD4ObT49yktUAgxb838LGg89vTNPlXGbAg0iXI6Qs/edit?usp=sharing",
    },
    {
      level: "WR Route (Old)",
      tag: "Does Bow Skip",
      external: "https://docs.google.com/document/d/1HCp9uhU8AMibAtbSUNaL9-fTu9EIsMlLr1-oKZKGRm0/edit?usp=sharing",
    },
  ],
};
