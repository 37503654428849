import { Link } from "react-router-dom";

export const anyNoPWBeginner = (
  <>
    <h1 className="heading">Prologue</h1>

    <dl className="list">
      <dt className="route-instruction">
        - Do <Link to="/Quick_Jump">Quick Jumps</Link> on Bowser by pressing A when Mario squats before jumping
      </dt>
      <dt className="route-instruction">- Talk to Goompa on the balcony</dt>
      <dt className="route-instruction">- Talk to Goompapa by the gate</dt>
      <dt className="route-instruction">- Head to the balcony</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 3 bush coins from 3 different bushes before the hammer. [3 coins]</dt>
      <dt className="route-instruction">
        - Hammer from the leftmost bush (get it from the front of the bush, it's faster)
      </dt>
      <dt className="route-instruction-bold">- 2 coins from the top bush [5 coins]</dt>
      <dt className="route-instruction-bold">- 10 coins from the brick block on the ground. [15 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Defeat Jr. Troopa by spamming Jump. (Don't forget your <Link to="/Quick_Jump">Quick Jumps</Link>!)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hammer coin block for 1 coin. [16 coins]</dt>
      <dt className="route-instruction-bold">- Fire Flower and 4 trampoline coins. [20 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Power Jump Tutorial</dt>
      <dt className="route-instruction-bold">- Equip Power Jump immediately</dt>
      <dt className="route-instruction-bold">- Two coin blocks on the next screen. [22 coins]</dt>
      <dt className="route-instruction-bold">- Close Call</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba Bros</dt>
      <dd className="route-instruction-bold">T1: Power Jump Red / Headbonk Red</dd>
      <dd className="route-instruction-bold">T2: Power Jump Blue / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Fire Flower (Mash A)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Goomba King</dt>
      <dd className="route-instruction-bold">T1: Power Jump King / Headbonk Tree</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Headbonk</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Shooting Star Summit</dt>
      <dt className="route-instruction">- Say NO (2nd option, press B) TWICE to the Action Command Tutorial</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Magikoopa</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Do Nothing - (Blocking saves a few frames!)</dd>
      <dd className="route-instruction-bold">T2: Power Jump</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Black_Toad_Skip">Black Toad Skip</Link> or <Link to="/Merlon_Skip">Merlon Skip</Link>
      </dt>
      <dt className="route-instruction">
        - Alternatively, go speak to the black toads, then go speak to Merlon, then go speak to the black toads again
      </dt>
    </dl>

    <h1 className="heading">Chapter 1</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- 1 coin block [23 coins]</dt>
      <dt className="route-instruction-bold">- Fright Jar</dt>
      <dt className="route-instruction-bold">- 10 coin block [33 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to Koopa Village and go to the 5th house</dt>
      <dt className="route-instruction">
        - It helps to look at the fuzzies and count the amount of bounces that they do to ensure you never fail the
        minigame.{" "}
      </dt>
      <dd className="route-instruction">
        If they bounce an even number of times, it's guaranteed to not be in the tree to the left or right of its
        original position.{" "}
      </dd>
      <dd className="route-instruction">
        If they bounce and odd number of times, it's guaranteed to be to the left or right of its original position,
        unless it bounces from the far left to the far right or vise versa
      </dd>
    </dl>
    <dl className="list">
      <dt className="fight">Fuzzy Fight</dt>
      <dd className="route-instruction-bold">T1: Fright Jar (Mash A) / Kill any remaining fuzzies with basic attacks</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Mash A and B after the fight ends if you gained 0 star points. (It's faster!)
      </dt>
      <dt className="route-instruction-bold">- HP Plus</dt>
      <dt className="route-instruction">- Leave Koopa Village and heads towards the Fortress</dt>
      <dt className="route-instruction-bold">- 5 coins on ground [38 coins]</dt>
      <dt className="route-instruction-bold">- Thunder Bolt</dt>
      <dt className="route-instruction-bold">- Hidden Fire Flower</dt>
      <img className="image" src="https://mario.wiki.gallery/images/a/a8/PMHiddenBlock2.png" alt="Fire Flower"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
      <dt className="route-instruction-bold">- First Strike the Koopa with a jump</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopa Key Fight</dt>
      <dd className="route-instruction-bold">T0: First Strike Jump</dd>
      <dd className="route-instruction-bold">
        T1: Power Jump / Untimed Shell Toss (Use untimed Power Shell instead if you missed the first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Power Bounce</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Staircase_Skip">Staircase Skip</Link>
      </dt>
      <dt className="route-instruction">- Fall for the trap</dt>
      <dt className="route-instruction">- Blow up the wall</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jail Fight</dt>
      <dd className="route-instruction-bold">
        T1: Fire Flower (Mash A) / Bomb (Body Slam instead if you missed the Koopa first strike)
      </dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 10 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- 9 coins from Jail Fight [47 coins]</dt>
      <dt className="route-instruction">- Go to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Log_Skip">Log Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Get 1st Magical Seed from the Bub-ulb in Toad Town</dt>
      <dt className="route-instruction">- Take Train to Chapter 2</dt>
    </dl>

    <h1 className="heading">Blue House Skip</h1>

    <dl className="list">
      <dt className="route-instruction">- Find 3 Letters and bring them to Parakarry</dt>
      <dt className="route-instruction-bold">- Get 2 Whacka Bumps in total</dt>
      <dt className="route-instruction-bold">- 3 coins from slide [50 coins]</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Rock_Climb">Rock Climb</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Early_Seed">Early Seed</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get Parakarry</dt>
      <dt className="route-instruction-bold">
        - Go back and get the 2nd Magical Seed using Parakarry if you didn't do <Link to="/Early_Seed">Early Seed</Link>
      </dt>
      <dt className="route-instruction">- Go back to Toad Town</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Buy Speedy Spin</dt>
      <dt className="route-instruction">
        - If Speedy Spin is not at the shop, go down 1 room, then left 1 room, then return to the shop
      </dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Speedy Spin (Down 2)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Blue_House_Skip">Blue House Skip</Link>
      </dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Blooper</dt>
      <dd className="route-instruction-bold">T1: Power Bounce / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Bounce / Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Take the pipe to Chapter 5</dt>
    </dl>

    <h1 className="heading">Chapter 5</h1>

    <dl className="list">
      <dt className="route-instruction">- Head right</dt>
      <dt className="route-instruction">- Talk to Kolorado by the volcano</dt>
      <dt className="route-instruction-bold">- Jammin' Jelly behind the tree</dt>
      <dt className="route-instruction-bold">- Get Sushie</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Raphael_Skip">Raphael Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Volcano_Jump">Volcano Jump</Link>
      </dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/e/e4/PMHiddenBlock32.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ultra_Hammer_Early">Ultra Hammer Early</Link>
      </dt>
      <dt className="route-instruction-bold">- Ultra Hammer</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Fall down on the zipline in the middle of the room (White glowing wall)</dt>
      <dt className="route-instruction-bold">- Upgrade Bombette (Down 2)</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Bounce (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Close Call (Down 2)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Z)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Flarakarry">Flarakarry</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Maple Syrup (2nd block)</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Hit the switch on the wall (right of fireplace), run south to the lowest door, and go into the leftmost door.
        Watch out for the guards
      </dt>
      <dt className="route-instruction">- Quiz: 1, 1, 1, 1, 1, 1, 1, 1, 1, 2</dt>
    </dl>

    <h1 className="heading">Chapter 6</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Volcano Vase from the chest in the jungle</dt>
      <dt className="route-instruction-bold">- 3rd Magical Seed from Kolorado</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Hidden Repel Gel on the beach</dt>
      <img className="image" src="https://mario.wiki.gallery/images/d/d4/PMHiddenBlock27.png" alt="Repel Gel"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Take the blue pipe and head towards the blue house</dt>
      <dt className="route-instruction">- Go to Forever Forest</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Forever Forest</dt>
      <dd className="route-instruction">Right x2</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction">Left x1</dd>
      <dd className="route-instruction-bold">Go left and get the 4th Magical Seed</dd>
      <dd className="route-instruction">Right x1</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Give all the seeds to Minh T</dt>
      <dt className="route-instruction">- Go right</dt>
      <dt className="route-instruction-bold">- Get a Stinky Herb from the ivy</dt>
      <dt className="route-instruction">- Talk to Petunia</dt>
      <dt className="route-instruction-bold">- Mole order: Top left, then clockwise</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Top Left Mole</dt>
      <dd className="route-instruction-bold">T1: Hammer / Bomb</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Red Berry</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Top Right Mole</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Stinky Herb (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Right Mole</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 3 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Bottom Left Mole</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Magical Bean</dt>
      <dt className="route-instruction">- Talk to Petunia</dt>
      <dt className="route-instruction-bold">- Go down-left, get Yellow Berry</dt>
      <dt className="route-instruction">- Go down-right, give Yellow Berry to Yellow Flower</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction-bold">- Get Blue Berry on the way back to the hub</dt>
      <dt className="route-instruction">- Go left, give Blue Berry to Blue Flower</dt>
      <dt className="route-instruction">- Hit the hidden block to the right of the spring on the way</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Lakitu Fight</dt>
      <dd className="route-instruction-bold">T1: Shell Shot Lakitu / Star Storm</dd>
      <dt className="fight">Spinyless</dt>
      <dd className="route-instruction-bold">T2: Shell Shot</dd>
      <dt className="fight">Spiny Spawn</dt>
      <dd className="route-instruction-bold">T2: Thunder Bolt Spiny (Up 1) / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Eat Maple Syrup (Down 1)</dt>
      <dt className="route-instruction">- Talk to Rosie</dt>
      <dt className="route-instruction-bold">- Get the hidden Shooting Star by taking the spring</dt>
      <img
        className="image"
        src="https://mario.wiki.gallery/images/1/1e/PMHiddenBlock35.png"
        alt="Shooting Star"
      ></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Talk to Posie (down-left)</dt>
      <dt className="route-instruction">- Talk to Rosie (left again)</dt>
      <dt className="route-instruction-bold">- Dizzy Dial in the left block in the Bubble Berry room</dt>
      <dt className="route-instruction">- Talk to Lily</dt>
      <dt className="route-instruction">- Place the Water Stone</dt>
      <dt className="route-instruction-bold">- Bubble Berry</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go up-left, give the Bubble Berry to the Bubble Plant</dt>
      <dt className="route-instruction-bold">- Shooting Star</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Swap to Parakarry before the fight (Down 1)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Spike Fight - Block 5/5</dt>
      <dd className="route-instruction-bold">T1: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Shell Shot / Whacka Bump (Up 1 or Up 2)</dd>
      <dd className="route-instruction-bold">T4: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Power Jump / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Leave Flower Fields</dt>
      <dt className="route-instruction">- Go through the Blue House and ride Lakilester across the spikes</dt>
      <dt className="route-instruction-bold">- Ultra Boots</dt>
      <dt className="route-instruction-bold">- Hidden Life Shroom</dt>
      <img className="image" src="https://mario.wiki.gallery/images/c/c1/PMHiddenBlock12.png" alt="Life Shroom"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Ride Laki across the spikes and go right</dt>
      <dt className="route-instruction-bold">- Shooting Star</dt>
      <dt className="route-instruction">- Hit the hidden blocks to open the path to Chapter 7</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Return to Flower Fields and go up-right</dt>
      <dt className="route-instruction-bold">- Hidden Thunder Rage</dt>
      <img className="image" src="https://mario.wiki.gallery/images/5/51/PMHiddenBlock36.png" alt="Thunder Rage"></img>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Dizzy Dial works 100% on Lakitus, and the Magikoopa can't kill you</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Machine Fight</dt>
      <dd className="route-instruction-bold">T1: Dizzy Dial (Down 4) / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: Thunder Rage (Mash A) / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 15 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Upgrade Parakarry (Down 3)</dt>
      <dt className="route-instruction">- Swap to Lakilester (Down 2)</dt>
      <dt className="route-instruction">- Talk to the tree and plant the beanstalk</dt>
      <dt className="route-instruction-bold">- S. Jump Charge</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Power Jump (A)</dd>
      <dd className="route-instruction-bold">Equip S. Jump Charge (Down 1)</dd>
      <dd className="route-instruction-bold">Equip Power Bounce (Down 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - You will usually lose 1 Life Shroom in this fight. The safer backup fight is less likely to lose Life Shrooms
        at the cost of 10 seconds, but it requires a difficult mash!
      </dt>
      <dt className="route-instruction">
        - On T6, Huff may be electrified. In this case, Mario will only be able to Jump x1, and you will win with Spiny
        Flip
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Huff N' Puff - Block only when in Danger</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x7 / Spiny Flip</dd>
      <dd className="route-instruction-bold">T5: Jump x2 (Star Storm instead if electrified) / Spiny Flip</dd>
      <dd className="route-instruction-bold">T6: Jump x2 (Star Storm instead if in peril) / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Safer Backup - Block when in Danger after T2</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Spiny Flip - Mash to block (You will take 2 damage)</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Do Nothing - Take 3 damage from wind</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x7 / Spiny Flip</dd>
      <dd className="route-instruction-bold">T5: Jump x2 (Star Storm instead if electrified) / Spiny Flip</dd>
      <dd className="route-instruction-bold">T6: Jump x2 (Star Storm instead if in peril) / Spiny Flip</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">
        - Transform into a Koopatrol, talk to the guard at the top, go down and transform into a Clubba, and then head
        back to the top
      </dt>
    </dl>

    <h1 className="heading">Chapter 7</h1>

    <dl className="list">
      <dt className="route-instruction-bold">- Talk to Merlon</dt>
      <dt className="route-instruction">- Go through the Blue House to Shiver City</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Murder_Solved_Early">Murder Solved Early</Link> (6 steps on the staircase)
      </dt>
      <dt className="route-instruction">
        - Alternatively, go to the Mayor's House, break the ice, talk to Herringway, and return to the Mayor's house
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - If you lost both Life Shrooms to Huff N' Puff, sell your Jammin' Jelly and buy a Life Shroom
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - <Link to="/Chapter_7_Sushie_Glitch">Sushie Glitch</Link>
      </dt>
      <dt className="route-instruction">- Head right on Sushie</dt>
      <dt className="route-instruction">
        - You will usually lose 1 Life Shroom here, but you could lose 2 if you are very unlucky
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr Troopa - Blocking is faster</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Belly Flop</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Belly Flop</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Do Nothing</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x6 / Belly Flop</dd>
      <dd className="route-instruction-bold">T5: Jump x2 / Belly Flop</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP (Spiny Spawn)</dt>
      <dt className="route-instruction-bold">15 HP | 20 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Enter Shiver Mountain</dt>
      <dt className="route-instruction-bold">- Encounter the first enemy</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Gulpit</dt>
      <dd className="route-instruction-bold">T1: Run Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Continue to the next room</dt>
      <dt className="route-instruction">- Swim past the Kooper switch and encounter the White Clubba</dt>
    </dl>
    <dl className="list">
      <dt className="fight">White Clubba</dt>
      <dd className="route-instruction-bold">T1: WITH PARTNER'S TURN: Swap to Lakilester (A) / Run Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Ice_Staircase_Skip">Ice Staircase Skip</Link> (Japanese Only)
      </dt>
      <dt className="route-instruction">- Alternatively, get the Star Stone and place it to raise the stairs</dt>
      <dt className="route-instruction-bold">- Mega Jump</dt>
      <dt className="route-instruction-bold">- Hit the Heart Block if Spinyless</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go through the bottom door and get the Blue Key</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Mirror_Clip">Mirror Clip</Link>
      </dt>
      <dt className="route-instruction">- Go through the top door and break the panel</dt>
      <dt className="route-instruction">- Hit Red button</dt>
      <dt className="route-instruction">- Go through the blue door and head to the front</dt>
      <dt className="route-instruction-bold">- Fall down and get the Red Key (4th Bombette is real)</dt>
      <dt className="route-instruction">- Hit Blue button and go right</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 1</dt>
      <dd className="route-instruction-bold">T1: Jump x2 Clubba / Bomb (Magikoopa will run away)</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP (Spinyless)</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 6 BP</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 2</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Clubba 3</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Blow up the wall in the room with the revolving door</dt>
      <dt className="route-instruction-bold">- Triple Dip</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Use Bombette to hit the switch and take the revolving door to the foreground
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Kooper_Puzzle_Skip">Kooper Puzzle Skip</Link> (Japanese Only)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go right, Kooper the switch, head to the background</dt>
      <dt className="route-instruction-bold">- Hidden Jammin' Jelly</dt>
      <img
        className="image"
        src="https://mario.wiki.gallery/images/5/5a/PMHiddenBlock39.png"
        alt="Jammin' Jelly"
      ></img>
      <dt className="route-instruction">- Push the statue then leave</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Head back to the front and fall down</dt>
      <dt className="route-instruction-bold">- Palace Key</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 2) while opening the lock</dt>
      <dt className="route-instruction-bold">- Hit the Heart Block</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Crystal King - Block 9/9</dt>
      <dd className="route-instruction-bold">T1: Chill Out / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Jammin' Jelly (Mash A) / Shell Shot</dd>
      <dd className="route-instruction-bold">
        T5: S. Jump Charge / Shell Shot (If you missed a block, Sky Dive a Crystal Bit and don't block)
      </dd>
      <dd className="route-instruction-bold">T6: S. Jump Charge / Do Nothing (If you missed a block, Shell Shot)</dd>
      <dd className="route-instruction-bold">T7: Power Bounce x5 / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-bp">- Level Up: BP</dt>
      <dt className="route-instruction-bold">10 HP | 20 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Equip HP Plus (Down 5)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">Peach Scene</dt>
      <dt className="route-instruction">- Hold B</dt>
    </dl>

    <h1 className="heading">Chapter 8</h1>

    <dl className="list">
      <dt className="route-instruction-bold">
        - Thunder Rage from the pillar if Spiny Spawn (No Thunder Bolt in inventory)
      </dt>
      <dt className="route-instruction-bold">- Shooting Star from the pillar</dt>
      <dt className="route-instruction-bold">
        - Repel Gel behind the tree (if items are full because you didn't use any Life Shrooms, toss a Life Shroom)
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Head to Shooting Star Summit</dt>
      <dt className="route-instruction">
        - Up & Away saves 6.8 seconds but has a 90% chance to work on both of them. It loses 25 seconds to use it again
        if it fails
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols (Safe)</dt>
      <dd className="route-instruction-bold">T1: Power Bomb / Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Koopatrols (Risky)</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Say yes (1st option) to the door</dt>
      <dt className="route-instruction-bold">- Jail Life Shroom if you don't have any</dt>
      <dt className="route-instruction-bold">
        - <Link to="/Block_Clip">Block Clip</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - Use Lakilester to <Link to="/Laki_Clip">Laki Clip</Link> at any corner in the flood room
      </dt>
      <dt className="route-instruction-bold">
        - Optional: <Link to="/Fast_Flood_Room">Fast Flood Room</Link>
      </dt>
      <dt className="route-instruction-bold">
        - <Link to="/Cannonless">Cannonless</Link>
      </dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Push 3rd block, 1st block, then 2nd block to get the key. Return to the second block room and use the key
      </dt>
      <dt className="route-instruction">- Answer 1st option, then 2nd option (A, then B)</dt>
      <dt className="route-instruction">- Quiz: 1, 1, 2, 1, 1, 3, 2</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Get the Jammin' Jelly by the stairs if you had to sell one in Shiver City</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Go to the top floor, hit the blue switch with Kooper and get the key</dt>
      <dt className="route-instruction">- Maze: Up, Down, Down, Up, Down, Up</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Hit the Heart Block if you don't have full stats</dt>
      <dt className="route-instruction-bold">- Swap to Parakarry (Down 2)</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Duplighosts</dt>
      <dd className="route-instruction-bold">T1: Up &amp; Away / Air Lift</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">
        - If you took damage or have less than 14 FP, go back and hit the Heart Block
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Jr. Troopa - Block 4/4</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Whacka Bump / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Power Bounce x6 / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Star Storm</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction-bold">- Go back and hit the Heart Block</dt>
      <dt className="route-instruction">- Head to the Library (upper floor, first room on the left)</dt>
      <dt className="route-instruction-bold">- Life Shroom if you don't have any or want to be extra safe</dt>
      <dt className="route-instruction-bold">- Power Rush</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Close Call (R, Up 1)</dd>
      <dd className="route-instruction-bold">Equip Power Rush (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Hallway Bowser - Block all</dt>
      <dd className="route-instruction-bold">T1: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: S. Jump Charge / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Star Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Power Bounce x5 / Sky Dive</dd>
      <dd className="route-instruction-bold">T5: Hammer / Sky Dive</dd>
    </dl>
    <dl className="list">
      <dt className="level-up-fp">- Level Up: FP</dt>
      <dt className="route-instruction-bold">15 HP | 25 FP | 9 BP</dt>
    </dl>
    <dl className="list">
      <dt className="route-instruction">- Right before Final Bowser:</dt>
    </dl>
    <dl className="list">
      <dt className="rebadge">Rebadge:</dt>
      <dd className="route-instruction-bold">Unequip Speedy Spin (R)</dd>
      <dd className="route-instruction-bold">Equip Close Call (Up 1)</dd>
      <dd className="route-instruction-bold">Unequip HP Plus (Up 2)</dd>
      <dd className="route-instruction-bold">Equip Triple Dip (Up 1)</dd>
      <dd className="route-instruction-bold">Unequip Power Bounce (Up 1)</dd>
      <dd className="route-instruction-bold">Unequip S. Jump Charge (Up 1)</dd>
      <dd className="route-instruction-bold">Equip Mega Jump (Up 1)</dd>
      <dd className="route-instruction-bold">Equip Power Jump (Up 1)</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - It is impossible to miss Shell Shot on Final Bowser, so just flick the stick to go fast
      </dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Phase 1 - Block all</dt>
      <dd className="route-instruction-bold">
        T1: Triple Dip: Shooting Star, Thunder Bolt (Thunder Rage), Shooting Star / Shell Shot
      </dd>
      <dd className="route-instruction-bold">T2: Mega Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Star Beam</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - Bowser will use Star Shield randomly, so you will have to scroll to find the correct fight based on his Star
        Shield pattern
      </dt>
      <dt className="route-instruction">
        - Follow the beamless route, then switch to your fight when Bowser uses Star Shield
      </dt>
      <dt className="route-instruction">
        - If you get T3 Star Shield, you need a Close Call or for Bowser to delay healing. Otherwise you will lose
      </dt>
      <dt className="route-instruction">- Block all attacks</dt>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: Beamless</dt>
      <dd className="route-instruction-bold">T1: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T2: Triple Dip: Repel Gel, Shooting Star, Shooting Star / Shell Shot</dd>
      <dd className="route-instruction-bold">T3: Power Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T4: Triple Dip: Repel Gel, Jammin' Jelly / Shell Shot</dd>
      <dd className="route-instruction-bold">T5: Mega Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Mega Jump / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3</dt>
      <dd className="route-instruction-bold">T4: Triple Dip: Repel Gel, Jammin' Jelly / Do Nothing</dd>
      <dd className="route-instruction-bold">T5: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Mega Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T7: Mega Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T8: Jump x1</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 3, 7</dt>
      <dd className="route-instruction-bold">T8: Peach Beam / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 4</dt>
      <dd className="route-instruction-bold">T5: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T6: Mega Jump / Shell Shot</dd>
      <dd className="route-instruction-bold">T7: Mega Jump / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="fight">Final Bowser: 5</dt>
      <dd className="route-instruction-bold">T6: Peach Beam / Shell Shot</dd>
      <dd className="route-instruction-bold">T7: Mega Jump / Shell Shot</dd>
    </dl>
    <dl className="list">
      <dt className="route-instruction">
        - <Link to="/Luigi_Skip">Luigi Skip</Link>
      </dt>
    </dl>
  </>
);
