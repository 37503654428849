import { Fragment, useEffect, useState } from "react";
import bingoSquares, { BingoSquare } from "../data/bingoSquares";
import { Link } from "react-router-dom";
import { PageProps } from "../types/PageProps";

export default function Bingo({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Bingo");
  });

  const sortedSquares = bingoSquares.map((weight) => {
    return weight.sort((a, b) => (a.name > b.name ? 1 : -1));
  });

  const allSquares = sortedSquares.reduce((sortedList, currentList) => currentList.concat(sortedList));

  const [focusedSquare, setFocusedSquare] = useState<BingoSquare | undefined>();

  const setActiveSquare = (name: string) => {
    if (focusedSquare?.name !== name) {
      setFocusedSquare(allSquares.find((square) => square.name === name));
    } else {
      setFocusedSquare(undefined);
    }
  };

  return (
    <>
      <h1 className="heading">Bingo</h1>

      <p className="page-description">
        <Link to="/Bingo_Generator">Want to generate a Bingo card? Click here!</Link>
      </p>

      <p className="page-description">
        The most important tricks to know for new players are <Link to="/Staircase_Skip">Staircase Skip</Link>,{" "}
        <Link to="/Log_Skip">Log Skip</Link>, <Link to="/Blue_House_Skip">Blue House Skip</Link>,{" "}
        <Link to="/Raphael_Skip">Raphael Skip/Yoshi Skip</Link>, and{" "}
        <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>.
      </p>

      <div className="wrapping-list">
        <ul className="index-list">
          <p className="tricks-list-title">Prologue</p>
          <li>
            <Link to="/Early_Star_Piece">Early Star Piece</Link>
          </li>
          <li>
            <Link to="/Jr_Skip">Jr Skip</Link>
          </li>
          <li>
            <Link to="/Refight_Goomba_King">Refight Goomba King</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Toad Town</p>
          <li>
            <Link to="/Black_Toad_Skip">Black Toad Skip</Link>
          </li>
          <li>
            <Link to="/Blue_House_Skip">Blue House Skip</Link>
          </li>
          <li>
            <Link to="/Chapter_4_Early">Chapter 4 Early</Link>
          </li>
          <li>
            <Link to="/Early_Train">Early Train</Link>
          </li>
          <li>
            <Link to="/Early_Whale">Early Whale</Link>
          </li>
          <li>
            <Link to="/Log_Skip">Log Skip</Link>
          </li>
          <li>
            <Link to="/Black_Toad_Skip">Lucky Star Skip</Link>
          </li>
          <li>
            <Link to="/Merlon_Skip">Merlon Skip</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Chapter 1</p>
          <li>
            <Link to="/FP_Plus_Early">FP Plus Early</Link>
          </li>
          <li>
            <Link to="/Smash_Charge_KSJ">Smash Charge KSJ</Link>
          </li>
          <li>
            <Link to="/Staircase_Skip">Staircase Skip</Link>
          </li>
          <li>
            <Link to="/Pie_Jumps">Pie Jumps</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Chapter 2</p>
          <li>
            <Link to="/Buzzar_Skip">Buzzar Skip</Link>
          </li>
          <li>
            <Link to="/Outpost_Jump">Outpost Jump</Link>
          </li>
          <li>
            <Link to="/Early_Seed">Parakarryless Seed</Link>
          </li>
          <li>
            <Link to="/Rock_Climb">Rock Climb</Link>
          </li>
          <li>
            <Link to="/Slow_Go_Early">Slow Go Early</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Chapter 3</p>
          <li>
            <Link to="/Bow_Skip">Bow Skip</Link>
          </li>
          <li>
            <Link to="/Chapter_3_Early">Chapter 3 Early</Link>
          </li>
          <li>
            <Link to="/Quick Escape">Quick Escape</Link>
          </li>
          <li>
            <Link to="/Record_Skip">Record Skip</Link>
          </li>
          <li>
            <Link to="/Stanley_Save">Stanley Save</Link>
          </li>
          <li>
            <Link to="/Yakkey_Trickshot">Yakkey Trickshot</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Chapter 4</p>
          <li>
            <Link to="/Bowless_Green_Station">Bowless Green Station</Link>
          </li>
          <li>
            <Link to="/Pink_Station_Clips">Pink Station Clips</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Chapter 5</p>
          <li>
            <Link to="/Volcano_Jump">Volcano Jump</Link>
          </li>
          <li>
            <Link to="/Flarakarry">Flarakarry</Link>
          </li>
          <li>
            <Link to="/Lakilester_Peach_Warp">Lakilester Peach Warp</Link>
          </li>
          <li>
            <Link to="/Lava_Piranha_Out_Of_Bounds">Lava Piranha Out Of Bounds</Link>
          </li>
          <li>
            <Link to="/Lava_Piranha_Skip">Lava Piranha Skip</Link>
          </li>
          <li>
            <Link to="/Lava_Puzzle_Skip">Lava Puzzle Skip</Link>
          </li>
          <li>
            <Link to="/Raphael_Skip">Raphael Skip/Yoshi Skip</Link>
          </li>
          <li>
            <Link to="/Sushie_Peach_Warp">Sushie Peach Warp</Link>
          </li>
          <li>
            <Link to="/Ultra_Hammer_Early">Ultra Hammer Early</Link>
          </li>
          <li>
            <Link to="/Ultra_Hammer_Skip">Ultra Hammer Skip</Link>
          </li>
        </ul>

        <ul className="index-list">
          <p className="tricks-list-title">Chapter 6</p>
          <li>
            <Link to="/Yellow_Berry_Skip">Yellow Berry Skip</Link>
          </li>
          <li>
            <Link to="/Early_Lakilester">Early Lakilester</Link>
          </li>
          <li>
            <Link to="/Sun_Tower_Skip">Sun Tower Skip</Link>
          </li>
        </ul>
      </div>

      <h1 className="heading">Bingo Squares</h1>

      <p className="page-description">
        Each Bingo square has a weight associated with it, which ranges from 1 (easiest) to 25 (hardest). Each Bingo
        card contains one square from each weight and is placed so that each row, column, and diagonal adds up to 65.
        Additionally, each Bingo square has tags to ensure that two closely related squares don't appear in the same
        row, column, or diagonal.
      </p>

      {sortedSquares.map((weight, i) => (
        <Fragment key={`${weight}-${i}`}>
          <p className="detail-title">Weight: {i}</p>
          {weight.find((square) => square === focusedSquare) && (
            <div className="bingo-content">{focusedSquare?.info}</div>
          )}
          <div className="bingo-info-list">
            {weight.map((square) => (
              <button
                key={square.name}
                className={square === focusedSquare ? "bingo-button-show bingo-button-active" : "bingo-button-show"}
                onClick={() => setActiveSquare(square.name)}
              >
                {square.name}
              </button>
            ))}
          </div>
        </Fragment>
      ))}
    </>
  );
}
