import { Link } from "react-router-dom";
import { Category } from "../../types/Category";
import { anyNoPWExpert } from "../routes/anynopwexpert";
import { anyNoPWBeginner } from "../routes/anynopwbeginner";

export const anyPercentNoPeachWarp: Category = {
  name: "Any% (No Peach Warp)",
  path: "Any_(No_Peach_Warp)",
  description: (
    <>
      Any% (No Peach Warp) is a speedrun category where the goal is to beat Paper Mario as fast as possible without
      using <Link to="/Peach_Warp">Peach Warp</Link>.
    </>
  ),
  history: (
    <>
      Any% (No Peach Warp) was created after <Link to="/Peach_Warp">Peach Warp</Link> was discovered. Peach Warp made a
      large impact on <Link to="/Any_(No_ACE)">Any%</Link> that many runners didn't like. As a result, Any% (No Peach
      Warp) was created to be the "old <Link to="/Any_(No_ACE)">Any%</Link>" category that many runners enjoyed.
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#Any_No_PW",
  videoTutorials: [
    {
      author: "iycewynd (Part 1)",
      url: "https://www.youtube.com/watch?v=BBECVy9oqWU",
    },
    {
      author: "iycewynd (Part 2)",
      url: "https://www.youtube.com/watch?v=2Yv66Ex6WBc",
    },
  ],
  routes: [
    {
      level: "Beginner",
      path: "Beginner",
      tag: "No Jr Skip",
      external: "https://docs.google.com/document/d/1kOs0CMHoJDK94FdxgGiivzo1sS4s3fDit8wcCH5I70Y/edit?usp=sharing",
      description: (
        <>
          This is a beginner <Link to="/Any_(No_Peach_Warp)">Any% (No Peach Warp)</Link> route that has a high chance of
          winning. I recommend that new players save frequently.
        </>
      ),
      versions: "All",
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Log Skip",
          path: "/Tricks/Log_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Piranha Skip",
          path: "/Tricks/Lava_Piranha_Skip",
        },
        {
          name: "Chapter 7 Sushie Glitch",
          path: "/Tricks/Chapter_7_Sushie_Glitch",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Volcano Jump",
          path: "/Tricks/Volcano_Jump",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Murder Solved Early",
          path: "/Tricks/Murder_Solved_Early",
        },
        {
          name: "Ice Staircase Skip",
          path: "/Tricks/Ice_Staircase_Skip",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
      ],
      content: anyNoPWBeginner,
    },
    {
      level: "Expert",
      path: "Expert",
      tag: "Uses Jr Skip and Item Duplication",
      external: "https://docs.google.com/document/d/1AcfEoC_QXNUwnc2KJlAPBML4AzQRAWJwG4AgYyYnTbY/edit?usp=sharing",
      description: (
        <>
          This is an Expert <Link to="/Any_(No_Peach_Warp)">Any% (No Peach Warp)</Link> route that has a high chance to
          win. It uses <Link to="/Item_Duplication">Item Duplication</Link> to skip getting the Repel Gel on the beach.
          Familiarity with the route and category are expected.
        </>
      ),
      versions: "NTSC-J",
      expectedTricks: [
        {
          name: "Jr Skip",
          path: "/Tricks/Jr_Skip",
        },
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Log Skip",
          path: "/Tricks/Log_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Lava Piranha Skip",
          path: "/Tricks/Lava_Piranha_Skip",
        },
        {
          name: "Murder Solved Early",
          path: "/Tricks/Murder_Solved_Early",
        },
        {
          name: "Chapter 7 Sushie Glitch",
          path: "/Tricks/Chapter_7_Sushie_Glitch",
        },
        {
          name: "Ice Staircase Skip",
          path: "/Tricks/Ice_Staircase_Skip",
        },
        {
          name: "Mirror Clip",
          path: "/Tricks/Mirror_Clip",
        },
        {
          name: "Kooper Puzzle Skip",
          path: "/Tricks/Kooper_Puzzle_Skip",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Volcano Jump",
          path: "/Tricks/Volcano_Jump",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
      ],
      content: anyNoPWExpert,
    },
    {
      level: "Expert",
      tag: "Risky - Skips Triple Dip and Item Duplication",
      external: "https://docs.google.com/document/d/1-a2yxuUKUQ1c8Y-5lzx6ycDWwzoQjbpVSdDKlPXZR4c/edit",
    },
    {
      level: "WR Route",
      tag: "Risky - Seed Dupe",
      external: "https://docs.google.com/document/d/1jWAD2l8ovKEwlfEOG_RdNAd4choTX-aDB2kOVkVdFXQ/edit?usp=sharing",
    },
  ],
};
