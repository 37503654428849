export enum InstructionTypes {
  Text = "text",
  Fight = "fight",
  LevelUp = "levelup",
  Heading = "heading",
  Rebadge = "rebadge",
  Image = "image",
}

export type Stat = "fp" | "bp" | "hp";

export type Instruction = {
  type: InstructionTypes;
  content?: string;
  bold?: boolean;
  space?: boolean;
  stat?: Stat;
  directions?: string[];
  hp?: number;
  fp?: number;
  bp?: number;
  conditions?: {[key: string]: boolean};
};

export type RouteComponentProps = {
  instruction: Instruction;
};

export type UserRoute = {
  name: string;
  description: string;
  instructions: Instruction[];
  variables?: string[];
};
