import { RouteComponentProps } from "../../types/RouteComponents";
import { parseContent } from "../../utilities/parseContent";

export const RouteText = ({ instruction }: RouteComponentProps) => {
  const { bold, content, space } = instruction;

  return (
    <>
      <p className={bold ? "route-instruction-bold" : "route-instruction"}>{parseContent(content || "")}</p>
      {space && <br />}
    </>
  );
};
