import { useState } from "react";
import { Instruction } from "../../types/RouteComponents";

type RouteEditorVariableMenuProps = {
  variables: string[];
  setVariables: (newVariables: string[]) => void;
  instructions: Instruction[];
  setInstructions: (newInstructions: Instruction[]) => void;
};

export const RouteEditorVariableMenu = ({
  variables,
  setVariables,
  instructions,
  setInstructions,
}: RouteEditorVariableMenuProps) => {
  const [input, setInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const addVariableToRoute = () => {
    if (input.length < 1 || variables.find((variable) => variable === input)) {
      setErrorMessage("Variable names must be unique and cannot be blank.");
      return;
    }
    let newVariables = [...variables];
    newVariables.push(input);
    setVariables(newVariables);
    setErrorMessage(undefined);
  };
  const removeVariable = (variable: string) => {
    let newVariables = [...variables];
    newVariables = newVariables.filter((vrbl) => vrbl !== variable);
    setVariables(newVariables);
    let newInstructions = [...instructions];
    newInstructions.forEach((instruction) => {
      if (instruction.conditions && Object.keys(instruction.conditions).find((key) => key === variable)) {
        delete instruction.conditions[variable];
      }
    });
    setInstructions(newInstructions);
  };

  return (
    <>
      <div>
        Name:{" "}
        <input
          type="text"
          className="route-input"
          style={{ minWidth: "18rem" }}
          onKeyDown={(e) => e.key === "Enter" && addVariableToRoute()}
          onChange={(e) => {
            setInput(e.target.value);
          }}
          value={input}
        ></input>
        <button className="route-editor-control" onClick={addVariableToRoute}>
          Add
        </button>
      </div>
      {errorMessage && <p>{errorMessage}</p>}
      {variables && (
        <dl className="route-list">
          <dt className="detail-title" style={{ margin: "0 0 0.5rem 0" }}>
            Variables:
          </dt>
          {variables.map((variable) => (
            <li style={{ margin: "0 0 0.5rem 0.5rem", display: "flex" }} key={`variable-${variable}`}>
              <span style={{ width: "12rem" }}>{variable}</span>
              <button onClick={() => window.confirm("Are you sure?") && removeVariable(variable)}>
                Remove Variable
              </button>
            </li>
          ))}
        </dl>
      )}
    </>
  );
};
