export default function Footer() {
  return (
    <footer className="footer">
      <p className="footertext">
        Paper Mario Archives developed by{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://twitch.tv/starry_arie">
          Starry_Arie
        </a>
        . All Rights Reserved.
      </p>
    </footer>
  );
}
