import { Link } from "react-router-dom";
import { useEffect } from "react";
import { PageProps } from "../types/PageProps";
import { categories } from "../data/categories";

export default function RoutesPage({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Routes");
  });
  return (
    <>
      <h1 className="heading">Routes</h1>
      <p>
        Since the discovery of <Link to="/Seed_Duplication">Seed Duplication</Link>, a glitch that saves time in many
        categories, we are currently working on creating and polishing routes with the new strategies. Thank you for
        your patience!
      </p>
      <div>
        {categories.map((category, i) => (
          <ul className="route-list" key={`category-${i}`}>
            <li className="route-title">{category.name}</li>
            {category.routes.map((route, j) => (
              <li className="list-link" key={`route-${j}`}>
                {route.path ? (
                  <Link to={`/Categories/${category.path}/${route.path}`}>{route.level}</Link>
                ) : (
                  <a target="_blank" rel="noopener noreferrer" href={route.external}>
                    {route.level}
                  </a>
                )}{" "}
                - {route.tag}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </>
  );
}
