export const noMajorSequenceBreaks = {
  name: "No Major Sequence Breaks",
  path: "No_Major_Sequence_Breaks",
  description: (
    <>
      No Major Sequence Breaks is a category that aims to play through the whole game in the intended order while also
      incorporating many glitches. It serves as a middle ground between Glitchless and the more glitch-heavy categories,
      and resembles old All Cards routes.
    </>
  ),
  history: (
    <>
      No Major Sequence Breaks was created and designed in the Paper Mario Speedrunning Discord in mid-2021 mostly by
      JCog, mx_four, and Arie. It started off on the extension leaderboards and was moved to the main leaderboards in
      January 2022.
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#No_Major_Sequence_Breaks",
  routes: [
    {
      level: "Intermediate",
      tag: "No Jr Skip - Many tricks are optional",
      external: "https://docs.google.com/document/d/1F5-ocwjw9b1e_M9mz-Psqchcw_6qY28gbt78wS65GH0/edit?usp=sharing",
    },
    {
      level: "Expert",
      tag: "Jr Skip - Expects most tricks",
      external: "https://docs.google.com/document/d/1wvZZymFfsJAvz83sLDe9DZdRO2Uvj0n8603j0Hn45cA/edit?usp=sharing",
    },
  ],
};
