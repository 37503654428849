import { Category } from "../../types/Category";

export const oneHundredPercent: Category = {
  name: "100%",
  path: "100",
  description: (
    <>
      Beat Paper Mario as fast as possible while also touching all 7 star spirit cards and collecting all star pieces,
      badges, and cooking all recipes.
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#100",
  routes: [
    {
      level: "Expert",
      tag: "JP Only - No Jr Skip",
      external: "https://docs.google.com/document/d/1HBlWDHPYydLwwHy76Gw7BIsZAka0hqAsIS-DW1pC38U/edit",
    },
    {
      level: "WR Route (Old)",
      tag: "JP Only - Uses Jr Skip",
      external: "https://docs.google.com/document/d/1tEIsHHgygfjO75YLcd4ZR0tCr2AYJCEmUC4rx9_ZstM/edit?usp=sharing",
    },
  ],
};
