type DownloadButtonProps = {
  name: string;
  contents: string;
};

export function DownloadButton({ name, contents }: DownloadButtonProps) {
  const blob = new Blob([contents], { type: "text/plain" });
  const url = URL.createObjectURL(blob);

  return (
    <a href={url} download={`${name}.txt`}>
      <button className="bingo-button-show">Download .txt</button>
    </a>
  );
}
