import { Link } from "react-router-dom";
import { Category } from "../../types/Category";
import { anyNoAceBeginner } from "../routes/anynoacebeginner";
import { anyNoAceIntermediate } from "../routes/anynoaceintermediate";
import { anyNoAceExpertNeither } from "../routes/anynoaceexpertneither";
import { anyNoAceExpertJrSkip } from "../routes/anynoaceexpertjrskip";
import { anyNoAceExpertItemDuplication } from "../routes/anynoaceexpertid";
import { anyNoAceExpertBoth } from "../routes/anynoaceexpertboth";
import FourRouteSwitcher from "../../components/FourRouteSwitcher";

export const anyPercentNoAce: Category = {
  name: "Any% (No ACE)",
  path: "Any_(No_ACE)",
  description: (
    <>
      Any% (No ACE) is the standard speedrun category where the goal is to beat Paper Mario{" "}
      <Link to="/Arbitrary_Code_Execution">Arbitrary Code Execution</Link> where the goal is to beat Paper Mario as fast
      as possible. It was separated from Any% in early 2022 when{" "}
      <Link to="/Arbitrary_Code_Execution">Arbitrary Code Execution</Link> without the use of Stop n' Swop was
      discovered.
    </>
  ),
  leaderboards: "https://www.speedrun.com/pm64#Any",
  routes: [
    {
      level: "Beginner",
      path: "Beginner",
      tag: "Safe - Recommended for Beginners",
      external: "https://docs.google.com/document/d/159PzudXOKKRv08L81EaksqjPw5RAdEzIuWmnS1gQdls/edit?usp=sharing",
      description: (
        <>
          This is a beginner <Link to="/Categories/Any_(No_ACE)">Any% (No ACE)</Link> route that also has a safe ending.
          If you can hit your action commands, you will always win. I recommend that new players save frequently.
        </>
      ),
      versions: "All",
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Log Skip",
          path: "/Tricks/Log_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Piranha Skip",
          path: "/Tricks/Lava_Piranha_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
        {
          name: "Lakilester Peach Warp",
          path: "/Tricks/Lakilester_Peach_Warp",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
      ],
      optionalTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Volcano Jump",
          path: "/Tricks/Volcano_Jump",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      content: anyNoAceBeginner,
    },
    {
      level: "Intermediate",
      path: "Intermediate",
      tag: "Safe - Highly optimized",
      external: "https://docs.google.com/document/d/1YZAJ8mosO3bDzfeLCV8WxLjkqyna-y9U2ZnNXMMLz8I/edit?usp=sharing",
      description: (
        <>
          This is an intermediate <Link to="/Categories/Any_(No_ACE)">Any% (No ACE)</Link> route that also has a safe
          ending. If you can hit your action commands, you will always win. This route expects familiarity with the
          category, and uses some slightly faster strategies compared to the{" "}
          <Link to="/Categories/Any_(No_ACE)/Beginner">Beginner</Link> route.
        </>
      ),
      versions: "All",
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Log Skip",
          path: "/Tricks/Log_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Piranha Skip",
          path: "/Tricks/Lava_Piranha_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
        {
          name: "Lakilester Peach Warp",
          path: "/Tricks/Lakilester_Peach_Warp",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Volcano Jump",
          path: "/Tricks/Volcano_Jump",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Mega Smash Early",
          path: "/Tricks/Mega_Smash_Early",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
      ],
      content: anyNoAceIntermediate,
    },
    {
      level: "Expert",
      path: "Expert",
      tag: "Risky - Optional Jr Skip and Item Duplication",
      external: "https://docs.google.com/spreadsheets/d/1GTftQAvIoh3HMxgAN41xof2Dr1CbmY4KyybWDGjnxWU/edit?usp=sharing",
      description: (
        <>
          This is an expert <Link to="/Any_(No_ACE)">Any% (No ACE)</Link> route that has a risky ending. Basic
          familiarity with the game and category is expected. <Link to="/Jr_Skip">Jr Skip</Link> and{" "}
          <Link to="/Item_Duplication">Item Duplication</Link> are optional. This route does not do{" "}
          <Link to="/Tricks/Seed_Duplication">Seed Duplication</Link>, and is thus outdated. However, since{" "}
          <Link to="/Tricks/Seed_Duplication">Seed Duplication</Link> introduces a much higher difficulty level thanks
          to <Link to="/Tricks/Clippy Boots">Double Clippy Boots</Link>, this route is still recommended for runners who
          aren't ready for the newest strategies.
        </>
      ),
      versions: "All (No Item Duplication), NTSC-J (Item Duplication)",
      requiredTricks: [
        {
          name: "Staircase Skip",
          path: "/Tricks/Staircase_Skip",
        },
        {
          name: "Log Skip",
          path: "/Tricks/Log_Skip",
        },
        {
          name: "Blue House Skip",
          path: "/Tricks/Blue_House_Skip",
        },
        {
          name: "Raphael Skip",
          path: "/Tricks/Raphael_Skip",
        },
        {
          name: "Lava Piranha Skip",
          path: "/Tricks/Lava_Piranha_Skip",
        },
        {
          name: "Early Lakilester",
          path: "/Tricks/Early_Lakilester",
        },
        {
          name: "Lakilester Peach Warp",
          path: "/Tricks/Lakilester_Peach_Warp",
        },
        {
          name: "Block Clip",
          path: "/Tricks/Block_Clip",
        },
        {
          name: "Cannonless",
          path: "/Tricks/Cannonless",
        },
      ],
      optionalTricks: [
        {
          name: "Jr Skip",
          path: "/Tricks/Jr_Skip",
        },
        {
          name: "Black Toad Skip",
          path: "/Tricks/Black_Toad_Skip",
        },
        {
          name: "Rock Climb",
          path: "/Tricks/Rock_Climb",
        },
        {
          name: "Early Seed",
          path: "/Tricks/Early_Seed",
        },
        {
          name: "Lava Puzzle Skip",
          path: "/Tricks/Lava_Puzzle_Skip",
        },
        {
          name: "Ultra Hammer Early",
          path: "/Tricks/Ultra_Hammer_Early",
        },
        {
          name: "Flarakarry",
          path: "/Tricks/Flarakarry",
        },
        {
          name: "Fast Flood Room",
          path: "/Tricks/Fast_Flood_Room",
        },
      ],
      content: (
        <>
          <dt className="detail-title">
            This route has variations for <Link to="/Jr_Skip">Jr Skip</Link> and{" "}
            <Link to="/Item_Duplication">Item Duplication</Link>. You can enable these tricks by clicking the toggles
            below.
          </dt>
          <FourRouteSwitcher
            route1={anyNoAceExpertNeither}
            route2={anyNoAceExpertJrSkip}
            route3={anyNoAceExpertItemDuplication}
            route4={anyNoAceExpertBoth}
            toggle1={"Jr Skip"}
            toggle2={"Item Duplication"}
          />
        </>
      ),
    },
    {
      level: "Expert",
      tag: "Safe - Seed Dupe, No Jr Skip or Item Dupe",
      external: "https://docs.google.com/document/d/1Z7ZUQyFrEcU0b0PIAX_XkMdmhwdAug5WS7YGPiVWcGw/edit?usp=sharing",
    },
    {
      level: "Expert",
      tag: "Risky - Jr Skip, Item Dupe, Seed Dupe",
      external: "https://docs.google.com/document/d/10SK2pSpRISb2njzlOn5coGBzgPgvjf1tmKwBsWvi8a0/edit?usp=sharing",
    },
    {
      level: "WR Route",
      tag: "Risky - Skips Parakarry",
      external: "https://docs.google.com/document/d/1u-KnyJW5t_U3LNqNMcszTJ9XbT65BKV3YkiWPB3QU_w/edit?usp=sharing",
    },
    {
      level: "WR Route (Old)",
      tag: "Very Risky - Uses Time Out on Hallway Bowser",
      external: "https://docs.google.com/document/d/15DbjABuB2GztiPToAtxgJcpbsZ5w2Izdn2dUjKl6u_A/edit",
    },
  ],
};
