import { Link } from "react-router-dom";
import { useEffect } from "react";
import { PageProps } from "../types/PageProps";

export default function Tips({ setActive }: PageProps) {
  useEffect(() => {
    setActive("Tips");
  });
  const items = [
    {
      title: <>Text</>,
      content: (
        <>
          The fastest way to scroll text in Paper Mario is by holding B. If you need to say no to any tutorials, mash B
          to clear them quickly.
        </>
      ),
    },
    {
      title: <>Movement</>,
      content: (
        <>
          The fastest way to move is to spin (Z) and tap jump (A) at the end of each spin to cancel the slowdown. Spins
          can also be canceled by small ledges, which means that the fastest way from point A to point B isn't always a
          straight line.
        </>
      ),
    },
    {
      title: <>Quick Jumps</>,
      content: (
        <>
          <Link to="/Quick_Jumps">Quick Jumps</Link> are a technique that lets Mario jump a little faster in battle.
          Simply press A while Mario is crouching before each jump to make him jump immediately!
        </>
      ),
    },
    {
      title: <>Chapter Transition Screens</>,
      content: <>For the "Start of Chapter" screens, mash A and START to clear them quickly.</>,
    },
    {
      title: <>Badges</>,
      content: <>When taking badges on or off, always exit the menu by pressing START rather than mashing B.</>,
    },
    {
      title: <>Extra Encounters</>,
      content: (
        <>
          If you accidentally encounter an enemy, run away! Don't kill any enemies that the route doesn't say to kill,
          because it can mess up your Star Points.
        </>
      ),
    },
    {
      title: <>Backup Coins</>,
      content: (
        <>
          If you are short on coins to buy Speedy Spin, check out the backups <Link to="/Backup_Coins">here</Link>.
        </>
      ),
    },
    {
      title: <>Saving</>,
      content: (
        <>
          Always save whenever possible if you are new to the game! There are lots of things that can go wrong while
          learning, so don't be afraid to hit a save block.
        </>
      ),
    },
    {
      title: <>Route Directions</>,
      content: (
        <>
          Always follow route directions in the order that they are written! If something is written strangely, such as
          attacking with the partner before Mario, there is likely a reason for that.
        </>
      ),
    },
    {
      title: <>Timing</>,
      content: (
        <>
          Timing begins when you select "Yes" to start a new save file. It ends when the final text box clears and
          Peach's hands are raised during the epilogue.
        </>
      ),
    },
    {
      title: <>Versions</>,
      content: (
        <>
          We recommend playing on the Japanese version if possible. The English version of Paper Mario has slower text
          and a few changes that make some glitches impossible and others much harder. Most routes can be run on either
          version, but there are a few that only work on the Japanese version.
        </>
      ),
    },
    {
      title: <>Consoles</>,
      content: (
        <>
          The Nintendo 64 is the most popular console for Paper Mario. This is because the Nintendo 64 plays smoothly
          and has no input lag. The Wii U and Switch versions are the fastest, but the Wii U has input issues and the
          Switch has a strange audio delay. The Wii is not recommended because of programmed speedups during laggy
          sections, which can make Action Commands unreliable on General Guy and Lava Piranha.
        </>
      ),
    },
    {
      title: <>Practice Rom</>,
      content: (
        <>
          The{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/JCog/fp">
            Practice Rom
          </a>{" "}
          is the quintessential tool for practicing Paper Mario! With it, you can easily practice blocking, movement,
          and glitches!
        </>
      ),
    },
    {
      title: <>Leaderboards</>,
      content: (
        <>
          Our leaderboards can be found{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://www.speedrun.com/pm64">
            here
          </a>
          .
        </>
      ),
    },
    {
      title: <>Other Questions?</>,
      content: (
        <>
          Come to the{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/3RmB33dxDH">
            Paper Mario Speedrunning Discord Server
          </a>{" "}
          if you have any questions! We would be happy to answer any other questions you may have. Also, check out the{" "}
          <Link to="/Resources">Resources</Link> page for other helpful tools!
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="heading">Tips for Beginners</h1>
      <dl className="list">
        <dt className="detail-title">
          Welcome to PaperMarioArchives! If you're new to speedrunning Paper Mario, here are several tips that will help
          you regardless of which category you start with.
        </dt>
        {items.map((item, i) => (
          <div key={i}>
            <br />
            <dt className="detail-title">{item.title}</dt>
            <dt>{item.content}</dt>
          </div>
        ))}
      </dl>
    </>
  );
}
