import { useState, useEffect, useCallback } from "react";
import { PageProps } from "../types/PageProps";
import bingoSquares, { BingoSquare } from "../data/bingoSquares";
import BingoButton from "../components/BingoButton";

export default function BingoGenerator({ setActive }: PageProps) {
  useEffect(() => {
    setActive("");
  });

  const [seed, setSeed] = useState<number>();
  const [input, setInput] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [card, setCard] = useState<Array<Array<BingoSquare>>>([]);
  const [focusedSquares, setFocusedSquares] = useState<BingoSquare[]>([]);
  const [infos, setInfos] = useState<JSX.Element>(<></>);

  const HandleChange = (e: any) => {
    setInput(e.target.value);
  };

  const clearSquares = () => {
    for (let i = 1; i < 26; i++) {
      localStorage?.setItem(`square-${i}`, "false");
    }
  };

  const GenerateSetSeed = () => {
    if (Number.isNaN(Number(input))) {
      setCard([]);
      setErrorMessage("Invalid seed. Please enter a positive number.");
    } else {
      setErrorMessage(undefined);
      setSeed(Number(input));
      clearSquares();
      generateCard(Number(input));
    }
  };

  const GenerateRandomSeed = () => {
    setErrorMessage(undefined);
    let newSeed = Math.random() * 1000000;
    setInput(`${newSeed - (newSeed % 1)}`);
    setSeed(newSeed - (newSeed % 1));
    clearSquares();
    generateCard(newSeed - (newSeed % 1));
  };

  const magicSquare = (seed: number): (number | BingoSquare)[][] => {
    let second = (seed / 100000 - ((seed / 100000) % 1)) % 10;
    let used = [false, false, false, false, false];
    let highOrderSquare = [
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
    ];
    let lowOrderSquare = [
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
    ];
    for (let i = 0; i < 5; i++) {
      let placed = false;
      let identifier = (seed / (10 ^ i) - ((seed / (10 ^ i)) % 1)) % 5;
      while (placed === false) {
        if (identifier === 0 && !used[0]) {
          highOrderSquare[0][0] = i * 5;
          highOrderSquare[1][2] = i * 5;
          highOrderSquare[2][4] = i * 5;
          highOrderSquare[3][1] = i * 5;
          highOrderSquare[4][3] = i * 5;
          used[0] = true;
          placed = true;
        } else if (identifier === 1 && !used[1]) {
          highOrderSquare[0][1] = i * 5;
          highOrderSquare[1][3] = i * 5;
          highOrderSquare[2][0] = i * 5;
          highOrderSquare[3][2] = i * 5;
          highOrderSquare[4][4] = i * 5;
          used[1] = true;
          placed = true;
        } else if (identifier === 2 && !used[2]) {
          highOrderSquare[0][2] = i * 5;
          highOrderSquare[1][4] = i * 5;
          highOrderSquare[2][1] = i * 5;
          highOrderSquare[3][3] = i * 5;
          highOrderSquare[4][0] = i * 5;
          used[2] = true;
          placed = true;
        } else if (identifier === 3 && !used[3]) {
          highOrderSquare[0][3] = i * 5;
          highOrderSquare[1][0] = i * 5;
          highOrderSquare[2][2] = i * 5;
          highOrderSquare[3][4] = i * 5;
          highOrderSquare[4][1] = i * 5;
          used[3] = true;
          placed = true;
        } else if (identifier === 4 && !used[4]) {
          highOrderSquare[0][4] = i * 5;
          highOrderSquare[1][1] = i * 5;
          highOrderSquare[2][3] = i * 5;
          highOrderSquare[3][0] = i * 5;
          highOrderSquare[4][2] = i * 5;
          used[4] = true;
          placed = true;
        }
        identifier = identifier + 1;
        if (identifier === 5) {
          identifier = 0;
        }
      }
    }
    used = [false, false, false, false, false];
    for (let i = 0; i < 5; i++) {
      let placed = false;
      let identifier = (seed / (10 ^ i) - ((seed / (10 ^ i)) % 1) + second * (i + 1)) % 5;
      while (placed === false) {
        if (identifier === 0 && !used[0]) {
          lowOrderSquare[0][0] = i + 1;
          lowOrderSquare[1][3] = i + 1;
          lowOrderSquare[2][1] = i + 1;
          lowOrderSquare[3][4] = i + 1;
          lowOrderSquare[4][2] = i + 1;
          used[0] = true;
          placed = true;
        } else if (identifier === 1 && !used[1]) {
          lowOrderSquare[0][1] = i + 1;
          lowOrderSquare[1][4] = i + 1;
          lowOrderSquare[2][2] = i + 1;
          lowOrderSquare[3][0] = i + 1;
          lowOrderSquare[4][3] = i + 1;
          used[1] = true;
          placed = true;
        } else if (identifier === 2 && !used[2]) {
          lowOrderSquare[0][2] = i + 1;
          lowOrderSquare[1][0] = i + 1;
          lowOrderSquare[2][3] = i + 1;
          lowOrderSquare[3][1] = i + 1;
          lowOrderSquare[4][4] = i + 1;
          used[2] = true;
          placed = true;
        } else if (identifier === 3 && !used[3]) {
          lowOrderSquare[0][3] = i + 1;
          lowOrderSquare[1][1] = i + 1;
          lowOrderSquare[2][4] = i + 1;
          lowOrderSquare[3][2] = i + 1;
          lowOrderSquare[4][0] = i + 1;
          used[3] = true;
          placed = true;
        } else if (identifier === 4 && !used[4]) {
          lowOrderSquare[0][4] = i + 1;
          lowOrderSquare[1][2] = i + 1;
          lowOrderSquare[2][0] = i + 1;
          lowOrderSquare[3][3] = i + 1;
          lowOrderSquare[4][1] = i + 1;
          used[4] = true;
          placed = true;
        }
        identifier = identifier + 1;
        if (identifier === 5) {
          identifier = 0;
        }
      }
    }
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        lowOrderSquare[i][j] = lowOrderSquare[i][j] + highOrderSquare[i][j];
      }
    }
    return lowOrderSquare;
  };

  const generateCard = useCallback((seed: number) => {
    if (Number.isNaN(seed)) return;
    const newSeed: number = seed as number;
    localStorage.setItem("seed", `${newSeed}`);
    let card = magicSquare(newSeed as number);
    let r1 = [];
    let r2 = [];
    let r3 = [];
    let r4 = [];
    let r5 = [];
    let c1 = [];
    let c2 = [];
    let c3 = [];
    let c4 = [];
    let c5 = [];
    let tlbr = [];
    let bltr = [];
    let start = 25;
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        let xindex = 0;
        let yindex = 0;
        for (let ii = 0; ii < 5; ii++) {
          for (let jj = 0; jj < 5; jj++) {
            if (card[ii][jj] === start) {
              xindex = ii;
              yindex = jj;
            }
          }
        }
        let end = false;
        let temp = bingoSquares[card[xindex][yindex] as number];
        let index;
        if (i % 2 === 1) {
          index = ((newSeed / 100 - ((newSeed / 100) % 1)) % temp.length) - 1;
        }
        index = newSeed % temp.length;
        let indexstart = index;
        let panel;
        let overlap = 0;
        let emergencyExit = 0;
        while (end === false && overlap < 1 && emergencyExit < 3) {
          end = true;
          panel = temp[index];

          if (xindex === 0) {
            for (let k = 0; k < r1.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (r1[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (xindex === 1) {
            for (let k = 0; k < r2.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (r2[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (xindex === 2) {
            for (let k = 0; k < r3.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (r3[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (xindex === 3) {
            for (let k = 0; k < r4.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (r4[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (xindex === 4) {
            for (let k = 0; k < r5.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (r5[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (yindex === 0) {
            for (let k = 0; k < c1.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (c1[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (yindex === 1) {
            for (let k = 0; k < c2.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (c2[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (yindex === 2) {
            for (let k = 0; k < c3.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (c3[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (yindex === 3) {
            for (let k = 0; k < c4.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (c4[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (yindex === 4) {
            for (let k = 0; k < c5.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (c5[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (xindex === yindex) {
            for (let k = 0; k < tlbr.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (tlbr[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (xindex + yindex === 4) {
            for (let k = 0; k < bltr.length; k++) {
              for (let l = 0; l < panel.types.length; l++) {
                if (bltr[k] === panel.types[l]) {
                  end = false;
                }
              }
            }
          }
          if (end === false) {
            index = index + 1;
            if (index === temp.length) {
              index = 0;
              emergencyExit++;
              if (emergencyExit === 3) {
                alert(
                  "exited via Emergency Exit: card position = " +
                    card[i][j] +
                    " and indexstart =" +
                    indexstart +
                    " and overlap =" +
                    overlap +
                    " and temp.length =" +
                    temp.length
                );
              }
            }
            if (index === indexstart) {
              overlap++;
              panel = temp[index];
            }
          }
        }
        let myPanel: BingoSquare = panel as BingoSquare;
        card[xindex][yindex] = myPanel;
        start--;
        if (xindex === 0) {
          for (let l = 0; l < myPanel.types.length; l++) {
            r1.push(myPanel.types[l]);
          }
        }
        if (xindex === 1) {
          for (let l = 0; l < myPanel.types.length; l++) {
            r2.push(myPanel.types[l]);
          }
        }
        if (xindex === 2) {
          for (let l = 0; l < myPanel.types.length; l++) {
            r3.push(myPanel.types[l]);
          }
        }
        if (xindex === 3) {
          for (let l = 0; l < myPanel.types.length; l++) {
            r4.push(myPanel.types[l]);
          }
        }
        if (xindex === 4) {
          for (let l = 0; l < myPanel.types.length; l++) {
            r5.push(myPanel.types[l]);
          }
        }
        if (yindex === 0) {
          for (let l = 0; l < myPanel.types.length; l++) {
            c1.push(myPanel.types[l]);
          }
        }
        if (yindex === 1) {
          for (let l = 0; l < myPanel.types.length; l++) {
            c2.push(myPanel.types[l]);
          }
        }
        if (yindex === 2) {
          for (let l = 0; l < myPanel.types.length; l++) {
            c3.push(myPanel.types[l]);
          }
        }
        if (yindex === 3) {
          for (let l = 0; l < myPanel.types.length; l++) {
            c4.push(myPanel.types[l]);
          }
        }
        if (yindex === 4) {
          for (let l = 0; l < myPanel.types.length; l++) {
            c5.push(myPanel.types[l]);
          }
        }
        if (xindex === yindex) {
          for (let l = 0; l < myPanel.types.length; l++) {
            tlbr.push(myPanel.types[l]);
          }
        }
        if (xindex + yindex === 4) {
          for (let l = 0; l < myPanel.types.length; l++) {
            bltr.push(myPanel.types[l]);
          }
        }
      }
      setCard(card as BingoSquare[][]);
    }
  }, []);

  const sortedSquares = bingoSquares.map((weight) => {
    return weight.sort((a, b) => (a.name > b.name ? 1 : -1));
  });

  const allSquares = sortedSquares
    .reduce((sortedList, currentList) => currentList.concat(sortedList))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const setActiveSquares = (name: string) => {
    if (focusedSquares.find((square) => square?.name === name)) {
      let newFocusedSquares: BingoSquare[] = Array<BingoSquare>().concat(focusedSquares);
      newFocusedSquares = newFocusedSquares.filter((square) => square.name !== name);
      setFocusedSquares(newFocusedSquares);
    } else {
      let newFocusedSquares: BingoSquare[] = Array<BingoSquare>().concat(focusedSquares);
      newFocusedSquares.push(allSquares.find((square) => square.name === name) as BingoSquare);
      setFocusedSquares(newFocusedSquares);
    }
  };

  useEffect(() => {
    let initialSeed: number | "" | null = null;
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      initialSeed = localStorage?.getItem("seed") && Number(localStorage?.getItem("seed"));
    }
    if (initialSeed) {
      setInput(`${initialSeed}`);
      setSeed(initialSeed);
      generateCard(initialSeed);
    }
  }, [generateCard]);

  useEffect(() => {
    setInfos(
      <>
        {focusedSquares.map((focusedSquare) => (
          <div key={focusedSquare.name} className="bingo-content">
            {focusedSquare?.info}
          </div>
        ))}
      </>
    );
  }, [focusedSquares]);

  return (
    <>
      <h1 className="heading">Bingo Generator</h1>
      <br />
      <label>
        Seed:&nbsp;
        <input className="input-box" id="seedInput" type="text" value={input ? input : ""} onChange={HandleChange} />
      </label>
      <br />
      <br />
      <button className="card-generator-button" onClick={GenerateSetSeed}>
        Generate using Seed
      </button>
      <br />
      <br />
      <button className="card-generator-button" onClick={GenerateRandomSeed}>
        Generate random Seed
      </button>
      <br />
      <br />
      {errorMessage && <div>{errorMessage}</div>}
      {card.length !== 0 && (
        <>
          <table className="btabletop">
            <tbody>
              <tr className="bingo-row">
                <BingoButton name={card[0][0].name} position={"square-1"} />
                <BingoButton name={card[0][1].name} position={"square-2"} />
                <BingoButton name={card[0][2].name} position={"square-3"} />
                <BingoButton name={card[0][3].name} position={"square-4"} />
                <BingoButton name={card[0][4].name} position={"square-5"} />
              </tr>
              <tr className="bingo-row">
                <BingoButton name={card[1][0].name} position={"square-6"} />
                <BingoButton name={card[1][1].name} position={"square-7"} />
                <BingoButton name={card[1][2].name} position={"square-8"} />
                <BingoButton name={card[1][3].name} position={"square-9"} />
                <BingoButton name={card[1][4].name} position={"square-10"} />
              </tr>
              <tr className="bingo-row">
                <BingoButton name={card[2][0].name} position={"square-11"} />
                <BingoButton name={card[2][1].name} position={"square-12"} />
                <BingoButton name={card[2][2].name} position={"square-13"} />
                <BingoButton name={card[2][3].name} position={"square-14"} />
                <BingoButton name={card[2][4].name} position={"square-15"} />
              </tr>
              <tr className="bingo-row">
                <BingoButton name={card[3][0].name} position={"square-16"} />
                <BingoButton name={card[3][1].name} position={"square-17"} />
                <BingoButton name={card[3][2].name} position={"square-18"} />
                <BingoButton name={card[3][3].name} position={"square-19"} />
                <BingoButton name={card[3][4].name} position={"square-20"} />
              </tr>
              <tr className="bingo-row">
                <BingoButton name={card[4][0].name} position={"square-21"} />
                <BingoButton name={card[4][1].name} position={"square-22"} />
                <BingoButton name={card[4][2].name} position={"square-23"} />
                <BingoButton name={card[4][3].name} position={"square-24"} />
                <BingoButton name={card[4][4].name} position={"square-25"} />
              </tr>
            </tbody>
          </table>
          <p className="route-instruction-bold">Seed: {seed}</p>
          <br />
          <h1 className="heading">Hints</h1>
          <br />
          {infos}
          <br />
          <div className="bingo-info-list">
            {allSquares.map(
              (square, i) =>
                card.flat().find((card) => card === square) && (
                  <div key={i}>
                    <button
                      className={
                        focusedSquares.find((focusedSquare) => focusedSquare === square)
                          ? "bingo-button-show bingo-button-active"
                          : "bingo-button-show"
                      }
                      onClick={() => setActiveSquares(square.name)}
                    >
                      {square.name}
                    </button>
                  </div>
                )
            )}
          </div>
        </>
      )}
    </>
  );
}
