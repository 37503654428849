import { Category } from "../../types/Category";

export const reverseAllCards: Category = {
  name: "Reverse All Cards",
  path: "Reverse_All_Cards",
  description: (
    <>
      Beat Paper Mario as fast as possible while also touching all 7 star spirit cards in reverse order (7, 6, 5, 4, 3,
      2, 1).
    </>
  ),
  history: (
    <>It was discovered that Reverse All Cards could be completed in 2018. It was added to the leaderboards in 2020.</>
  ),
  leaderboards: "https://www.speedrun.com/pm64",
  routes: [
    {
      level: "Expert (Old)",
      tag: "SwayNC's Route - Highly Detailed",
      external: "https://docs.google.com/document/d/1QeXb_aaIwT2Oa_IW0MESi2emWoDNZQd-Pp53OFjOFCE/edit",
    },
    {
      level: "Expert (Old)",
      tag: "Expects familiarity with the category",
      external: "https://docs.google.com/document/d/1E4M8MRt_ivsC7p16kMKF15po4RiLVGAPCN8-f8rOZu0/edit?usp=sharing",
    },
    {
      level: "WR Route (Old)",
      tag: "JP Only - Key Item Duplication and Bow Skip",
      external: "https://docs.google.com/document/d/1okJAM5H-XeIFjSHyRLLljGgNP9YZ3XaYKnKkNou5Ew4/edit?usp=sharing",
    },
  ],
};
